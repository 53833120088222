import { useEffect, useMemo } from 'react'
import {
  Filter,
  SearchStrFilter,
  TimeFilter,
  useFilterReducer
} from '../../hooks/filter'
import { useAppDispatch } from '../../store/hooks'
import { SportTypeValues, sportTypes } from '../sports/data_types'
import { useUserFormattedTeams } from '../teams/hooks'
import { SessionTypeValues, sessionTypes } from './data_types'
import { useSessions } from './hooks'
import { SessionStateTypeValues, sessionStateTypes } from './states/data_types'
import {
  SubSessionTypeValues,
  subSessionTypes
} from './sub_sessions/data_types'
import { SessionFilterRequestBody } from './types'
import { getFilteredSessions } from './actions'

export interface SessionFilters {
  team: Filter<string | 'All'>
  startTime: TimeFilter
  endTime: TimeFilter
  limitQuantity: Filter<number>
  type: Filter<SessionTypeValues | 'All'>
  subType: Filter<SubSessionTypeValues | 'All'>
  searchStr: SearchStrFilter
  state: Filter<SessionStateTypeValues | 'All'>
  sportType: Filter<SportTypeValues | 'All'>
}

export const useSessionFilter = () => {
  const dispatch = useAppDispatch()

  const teams = useUserFormattedTeams()
  const { filter, rawData } = useSessions()

  // Set initial session filter state //
  // TODO: initial values could be passed in as arguments - this allows for flexible use across the application //
  // - set up a separate hook for the activity list page that uses the filter values in redux //
  const initialSessionFilterState: SessionFilters = {
    team: {
      options: teams.optionsWithAll,
      value: filter.options.team
    },
    startTime: {
      value: filter.options.startTime
    },
    endTime: {
      value: filter.options.endTime
    },
    limitQuantity: {
      options: [
        { name: '10', value: 10 },
        { name: '25', value: 25 },
        { name: '50', value: 50 },
        { name: '100', value: 100 }
      ],
      value: filter.options.limitQuantity
    },
    type: {
      options: sessionTypes.optionsWithAll,
      value: filter.options.type
    },
    subType: {
      options: subSessionTypes.optionsWithAll,
      value: filter.options.subType
    },
    searchStr: {
      value: filter.options.searchStr
    },
    state: {
      options: sessionStateTypes.optionsWithAll,
      value: filter.options.state
    },
    sportType: {
      options: sportTypes.optionsWithAll,
      value: filter.options.sportType
    }
  }

  const { filters, updateFilterValue, updateFilterOptions } =
    useFilterReducer<SessionFilters>(initialSessionFilterState)

  // If new teams available, update team filter options //
  useEffect(() => {
    updateFilterOptions('team', teams.optionsWithAll)
  }, [teams])

  // Generate request body for BE filter API request //
  const generateFilterOptionsRequestBody = (
    filters: SessionFilters
  ): SessionFilterRequestBody => {
    return {
      team: filters.team.value,
      startTime: filters.startTime.value,
      endTime: filters.endTime.value,
      limitQuantity: filters.limitQuantity.value,
      type: filters.type.value,
      subType: filters.subType.value,
      searchStr: filters.searchStr.value,
      state: filters.state.value,
      sportType: filters.sportType.value
    }
  }

  // Fire API request if BE filter values change or data is no longer valid (session ended) //
  useEffect(() => {
    dispatch(getFilteredSessions(generateFilterOptionsRequestBody(filters)))
  }, [
    filters.team.value,
    filters.startTime.value,
    filters.endTime.value,
    filters.limitQuantity.value
  ])

  useEffect(() => {
    if (!filter.hasValidData) {
      dispatch(getFilteredSessions(generateFilterOptionsRequestBody(filters)))
    }
  }, [filter.hasValidData])

  // Sort Sessions //
  const sortedSessions = useMemo(() => {
    const items = Object.values(rawData)
    let sessions = items.filter((session) => {
      if (filters.type.value === 'All') return true
      return filters.type.value === session.type
    })

    sessions = sessions.filter((session) => session.id)

    sessions = sessions.filter((session) => {
      if (filters.sportType.value === 'All') return true
      return filters.sportType.value === session.sportType
    })

    sessions = sessions.filter((session) => {
      if (filters.subType.value === 'All') return true
      return filters.subType.value === session.subType
    })

    sessions = sessions.filter((session) => {
      if (filters.state.value === 'All') {
        return session.state !== sessionStateTypes.items.notStarted.value
      }
      return filters.state.value === session.state
    })

    sessions = sessions.filter((session) => {
      const sessionName = session.name.toLowerCase()
      const searchStr = filters.searchStr.value.toLowerCase()
      return sessionName.search(searchStr) >= 0
    })

    sessions = sessions.filter((session) => {
      if (filters.team.value === 'All') return true
      return session.teamsSessions.some(
        (teamSession) => teamSession.teamId === filters.team.value
      )
    })

    sessions = sessions.filter((session) => {
      return session.startTime >= filters.startTime.value
    })

    sessions = sessions.filter((session) => {
      return session.startTime <= filters.endTime.value
    })

    sessions = sessions.sort((a, b) => {
      if (a.startTime > b.startTime) return -1
      if (a.startTime < b.startTime) return 1
      return 0
    })

    return sessions
  }, [filters, rawData])

  return {
    filters,
    updateFilterValue,
    sortedSessions
  }
}
