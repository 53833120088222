import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../components/Loader/Loader'
import Modal from '../components/Modal/Modal'
import MqttProvider from '../metrics_server/mqtt/Provider'

import * as userActions from '../metrics_server/user/actions'
import * as ui from '../ui'
import * as sessionActions from '../metrics_server/sessions/actions'

import StatusBar from '../components/StatusBar/StatusBar'
import * as versionActions from '../metrics_server/version/actions'
import * as pitchActions from '../metrics_server/pitches/actions'
import * as sportscasterActions from '../metrics_server/sportscaster/actions'
import * as statusActions from '../metrics_server/status/actions'
import { UserState } from '../metrics_server/user/types'
import { VersionState } from '../metrics_server/version/types'
import { isLocal } from '../metrics_server/env'

let actions = {
  ...versionActions,
  ...sportscasterActions,
  ...userActions,
  ...pitchActions,
  ...statusActions,
  ...sessionActions
}

for (const key in ui.actions) {
  actions = { ...actions, ...ui.actions[key] }
}

interface MSServerHOCProps {
  // MS
  version: VersionState
  user: UserState

  getPitchConfig
  getServerVersion
  getHealth
  getDaemonVersion
  getApp
}

export const MSServerHOC: any = (ChildComponent) => {
  class ComposedComponent extends Component<any> {
    // Our component just got rendered
    private healthCheckInterval = null

    componentDidMount() {
      this.props.getServerVersion()
      this.props.getApp()
      isLocal && this.props.getDaemonVersion()
      this.pollMSHealth()

      // Check For Active Session
      this.props.checkActiveSession()
    }

    componentWillUnmount() {
      clearInterval(this.healthCheckInterval)
    }

    pollMSHealth = () => {
      this.healthCheckInterval = setInterval(() => {
        this.props.getHealth()
        this.props.getDBSyncStatus()
      }, 2000)
    }

    componentDidUpdate(prevProps, prevState) {
      const { connected } = this.props.version
      if (!prevProps.version.connected && connected) {
        this.props.getPitchConfig()
        this.props.getStartupErrors()
      } else if (prevProps.version.connected && !connected) {
        window.location.reload()
      }
    }

    render() {
      const { version } = this.props

      return (
        <React.Fragment>
          {/* {isMatchTracker && (

          )} */}
          <StatusBar
            setRedirect={this.props.setRedirect}
            toggleModal={this.props.toggleModal}
            toggleNotifications={this.props.toggleNotifications}
            clearNotificationsHistory={this.props.clearNotificationsHistory}
            clearNotificationInHistory={this.props.clearNotificationInHistory}
            {...this.props}
          />
          {!version.connected ? (
            <div className={'mainView'}>
              <Modal />
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader message='Connecting to server...' />
              </div>
            </div>
          ) : (
            <MqttProvider>
              <ChildComponent {...this.props} />
            </MqttProvider>
          )}
        </React.Fragment>
      )
    }
  }

  function mapStateToProps(state) {
    return {
      version: state.version,
      user: state.user,
      sessions: state.sessions
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
