import React, { Component } from 'react'
import styles from './ColumnForm.module.scss'

import Checkbox from '../Inputs/Checkbox/Checkbox'

export interface ColumnFormState {
  invalidSelection: boolean
}

interface Option {
  name: string
  value: string | number
}

export interface ColumnFormProps {
  title: string
  options: Option[]
  selected: (string | number)[]
  handleChange: (selectedValues: (string | number)[]) => void
  maxSelections?: number
  invalidSelectionMessage?: string
}

export class ColumnForm extends Component<ColumnFormProps, ColumnFormState> {
  constructor(props) {
    super(props)

    this.state = {
      invalidSelection: false
    }
  }

  handleCheckboxChange = (name, value) => {
    const { selected } = this.props
    const { maxSelections, handleChange } = this.props

    let newColumns = [...selected]

    const valueIndex = newColumns.indexOf(value)

    if (maxSelections === 1) {
      newColumns = [value]
    } else {
      if (valueIndex > -1) {
        newColumns.splice(valueIndex, 1)
      } else {
        newColumns.push(value)
        if (newColumns.length > maxSelections) {
          newColumns = newColumns.slice(1, newColumns.length)
        }
      }
    }

    this.props.handleChange(newColumns)
  }

  renderCheckbox = (option) => {
    const { selected, maxSelections } = this.props
    return (
      <Checkbox
        key={option.value}
        onClicked={(value) =>
          this.handleCheckboxChange(option.name, option.value)
        }
        label={option.name}
        checked={selected.indexOf(option.value) >= 0}
        disabled={
          selected.length > 1 &&
          selected.length >= maxSelections &&
          selected.indexOf(option.value) < 0
        }
      />
    )
  }

  render() {
    const { invalidSelection } = this.state
    const { options, title, invalidSelectionMessage } = this.props

    const optionsLimitPerColumn = 7
    const islongOptionsList = options.length >= optionsLimitPerColumn
    const secondColumnStart = Math.floor(options.length / 2 + 1)

    return (
      <div>
        <div>
          <h5>{title}</h5>
          {islongOptionsList ? (
            <div style={{ display: 'flex' }}>
              <div>
                {options.slice(0, secondColumnStart).map(this.renderCheckbox)}
              </div>
              <div style={{ paddingLeft: '50px' }}>
                {options.slice(secondColumnStart).map(this.renderCheckbox)}
              </div>
            </div>
          ) : (
            <>{options.map(this.renderCheckbox)}</>
          )}
        </div>

        <div>
          {invalidSelection && invalidSelectionMessage && (
            <h5 style={{ color: '#0099CC' }}>{invalidSelectionMessage}</h5>
          )}
        </div>
      </div>
    )
  }
}
