import { Sport, SportTypeKeys } from '../metrics_server/sports/data_types'
import { AppTypeCheck } from '../metrics_server/user/types'

export type ShortcutScreenType = {
  screenName: string
  shortcuts: Array<Shortcut>
}

export type Shortcut = {
  key: Array<string>
  action: { default?: string; [key: string]: string } | string
}

export const getShortcuts = (appTypeCheck: AppTypeCheck) => {
  const shortcuts: Array<ShortcutScreenType> = [
    {
      screenName: 'Validation - Table',
      shortcuts: [
        { key: ['Tab'], action: 'Toggle ignore flight' },
        { key: ['f'], action: 'Favourite Flight' },
        { key: ['↑'], action: 'Scroll up' },
        { key: ['↓'], action: 'Scroll down' },
        { key: ['Shift', ' ↑'], action: 'Scroll to the most recent kick' }
      ]
    },
    {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: [
        {
          key: ['a'],
          action: {
            default: 'Retained outcome (kick only)',
            soccer: 'Pass complete'
          }
        },
        { key: ['c'], action: 'Contested Lost outcome (kick only)' },
        { key: ['u'], action: 'Uncontested outcome (kick only)' },
        {
          key: ['w'],
          action: {
            default: 'Won outcome (lineout only)',
            canadianFootball: 'Handoff'
          }
        },
        { key: ['l'], action: 'Lost outcome (lineout only)' },
        {
          key: ['x'],
          action: {
            default: 'Pass incomplete (pass only)',
            soccer: 'Pass incomplete'
          }
        },
        { key: ['t'], action: 'Touchdown' },
        {
          key: ['i'],
          action: {
            default: 'Interception',
            soccer: 'Pass intercepted'
          }
        },
        {
          key: ['o'],
          action: 'Tackle'
        },
        {
          key: ['n'],
          action: 'Penalty'
        }
      ]
    },
    {
      screenName: 'Validation - Assign player to flight',
      shortcuts: [
        {
          key: ['p'],
          action: 'Hold `p`, enter player number, release `p`'
        }
      ]
    }
  ]

  if (appTypeCheck.isAdmin) {
    shortcuts.push({
      screenName: 'Coding',
      shortcuts: [
        { key: ['←'], action: 'Possession left' },
        { key: ['→'], action: 'Possession right' },
        { key: ['Space Bar'], action: 'Start/Stop Play' },
        { key: ['n'], action: 'Start/Stop Penalty Countdown' }
      ]
    })
  }

  return shortcuts
}

export const getActionPhrase = (
  shortcut: Shortcut,
  sportType: SportTypeKeys
) => {
  if (typeof shortcut.action === 'object') {
    return shortcut.action[sportType] || shortcut.action.default
  }
  return shortcut.action
}
