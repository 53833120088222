import {
  UPDATE_HARDWARE,
  TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL
} from './types'
import { REMOVE_USER_DATA } from '../types'

import { HardwareState } from './types'

import { encodeHardwareId } from '../../utils/encoding/index'

const initialState: HardwareState = {
  tagIdsIgnoredForSleepAndWakeAll: [],
  diagnostics: {
    hardwareIndex: [],
    encodedHardwareIndex: [],
    countMatrix: [],
    rssiMatrix: [],
    imuLenMatrix: [],
    distanceMatrix: [],
    status: {
      online: {
        tags: [],
        balls: [],
        anchors: []
      },
      offline: {
        tags: [],
        balls: [],
        anchors: []
      },
      sleep: {
        tags: [],
        balls: [],
        anchors: []
      }
    },
    finalAnchorIndex: 0,
    masterSignalStrength: [],
    hardwareState: {
      devices: {}
    }
  }
}

const generateEncodedHardwareIndex = ({ hardwareIndex }) => {
  return hardwareIndex.map((id) => encodeHardwareId(id))
}

const getGreylistedHardware = (
  hardwareIds,
  diagnostics: HardwareState['diagnostics']
) => {
  return hardwareIds.filter((hardwareId) => {
    let device = diagnostics.hardwareState.devices[hardwareId]
    if (device.greyListed) {
      return true
    }
  })
}

const getFramelapsingHardware = (
  onlineHardwareIds,
  diagnostics: HardwareState['diagnostics']
) => {
  return onlineHardwareIds.filter((hardwareId) => {
    if (diagnostics.hardwareState.devices[hardwareId].state === 'Framelapsing')
      return true
  })
}

const getTimelapsingHardware = (
  onlineHardwareIds,
  diagnostics: HardwareState['diagnostics']
) => {
  return onlineHardwareIds.filter((hardwareId) => {
    if (diagnostics.hardwareState.devices[hardwareId].state === 'Timelapsing')
      return true
  })
}

const greylistTimeouts = {}

export function hardwareReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL:
      if (state.tagIdsIgnoredForSleepAndWakeAll.includes(action.payload)) {
        return {
          ...state,
          tagIdsIgnoredForSleepAndWakeAll:
            state.tagIdsIgnoredForSleepAndWakeAll.filter(
              (id) => id !== action.payload
            )
        }
      } else {
        return {
          ...state,
          tagIdsIgnoredForSleepAndWakeAll: [
            ...state.tagIdsIgnoredForSleepAndWakeAll,
            action.payload
          ]
        }
      }

    case UPDATE_HARDWARE:
      let data = action.payload
      let encodedHardwareIndex = generateEncodedHardwareIndex(action.payload)

      for (let type in data.status.online) {
        if (!data.status.online[type]) data.status.online[type] = []
      }
      for (let type in data.status.offline) {
        if (!data.status.offline[type]) data.status.offline[type] = []
      }
      for (let type in data.status.sleep) {
        if (!data.status.sleep[type]) data.status.sleep[type] = []
      }

      if (!data.hardwareState)
        data.hardwareState = initialState.diagnostics.hardwareState

      return {
        ...state,
        diagnostics: {
          ...data,
          encodedHardwareIndex
        }
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
