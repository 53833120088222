import React from 'react'

import { SigninForm } from './SigninForm/SigninForm'
import { SignupForm } from './SignupForm/SignupForm'
import { RequestPasswordForm } from './RequestPasswordForm/RequestPasswordForm'
import { CreatePasswordForm } from './CreatePasswordForm/CreatePasswordForm'
import { VerifyEmailForm } from './VerifyEmailForm/VerifyEmailForm'
import { ConfirmPasswordForm } from './ConfirmPasswordForm/ConfirmPasswordForm'

import { ViewType } from '../../components/Views/Views.types'
import { FormProps } from '../../components/Views/Form/Form.types'

import sportableLogo from '../../assets/img/sportable-login.svg'

export const formConfig = {
  signin: {
    key: 'signin',
    name: 'Sign in',
    Component: SigninForm,
    title: () => {
      return (
        <h1>
          Login
          <br />
          to <span>HAL0</span>
        </h1>
      )
    },
    logoImg: sportableLogo
  },
  signup: {
    key: 'signup',
    name: 'Sign up',
    Component: SignupForm,
    title: () => {
      return (
        <h1>
          Create <span>new account</span>
        </h1>
      )
    },
    previousFormKey: 'signin'
  },
  verifyEmail: {
    key: 'verifyEmail',
    name: 'Verify Email',
    Component: VerifyEmailForm,
    title: () => {
      return (
        <h1>
          Verify <span>email address</span>
        </h1>
      )
    },
    previousFormKey: 'signin'
  },
  createPassword: {
    key: 'createPassword',
    name: 'Create Password',
    Component: CreatePasswordForm,
    title: () => {
      return (
        <h1>
          Create your <span>password</span>
        </h1>
      )
    }
  },
  requestPassword: {
    key: 'requestPassword',
    name: 'Request Password',
    Component: RequestPasswordForm,
    title: () => {
      return (
        <h1>
          Confirm <span>email</span>
        </h1>
      )
    },
    previousFormKey: 'signin'
  },
  confirmNewPassword: {
    key: 'confirmNewPassword',
    name: 'Confirm Password',
    Component: ConfirmPasswordForm,
    title: () => {
      return (
        <h1>
          Confirm <span>password</span>
        </h1>
      )
    },
    previousFormKey: 'signin'
  }
}

export const formMatrix = [
  [
    formConfig.signin,
    formConfig.signup,
    formConfig.requestPassword,
    formConfig.createPassword
  ],
  [null, formConfig.verifyEmail, formConfig.confirmNewPassword]
]
