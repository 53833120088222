import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  useFormattedSession,
  useSelectedFormattedSession
} from '../sessions/hooks'
import { useMemo, useEffect } from 'react'
import {
  checkGeniusFixture,
  checkGeniusPlayers,
  checkGeniusStatus
} from './thunks'
import {
  formatBroadcastIntegrationSessionData,
  getBroadcastIntegrationFixtureCheckRequestBody,
  getBroadcastIntegrationPlayersCheckRequestBodyFromSession
} from './functions'
import { useVersion } from '../version/hooks'

export const useBroadcastIntegration = () => {
  const broadcastIntegration = useAppSelector(
    (state) => state.broadcastIntegration
  )
  return broadcastIntegration
}

export const useBroadcastIntegrationSessionState = (sessionId?) => {
  const version = useVersion()

  const broadcastIntegration = useBroadcastIntegration()

  const formattedSession = useFormattedSession(sessionId || 'setup')

  return useMemo(() => {
    return formatBroadcastIntegrationSessionData(
      broadcastIntegration,
      formattedSession,
      version.app.GeniusIntegration
    )
  }, [formattedSession, broadcastIntegration, version.app.GeniusIntegration])
}

export const useBroadcastIntegrationStatusCheck = () => {
  const dispatch = useAppDispatch()

  const { live, id } = useSelectedFormattedSession()

  const broadcastIntegrationSessionState =
    useBroadcastIntegrationSessionState(id)

  useEffect(() => {
    if (broadcastIntegrationSessionState.isEnabled && live) {
      dispatch(checkGeniusStatus())
    }
  }, [broadcastIntegrationSessionState.isEnabled, dispatch])
}
// Check for fixture
export const useBroadcastIntegrationFixtureCheck = () => {
  const dispatch = useAppDispatch()

  const { homeTeam, awayTeam } = useFormattedSession('setup')
  const { fixtureDate } = useBroadcastIntegration()
  const broadcastIntegrationSessionState = useBroadcastIntegrationSessionState()

  // Update request body data
  const requestBodyData = useMemo(() => {
    if (homeTeam && awayTeam) {
      return getBroadcastIntegrationFixtureCheckRequestBody(
        homeTeam,
        awayTeam,
        broadcastIntegrationSessionState.fixtureDateRequired
          ? fixtureDate
          : new Date()
      )
    }
  }, [
    homeTeam,
    awayTeam,
    broadcastIntegrationSessionState.fixtureDateRequired,
    fixtureDate
  ])

  useEffect(() => {
    // If broadcast integration is enabled - check for teams
    if (broadcastIntegrationSessionState.isEnabled && requestBodyData) {
      dispatch(checkGeniusFixture(requestBodyData))
    }
  }, [broadcastIntegrationSessionState.isEnabled, requestBodyData, dispatch])
}

// Check for players
export const useBroadcastIntegrationPlayersCheck = () => {
  const dispatch = useAppDispatch()

  const formattedSession = useFormattedSession('setup')
  // const { fixtureDate } = useBroadcastIntegration()
  const broadcastIntegrationSessionState = useBroadcastIntegrationSessionState()

  // Update request body data
  const requestBodyData = useMemo(() => {
    if (formattedSession.homeTeam && formattedSession.awayTeam) {
      return getBroadcastIntegrationPlayersCheckRequestBodyFromSession(
        formattedSession
      )
    }
  }, [formattedSession])

  useEffect(() => {
    // If broadcast integration is enabled - check for teams
    if (broadcastIntegrationSessionState.isEnabled && requestBodyData) {
      dispatch(checkGeniusPlayers(requestBodyData))
    }
  }, [broadcastIntegrationSessionState.isEnabled, requestBodyData, dispatch])
}
