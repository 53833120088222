import { Team } from '../teams/types'
import { IDMap } from '../types'

export interface PlayersState {
  fetched: boolean
  hasValidData: boolean
  selectedPlayer: string
  items: IDMap<PlayerData>
  rawData: IDMap<RawPlayerData>
}

export interface PlayerData {
  id: string
  firstName: string
  lastName: string
  number?: number
  color?: string
  position?: string
  weight?: number
  height?: number
  teamId?: string
  img?: string
  sessionId?: string
}

export interface RawPlayerData {
  id: string
  firstName: string
  lastName: string
  number: number
  color: string
  position: string
  weight: number
  height: number
  teamId: string
  img: string
}

export interface FormattedPlayer {
  rawData: RawPlayerData
  id: string
  firstName: string
  lastName: string
  fullName: string
  abbreviatedName: string
  number: number
  color: string
  position: string
  weight: number
  height: number
  teamId: string
  img: string
  nameAndNumber: string
}

export interface FormattedPlayerData extends PlayerData {
  team: Team
}

export type AppPlayer = {
  data: PlayerData
}

export interface PlayerFilterType {
  name: string
  teamId: string
  value: string
}

export const GET_PLAYERS = 'GET_PLAYERS'
export const CREATE_PLAYER = 'CREATE_PLAYER'
export const EDIT_PLAYER = 'EDIT_PLAYER'
export const DELETE_PLAYER = 'DELETE_PLAYER'
export const SET_PLAYER = 'SET_PLAYER'
export const GET_PLAYER_USERS = 'GET_PLAYER_USERS'
export const GET_TEAM_PLAYERS = 'GET_TEAM_PLAYERS'
