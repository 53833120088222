import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../../data_types'
import { EventTypeProps } from '../data_types'

export type TimeEventTypeFeatures = {
  period: boolean
  startName: string
  endName: string
  buttons: {
    start: {
      text: string
      colour: string
    }
    stop: {
      text: string
      colour: string
    }
  }
}

export const defaultTimeEventTypeFeatures: TimeEventTypeFeatures = {
  period: true,
  startName: 'Started',
  endName: 'Stopped',
  buttons: {
    start: {
      text: 'Start',
      colour: '#00ff00'
    },
    stop: {
      text: 'Stop',
      colour: '#ff0000'
    }
  }
}

export const timeEventTypesConfig = {
  firstPeriod: {
    key: 'firstPeriod',
    value: 0,
    name: 'First',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultTimeEventTypeFeatures }
    }
  },
  secondPeriod: {
    key: 'secondPeriod',
    value: 1,
    name: 'Second',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultTimeEventTypeFeatures }
    }
  },
  thirdPeriod: {
    key: 'thirdPeriod',
    value: 2,
    name: 'Third',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultTimeEventTypeFeatures }
    }
  },
  fourthPeriod: {
    key: 'fourthPeriod',
    value: 3,
    name: 'Fourth',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultTimeEventTypeFeatures }
    }
  },
  stoppage: {
    key: 'stoppage',
    value: 4,
    name: 'Play',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultTimeEventTypeFeatures,
        period: false,
        startName: 'Stopped',
        endName: 'Resumed'
      }
    }
  },
  overTime: {
    key: 'overTime',
    value: 5,
    name: 'Start Overtime',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: { ...defaultTimeEventTypeFeatures }
    }
  }
} as const

export type TimeEventTypeProps = EventTypeProps<null, TimeEventTypeFeatures>

export type TimeEventTypes = DataTypes<typeof timeEventTypesConfig>

export type TimeEventTypeKey = DataTypeKey<TimeEventTypes>

export type TimeEventTypeKeys = DataTypeKeys<TimeEventTypeKey>

export type TimeEventTypeValues = DataTypeValues<TimeEventTypeKey>

export type TimeEventTypeMap = {
  [k in TimeEventTypeKeys]: TimeEventTypes[k]['props']
}

export const getTimeEventTypeGroup = (items: {
  [key in TimeEventTypeKeys]?: TimeEventTypes[key]
}) => {
  return getDataTypeGroup<
    TimeEventTypeKeys,
    TimeEventTypeValues,
    TimeEventTypes,
    TimeEventTypeProps
  >(items)
}

export type TimeEventTypeGroup = ReturnType<typeof getTimeEventTypeGroup>

export type TimeEventTypeConfig = DataTypeConfig<
  TimeEventTypeKeys,
  TimeEventTypeValues,
  TimeEventTypeProps
>

export const timeEventTypes = getTimeEventTypeGroup(timeEventTypesConfig)

export type TimeEventType = ReturnType<typeof timeEventTypes.getTypeByValue>

export const isTimeEventType = generateTypeChecks<
  TimeEventTypeKeys,
  TimeEventTypeGroup
>(timeEventTypes)

export type TimeEventTypeJson = {
  name?: string
  period?: boolean
  startName?: string
  endName?: string
  buttons?: {
    start: {
      text: string
      colour: string
    }
    stop: {
      text: string
      colour: string
    }
  }
}

export type TimeEventJson = {
  types: {
    [key in TimeEventTypeKeys]?: TimeEventTypeJson
  }
  periodName: string
}
