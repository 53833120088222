import { useState, useEffect } from 'react'
import { HardwareState } from '../../metrics_server/hardware/types'

export const useHardwareDevice = (
  devices: HardwareState['diagnostics']['hardwareState']['devices']
) => {
  const [deviceCategorized, setDeviceCategorized] = useState({
    Ball: [],
    PlayerTag: []
  })

  useEffect(() => {
    const categorized = {
      Ball: [],
      PlayerTag: []
    }

    Object.values(devices).forEach((device) => {
      if (categorized[device.type]) {
        categorized[device.type].push({
          id: device.id,
          type: device.type,
          dataMetrics: device.dataMetrics,
          serial: device.serial,
          offline: device.offline,
          location: device.dataMetrics?.location
        })
      }
    })

    setDeviceCategorized(categorized)
  }, [devices])

  return deviceCategorized
}
