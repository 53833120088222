import {
  GET_SERVER_VERSION,
  GET_APP,
  GET_HEALTH,
  SET_MS_CONNECTION_STATUS,
  SWITCH_UI,
  GET_DEAMON_VERSION,
  SET_MS_ERRORS
} from './action_types'
import { environment } from '../env'

import { VersionState } from './types'

function getEnvString(env) {
  if (env === 'Dev') return ' (development)'
  if (env === 'Staging') return ' (staging)'
  return ''
}

const envMap = {
  Dev: 'development',
  Staging: 'staging',
  Prod: 'production'
}

const regionMap = {
  London: 'london',
  Ireland: 'ireland',
  Sydney: 'sydney'
}

function getWebAppDomain(app) {
  const { Env, Region } = app
  return `match-tracker.${Env}.${regionMap[Region]}.sportable.com`
}

const initialState: VersionState = {
  ui: null,
  uiType: null,
  server: null,
  fetched: false,
  health: null,
  connected: false,
  app: {
    Product: null,
    Env: null,
    FeatureFlags: null,
    Region: null,
    Mode: null,
    EnableAutoSleepToggle: true,
    UnitSystems: [],
    GeniusIntegration: false
  },
  webAppDomain: null,
  deamon: null,
  errors: null,
  msVersion: null
}

export default function (state = initialState, action) {
  let envString, msEnv, webAppDomain, app
  switch (action.type) {
    case GET_SERVER_VERSION:
      return {
        ...state,
        msVersion: action.payload,
        server: `${action.payload}`,
        fetched: true
      }
    case GET_APP:
      app = action.payload
      envString = getEnvString(app.Env)
      webAppDomain = getWebAppDomain(app)
      return {
        ...state,
        app: action.payload,
        webAppDomain,
        server: `${state.msVersion} (${app.Env})`
      }

    case GET_HEALTH:
      return {
        ...state,
        health: action.payload
      }

    case SET_MS_CONNECTION_STATUS:
      return {
        ...state,
        connected: action.payload
      }

    case SET_MS_ERRORS:
      return {
        ...state,
        errors: action.payload
      }

    case SWITCH_UI:
      window.localStorage.setItem('uiTypeKey', action.payload.key)
      return { ...state, uiType: action.payload }
    case GET_DEAMON_VERSION:
      return {
        ...state,
        deamon: `${action.payload}`
      }
    default:
      return state
  }
}
