import React from 'react'

export interface MIconProps {
  scale?: number
  color?: string
}

export const MIcon: React.FC<MIconProps> = ({ scale = 1, color = 'white' }) => {
  return (
    <svg
      width={`${34 * scale}`}
      height={`${34 * scale}`}
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M2 5.75C2 4.75544 2.39509 3.80161 3.09835 3.09835C3.80161 2.39509 4.75544 2 5.75 2H28.25C29.2446 2 30.1984 2.39509 30.9016 3.09835C31.6049 3.80161 32 4.75544 32 5.75V28.25C32 29.2446 31.6049 30.1984 30.9016 30.9016C30.1984 31.6049 29.2446 32 28.25 32H5.75C4.75544 32 3.80161 31.6049 3.09835 30.9016C2.39509 30.1984 2 29.2446 2 28.25V5.75Z'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.7 24.1999V9.79993L17 18.7999L23.3 9.79993V24.1999'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
