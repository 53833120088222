import React, { useMemo, useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { CardList } from '../../../components/CardList/CardList'
import { Table } from '../../../components/Table/Table'
import { TableRow } from '../../../components/Table/Table.types'
import { sportableColors } from '../../../const'
import {
  DeviceState,
  FormattedDevice
} from '../../../metrics_server/hardware/types'
import styles from './Controller.module.scss'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'
import { Group } from '../../../metrics_server/types'
import { useAppDispatch } from '../../../store/hooks'
import {
  disableRSSIAction,
  enableRSSIAction,
  serviceAction
} from '../../../metrics_server/hardware/actions'
import { setError } from '../../../ui/error/actions'

export function Controller() {
  const dispatch = useAppDispatch()

  const formattedHardware = useFormattedHardware()

  const [highlightedRow, setHighlightedRow] = useState(null)

  const getRowData = (devices: Group<FormattedDevice, number>) => {
    return devices.list.map((device) => {
      const row = {} as DeviceState & TableRow
      row.id = device.id
      row.serial = device.serial
      row.lastOnline = device.lastOnline * 1000
      row.highlightColor = device.status.colour
      row.state = device.state
      row.__buttons = {
        greyList: {
          text: device.greyListed ? 'Ungreylist' : 'Greylist',
          value: device.greyListed ? 'unGreyList' : 'greyList'
        },
        timeLapse: {
          text: 'Timelapse',
          value: 'balls/timelapse'
        },
        frameLapse: {
          text: 'Framelapse',
          value: 'balls/framelapse'
        },
        online: {
          text: 'Online',
          value: 'balls/online'
        },
        reset: {
          text: 'Reset',
          value: 'reset'
        },
        standby: {
          text: 'Stand by',
          value: 'standby',
          disabled: device.productName === 'Junco'
        },
        noHibernate: {
          text: 'No Hibernate',
          value: 'hibernate/disable'
        },
        hibernate: {
          text: 'Hibernate',
          value: 'hibernate'
        },
        shutdown: {
          text: 'Shutdown',
          value: 'shutdown'
        },
        unseat: {
          text: 'Unseat',
          value: 'unseat',
          disabled: device.status.value !== 'offline'
        },

        // use new frame lapse mode field
        frameLapseMode: {
          text: device.greyListed ? 'Disable FL Mode' : 'Enable FL Mode',
          value: device.greyListed ? 'balls/online' : 'balls/framelapse'
        }
      }
      return row
    })
  }

  const ballTableData = useMemo(() => {
    return getRowData(formattedHardware.types.ball.devices)
  }, [formattedHardware])

  const tagTableData = useMemo(() => {
    return getRowData(formattedHardware.types.playerTag.devices)
  }, [formattedHardware])

  const anchorTableData = useMemo(() => {
    return getRowData(formattedHardware.types.anchor.devices)
  }, [formattedHardware])

  const getTableHeaders = () => {
    const callback = (item, value) => {
      dispatch(
        setError({
          message: `${value}: ${item.id} - Are you sure?`,
          type: 'warning',
          proceed: () => {
            dispatch(serviceAction([item.id], value))
          }
        })
      )
    }

    return [
      { name: 'Serial', key: 'serial' },
      { name: 'Last Online', key: 'lastOnline', type: 'date' },
      {
        name: '',
        key: 'unseat',
        input: {
          type: 'button',
          callback,
          text: 'Unseat'
        }
      },
      {
        name: '',
        key: 'reset',
        input: {
          type: 'button',
          callback,
          text: 'Reset'
        },
        group: 'offline'
      },
      {
        name: '',
        key: 'standby',
        input: {
          type: 'button',
          callback,
          text: 'Stand by'
        },
        group: 'offline'
      },
      {
        name: '',
        key: 'noHibernate',
        input: {
          type: 'button',
          callback,
          text: 'No Hibernate'
        },
        group: 'offline'
      },
      {
        name: '',
        key: 'hibernate',
        input: {
          type: 'button',
          callback,
          text: 'Hibernate'
        },
        group: 'offline'
      },
      {
        name: '',
        key: 'shutdown',
        input: {
          type: 'button',
          callback,
          text: 'Shutdown'
        },
        group: 'offline'
      },
      {
        name: '',
        key: 'greyList',
        input: {
          type: 'button',
          callback,
          text: 'Greylist'
        },
        group: 'sleep'
      },
      {
        name: '',
        key: 'timeLapse',
        input: {
          type: 'button',
          callback,
          text: 'Timelapse'
        },
        group: 'sleep'
      },
      {
        name: '',
        key: 'frameLapse',
        input: {
          type: 'button',
          callback,
          text: 'Framelapse'
        },
        group: 'sleep'
      },
      {
        name: '',
        key: 'online',
        input: {
          type: 'button',
          callback,
          text: 'Online'
        },
        group: 'online'
      },
      { name: 'State', key: 'state' }
    ]
  }

  return (
    <React.Fragment>
      <div className={styles.ballTable}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.controlTitle}>
            <h5>Balls</h5>
          </div>
          <div className={styles.controlTable}>
            <Table
              options={{
                initialOrder: 'asc',
                initialSortBy: 'id',
                sortActive: true
              }}
              highlightRow={(id) => setHighlightedRow(id)}
              highlightedRow={highlightedRow}
              headerFont={13}
              smallHead={true}
              tableClass={'minimalistBlack'}
              className={'container'}
              // bodyFont={13}
              data={ballTableData}
              headers={getTableHeaders()}
              groups={
                ballTableData.length > 0 && {
                  online: {
                    name: 'Online',
                    color: sportableColors.colors.colorSuccess
                  },
                  offline: {
                    name: 'Offline',
                    color: sportableColors.colors.colorError
                  },
                  sleep: {
                    name: 'Sleep',
                    color: sportableColors.colors.colorWarning
                  }
                }
              }
              controls={[
                {
                  name: 'Reset all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        ballTableData.map((x) => x.id),
                        'reset'
                      )
                    )
                },
                {
                  name: 'Standby all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        ballTableData.map((x) => x.id),
                        'standby'
                      )
                    )
                },
                {
                  name: 'Shutdown all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        ballTableData.map((x) => x.id),
                        'shutdown'
                      )
                    )
                },
                {
                  name: 'Greylist all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        ballTableData.map((x) => x.id),
                        'greyList'
                      )
                    )
                },
                {
                  name: 'Ungreylist all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        ballTableData.map((x) => x.id),
                        'unGreyList'
                      )
                    )
                }
              ]}
            />
          </div>
        </CardList>
      </div>
      <div className={styles.tagTable}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.controlTitle}>
            <h5>Tags</h5>
          </div>
          <div className={styles.controlTable}>
            <Table
              options={{
                initialOrder: 'asc',
                initialSortBy: 'id',
                sortActive: true
              }}
              highlightRow={(id) => setHighlightedRow(id)}
              highlightedRow={highlightedRow}
              headerFont={13}
              smallHead={true}
              tableClass={'minimalistBlack'}
              className={'container'}
              // bodyFont={13}
              data={tagTableData}
              headers={getTableHeaders()}
              groups={
                tagTableData.length > 0 && {
                  online: {
                    name: 'Online',
                    color: sportableColors.colors.colorSuccess
                  },
                  offline: {
                    name: 'Offline',
                    color: sportableColors.colors.colorError
                  },
                  sleep: {
                    name: 'Sleep',
                    color: sportableColors.colors.colorWarning
                  }
                }
              }
              controls={[
                {
                  name: 'Reset all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        tagTableData.map((x) => x.id),
                        'reset'
                      )
                    )
                },
                {
                  name: 'Standby all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        tagTableData.map((x) => x.id),
                        'standby'
                      )
                    )
                },
                {
                  name: 'Greylist all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        tagTableData.map((x) => x.id),
                        'greyList'
                      )
                    )
                },
                {
                  name: 'Ungreylist all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        tagTableData.map((x) => x.id),
                        'unGreyList'
                      )
                    )
                }
              ]}
            />
          </div>
        </CardList>
      </div>
      <div className={styles.anchorTable}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.controlTitle}>
            <h5>Anchors</h5>
          </div>
          <div className={styles.controlTable}>
            <Table
              options={{
                initialOrder: 'asc',
                initialSortBy: 'id',
                sortActive: true
              }}
              highlightRow={(id) => setHighlightedRow(id)}
              highlightedRow={highlightedRow}
              headerFont={13}
              smallHead={true}
              tableClass={'minimalistBlack'}
              className={'container'}
              // bodyFont={13}
              data={anchorTableData}
              headers={getTableHeaders()}
              groups={
                anchorTableData.length > 0 && {
                  online: {
                    name: 'Online',
                    color: sportableColors.colors.colorSuccess
                  },
                  offline: {
                    name: 'Offline',
                    color: sportableColors.colors.colorError
                  },
                  sleep: {
                    name: 'Sleep',
                    color: sportableColors.colors.colorWarning
                  }
                }
              }
              controls={[
                {
                  name: 'Reset all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        anchorTableData.map((x) => x.id),
                        'reset'
                      )
                    )
                },
                {
                  name: 'Standby all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        anchorTableData.map((x) => x.id),
                        'standby'
                      )
                    )
                },
                {
                  name: 'Ungreylist all',
                  callback: () =>
                    dispatch(
                      serviceAction(
                        anchorTableData.map((x) => x.id),
                        'unGreyList'
                      )
                    )
                }
              ]}
            />
          </div>
        </CardList>
      </div>
      <div className={styles.generalControls}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.rssiButtons}>
            <Button
              handleClick={() => {
                dispatch(enableRSSIAction())
              }}
              className='btn--primary'
            >
              Enable RSSI
            </Button>
            <Button
              handleClick={() => {
                dispatch(disableRSSIAction())
              }}
              className='btn--primary'
            >
              Disable RSSI
            </Button>
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
