import React from 'react'
import playerAvatar from '../../../assets/img/player_profile_avatar.svg'
import { EditPencilIcon } from '../../Icons/EditPencilIcon/EditPencilIcon'
import { SportableTimePicker } from '../../TimePicker/TimePicker'
import styles from './Profile.module.scss'
import { EventOperatorNotes } from '../../EventOperatorNotes/EventOperatorNotes'
import { useChangeFromPlayerModal } from '../../../views/Session/modals/change_from_player'
import { useChangeFromTeamModal } from '../../../views/Session/modals/change_from_team'
import { Sport } from '../../../metrics_server/sports/data_types'
import { Team } from '../../../metrics_server/teams/types'
import { OperatorNotes } from '../../../metrics_server/events/types'
import { FormattedPlayer } from '../../../metrics_server/players/types'
import { ZoomControls } from '../FormattedEventDetailCard'

// switch operatorNotes for event in EventOperatorNotes props

export interface ProfileProps {
  team?: Team
  player?: FormattedPlayer
  sport: Sport
  matchTime: string | number
  eventStartTime?: string | number
  eventTimeSinceStart?: string
  operatorNotes?: OperatorNotes
  updateMatchTime: (value: string) => void
  updateEventHighlight?: (value: boolean) => void
  saveFlash?: boolean
  zoomControls?: ZoomControls
}

export const Profile = ({
  team,
  player,
  matchTime,
  eventStartTime,
  eventTimeSinceStart,
  operatorNotes,
  updateMatchTime,
  updateEventHighlight,
  saveFlash,
  zoomControls
}: ProfileProps) => {
  // Modals //
  const { openChangeFromPlayerModal } = useChangeFromPlayerModal()
  const { openChangeFromTeamModal } = useChangeFromTeamModal()
  // ====== //

  return (
    <div className={styles.profileContainer}>
      <div className={styles.imgAndTextWrapper}>
        <div className={styles.imgAndNotesContainer}>
          {player ? (
            <img
              className={player.img && styles.img}
              src={!player.img ? playerAvatar : player.img}
              alt=''
            />
          ) : (
            <img className={styles.img} src={playerAvatar} alt='' />
          )}

          <EventOperatorNotes
            operatorNotes={operatorNotes}
            updateEventHighlight={updateEventHighlight}
          />
        </div>

        <div className={styles.nameAndTeamtextContainer}>
          <div
            className={styles.teamContainer}
            onClick={openChangeFromTeamModal}
          >
            <div>
              <div>
                {team ? (
                  <h3 style={{ fontSize: zoomControls.adjustedFontSize?.h3 }}>
                    {team.name}
                  </h3>
                ) : (
                  <h3 style={{ fontSize: zoomControls.adjustedFontSize?.h3 }}>
                    No team
                  </h3>
                )}
              </div>
            </div>
            <EditPencilIcon id={'edit-team'} scale={0.3} />
          </div>
          <div
            className={styles.nameContainer}
            onClick={openChangeFromPlayerModal}
          >
            {player ? (
              <h5 style={{ fontSize: zoomControls.adjustedFontSize?.h5 }}>
                {`${player?.number}` ? `${player.number}. ` : ''}
                {player.firstName} {player.lastName}
              </h5>
            ) : (
              <h5 style={{ fontSize: zoomControls.adjustedFontSize?.h5 }}>
                No player
              </h5>
            )}
            <EditPencilIcon id={'edit-player'} scale={0.3} />
          </div>
        </div>
      </div>

      <div className={styles.timeContainer}>
        <div className={styles.time}>
          {/* From Operator notes */}
          <p style={{ fontSize: zoomControls.adjustedFontSize?.p }}>
            Ev. Start Time
          </p>
          {/* <div style={{ fontSize: zoomControls.adjustedFontSize?.p }}> */}
          {matchTime && (
            <SportableTimePicker
              onChange={updateMatchTime}
              value={matchTime || '00:00:00'}
              format={'HH:mm:ss'}
              disableClock={true}
              clearIcon={null}
              maxDetail={'second'}
            />
          )}
          {eventStartTime && (
            <SportableTimePicker
              // onChange={updateMatchTime}
              onChange={() => console.log('test')}
              value={eventStartTime || '00:00:00'}
              format={'HH:mm:ss'}
              disableClock={true}
              clearIcon={null}
              maxDetail={'second'}
            />
          )}
          {/* </div> */}
        </div>
        <div className={styles.time}>
          <p style={{ fontSize: zoomControls.adjustedFontSize?.p }}>
            Time Since Start
          </p>
          {/* <div style={{ fontSize: zoomControls.adjustedFontSize?.p }}> */}
          <SportableTimePicker
            onChange={() => console.log('test')}
            value={eventTimeSinceStart || '00:00:00'}
            format={'HH:mm:ss'}
            disableClock={true}
            clearIcon={null}
            maxDetail={'second'}
          />
          {/* </div> */}
        </div>
      </div>
      <div className={styles.saveFlash}>
        {saveFlash && <h5 className={styles.saveFlashText}>Saved !</h5>}
      </div>

      <div className={styles.zoomButtons}>
        <button
          className={styles.zoomButton}
          onClick={zoomControls.zoomIn}
          aria-label='Increase Text Size'
        >
          A
        </button>
        <button
          className={styles.zoomButton}
          onClick={zoomControls.zoomOut}
          aria-label='Decrease Text Size'
        >
          a
        </button>
      </div>
    </div>
  )
}
