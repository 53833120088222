import styles from './Toggle.module.scss'

type Option = {
  name: string
  value: number
}

export const useToggleLogic = (props) => {
  const toggleWidth = 100

  const calculateLeft = (value: any, options: Array<Option>) => {
    if (!props.input.value && props.input.value !== 0) return 0
    let interval = 100 / options.length
    let optionIndex = 0
    options.forEach((option, index) => {
      if (option.value === value) optionIndex = index
    })
    let percentage = interval * optionIndex
    return percentage
  }

  let toggleStyle = {
    width: `${toggleWidth}%`
  }

  let sliderStyle = {
    left: `${calculateLeft(props.input.value, props.options)}%`,
    width: `${toggleWidth / props.options.length}%`,
    background: props.toggleColor
      ? `${props.toggleColor}`
      : props.isSkillTracker
      ? '#f64645'
      : '#0099CC'
  }

  let toggleItemStyle = {
    width: `${toggleWidth / props.options.length}%`,
    border: props.toggleColor
      ? `${props.toggleColor} solid 1px`
      : props.isSkillTracker
      ? '#f64645 solid 1px'
      : '#0099CC solid 1px'
  }

  const size =
    props.size === 'xs'
      ? styles.toggleXSmall
      : props.size === 's'
      ? styles.toggleSmall
      : props.size === 'm'
      ? styles.toggleMedium
      : styles.toggleLarge

  return {
    toggleStyle,
    sliderStyle,
    toggleItemStyle,
    size
  }
}
