import React from 'react'
import styles from '../SessionSetup.module.scss'
import { HardwareGrid } from '../../../components/HardwareGrid/HardwareGrid'
import { deviceTypes } from '../../../metrics_server/hardware/data_types'

export const BallSetup = () => {
  return (
    <div className={styles.tagsGridContainer}>
      <HardwareGrid
        deviceTypeValue={deviceTypes.items.ball.value}
        isSessionAssignment={true}
      />
    </div>
  )
}
