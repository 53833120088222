import React, { Component } from 'react'
import animation from '../../../utils/animations'
import styles from './Form.module.scss'

// Components
import { Header } from './Header/Header'
import Scroller from '../../Scroller/Scroller'

// Types
import { FormProps, Form } from './Form.types'

interface FormControllerState {
  selectedFormIndex: [number, number]
  prevSelectedFormIndex: [number, number]
}

export class FormController extends Component<FormProps, FormControllerState> {
  formContainer: React.RefObject<HTMLInputElement>

  getFormByIndex = (index) => {
    if (!index) return null

    const { formMatrix } = this.props
    let form = null

    formMatrix.every((formArray, i) => {
      form = formArray.find((form, j) => {
        return index[0] === i && index[1] === j
      })
      return !form
    })

    return form
  }

  getFormIndexByFormKey = (formKey) => {
    const { formMatrix } = this.props
    let formIndex = null
    formMatrix.forEach((formArray, i) => {
      formArray.forEach((form, j) => {
        if (form && form.key === formKey) formIndex = [i, j]
      })
    })
    return formIndex
  }

  getFormByFormKey = (formKey) => {
    const formIndex = this.getFormIndexByFormKey(formKey)
    const form = this.getFormByIndex(formIndex)
    return form
  }

  constructor(props) {
    super(props)

    const formIndex = this.getFormIndexByFormKey(props.initialFormKey)

    this.state = {
      selectedFormIndex: formIndex || [0, 0],
      prevSelectedFormIndex: null
    }

    this.formContainer = React.createRef()

    this.switchForm = this.switchForm.bind(this)
  }

  componentDidMount() {
    const { prevSelectedFormIndex } = this.state
    if (prevSelectedFormIndex == null) {
      animation.show(this.formContainer.current)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { prevSelectedFormIndex, selectedFormIndex } = this.state

    if (prevState.selectedFormIndex !== selectedFormIndex) {
      if (selectedFormIndex[1] !== prevSelectedFormIndex[1]) {
        if (selectedFormIndex[1] > prevSelectedFormIndex[1]) {
          animation.slideInRight(this.formContainer.current)
        } else if (selectedFormIndex[1] < prevSelectedFormIndex[1]) {
          animation.slideInLeft(this.formContainer.current)
        }
      } else if (selectedFormIndex[0] !== prevSelectedFormIndex[0]) {
        if (selectedFormIndex[0] > prevSelectedFormIndex[0]) {
          animation.slideInBottom(this.formContainer.current)
        } else if (selectedFormIndex[0] < prevSelectedFormIndex[0]) {
          animation.slideInTop(this.formContainer.current)
        }
      }
    }
  }

  switchForm(formKey) {
    const { onSwitchForm } = this.props
    const { selectedFormIndex } = this.state

    if (onSwitchForm) onSwitchForm(formKey)

    const formIndex = this.getFormIndexByFormKey(formKey)

    const cb = () => {
      this.setState({
        selectedFormIndex: formIndex,
        prevSelectedFormIndex: selectedFormIndex
      })
    }

    if (!formIndex) {
      animation.hide(this.formContainer.current)
    } else if (formIndex[1] !== selectedFormIndex[1]) {
      if (formIndex[1] > selectedFormIndex[1]) {
        animation.slideOutLeft(this.formContainer.current, cb)
      } else if (formIndex[1] < selectedFormIndex[1]) {
        animation.slideOutRight(this.formContainer.current, cb)
      }
    } else if (formIndex[0] !== selectedFormIndex[0]) {
      if (formIndex[0] > selectedFormIndex[0]) {
        animation.slideOutTop(this.formContainer.current, cb)
      } else if (formIndex[0] < selectedFormIndex[0]) {
        animation.slideOutBottom(this.formContainer.current, cb)
      }
    }
  }

  nextForm = () => {
    const { selectedFormIndex } = this.state
    const form = this.getFormByIndex(selectedFormIndex)
    this.switchForm(form.nextKey)
  }

  switchToPreviousForm = () => {
    const { selectedFormIndex } = this.state
    const form = this.getFormByIndex(selectedFormIndex)
    if (form) {
      this.switchForm(form.previousFormKey)
    }
  }

  render() {
    const { selectedFormIndex } = this.state
    const { formMatrix } = this.props
    let formArray = [] as Form[]

    formMatrix.forEach((x) => {
      formArray = [...formArray, ...x]
    })

    const selectedForm = this.getFormByIndex(selectedFormIndex)

    return (
      <React.Fragment>
        <Scroller scrollerId='scroller' scrollerClass='form-scroller'>
          <div className={styles.formElementContainer}>
            <div className='paddingMd'>
              {formArray.map((form, i) => {
                if (form && form.key === selectedForm.key) {
                  return (
                    <div key={`${form.key}-formKey`} ref={this.formContainer}>
                      <div className={styles.form}>
                        <Header
                          {...form}
                          switchToPreviousForm={this.switchToPreviousForm}
                          getFormByFormKey={this.getFormByFormKey}
                        />
                        <form.Component
                          {...this.props}
                          form={form}
                          switchForm={this.switchForm}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return <React.Fragment key={i}></React.Fragment>
                }
              })}
            </div>
          </div>
        </Scroller>
      </React.Fragment>
    )
  }
}

export default FormController
