import React, { ReactElement } from 'react'
import styles from './CardList.module.scss'
import _ from 'lodash'
import Scroller from '../Scroller/Scroller'

export interface CardListProps {
  col: number
  className?: any
  items: Array<any>
  scrollerId: string
  padding?: any
  border?: boolean
  initialScrollHeight?: any
  saveScrollHeight?: any
  children?: ReactElement | React.ReactNode
  item?: any
  selected?: string
  backgroundColor?: string
}

export const CardList = (props: CardListProps) => {
  const {
    className,
    items,
    scrollerId,
    padding,
    border,
    initialScrollHeight,
    saveScrollHeight
  } = props

  const renderItem = (item: any, index: number) => {
    return <Card {...props} item={item} key={`${item.id} - ${index}`} />
  }

  if (className === 'maxHeight') {
    return <ul className={styles[className]}>{items.map(renderItem)}</ul>
  }

  return (
    <Scroller
      padding={padding || 0}
      scrollerId={scrollerId}
      scrollerClass='form-scroller'
      border={border}
      initialScrollHeight={initialScrollHeight}
      saveScrollHeight={saveScrollHeight}
    >
      <ul className={styles[className]}>{items.map(renderItem)}</ul>
    </Scroller>
  )
}

export interface CardProps {
  children?: ReactElement | React.ReactNode
  item: any
  col: number
  selected?: string
  backgroundColor?: string
}

export const Card: React.FC<CardProps> = (props) => {
  const { children, item, col, selected, backgroundColor } = props

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child as any, { item })
  })

  if (item.id === undefined) {
    item.id = '-'
  }

  return (
    <div className={`col${col} ${styles.cardContainer}`}>
      <div
        style={{ backgroundColor: backgroundColor ? backgroundColor : 'white' }}
        className={`${selected === item.id ? styles.selected : ''} ${
          styles.card
        }`}
      >
        {childrenWithProps}
      </div>
    </div>
  )
}
