import { AppTypeCheck, UserState } from './types'

export function getAppTypeCheck(user: UserState): AppTypeCheck {
  const isMatchTracker = user?.data?.permissionLevel === 0
  const isBroadcaster = user?.data?.permissionLevel === 1
  const isCommentator = user?.data?.permissionLevel === 2
  const isTraining = user?.data?.permissionLevel === 3
  const isMatchDay = user?.data?.permissionLevel === 4
  const isExternalSoccerTraining = user?.data?.permissionLevel === 5
  const isAdmin =
    !isMatchTracker &&
    !isBroadcaster &&
    !isCommentator &&
    !isTraining &&
    !isMatchDay &&
    !isExternalSoccerTraining

  return {
    isMatchTracker,
    isBroadcaster,
    isCommentator,
    isTraining,
    isMatchDay,
    isExternalSoccerTraining,
    isAdmin
  }
}
