import React, { Component } from 'react'
import { StrackController } from '../../../utils/strack/strack'

import CanvasContainer from '../../Canvas/CanvasContainer'

import { Canvases, CanvasViews, InitiateStrack } from '../Strack.types'

import styles from './StrackContainer.module.scss'
import { UnitSystem } from '../../../metrics_server/units/types'

interface StrackContainerProps {
  padding: number
  hiddenCanvases: string[]
  children: React.ReactNode
  unitSystem?: UnitSystem
}

interface StrackContainerState {
  canvases: Canvases
  canvasViews: CanvasViews
  windowWidth: number
  windowHeight: number
  selectedCanvas: string
  canvasWidths?: { [canvasId: string]: string }
  canvasHeights?: { [canvasId: string]: string }
  hideCanvas?: boolean
}

export class StrackContainer extends Component<
  StrackContainerProps,
  StrackContainerState
> {
  private windowResizeTimeout: number

  constructor(props) {
    super(props)

    this.state = {
      canvases: {},
      canvasViews: {},
      windowWidth: 0,
      windowHeight: 0,
      selectedCanvas: null,
      canvasWidths: {},
      canvasHeights: {},
      hideCanvas: false
    }

    // Window resize timeout
    this.windowResizeTimeout = null
  }

  componentDidMount() {
    this.handleWindowResize()
  }

  componentDidUpdate(prevProps, prevState) {
    const { canvases } = this.state
    const init = (canvas) => {
      const { strack, cb, options } = canvas
      try {
        strack.init(options, () => {
          strack.canvasReady = true

          // Initiate event listener canvas
          strack.initiateEventsCanvas(() => {})

          if (cb) cb()
          this.setState({
            canvases: {
              ...this.state.canvases,
              [options.canvasId]: {
                ...this.state.canvases[options.canvasId],
                strackReady: true
              }
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    }

    for (const canvasId in canvases) {
      const canvas = canvases[canvasId]
      if (!prevState.canvases[canvasId]) {
        init(canvas)
      }
    }
  }

  componentWillUnmount() {
    // Stop all render loops, timeouts and intervals running in strack before component unmounts
    // Disconnect from position websocket (and spinGraph)

    const { canvases } = this.state
    for (const canvasId in canvases) {
      if (canvases[canvasId].strack) {
        canvases[canvasId].strack.destroy()
      }
    }

    StrackController.clearTimeout()

    // Clear canvas resize timeout
    clearTimeout(this.windowResizeTimeout)
    window.onresize = null
  }

  /*============= Strack Initiating ============ */

  /* options

    1. diags - initiate strack in diags mode
    2. sessionConfig
    3. team
    4. teamB
    5. canvasId3D

  */

  initiateStrack: InitiateStrack = (options, canvasStyle, cb) => {
    if (!options.canvasId) {
      console.log('Must supply canvasIds if multiple canvases used')
      options.canvasId = 'strack'
    }

    // Instance of strack setup and initiation
    const strack = new StrackController.Strack()

    const { [options.canvasId]: value, ...withoutCanvasState } =
      this.state.canvases

    this.setState(
      {
        canvases: withoutCanvasState
      },
      () => {
        this.setState({
          canvases: {
            ...this.state.canvases,
            [options.canvasId]: {
              options,
              strack,
              canvasStyle,
              cb
            }
          },
          canvasViews: {
            ...this.state.canvasViews,
            [options.canvasId]: options.initialView || '2D'
          }
        })
      }
    )
  }

  setSelectedCanvas = (selectedCanvasId) => {
    this.setState({ selectedCanvas: selectedCanvasId })
  }

  // Handle window resize
  handleWindowResize = () => {
    const resizedw = () => {
      const width = document.body.clientWidth
      const height = document.body.clientHeight
      this.setState({
        windowHeight: height,
        windowWidth: width
      })
    }

    window.onresize = function () {
      clearTimeout(this.windowResizeTimeout)
      this.windowResizeTimeout = setTimeout(resizedw, 1000)
    }
  }

  updateCanvasWidth = (canvasId, width) => {
    this.setState({
      canvasWidths: { [canvasId]: width }
    })
  }
  setCanvasStyle = (canvasId, height) => {
    this.setState({
      canvasHeights: { [canvasId]: height }
    })
  }
  hideCanvas = (boolean) => {
    this.setState({
      hideCanvas: boolean
    })
  }

  toggleCanvasView = (canvasId, view) => {
    const { canvases } = this.state
    const canvas = canvases[canvasId]
    if (canvas.strack) {
      canvas.strack.switchView(view || '2D')
      this.setState({
        canvasViews: {
          ...this.state.canvasViews,
          [canvasId]: view || '2D'
        }
      })
    }
  }

  /*============= Clone children with props =======*/

  renderChildrenWithProps = () => {
    const { children, hiddenCanvases } = this.props
    return React.Children.map(children, (child) => {
      const {
        canvases,
        canvasViews,
        selectedCanvas,
        windowWidth,
        windowHeight,
        canvasWidths,
        canvasHeights
      } = this.state
      return React.cloneElement(child as React.ReactElement<any>, {
        canvases,
        canvasViews,
        windowWidth,
        windowHeight,
        initiateStrack: this.initiateStrack,
        setSelectedCanvas: this.setSelectedCanvas,
        toggleCanvasView: this.toggleCanvasView,
        selectedCanvas,
        hiddenCanvases,
        updateCanvasWidth: this.updateCanvasWidth,
        setCanvasStyle: this.setCanvasStyle,
        hideCanvas: this.hideCanvas,
        canvasWidths,
        canvasHeights
      })
    })
  }

  renderCanvases = (canvas, i) => {
    const {
      selectedCanvas,
      canvasViews,
      canvasWidths,
      hideCanvas,
      canvasHeights
    } = this.state
    const { children, hiddenCanvases } = this.props
    const canvasChild = React.Children.toArray(children).find((child) => {
      if (React.isValidElement<{ canvasId: string; hidden: boolean }>(child)) {
        return child.props.canvasId === canvas.options.canvasId
      } else {
        return false
      }
    })

    if (
      React.isValidElement<{ canvasId: string; hidden: boolean }>(canvasChild)
    ) {
      const isHidden =
        hideCanvas ||
        (canvasChild && hiddenCanvases.indexOf(canvas.options.canvasId) >= 0)
      const canvasView = canvasViews[canvas.options.canvasId]
      const canvasWidth = canvasWidths[canvas.options.canvasId]
      const canvasHeight = canvasHeights[canvas.options.canvasId]

      return (
        <div
          key={canvas.options.canvasId}
          className={styles.sCont}
          style={{
            ...canvas.canvasStyle,
            width: canvasWidth ? canvasWidth : canvas.canvasStyle.width,
            height: canvasHeight ? canvasHeight : canvas.canvasStyle.height,
            opacity:
              selectedCanvas === canvas.options.canvasId && !isHidden ? 1 : 0

            // zIndex:
            //   selectedCanvas === canvas.options.canvasId && !isHidden ? 1 : 0
          }}
        >
          {canvas.strack && (
            <CanvasContainer
              view={canvasView}
              show2DOnly={canvas.options.show2DOnly}
              id={canvas.options.canvasId}
              cover={canvas.options.cover}
            />
          )}
        </div>
      )
    } else {
      return <noscript />
    }
  }

  render() {
    const { padding } = this.props
    const { canvases } = this.state
    const canvasArray = Object.values(canvases)
    return (
      <div
        style={{ padding: padding ? `${padding}px` : 0 }}
        className={styles.strackContainer}
      >
        {canvasArray.map(this.renderCanvases)}
        {this.renderChildrenWithProps()}
      </div>
    )
  }
}

export default StrackContainer
