import { defaultPitch } from './pitch'
import { canadianFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import { FormattedEventData } from '../../events/types'

export const canadianFootballProps = {
  eventTypes: canadianFootballEventTypes,
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 13,
    tenYardMarkings: 11
  },
  features: {
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'Canadian',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.complete?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        t: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.touchdown?.value
        },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        },
        w: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.handOff
        },
        o: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.tackle
        },
        n: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.penalty
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: true,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: true
  } as const
}
