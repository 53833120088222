import React, { useRef, useState, useEffect } from 'react'
import { EditIcon } from '../Icons/EditIcon/EditIcon'
import { SettingsIcon } from '../Icons/SettingsIcon/SettingsIcon'
import styles from './Options.module.scss'

type OptionsProps = {
  type?: string
  backgroundColor?: string
  color?: string
  options: Array<{ name: string; handle: Function }>
  dropdownLeft?: boolean
}

export const Options: React.FC<OptionsProps> = ({
  type,
  backgroundColor,
  color,
  options,
  dropdownLeft = true
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const isVisible = (elem: any) =>
    elem &&
    (elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)

  const handleOutsideClick = (event: any) => {
    if (
      !dropdownRef.current?.contains(event.target) &&
      isVisible(dropdownRef.current)
    ) {
      setExpanded(false)
    }
  }

  const handleToggleExpand = (event: any) => {
    event?.stopPropagation()
    setExpanded(!isExpanded)
  }

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isExpanded])

  const renderOption = (option: any, index: any) => {
    return (
      <div
        onClick={() => {
          option.handle()
          const rootElement = document.getElementById('root')
          if (rootElement) {
            rootElement.click()
          }
        }}
        key={index}
      >
        {option.name}
      </div>
    )
  }

  const defaultEditIcon = () => (
    <EditIcon id='edit-btn' backgroundColor={backgroundColor} color={color} />
  )

  const getOptionIcon = () => {
    switch (type) {
      case 'edit':
        return defaultEditIcon()
      case 'settings':
        return <SettingsIcon scale={0.9} active={isExpanded} color='white' />
      default:
        return defaultEditIcon()
    }
  }

  return (
    <div onClick={handleToggleExpand} className={styles.optionContainer}>
      {getOptionIcon()}
      {isExpanded && (
        <div
          ref={dropdownRef}
          className={styles.optionDropdown}
          style={{
            right: dropdownLeft ? '15px' : undefined,
            left: dropdownLeft ? undefined : '15px'
          }}
        >
          {options.map(renderOption)}
        </div>
      )}
    </div>
  )
}
