import React from 'react'
import styles from './Canvas.module.scss'

interface Canvas3DProps {
  id: string
  hidden: boolean
}

export const Canvas3D = (props: Canvas3DProps) => {
  return (
    <div
      className={
        props.hidden
          ? `${styles.canvas3dContainer} ${styles.canvasHidden}`
          : styles.canvas3dContainer
      }
    >
      <canvas id={`${props.id}-3D`} className={styles.canvas}></canvas>
    </div>
  )
}
