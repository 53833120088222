import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../../data_types'
import {
  FlightEventSubTypeGroup,
  FlightEventSubTypeKeys
} from './subTypes/data_types'
import { EventTypeJson, EventTypeProps } from '../data_types'

export type FlightEventFeatures = {
  graph: boolean
  importantFeature: boolean
  tagToTouchXDistance: boolean
}

export const defaultFlightEventFeatures: FlightEventFeatures = {
  graph: true,
  importantFeature: true,
  tagToTouchXDistance: false
}

export const flightEventTypesConfig = {
  pass: {
    name: 'Pass',
    key: 'pass',
    value: 'Pass',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  kick: {
    name: 'Kick',
    key: 'kick',
    value: 'Kick',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerPass: {
    name: 'Pass',
    key: 'soccerPass',
    value: 'SoccerPass',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerShotAtGoal: {
    name: 'Shot At Goal',
    key: 'soccerShotAtGoal',
    value: 'SoccerShotAtGoal',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerKickOff: {
    name: 'Kick off',
    key: 'soccerKickOff',
    value: 'SoccerKickOff',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerThrowIn: {
    name: 'Throw In',
    key: 'soccerThrowIn',
    value: 'SoccerThrowIn',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerCorner: {
    name: 'Corner',
    key: 'soccerCorner',
    value: 'SoccerCorner',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerPenalty: {
    name: 'Penalty',
    key: 'soccerPenalty',
    value: 'SoccerPenalty',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerDribble: {
    name: 'Dribble',
    key: 'soccerDribble',
    value: 'SoccerDribble',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerCross: {
    name: 'Cross',
    key: 'soccerCross',
    value: 'SoccerCross',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  // soccerGoalKick: {
  //   name: 'Goal Kick',
  //   key: 'soccerGoalKick',
  //   value: 'SoccerGoalKick',
  //   props: {
  //     types: null,
  //     metricTypes: null,
  //     outcomeTypes: null,
  //     features: defaultFlightEventFeatures
  //   }
  // },
  soccerClearance: {
    name: 'Clearance',
    key: 'soccerClearance',
    value: 'SoccerClearance',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  soccerDeflection: {
    name: 'Deflection',
    key: 'soccerDeflection',
    value: 'SoccerDeflection',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  },
  lineout: {
    name: 'Lineout',
    key: 'lineout',
    value: 'Lineout',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultFlightEventFeatures
    }
  }
} as const

export type FlightEventTypeProps = EventTypeProps<
  FlightEventSubTypeGroup,
  FlightEventFeatures
>

export type FlightEventTypes = DataTypes<typeof flightEventTypesConfig>

export type FlightEventTypeKey = DataTypeKey<FlightEventTypes>

export type FlightEventTypeKeys = DataTypeKeys<FlightEventTypeKey>

export type FlightEventTypeValues = DataTypeValues<FlightEventTypeKey>

export const getFlightEventTypeGroup = (items: {
  [key in FlightEventTypeKeys]?: FlightEventTypes[key]
}) => {
  return getDataTypeGroup<
    FlightEventTypeKeys,
    FlightEventTypeValues,
    FlightEventTypes,
    FlightEventTypeProps
  >(items)
}

export type FlightEventTypeGroup = ReturnType<typeof getFlightEventTypeGroup>

export type FlightEventTypeConfig = DataTypeConfig<
  FlightEventTypeKeys,
  FlightEventTypeValues,
  FlightEventTypeProps
>

export const flightEventTypes = getFlightEventTypeGroup(flightEventTypesConfig)

export type FlightEventType = ReturnType<typeof flightEventTypes.getTypeByValue>

export const isFlightEventType = generateTypeChecks<
  FlightEventTypeKeys,
  FlightEventTypeGroup
>(flightEventTypes)

export type FlightEventSubTypesJson = {
  [key in FlightEventSubTypeKeys]?: EventTypeJson<FlightEventFeatures>
}

export type FlightEventTypesJson = {
  [key in FlightEventTypeKeys]?: EventTypeJson<
    FlightEventFeatures,
    FlightEventSubTypesJson
  >
}

export type FlightEventJson = EventTypeJson<
  FlightEventFeatures,
  FlightEventTypesJson
>
