import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../../data_types'
import { EventTypeProps } from '../../data_types'
import { GameEventFeatures, defaultGameEventFeatures } from '../data_types'

export const gameEventSubTypesConfig = {
  unclassified: {
    key: 'unclassified',
    value: 'UNCLASSIFIED',
    name: 'Unclassified',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  // Penalty //
  scrum: {
    key: 'scrum',
    value: 'PENALTY_SCRUM',
    name: 'Scrum',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  penaltyKickToTouch: {
    key: 'penaltyKickToTouch',
    value: 'PENALTY_KICK_TO_TOUCH',
    name: 'Penalty Kick to Touch',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  penaltyGoalSuccessful: {
    key: 'penaltyGoalSuccessful',
    value: 'PENALTY_GOAL_SUCCESSFUL',
    name: 'Penalty Goal Successful',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  penaltyGoalUnsuccessful: {
    key: 'penaltyGoalUnsuccessful',
    value: 'PENALTY_GOAL_UNSUCCESSFUL',
    name: 'Penalty Goal Unsuccessful',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  tapPenalty: {
    key: 'tapPenalty',
    value: 'PENALTY_TAP',
    name: 'Tap Penalty',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  penaltyTry: {
    key: 'penaltyTry',
    value: 'PENALTY_TRY',
    name: 'Penalty Try',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  // ======= //
  // Snap //
  shotgun: {
    key: 'shotgun',
    value: 'SHOTGUN',
    name: 'Shotgun',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  underCenter: {
    key: 'underCenter',
    value: 'UNDER_CENTRE',
    name: 'Under Center',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  long: {
    key: 'long',
    value: 'LONG',
    name: 'Long',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  }
  // ======= //
} as const

export type GameEventSubTypeProps = EventTypeProps<null, GameEventFeatures>

export type GameEventSubTypes = DataTypes<typeof gameEventSubTypesConfig>

export type GameEventSubTypeKey = DataTypeKey<GameEventSubTypes>

export type GameEventSubTypeKeys = DataTypeKeys<GameEventSubTypeKey>

export type GameEventSubTypeValues = DataTypeValues<GameEventSubTypeKey>

export const getGameEventSubTypeGroup = (items: {
  [key in GameEventSubTypeKeys]?: GameEventSubTypes[key]
}) => {
  return getDataTypeGroup<
    GameEventSubTypeKeys,
    GameEventSubTypeValues,
    GameEventSubTypes,
    GameEventSubTypeProps
  >(items)
}

export type GameEventSubTypeGroup = ReturnType<typeof getGameEventSubTypeGroup>

export type GameEventSubTypeConfig = DataTypeConfig<
  GameEventSubTypeKeys,
  GameEventSubTypeValues,
  GameEventSubTypeProps
>

export const gameEventSubTypes = getGameEventSubTypeGroup(
  gameEventSubTypesConfig
)

export type GameEventSubType = ReturnType<
  typeof gameEventSubTypes.getTypeByValue
>
