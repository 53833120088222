export class Environment {
  private static localRegex =
    /(?<protocol>http:\/\/|https:\/\/)?(?<host>.*\.local)(:[0-9]{4})?/
  private static cloudRegex =
    /(http:\/\/|https:\/\/)?match-tracker\.(?<environment>development|staging|production)\.(?<region>.*)\.sportable\.com\.*/

  private static _defaultMSPort = '8888'
  private static _defaultSCPort = '8889'
  private static _defaultBrokerPort = '15675'

  public readonly metricsServer: EnvironmentDetail
  public readonly sportscaster: EnvironmentDetail
  public readonly mqttBroker?: EnvironmentDetail

  public readonly deployment: Deployment
  private env: Env = Env.UNKNOWN

  constructor(
    private hostname: string,
    private userOverrides?: Storage,
    private href?: string
  ) {
    const msPort = Environment.portConstructor(
      String(Environment._defaultMSPort),
      userOverrides
    )

    if (
      hostname.startsWith('http://localhost') ||
      hostname.startsWith('localhost') ||
      hostname.startsWith('0.0.0.0') ||
      hostname.startsWith('127.0.0.1')
    ) {
      const host = Environment.hostConstructor('localhost', userOverrides)
      const protocol = Environment.protocolConstructor('http://', userOverrides)

      this.metricsServer = buildEnvironmentDetail(host, msPort, protocol, true)
      this.sportscaster = buildEnvironmentDetail(
        host,
        Environment._defaultSCPort,
        protocol,
        true
      )
      this.mqttBroker = buildEnvironmentDetail(
        host,
        Environment._defaultBrokerPort,
        'ws://',
        false
      )

      this.deployment = Deployment.LOCAL
      this.updateEnvFromMS()
      return
    }

    const localDomain = hostname.match(Environment.localRegex)
    if (localDomain && localDomain.groups) {
      const host = Environment.hostConstructor(
        localDomain.groups.host,
        userOverrides
      )
      const protocol = Environment.protocolConstructor(
        localDomain.groups.protocol ?? 'http',
        userOverrides
      )
      this.metricsServer = buildEnvironmentDetail(host, msPort, protocol, true)
      this.sportscaster = buildEnvironmentDetail(
        host,
        Environment._defaultSCPort,
        protocol,
        true
      )
      this.mqttBroker = buildEnvironmentDetail(
        host,
        Environment._defaultBrokerPort,
        'ws://',
        false
      )

      this.deployment = Deployment.LOCAL
      this.updateEnvFromMS()
      return
    }

    const cloudDomain = hostname.match(Environment.cloudRegex)
    if (cloudDomain && cloudDomain.groups) {
      const environment = cloudDomain.groups.environment
      const region = cloudDomain.groups.region
      const host = Environment.hostConstructor(
        `metrics-server.${environment}.${region}.sportable.com`,
        userOverrides
      )
      const protocol = Environment.protocolConstructor(
        'https://',
        userOverrides
      )
      this.metricsServer = buildEnvironmentDetail(host, '443', protocol, true)
      this.sportscaster = buildEnvironmentDetail(
        `sportscaster.${environment}.${region}.sportable.com`,
        '443',
        protocol,
        true
      )
      // no broker on the cloud
      this.mqttBroker = undefined

      this.deployment = Deployment.CLOUD
      this.env = <Env>environment
      this.updateEnvFromMS()
      return
    }

    // Test for electron
    if (href && href.includes('app.asar')) {
      const host = Environment.hostConstructor('localhost', userOverrides)
      const protocol = Environment.protocolConstructor('http://', userOverrides)

      this.metricsServer = buildEnvironmentDetail(host, msPort, protocol, true)
      this.sportscaster = buildEnvironmentDetail(
        host,
        Environment._defaultSCPort,
        protocol,
        true
      )
      this.mqttBroker = buildEnvironmentDetail(
        host,
        Environment._defaultBrokerPort,
        'ws://',
        false
      )

      this.deployment = Deployment.LOCAL
      this.updateEnvFromMS()
      return
    }

    throw new Error(
      `Could not determine environment: hostname='${hostname}' href='${href}' userOverrides='${JSON.stringify(
        userOverrides
      )}'`
    )
  }

  private static hostConstructor(fallback: string, override?: Storage) {
    return override && override.getItem('ms_host')
      ? override.getItem('ms_host')
      : fallback
  }

  private static portConstructor(fallback: string, override?: Storage) {
    return override && override.getItem('ms_port')
      ? override.getItem('ms_port')
      : fallback
  }

  private static protocolConstructor(fallback: string, override?: Storage) {
    return override && override.getItem('proto')
      ? override.getItem('proto')
      : fallback
  }

  buildMetricsServerUrl(path: string): string {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }

    return `${this.metricsServer.url}/${path}`
  }

  get API_ROOT_URL(): string {
    return this.buildMetricsServerUrl('/api/')
  }

  get SERVICE_URL(): string {
    return this.buildMetricsServerUrl('/service/')
  }

  get WS_ROOT_URL(): string {
    return this.metricsServer?.websockets
  }

  get BROADCAST_ROOT_URL(): string {
    return this.buildMetricsServerUrl(`/broadcast/`)
  }

  get ENV(): Env {
    return this.env
  }

  buildSportscasterUrl(path: string): string {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    return `${this.sportscaster.url}/${path}`
  }

  private updateEnvFromMS() {
    fetch(this.buildMetricsServerUrl('/api/config/app'))
      .then((body) => body.json())
      .then(({ Env }) => (this.env = <Env>Env))
      .catch((e) => {
        console.error(`failed to get app config in env.ts`, e)
        //Retry in 1 seconds
        setTimeout(() => {
          this.updateEnvFromMS()
        }, 1_000)
      })
  }
}

export interface EnvironmentDetail {
  url: string
  port: string
  host: string
  hostAndPort: string
  protocol: string
  websockets?: string
}

function buildEnvironmentDetail(
  host: string,
  port: string,
  protocol: string,
  websockets?: boolean
): EnvironmentDetail {
  if (port.startsWith(':')) {
    port = port.substring(1)
  }
  // 443 is the default SSL port so doesn't need to be added to the url
  const portToAdd = port === '443' ? '' : `:${port}`

  if (protocol && !protocol.includes('://')) {
    protocol = `${protocol}://`
  }

  return {
    url: `${protocol}${host}${portToAdd}`,
    hostAndPort: `${host}${portToAdd}`,
    port: port,
    host,
    protocol,
    websockets: websockets ? `${host}${portToAdd}/ws/` : undefined
  }
}

export enum Env {
  UNKNOWN = 'unknown',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

export enum Deployment {
  LOCAL = 'local',
  CLOUD = 'cloud'
}
