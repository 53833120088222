import { WS_ROOT_URL } from '../../../metrics_server/api/config'
import WebSocketController from '../../../utils/websockets'
import { useEffect } from 'react'

export interface StrackTrackingProps {
  strackReady: boolean
  strack
  canvasId: string
}

export const StrackTracking: React.FC<StrackTrackingProps> = ({
  strackReady,
  strack,
  canvasId
}) => {
  // Listen to raw websocket and update strack buffer once strack has been successfully initiated
  useEffect(() => {
    if (strackReady) {
      // TODO: Removed live session tracking unless user is admin
      if (strack.tracking) {
        WebSocketController.connectWebSocket(
          WS_ROOT_URL,
          'raw',
          (data) => {
            // Update strack buffer for tracking
            strack.updateBuffer(data)
          },
          canvasId + '-canvas-tracking'
        )
      }
    } else if (!strackReady) {
      WebSocketController.disconnectWebSocket(
        'raw',
        canvasId + '-canvas-tracking'
      )
    }
    return () => {
      WebSocketController.disconnectWebSocket(
        'raw',
        canvasId + '-canvas-tracking'
      )
    }
  }, [strackReady, strack])

  return null
}
