import React, { useState } from 'react'
import { containsNumber, passwordCheck } from '../../../utils/helpers'
import { formConfig } from '../form_config'

// Components
import { FormContainer } from '../../../components/Forms/Form/Form'

import styles from './ConfirmPasswordForm.module.scss'

export interface FormValidationErrors {
  [key: string]: string | undefined
}

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.password) {
    errors.password = 'Required'
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Required'
  }
  if (values.password && !passwordCheck(values.password)) {
    errors.passwordInvalid = 'Invalid Password'
  }
  if (
    !values.password ||
    !values.password_confirmation ||
    (values.password && values.password !== values.password_confirmation)
  ) {
    errors.passwordMismatch = 'Passwords do not match'
  }

  return errors
}

export interface ConfirmPasswordFormProps {
  formConfig: any
  switchForm: (formKey: string) => void

  confirmNewPassword: (values, loadingId, cb) => void

  authentication: any
}

export const ConfirmPasswordForm = (props: ConfirmPasswordFormProps) => {
  const { switchForm, confirmNewPassword } = props

  const [invalidPassword, setInvalidPassword] = useState(false)
  const [passwordMismatch, setPasswordMismatch] = useState(true)

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return [
      {
        name: 'password',
        type: 'text',
        textType: 'password',
        label: 'Password'
      },
      {
        name: 'password_confirmation',
        type: 'text',
        textType: 'password',
        label: 'Confirm password'
      }
    ]
  }

  const handleSubmit = (values) => {
    const { createPassword, signin } = formConfig
    confirmNewPassword(values, createPassword.key, () => switchForm(signin.key))
  }

  const handleValidation = (values) => {
    const errors = validate(values)

    setInvalidPassword(!!errors.passwordInvalid)
    setPasswordMismatch(!!errors.passwordMismatch)

    return errors
  }

  return (
    <>
      <FormContainer
        fields={fields}
        validate={handleValidation}
        onSubmit={handleSubmit}
        submitText={'Confirm'}
        disableSubmit={invalidPassword || passwordMismatch}
      >
        <div className='row'>
          <p
            className={`${
              invalidPassword ? styles.highlightError : ''
            } small-text`}
          >
            Passwords must be at least 8 characters long and contain at least
            one upper case letter and number {invalidPassword && '*'}
          </p>
        </div>
      </FormContainer>
    </>
  )
}
