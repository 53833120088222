import { defaultPitch } from './pitch'
import { australianRulesEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'

export const australianRulesProps = {
  eventTypes: australianRulesEventTypes,
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0,
    textureCanvasScale: 8
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: false,
    maxPlayers: 22,
    defaultUnitSystem: 'Metric',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        u: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.uncontested.value
        },
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.contestedLost.value
        },
        a: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.retained.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false
  } as const
}
