import React, { useState } from 'react'
import { FormattedTeamSession } from '../../../metrics_server/sessions/types'
import { Button } from '../../Button/Button'
import Checkbox from '../Inputs/Checkbox/Checkbox'
import TextInput from '../Inputs/TextInput/TextInput'
import styles from './styles.module.scss'
import { Group } from '../../../metrics_server/types'
import { SessionDownloadType } from '../../../metrics_server/sessions/hooks'

export interface DownloadFormState {
  isAdmin: boolean
  teams: Group<FormattedTeamSession>
  handleProceed: (type: string, offset: number, team: string) => void
  handleClose: () => void
}

const DownloadForm = ({
  isAdmin = false,
  teams,
  handleProceed,
  handleClose
}: DownloadFormState): JSX.Element => {
  const [type, setType] = useState<SessionDownloadType>('csv')
  const [xmlTeam, setXMLTeam] = useState<string>('')
  const [csvTeam, setCSVTeam] = useState<string>('')
  const [offset, setOffset] = useState<number>(0)

  const handleCheckboxChange = (_: boolean, type: SessionDownloadType) => {
    setType(type)
  }

  const handleXMLTeamCheckboxChange = (value, name: string) => {
    let team = name
    if (!value) {
      team = ''
    }
    setXMLTeam(team)
  }

  const handleCSVTeamCheckboxChange = (value, name: string) => {
    let team = name
    if (!value) {
      team = ''
    }
    setCSVTeam(team)
  }
  let team1: FormattedTeamSession | undefined
  let team2: FormattedTeamSession | undefined
  if (teams?.list?.length === 2) {
    team1 = teams?.list[0]
    team2 = teams?.list[1]
  }
  return (
    <div>
      <h4 style={{ marginBottom: '30px' }}>Select file type to download:</h4>
      <div>
        <div>
          <div className={styles.xml}>
            <Checkbox
              onClicked={(value) => handleCheckboxChange(value, 'csv')}
              label={`Kicks & passes (.csv)`}
              checked={type === 'csv'}
            />
            {type === 'csv' && (
              <div className={styles.offsetInput}>
                <div style={{ fontSize: '13px' }}>Offset (s):</div>
                <div className='smallSelectContainer'>
                  <TextInput
                    disabled={type !== 'csv'}
                    small={true}
                    type='number'
                    input={{
                      onChange: (e) => {
                        const num = Number(e.target.value)
                        setOffset(num)
                      },
                      name: 'offset',
                      value: offset
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {team1 && team2 && (
            <div className={styles.indent}>
              <Checkbox
                onClicked={(value) => handleCSVTeamCheckboxChange(value, '')}
                label={`Both teams as single file`}
                checked={csvTeam === ''}
                disabled={type !== 'csv'}
              />
              <Checkbox
                onClicked={(value) =>
                  handleCSVTeamCheckboxChange(value, 'both')
                }
                label={`Both teams as separate files`}
                checked={csvTeam === 'both'}
                disabled={type !== 'csv'}
              />
              <Checkbox
                onClicked={(value) =>
                  handleCSVTeamCheckboxChange(value, team1.teamId)
                }
                label={`${team1.team.name}`}
                checked={csvTeam === team1.teamId}
                disabled={type !== 'csv'}
              />
              <Checkbox
                onClicked={(value) =>
                  handleCSVTeamCheckboxChange(value, team2.teamId)
                }
                label={`${team2.team.name}`}
                checked={csvTeam === team2.teamId}
                disabled={type !== 'csv'}
              />
            </div>
          )}
        </div>

        <div className={styles.xml}>
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'xml')}
            label={`Kicks & passes (.xml)`}
            checked={type === 'xml'}
          />
          {type === 'xml' && (
            <div className={styles.offsetInput}>
              <div style={{ fontSize: '13px' }}>Offset (s):</div>
              <div className='smallSelectContainer'>
                <TextInput
                  disabled={type !== 'xml'}
                  small={true}
                  type='number'
                  input={{
                    onChange: (e) => {
                      const num = Number(e.target.value)
                      setOffset(num)
                    },
                    name: 'offset',
                    value: offset
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {team1 && team2 && (
          <div className={styles.indent}>
            <Checkbox
              onClicked={(value) => handleXMLTeamCheckboxChange(value, '')}
              label={`Both teams as single file`}
              checked={xmlTeam === ''}
              disabled={type !== 'xml'}
            />
            <Checkbox
              onClicked={(value) => handleXMLTeamCheckboxChange(value, 'both')}
              label={`Both teams as separate files`}
              checked={xmlTeam === 'both'}
              disabled={type !== 'xml'}
            />
            <Checkbox
              onClicked={(value) =>
                handleXMLTeamCheckboxChange(value, team1.teamId)
              }
              label={`${team1.team.name}`}
              checked={xmlTeam === team1.teamId}
              disabled={type !== 'xml'}
            />
            <Checkbox
              onClicked={(value) =>
                handleXMLTeamCheckboxChange(value, team2.teamId)
              }
              label={`${team2.team.name}`}
              checked={xmlTeam === team2.teamId}
              disabled={type !== 'xml'}
            />
          </div>
        )}
        <Checkbox
          onClicked={(value) => handleCheckboxChange(value, 'ball_position')}
          label={`Ball Position data`}
          checked={type === 'ball_position'}
        />
        <Checkbox
          onClicked={(value) => handleCheckboxChange(value, 'player_position')}
          label={`Player Position data`}
          checked={type === 'player_position'}
        />
        <Checkbox
          onClicked={(value) => handleCheckboxChange(value, 'player_breakdown')}
          label={`Player Breakdown`}
          checked={type === 'player_breakdown'}
        />
        {isAdmin && (
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'raw_session')}
            label={`Raw Session Data`}
            checked={type === 'raw_session'}
          />
        )}
        {isAdmin && (
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'pitch')}
            label={`Pitch & anchor data (.json)`}
            checked={type === 'pitch'}
          />
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <div style={{ margin: '15px' }}>
          <Button
            type='submit'
            className='btn--primary'
            handleClick={() =>
              handleProceed(type, offset, type === 'csv' ? csvTeam : xmlTeam)
            }
          >
            Proceed
          </Button>
        </div>
        <div style={{ margin: '15px' }}>
          <Button type='submit' handleClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DownloadForm
