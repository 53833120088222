import { Coordinate } from '../pitches/types'
import { FormattedPlayerSession } from '../sessions/types'
import { Group } from '../types'
import {
  DeviceTypeValues,
  DeviceType,
  DeviceTypeKeys,
  DeviceTypes
} from './data_types'

export type HardwareProductNames =
  | 'Junco'
  | 'Swift'
  | 'Anamo'
  | 'Civet'
  | 'Unknown'

export type HardwareStates = 'online' | 'offline' | 'sleep'
export type HardwareStatusTypeKeys = 'balls' | 'anchors' | 'tags'

export interface HardwareState {
  tagIdsIgnoredForSleepAndWakeAll: number[]
  diagnostics: {
    hardwareIndex: number[]
    encodedHardwareIndex: string[]
    countMatrix: number[][]
    rssiMatrix: number[][]
    imuLenMatrix: number[][]
    distanceMatrix: number[][]
    status: {
      [key in HardwareStates]: {
        [key in HardwareStatusTypeKeys]: number[]
      }
    }
    hardwareState: {
      devices: Devices
    }
    finalAnchorIndex: number
    masterSignalStrength: number[]
  }
}

export type TagPackets = {
  [id: number]: TagPacket
}

export interface TagPacket {
  type
  id
  serial
  position
  time
}

export interface Devices {
  [id: number]: DeviceState
}

export interface DeviceState {
  accStdDeviation: number
  uwbFwVersion: string
  dataMetrics: {
    location: {
      inPitch: boolean
      ballInPlay: boolean
    }
  }
  greyListed: boolean
  id: number
  imuCalibration: {
    calibratedAt: number
    calibration: number
  }
  lastOnline: number
  masterRSSI: number
  offline: boolean
  positionDataRate: number
  productName: HardwareProductNames
  radioCalibration: {
    txDelay: number
    rxDelay: number
    calibratedAt: number
  }
  reports: QAReports
  serial: string
  slowSensors: SlowSensor
  state: string
  type: DeviceTypeValues
  appFwVersion: string
}

export type QAReportItemType = {
  note?: string
  passed: boolean | string
  updatedAt?: string
  updatedBy?: string
}

export type QAReports = {
  fieldQA: QAReportItemType
  hwQA: QAReportItemType
  investigate?: QAReportItemType
  retired?: QAReportItemType
}

export interface SlowSensor {
  appUptime: number
  avgCurrent: number
  battery: number
  deviceId: number
  dwTemp: number
  mcuTemp: number
  state: {
    machine: string
    state: string
  }
  timestamp: number
  uwbRole: string
  uwbUptime: number
  voltage: number
}

export type FormattedHardware = {
  types: {
    [key in DeviceTypeKeys]: {
      type: DeviceType
      status: FormattedHardwareStatus
      devices: Group<FormattedDevice, number>
    }
  }
  status: FormattedHardwareStatus
  devices: Group<FormattedDevice, number>
}

export type FormattedHardwareStatusItem = {
  name: string
  devices: Group<FormattedDevice, number>
}

export type FormattedHardwareStatus = {
  [key in HardwareStates]: FormattedHardwareStatusItem
}

export type FormattedDeviceMetric<v> = {
  value: v
  colour: string
  display: string
}

export interface FormattedDevice {
  id: number
  serial: string
  productName: HardwareProductNames
  battery: FormattedDeviceMetric<number>
  chargingState: ChargingStates
  isInPlay: boolean
  inPitch: DeviceState['dataMetrics']['location']['inPitch']
  type: DeviceType
  status: FormattedDeviceMetric<'online' | 'sleep' | 'offline'>
  positionDataRate: FormattedDeviceMetric<number>
  accelerationStdDev: FormattedDeviceMetric<number>
  positionHealth: FormattedDeviceMetric<number>
  rssi: {
    [id: string]: FormattedDeviceMetric<number>
  }
  imuLength: FormattedDeviceMetric<number>
  anchorMeasurements: {
    [id: string]: FormattedDeviceMetric<number>
  }
  slowSensor: SlowSensor
  master: boolean
  greyListed: boolean
  position: Coordinate
  reports: QAReports
  masterSignalStrength: number
  lastOnline: number
  state: string
}

export interface FormattedSessionDevice {
  id: number
  playerSession: FormattedPlayerSession
  device: FormattedDevice
}

export interface FormattedSessionHardware {
  teams: {
    [id: string]: Group<FormattedSessionDevice, number>
  }
  playerTags: Group<FormattedSessionDevice, number>
  balls: Group<FormattedSessionDevice, number>
  all: Group<FormattedSessionDevice, number>
}

export type ChargingStates = 'notCharging' | 'trickleCharging' | 'charging'

// Action types
export const UPDATE_HARDWARE = 'UPDATE_HARDWARE'
export const TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL =
  'TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL'
