import { useEffect, useMemo, useRef, useState } from 'react'
import { useEvents, useFormattedEvent } from '../../metrics_server/events/hooks'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../store/hooks'
import {
  getEvent,
  getLineChartData,
  updateEvent
} from '../../metrics_server/events/actions'
import { LineChart } from '../LineChart/LineChart'
import { Profile } from './Profile/Profile'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import moment from 'moment'
import { Button, Grid } from '@mui/material'
import BasicSelect from '../Material/Select'
import { Statistic } from '../Statistic/Statistic'
import { eventTypes } from '../../metrics_server/events/data_types'
import { isEventTypeData } from '../../metrics_server/events/functions'
import { useBroadcasting } from '../../metrics_server/broadcasting/hooks'
import { sportableColors } from '../../constants/sportableColors'
import { manualPublish } from '../../metrics_server/broadcasting/actions'
import { useLatestKick } from '../../metrics_server/events/flight/hooks'
import Loader from '../Loader/Loader'

export type AdjustedFontSizeType = {
  h3: string
  h5: string
  p: string
  span: string
}

export type ZoomControls = {
  zoomLevel: number
  zoomIn: () => void
  zoomOut: () => void
  adjustedFontSize: AdjustedFontSizeType
}

export interface FormattedEventDetailCardProps {
  saveFlash: boolean | null
}

export const FormattedEventDetailCard = ({
  saveFlash
}: FormattedEventDetailCardProps) => {
  const dispatch = useAppDispatch()

  const broadcasting = useBroadcasting()
  const { flightGraphs, selectedEventId, rawData } = useEvents()
  const formattedEvent = useFormattedEvent(selectedEventId)
  const formattedSession = useFormattedSession(formattedEvent?.sessionId)

  const [showLineChart, setShowLineChart] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [zoomLevel, setZoomLevel] = useState(1)

  // Manual publish //

  const latestKick = useLatestKick()

  const isLastValidFlight = latestKick && formattedEvent?.id === latestKick.id
  const isPublishedFlight =
    broadcasting.publishedFlights.length >= 1 &&
    broadcasting.publishedFlights.includes(formattedEvent?.id)

  const [showValidKickDataRequestButton, setShowValidKickDataRequestButton] =
    useState(false)

  const [publishButtonLoading, setPublishButtonLoading] = useState(false)

  const handleLastValidKickClick = (flight) => {
    setPublishButtonLoading(true)
    dispatch(manualPublish(flight, () => setPublishButtonLoading(false)))
    timerStart()
    setShowValidKickDataRequestButton(!showValidKickDataRequestButton)
  }

  const countRef = useRef(null)
  const [timer, setTimer] = useState(0)
  const tenSeconds = 10000

  const timerStart = () => {
    clearInterval(countRef.current)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  useEffect(() => {
    if (latestKick) {
      if (isLastValidFlight) {
        timerStart()
      }
      // show button if latest kick is within 10 seconds of current time. timeEnd is in seconds, so we multiply by 1000 to get milliseconds
      if (tenSeconds + latestKick.timeEnd * 1000 >= Date.now()) {
        setShowValidKickDataRequestButton(true)
      } else {
        setShowValidKickDataRequestButton(false)
      }
    }

    return () => {
      clearInterval(countRef.current)
    }
  }, [timer, isLastValidFlight, latestKick])

  const renderFlightPublishFeatures = () => {
    if (publishButtonLoading) {
      return <Loader />
    }
    if (isPublishedFlight) {
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.success,
            lineHeight: '1'
          }}
          disabled
        >
          Data Sent
        </Button>
      )
    }
    if (
      !broadcasting.autoBroadcastEnabled &&
      isLastValidFlight &&
      showValidKickDataRequestButton
    )
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.sportableRed,
            lineHeight: '1'
          }}
          onClick={() => handleLastValidKickClick(latestKick)}
        >
          Send Data
        </Button>
      )

    return null
  }

  // GRAPH DATA //

  const [flightGraphEventId, setFlightGraphEventId] = useState(null)

  // Get full event data //
  useEffect(() => {
    if (showLineChart) {
      dispatch(getEvent(selectedEventId))

      if (
        formattedEvent &&
        formattedEvent.eventType === eventTypes.items.flight.value
      ) {
        setFlightGraphEventId(formattedEvent?.id)
      } else {
        setFlightGraphEventId(formattedEvent?.rawData.associated_event_id)
      }
    }
  }, [showLineChart])

  const [timeCrossedLine, setTimeCrossedLine] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)

  useEffect(() => {
    if (flightGraphEventId) {
      dispatch(getLineChartData(flightGraphEventId))
    }

    const flightGraphEvent = rawData[flightGraphEventId]
    if (flightGraphEvent && isEventTypeData.flight(flightGraphEvent)) {
      setTimeCrossedLine(flightGraphEvent.inPitchHangTime)
      setStartTime(flightGraphEvent.startTime)
      setEndTime(flightGraphEvent.timeEnd)
    }
  }, [flightGraphEventId])

  useEffect(() => {
    // Set new coordinates for flight spin and acc //
    const graphData = flightGraphs[flightGraphEventId]
    if (graphData) {
      setCoordinates(graphData?.data || [])
    }
  }, [flightGraphs])

  // Set impact data for graph //
  const impactData = useMemo(() => {
    if (
      formattedEvent &&
      (formattedEvent.eventType === eventTypes.items.aussieRules.value ||
        formattedEvent.eventType === eventTypes.items.game.value)
    ) {
      return {
        spinGraph: formattedEvent?.rawData?.goalLineGraph?.spinGraph || [],
        impactGraph: formattedEvent?.rawData?.goalLineGraph?.impactGraph || [],
        impactEvents:
          formattedEvent?.rawData?.goalLineGraph?.impactEvents || [],
        hitPost: formattedEvent?.rawData?.goalLineGraph?.hitPost
      }
    } else {
      return {
        spinGraph: [],
        impactGraph: [],
        impactEvents: [],
        hitPost: false
      }
    }
  }, [formattedEvent])

  //hide chart when changing events
  useEffect(() => {
    if (showLineChart) {
      // Clear graph data //
      setTimeCrossedLine(null)
      setStartTime(null)
      setEndTime(null)
      setCoordinates([])
      setFlightGraphEventId(null)

      // Close graph //
      setShowLineChart(!showLineChart)
    }
  }, [selectedEventId])

  // If there is no formatted event selected, return null
  if (!formattedEvent) return null
  // If there is no formatted session for the event, return null
  if (!formattedSession) return null

  const { sport, live, isOfficiatingMode } = formattedSession

  const adjustedFontSize = {
    h3: `${18 * zoomLevel}px`,
    h5: `${14 * zoomLevel}px`,
    p: `${12 * zoomLevel}px`,
    span: `${12 * zoomLevel}px`,

    selectFontSize: `${12 * zoomLevel}px`,
    selectLabelMarginTop: `${8 * (0.7 - (zoomLevel - 1))}px`,
    selectPadding: `${0 * zoomLevel}px`
  }

  const toggleGraph = () => {
    setShowLineChart(!showLineChart)
  }

  const handleZoomIn = () => {
    setZoomLevel((prevProps) => (prevProps += 0.1))
  }
  const handleZoomOut = () => {
    setZoomLevel((prevProps) => (prevProps -= 0.1))
  }

  const zoomControls = {
    zoomLevel: zoomLevel,
    zoomIn: handleZoomIn,
    zoomOut: handleZoomOut,
    adjustedFontSize: adjustedFontSize
  }
  const eventTimeSinceStart = moment
    .unix(formattedEvent?.startTime - formattedSession.startTime.unixSeconds)
    .utc()
    .format('HH:mm:ss')

  const eventStartTime = moment
    .unix(formattedEvent?.startTime)
    .utc()
    .format('HH:mm:ss')

  const metricsWithOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter((metric) => {
        return metric.options && !metric.readonly
      })
    : null
  const metricsWithoutOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && !metric.group
      )
    : null
  const metricsWithoutOptionsWithGroup = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && metric.group
      )
    : null

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={3}>
        {showLineChart && (
          <LineChart
            startTime={startTime}
            endTime={endTime}
            timeCrossedLine={timeCrossedLine}
            impactData={impactData}
            coordinates={coordinates || []}
            width={400}
            height={240}
            marginTop={20}
            marginLeft={40}
            fontSize={10}
          />
        )}
        {formattedEvent.team || formattedEvent.player ? (
          <Profile
            zoomControls={{
              ...zoomControls,
              adjustedFontSize: {
                ...adjustedFontSize,
                h3: `${16 * zoomLevel}px`,
                h5: `${12 * zoomLevel}px`,
                p: `${10 * zoomLevel}px`
              }
            }}
            team={formattedEvent.team?.selected}
            player={formattedEvent.player?.selected}
            sport={sport}
            matchTime={formattedEvent?.operator?.matchTime}
            eventTimeSinceStart={eventTimeSinceStart}
            eventStartTime={eventStartTime}
            operatorNotes={formattedEvent.operator}
            updateMatchTime={debounce((value) => {
              dispatch(
                updateEvent({
                  id: formattedEvent.id,
                  operatorNotes: {
                    matchTime: value
                  }
                })
              )
            }, 500)}
            updateEventHighlight={(value) => {
              const data = { id: formattedEvent.id, operatorNotes: {} }
              if (formattedEvent.operator) {
                data.operatorNotes = {
                  ...formattedEvent.operator,
                  highlight: value
                }
              } else {
                data.operatorNotes = {
                  highlight: value
                }
              }
              dispatch(updateEvent(data))
            }}
            saveFlash={saveFlash}
          />
        ) : null}
      </Grid>
      <Grid item xs={3}>
        {formattedEvent.type && (
          <BasicSelect
            label={'Type'}
            selected={formattedEvent.type.selected.value}
            options={formattedEvent.type.options}
            size={'small'}
            variant={'standard'}
            readonly={!formattedEvent.type.options}
            onChange={(value) =>
              dispatch(
                updateEvent({
                  id: formattedEvent.id,
                  type: value
                })
              )
            }
            fontSize={adjustedFontSize.selectFontSize}
            labelMarginTop={adjustedFontSize.selectLabelMarginTop}
            padding={adjustedFontSize.selectPadding}
          />
        )}
        {formattedEvent.subType && (
          <BasicSelect
            label={'Sub Type'}
            options={formattedEvent.subType.options}
            selected={formattedEvent.subType.selected.value}
            onChange={(value) =>
              dispatch(
                updateEvent({
                  id: formattedEvent.id,
                  subType: value
                })
              )
            }
            size={'small'}
            variant={'standard'}
            fontSize={adjustedFontSize.selectFontSize}
            labelMarginTop={adjustedFontSize.selectLabelMarginTop}
            padding={adjustedFontSize.selectPadding}
          />
        )}
        {formattedEvent.outcome && (
          <BasicSelect
            label={'Outcome'}
            options={formattedEvent.outcome.options}
            selected={formattedEvent.outcome.selected.value}
            onChange={(value) =>
              dispatch(
                updateEvent({
                  id: formattedEvent.id,
                  outcome: value
                })
              )
            }
            size={'small'}
            variant={'standard'}
            fontSize={adjustedFontSize.selectFontSize}
            labelMarginTop={adjustedFontSize.selectLabelMarginTop}
            padding={adjustedFontSize.selectPadding}
          />
        )}
        {metricsWithOptions &&
          metricsWithOptions.map((metric) => {
            return (
              <BasicSelect
                key={metric.key}
                label={metric.name}
                options={metric.options}
                selected={metric.value === null ? '' : metric.value}
                onChange={(value) => {
                  const newValue = value === '' ? null : value
                  dispatch(
                    updateEvent({
                      id: formattedEvent.id,
                      [metric.key]: newValue
                    })
                  )
                }}
                size={'small'}
                variant={'standard'}
                fontSize={adjustedFontSize.selectFontSize}
                labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                padding={adjustedFontSize.selectPadding}
              />
            )
          })}
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={2}>
          {metricsWithoutOptions &&
            metricsWithoutOptions.map((metric) => {
              if (!metric.hideOnDetailCard) {
                return (
                  <Grid item xs={2} key={metric.name}>
                    <Statistic
                      title={metric.name}
                      stat={metric.display || '-'}
                      adjustedFontSize={adjustedFontSize}
                      tag={metric.tag}
                      tagOnClick={
                        metric.tagOnClick
                          ? () => {
                              metric.tagOnClick((key, value) => {
                                dispatch(
                                  updateEvent({
                                    id: formattedEvent.id,
                                    [key]: value
                                  })
                                )
                              })
                            }
                          : null
                      }
                    />
                  </Grid>
                )
              }
            })}
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 5 }}>
          {metricsWithoutOptionsWithGroup &&
            metricsWithoutOptionsWithGroup.map((metric) => {
              if (!metric.hideOnDetailCard) {
                return (
                  <Grid item xs={2} key={metric.name}>
                    <Statistic
                      title={metric.name}
                      stat={metric.display || '-'}
                      adjustedFontSize={adjustedFontSize}
                      tag={metric.tag}
                      tagOnClick={
                        metric.tagOnClick
                          ? () => {
                              metric?.tagOnClick((key, value) => {
                                dispatch(
                                  updateEvent({
                                    id: formattedEvent.id,
                                    [key]: value
                                  })
                                )
                              })
                            }
                          : null
                      }
                    />
                  </Grid>
                )
              }
            })}
        </Grid>
      </Grid>

      <Grid item xs={1}>
        {live &&
          isOfficiatingMode &&
          formattedEvent.eventType !== eventTypes.items.time.value &&
          formattedEvent.features.graph && (
            <Button
              variant='outlined'
              size='small'
              onClick={() => toggleGraph()}
              style={{ height: '30px' }}
            >
              Graph
            </Button>
          )}
        <Grid item xs={1} style={{ marginTop: '5px' }}>
          {renderFlightPublishFeatures()}
        </Grid>
      </Grid>
    </Grid>
  )
}
