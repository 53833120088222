import React from 'react'
import styles from './Shirt.module.scss'
import { ShirtIcon } from '../../Icons/ShirtIcon/ShirtIcon'
import { BatteryIcon } from '../../Icons/BatteryIcon/BatteryIcon'
import {
  PlayerPosition,
  useTagAssignmentDragAndDrop
} from '../../../metrics_server/sessions/hooks'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'
import { useAppDispatch } from '../../../store/hooks'
import {
  removePlayerSession,
  removeTagFromPlayer
} from '../../../metrics_server/sessions/actions'

export interface ShirtProps {
  playerPosition: PlayerPosition
}

export const Shirt = ({ playerPosition }: ShirtProps) => {
  const dispatch = useAppDispatch()

  const formattedHardware = useFormattedHardware()

  const device =
    playerPosition.playerSession &&
    formattedHardware.devices.map[playerPosition.playerSession.hardwareId]

  const { onDragLeave, onDragOver, onShirtDrop } =
    useTagAssignmentDragAndDrop('setup')

  const { playerSession, number } = playerPosition

  return (
    <div
      key={number + 'playerPosition'}
      onDragLeave={(e) => onDragLeave(e)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onShirtDrop(e, playerPosition.teamId)}
      className={styles.container}
      id={`${number}`}
    >
      {!playerSession ? (
        <>
          <div className={styles.icon} id={`${number}`}>
            <ShirtIcon
              primaryColor={'#C4C4C4'}
              secondaryColor={'#858585'}
              id={`${number}`}
              scale={2.3}
            />
          </div>
          <div className={styles.number} id={`${number}`}>
            {number}
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.linkedShirt}
            id={`${number}`}
            onClick={() =>
              dispatch(removePlayerSession(playerSession.playerId))
            }
          >
            <ShirtIcon
              primaryColor={'#0099CC'}
              secondaryColor={'#22648C'}
              id={`${number}`}
              scale={2.3}
            />
          </div>
          {playerSession.player && (
            <div className={styles.nameLinked} id={`${number}`}>
              {playerSession.player.fullName}
            </div>
          )}
          <div className={styles.numberLinked} id={`${number}`}>
            {number}
          </div>
          {device && (
            <div className={styles.tagInformation}>
              <div className={styles.batteryContainer}>
                <BatteryIcon device={device} />
              </div>{' '}
              <div
                className={styles.serial}
                onClick={() => dispatch(removeTagFromPlayer(playerSession))}
              >
                {device.serial}{' '}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
