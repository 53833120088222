import { defaultPitch } from './pitch'
import { americanFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'

export const americanFootballProps = {
  eventTypes: americanFootballEventTypes,
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    tenYardMarkings: 10
  },
  features: {
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'American',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.lost?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        t: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.touchdown?.value
        },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false
  } as const
}
