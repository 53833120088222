import { Deployment, Env, Environment } from '../../utils/env'

export const environment = new Environment(
  window.location.hostname,
  window.localStorage,
  window.location.href
)

export const isLocal = environment.deployment == Deployment.LOCAL
export const isProduction = environment.ENV === Env.PRODUCTION

export const brokerUrl = environment.mqttBroker?.url
export const sportscasterWSProtocol = environment.mqttBroker?.protocol
export const sportscasterWSPath = '/ws'

console.log('broker url:', brokerUrl)
console.log('broker protocol:', sportscasterWSProtocol)
console.log('broker path:', sportscasterWSPath)

export const sportscasterUrl = environment.sportscaster.hostAndPort
export const sportscasterHTTPProtocol = environment.sportscaster.protocol

console.log('sportscaster url:', sportscasterUrl)
