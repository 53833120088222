import { Option, Options, OptionsWithAll } from './data_types'

export const REMOVE_USER_DATA = 'REMOVE_USER_DATA'

export type IDMap<T> = {
  [id: string]: T
}

export function addItemToGroup<
  T extends Record<ValueKey, string | number>,
  ValueKey extends string
>(
  group: Group<T, T[ValueKey]>,
  item: T,
  groupKey: string | number,
  valueKey: ValueKey,
  optionName: string
) {
  group.map[groupKey] = item
  group.list.push(item)
  const option = {
    name: optionName,
    value: item[valueKey] || item['id']
  }
  group.options.push(option)
  group.optionsWithAll.push(option)
  group.optionsWithNull.push(option)
  group.count++
}

export type Group<T, ov = string> = {
  list: T[]
  map: IDMap<T>
  options: Options<ov>
  optionsWithAll: OptionsWithAll<ov>
  optionsWithNull: Options<ov | null>
  count: number
}
