import { useMemo } from 'react'
import { Filter, FilterMultiple, useFilterReducer } from '../../hooks/filter'
import { useFormattedSession } from '../sessions/hooks'
import { DeviceTypeValues, deviceTypes, isDeviceType } from './data_types'
import { useFormattedHardware } from './hooks'
import {
  FormattedDevice,
  FormattedSessionDevice,
  FormattedSessionHardware,
  HardwareStates
} from './types'
import { getEmptyGroup } from '../sessions/functions'
import { addItemToGroup } from '../types'

export type HardwareFilters = {
  assigned: Filter<'all' | 'assigned' | 'notAssigned'>
  status: FilterMultiple<HardwareStates>
  type: Filter<DeviceTypeValues | 'All'>
  team: Filter<string | 'All'>
}

export const useHardwareFilter = (
  initialValues: Partial<{
    [key in keyof HardwareFilters]: HardwareFilters[key]['value']
  }>,
  sessionId: string
) => {
  const formattedHardware = useFormattedHardware()

  const formattedSession = useFormattedSession(sessionId)

  const initialHardwareFilterState: HardwareFilters = {
    assigned: {
      options: [
        { name: 'All', value: 'all' },
        { name: 'Assigned', value: 'assigned' },
        { name: 'Not Assigned', value: 'notAssigned' }
      ],
      value: initialValues.assigned || 'all'
    },
    status: {
      options: [
        { name: 'Online', value: 'online' },
        { name: 'Sleep', value: 'sleep' },
        { name: 'Offline', value: 'offline' }
      ],
      value: initialValues.status || []
    },
    type: {
      options: deviceTypes.optionsWithAll,
      value: initialValues.type || 'All'
    },
    team: {
      options: formattedSession?.teams.optionsWithAll,
      value: initialValues.team || 'All'
    }
  }

  const { filters, updateFilterValue } = useFilterReducer<HardwareFilters>(
    initialHardwareFilterState
  )

  const filteredHardware = useMemo(() => {
    const filteredHardware = getEmptyGroup<FormattedDevice, number>()
    formattedHardware.devices.list
      // Filter devices by type
      .filter((device) => {
        if (filters.type.value === 'All') return true
        return device.type.value === filters.type.value
      })
      // Filter devices by team (remove all devices that are assigned to a team that is not selected)
      .filter((device) => {
        const playerSession =
          formattedSession.playersSessions.byHardwareId.map[device.id]
        // If team is set to all, return all devices
        if (filters.team.value === 'All') return true
        // If team filter is set, return only player tags
        if (!isDeviceType.playerTag(device.type)) return false
        // Remove device that are already assigned to a different team
        if (
          playerSession &&
          playerSession.teamId &&
          filters.team.value !== playerSession.teamId
        )
          return false
        return true
      })
      // Filter devices by session assignment
      .filter((device) => {
        const playerSession =
          formattedSession.playersSessions.byHardwareId.map[device.id]
        if (filters.assigned.value === 'all') return true
        if (filters.assigned.value === 'assigned') return playerSession
        if (filters.assigned.value === 'notAssigned') return !playerSession
        return true
      })
      // Filter devices by status
      .filter((device) => {
        if (filters.status.value.length === 0) return true
        return (
          (filters.status.value.includes('online') &&
            device.status.value === 'online') ||
          (filters.status.value.includes('offline') &&
            device.status.value === 'offline') ||
          (filters.status.value.includes('sleep') &&
            device.status.value === 'sleep')
        )
      })

      .forEach((device) => {
        // Add item to all group
        addItemToGroup(filteredHardware, device, device.id, 'id', device.serial)
      })
    return filteredHardware
  }, [formattedSession, formattedHardware, filters])

  return {
    filteredHardware,
    filters,
    updateFilterValue
  }
}
