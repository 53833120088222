import React, { Component, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as SessionSetupScreen from '../../views/SessionSetup/config'

import * as sessionActions from '../../metrics_server/sessions/actions'
import * as teamActions from '../../metrics_server/teams/actions'
import * as pitchActions from '../../metrics_server/pitches/actions'

// MS Types
import {
  RawSessionData,
  SessionData,
  SessionsState
} from '../../metrics_server/sessions/types'
import { SessionTypeValues } from '../../metrics_server/sessions/data_types'
import { TeamsState } from '../../metrics_server/teams/types'
import { PlayersState } from '../../metrics_server/players/types'
import { UserState } from '../../metrics_server/user/types'
import { PitchesState } from '../../metrics_server/pitches/types'
import { SportTypeValues } from '../../metrics_server/sports/data_types'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../store/hooks'
import { setRedirect } from '../../ui/router/actions'
import { RootState } from '../../store'
import { getTeamPlayers } from '../../metrics_server/teams/actions'
import { useFormattedHardware } from '../../metrics_server/hardware/hooks'

const actions = _.assign({}, teamActions, sessionActions, pitchActions)

export interface StartSessionContainerProps {
  sessions: SessionsState
  teams: TeamsState
  players: PlayersState
  user: UserState
  pitches: PitchesState

  bTeamActive: true

  updateSession
  getRecentSessions
  setTeam
  setBTeam
  addPlayerSession
  setSession

  setRedirect
}

export const CreateSessionContainer: any = (ChildComponent) => {
  const ComposedComponent = (props: StartSessionContainerProps) => {
    const { pitches, players } = props

    const dispatch = useAppDispatch()

    const formattedSession = useFormattedSession('setup')

    const formattedHardware = useFormattedHardware()

    // If session team changes fetch players for that team
    const teamIdsRef = useRef<string[]>([])
    useEffect(() => {
      if (formattedSession) {
        formattedSession.teams.list.forEach((team) => {
          if (teamIdsRef.current.includes(team.id)) return
          dispatch(getTeamPlayers(team.id))
        })
        teamIdsRef.current = formattedSession.teams.list.map((team) => team.id)
      }
    }, [formattedSession, dispatch])

    // If officiating mode is selected, fetch players for officiation team
    useEffect(() => {
      if (
        formattedSession &&
        formattedSession.isOfficiatingMode &&
        formattedSession.officiationTeam
      ) {
        dispatch(getTeamPlayers(formattedSession.officiationTeam.id))
      }
    }, [formattedSession, dispatch])

    const getTeamsRecentSessions = useCallback(
      (
        teamId: string,
        sessionType: SessionTypeValues,
        sportType: SportTypeValues
      ) => {
        if (!formattedSession) return
        const { sport } = formattedSession
        const data = {
          limitStart: 0,
          limitQuantity: 10,
          startTime: 0,
          endTime: new Date().getTime() / 1000,
          teamIds: [teamId]
        }

        return dispatch(
          sessionActions.getRecentSessions(data, (recentSessions) => {
            const lastSession = recentSessions.find((session) => {
              return (
                session.type === sessionType && session.sportType === sportType
              )
            })
            if (lastSession && lastSession.playersSessions) {
              lastSession.playersSessions.forEach((ps) => {
                const psWithoutId = { ...ps }
                delete psWithoutId.id
                delete psWithoutId.sessionId
                //ignore when player's number has been changed and
                if (
                  !sport.props.features.positionNumbers &&
                  ps.number !== ps.player?.number
                )
                  return
                // If playerSession is part of the correct team or is a ball
                if (
                  psWithoutId.teamId === teamId &&
                  !!players.items[ps.playerId]
                ) {
                  dispatch(sessionActions.addPlayerSession(psWithoutId))
                } else if (
                  !psWithoutId.playerId &&
                  psWithoutId.tag &&
                  formattedHardware.devices.map[psWithoutId.tag.id]
                ) {
                  dispatch(sessionActions.addPlayerSession(psWithoutId))
                }
              })
            }
          })
        )
      },
      [players, formattedSession]
    )

    const submitSessionForm = useCallback(async () => {
      // Clear playersSessions and use each selected teams' most recent session's playersSessions
      // Use pitch in use if it matches the sportType

      if (!formattedSession) return

      const { sport, type, teams } = formattedSession

      dispatch(
        sessionActions.updateSession({
          playersSessions: [],
          pitch: pitches.inUse.type === sport.value ? pitches.inUse : null
        })
      )

      await Promise.all(
        teams.list.map(({ id }) => {
          return getTeamsRecentSessions(id, type.value, sport.value)
        })
      )

      dispatch(setRedirect(SessionSetupScreen.path))
    }, [pitches, formattedSession, dispatch, getTeamsRecentSessions])

    if (formattedSession) {
      return (
        <ChildComponent
          formattedSession={formattedSession}
          {...props}
          side={'right'}
          onSubmit={submitSessionForm}
        />
      )
    } else {
      return null
    }
  }

  function mapStateToProps(state: RootState) {
    return {
      players: state.players,
      sessions: state.sessions,
      pitches: state.pitches
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}

export const toggleColors = {
  activeThumb: {
    base: 'rgb(250,250,250)'
  },
  inactiveThumb: {
    base: '#0099CC'
  },
  active: {
    base: '#3E3E3E',
    hover: '#3E3E3E'
  },
  inactive: {
    base: '#3E3E3E',
    hover: '#3E3E3E'
  }
}
