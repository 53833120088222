import {
  // API actions
  GET_SESSION_FLIGHTS,
  SELECT_EVENT,
  UPDATE_FLIGHT,
  BULK_UPDATING_FLIGHTS,
  ADD_FLIGHT,
  UPDATE_SELECTED_COLUMNS,
  SET_SESSION_TIME_EVENTS,
  SET_STOPPAGE_LOADING,
  SET_PENALTY_COUNTDOWN_ACTIVE,
  SET_SESSION_GAME_EVENTS,
  UPDATE_EVENT,
  CLEAR_SESSION_EVENTS,
  ADD_EVENT,
  GET_SESSION_EVENTS,
  GET_LINE_GRAPH_DATA,
  GET_FLIGHT,
  RESET_SELECTED_EVENT,
  GET_EVENT
} from './types'

import { setError } from '../../ui/error/actions'
import { API_ROOT_URL, BROADCAST_ROOT_URL } from '../../const'
import { request } from '../../utils/request_handler'
import { updateSession } from '../sessions/actions'
import { getFullTarget } from '../targets/actions'
import _ from 'lodash'
import { TargetsState } from '../targets/types'
import { getEventType } from '../../constants/eventTypes'

import { isEventTypeData } from './functions'
import { getUnitSystemFromSession } from '../units/functions'

// Get session events
export function getSessionEvents(sessionId) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response) => {
      dispatch({
        type: GET_SESSION_EVENTS,
        payload: { sessionId, data: response.data }
      })
      dispatch(getPenaltyCountdownState())
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session events. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/events?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Add Event
export function addEvent(event) {
  return (dispatch, getState) => {
    // Reload target if selected
    const { selected }: TargetsState = getState().targets
    if (selected.selectedTarget) {
      // Timeout delay to allow time for flight to be saved in the db before fetching target data
      setTimeout(() => {
        dispatch(getFullTarget(selected.selectedTarget, selected.id))
      }, 500)
    }
    // Check penalty countdown state
    dispatch(getPenaltyCountdownState())
    // Add event to store //
    dispatch({
      type: ADD_EVENT,
      payload: event
    })
  }
}

// Get session flights
export function getSessionFlights(sessionId) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response) => {
      dispatch({
        type: GET_SESSION_FLIGHTS,
        payload: { sessionId, data: response.data }
      })
      dispatch(getPenaltyCountdownState())
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session flights. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/flights?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Set selected flight
export function setSelectedFlight(flightId) {
  return {
    type: SELECT_EVENT,
    payload: flightId
  }
}

// Update Event
export function updateEvent(data) {
  return (dispatch, getState) => {
    const item = getState().events.rawData[data.id]
    let update
    if (isEventTypeData.flight(item)) {
      update = updateFlight
    } else if (isEventTypeData.game(item)) {
      update = updateGameEvent
    } else if (isEventTypeData.aussieRules(item)) {
      update = updateAussieRulesEvent
    }
    if (update) dispatch(update(data))
  }
}

export const resetSelectedEvent = () => {
  return {
    type: RESET_SELECTED_EVENT
  }
}

// Update Game Event
export function updateGameEvent(event) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const stateEvent = getState().events.rawData[event.id]
    const { id } = getState().sessions.selected
    const updatedEvent = { ...stateEvent, ...event }

    const success = (response) => {
      dispatch({
        type: UPDATE_EVENT,
        payload: response.data
      })
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update event. API request failed. Check log for more detail.`
        })
      )
    }
    updateEventRequest(
      updatedEvent,
      id,
      updatedEvent.eventId,
      dispatch,
      success,
      error,
      unitSystem
    )
  }
}

// Update Aussie Rules Event
export function updateAussieRulesEvent(event) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const stateEvent = getState().events.rawData[event.id]
    const sessionId = getState().sessions.selected.id
    const updatedEvent = { ...stateEvent, ...event }

    const success = (response) => {
      dispatch({
        type: UPDATE_EVENT,
        payload: response.data
      })
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update event. API request failed. Check log for more detail.`
        })
      )
    }
    updateEventRequest(
      updatedEvent,
      sessionId,
      updatedEvent.id,
      dispatch,
      success,
      error,
      unitSystem
    )
  }
}

// Get Flight
export function getFlight(flightId) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response) => {
      dispatch({
        type: GET_FLIGHT,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get flight. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `flights/${flightId}?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Get Flight
export function getEvent(eventId) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response) => {
      dispatch({
        type: GET_EVENT,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      console.log(
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to get event. API request failed. Check log for more detail.`
      )
      // dispatch(
      //   setError({
      //     message:
      //       error.response &&
      //       error.response.data &&
      //       error.response.data[errorType]
      //         ? error.response.data[errorType]
      //         : `Failed to get event. API request failed. Check log for more detail.`
      //   })
      // )
    }
    const options = {
      url: `events/${eventId}?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

function updateEventRequest(
  data,
  sessionId,
  eventId,
  dispatch,
  success,
  error,
  unitSystem
) {
  const options = {
    url: `sessions/${sessionId}/events/${eventId}?units=${unitSystem.key}`,
    data: data,
    method: 'put',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return request(options, success, error, dispatch)
}

// Update flight
export function updateFlight(flight, notifySuccess?) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    const stateFlight = getState().events.rawData[flight.id]
    const data = { ...stateFlight, ...flight }

    const eventType = getEventType(data.type)

    if (eventType) {
      data.type = eventType.oldValue
    }

    const success = (response) => {
      dispatch({
        type: UPDATE_EVENT,
        payload: response.data
      })
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update flight. API request failed. Check log for more detail.`
        })
      )
    }

    updateFlightRequest(data, dispatch, success, error, unitSystem)
  }
}

function updateFlightRequest(data, dispatch, success, error, unitSystem) {
  const options = {
    url: `flights/${data.id}?units=${unitSystem.key}`,
    data: data,
    method: 'put',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return request(options, success, error, dispatch)
}

// Bulk update flights
export function bulkUpdateFlights(flights, newData) {
  return (dispatch, getState) => {
    const { units } = getState()
    dispatch({
      type: BULK_UPDATING_FLIGHTS,
      payload: true
    })

    const successfullyUpdatedFlights = []

    const success = (response) => {
      successfullyUpdatedFlights.push(response.data)
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update all flights. API request failed. Check log for more detail.`
        })
      )
    }

    const queries = flights.map((item) =>
      updateFlightRequest(
        { ...item, ...newData },
        dispatch,
        success,
        error,
        units
      )
    )
    Promise.all(queries).then(() => {
      successfullyUpdatedFlights.forEach((item) => {
        dispatch({
          type: UPDATE_FLIGHT,
          payload: item
        })
        dispatch({
          type: BULK_UPDATING_FLIGHTS,
          payload: false
        })
      })
    })
  }
}

// Add flight
export function addFlight(flight, sport) {
  return (dispatch, getState) => {
    // Reload target if selected
    const { selected }: TargetsState = getState().targets
    if (selected.selectedTarget) {
      // Timeout delay to allow time for flight to be saved in the db before fetching target data
      setTimeout(() => {
        dispatch(getFullTarget(selected.selectedTarget, selected.id))
      }, 500)
    }
    dispatch(getPenaltyCountdownState())
    dispatch({
      type: ADD_FLIGHT,
      payload: {
        flight
      }
    })
  }
}

// Time Events
export function getSessionTimeEvents(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      const data = response.data || []
      dispatch({
        type: SET_SESSION_TIME_EVENTS,
        payload: { sessionId, data: data }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session timings. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/timings`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function createTimeEvent(data) {
  return (dispatch) => {
    dispatch({
      type: SET_STOPPAGE_LOADING,
      payload: { data: true }
    })
    const success = (response) => {
      dispatch({
        type: SET_SESSION_TIME_EVENTS,
        payload: { data: Object.values(response.data.timeEvents) }
      })
      dispatch(updateSession(response.data))
      setTimeout(() => {
        dispatch({
          type: SET_STOPPAGE_LOADING,
          payload: { data: false }
        })
      }, 500)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create time event. API request failed. Check log for more detail.`
        })
      )
    }

    const options = {
      url: `/events/time`,
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function endTimeEvent(eventId, data, gameEvent?) {
  return (dispatch) => {
    dispatch({
      type: SET_STOPPAGE_LOADING,
      payload: { data: true }
    })
    const success = (response) => {
      dispatch({
        type: SET_SESSION_TIME_EVENTS,
        payload: { data: Object.values(response.data.timeEvents) }
      })
      dispatch(updateSession(response.data))
      setTimeout(() => {
        dispatch({
          type: SET_STOPPAGE_LOADING,
          payload: { data: false }
        })
      }, 500)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to end time event. API request failed. Check log for more detail.`
        })
      )
      dispatch({
        type: SET_STOPPAGE_LOADING,
        payload: { data: false }
      })
    }
    const options = {
      url: `events/time/${eventId}`,
      data: data,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    if (gameEvent) {
      options.url += `?gameEvent=${gameEvent}`
    }
    return request(options, success, error, dispatch)
  }
}

export function updateTimeEvent(eventId, data) {
  return (dispatch, getState) => {
    const { events } = getState()
    const timeEvent = events.rawData[eventId]
    const success = (response) => {
      dispatch({
        type: SET_SESSION_TIME_EVENTS,
        payload: { data: Object.values(response.data.timeEvents) }
      })
      dispatch(updateSession(response.data))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update time event. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `events/time/${eventId}`,
      data: { ...timeEvent, ...data },
      method: 'patch',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function deleteTimeEvent(eventId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: SET_SESSION_TIME_EVENTS,
        payload: {
          data: Object.values(response.data.timeEvents),
          deletedId: eventId
        }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete time event. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `events/time/${eventId}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Game Events
export function getSessionGameEvents(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: SET_SESSION_GAME_EVENTS,
        payload: { sessionId, data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session events. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/gameEvents`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function createGameEvent(sessionId, data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: ADD_EVENT,
        payload: response.data
      })
    }
    const error = (error) => {
      console.log(error)
      dispatch(
        setError({
          message: `Failed to create game event. API request failed. Check log for more detail.`
        })
      )
    }

    const options = {
      url: `/sessions/${sessionId}/gameEvents`,
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function clearSessionEvents() {
  return {
    type: CLEAR_SESSION_EVENTS
  }
}

export function updateSelectedColumns(columns) {
  return {
    type: UPDATE_SELECTED_COLUMNS,
    payload: columns
  }
}

export function setPenaltyCountdownActive(bool) {
  return {
    type: SET_PENALTY_COUNTDOWN_ACTIVE,
    payload: bool
  }
}

export function getPenaltyCountdownState() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setPenaltyCountdownActive(response.data.enabled))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get penalty countdown state. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `penaltyCountdown`,
      method: 'get',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function startPenaltyCountdown() {
  return (dispatch) => {
    const success = () => {
      dispatch(getPenaltyCountdownState())
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to start penalty countdown. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `penaltyCountdown`,
      method: 'post',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function clearPenaltyCountdown() {
  return (dispatch) => {
    const success = () => {
      dispatch(getPenaltyCountdownState())
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to clear penalty countdown. API request failed. Check log for more detail `
        })
      )
    }
    const options = {
      url: `penaltyCountdown`,
      method: 'delete',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Ball in play

export function setBallInPlay(ballId) {
  return (dispatch) => {
    const success = () => null
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to set ball in play. API request failed. Check log for more detail `
        })
      )
    }
    const options = {
      url: `events/ballInPlay/${ballId}`,
      method: 'post',
      data: {},
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function getLineChartData(flightId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: GET_LINE_GRAPH_DATA,
        payload: { [flightId]: { data: response.data } }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get Line Chart Data. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `/flights/${flightId}/rawData`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
