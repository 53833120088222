import React, { Component } from 'react'
import * as Sentry from '@sentry/react'

import { connect } from 'react-redux'
import _ from 'lodash'

import * as errorActions from '../../ui/error/actions'
import { SetError } from '../../ui/error/types'

const actions = _.assign({}, errorActions)

interface DispatchProps {
  setError: SetError
}

interface OwnProps {
  children: React.ReactNode
}

type ErrorBoundaryProps = OwnProps & DispatchProps

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })

    //Send to Sentry
    Sentry.captureException(error)

    const { setError } = this.props
    setError({
      message: `Unexpected error occured. You will be redirected to the home page`,
      error: error,
      type: 'fatal',
      proceed: () => {
        window.location.reload()
      }
    })
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div id='fallback'>
          <noscript />
        </div>
      )
    }
    return this.props.children
  }
}

export default connect<null, DispatchProps, OwnProps>(
  null,
  actions
)(ErrorBoundary)
