import { defaultPitch } from './pitch'
import { demonstrationEventTypes } from './events'
import { pitchSetupTypes } from '../../pitches/data_types'

export const demonstrationProps = {
  eventTypes: demonstrationEventTypes,
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11
  },
  features: {
    targetEvents: [],
    positionNumbers: false,
    maxPlayers: 50,
    defaultUnitSystem: 'SI',
    keyboardShortcuts: {},
    recentEventTableMetrics: [],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false
  } as const
}
