import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'
import {
  SubSessionTypeGroup,
  getSubSessionTypeGroup,
  subSessionTypesConfig
} from './sub_sessions/data_types'

export type SessionTypeFeatures = {
  disableOption: boolean
  broadcastIntegrationAvailable: boolean
}

export const defaultSessionTypeFeatures: SessionTypeFeatures = {
  disableOption: false,
  broadcastIntegrationAvailable: false
}

export const sessionTypesConfig = {
  unknown: {
    key: 'unknown',
    name: 'Unknown',
    value: -1,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown
      }),
      features: { ...defaultSessionTypeFeatures, disableOption: true }
    }
  },
  unclassified: {
    key: 'unclassified',
    name: 'Unclassified',
    value: 0,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown,
        dataGathering: subSessionTypesConfig.dataGathering,
        demo: subSessionTypesConfig.demo,
        test: subSessionTypesConfig.test,
        teamPractice: subSessionTypesConfig.teamPractice
      }),
      features: { ...defaultSessionTypeFeatures, disableOption: true }
    }
  },
  kicking: {
    key: 'kicking',
    name: 'Kicking',
    value: 1,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown,
        dataGathering: subSessionTypesConfig.dataGathering,
        demo: subSessionTypesConfig.demo,
        test: subSessionTypesConfig.test,
        teamPractice: subSessionTypesConfig.teamPractice
      }),
      features: { ...defaultSessionTypeFeatures, disableOption: true }
    }
  },
  passing: {
    key: 'passing',
    name: 'Passing',
    value: 2,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown,
        dataGathering: subSessionTypesConfig.dataGathering,
        demo: subSessionTypesConfig.demo,
        test: subSessionTypesConfig.test,
        teamPractice: subSessionTypesConfig.teamPractice
      }),
      features: { ...defaultSessionTypeFeatures, disableOption: true }
    }
  },
  match: {
    key: 'match',
    name: 'Match',
    value: 3,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown,
        dataGathering: subSessionTypesConfig.dataGathering,
        demo: subSessionTypesConfig.demo,
        test: subSessionTypesConfig.test,
        broadcast: subSessionTypesConfig.broadcast
      }),
      features: {
        ...defaultSessionTypeFeatures,
        broadcastIntegrationAvailable: true
      }
    }
  },
  training: {
    key: 'training',
    name: 'Training',
    value: 4,
    props: {
      subTypes: getSubSessionTypeGroup({
        unknown: subSessionTypesConfig.unknown,
        dataGathering: subSessionTypesConfig.dataGathering,
        demo: subSessionTypesConfig.demo,
        test: subSessionTypesConfig.test,
        teamPractice: subSessionTypesConfig.teamPractice
      }),
      features: {
        ...defaultSessionTypeFeatures
      }
    }
  }
} as const

export type SessionTypeProps = {
  subTypes: SubSessionTypeGroup
  features: SessionTypeFeatures
}

export type SessionTypes = DataTypes<typeof sessionTypesConfig>

export type SessionTypeKey = DataTypeKey<SessionTypes>

export type SessionTypeKeys = DataTypeKeys<SessionTypeKey>

export type SessionTypeValues = DataTypeValues<SessionTypeKey>

export const getSessionTypeGroup = (items: {
  [key in SessionTypeKeys]?: SessionTypes[key]
}) => {
  return getDataTypeGroup<
    SessionTypeKeys,
    SessionTypeValues,
    SessionTypes,
    SessionTypeProps
  >(items)
}

export type SessionTypeGroup = ReturnType<typeof getSessionTypeGroup>

export type SessionTypeConfig = DataTypeConfig<
  SessionTypeKeys,
  SessionTypeValues,
  SessionTypeProps
>

export const sessionTypes = getSessionTypeGroup(sessionTypesConfig)

export type SessionType = SessionTypeGroup['items'][SessionTypeKeys]

export const isSessionType = generateTypeChecks<
  SessionTypeKeys,
  SessionTypeGroup
>(sessionTypes)
