import pitchTexture from '../../../assets/img/pitch-texture.png'
import boxingTexture from '../../../assets/img/boxing-texture.png'

import { calculateOffsetAndScale } from '../field'

import { draw2DCircle } from './drawing'

import { drawRugbyLines } from '../sports/rugby'
import { drawRugbyLeagueLines } from '../sports/rugby_league'
import {
  drawAmericanFootballLines,
  draw2DAmericanFootballPitchNumbers
} from '../sports/american_football'
import {
  drawCanadianFootballLines,
  drawCanadianFootballPitchNumbers
} from '../sports/canadian_football'
import { drawBoxingLines } from '../sports/boxing'
import { drawAustralianRulesLines } from '../sports/australian_rules'

import { drawDemonstrationLines } from '../sports/demonstration'
import { sportTypes } from '../../../metrics_server/sports/data_types'
import { StrackController } from '../strack'
import { drawSoccerLines } from '../sports/soccer'

export function load2D(props) {
  const {
    field,
    canvasId2D,
    coverId,
    canvasSectionWidth,
    canvasHeight,
    anchorSetup,
    canvasContainer,
    pitchType,
    getCanvasCoordinate,
    fieldWidth,
    fieldHeight,
    clearMapFrame,
    teams,
    dimensions
  } = props

  props.twoDCanvas = document.getElementById(canvasId2D)
  props.twoDCanvas.style.boxShadow = 'inset 0px 0px 66px rgba(0,0,0,0.5)'
  props.coverCanvas = document.getElementById(coverId)

  const { twoDCanvas, coverCanvas } = props

  // Set offset based on pitch type

  const sport = sportTypes.getTypeByValue(pitchType)

  let fieldScreenWidth = field.width
  let fieldScreenHeight = field.height

  if (props.pitchRotated) {
    fieldScreenWidth = field.height
    fieldScreenHeight = field.width
  }

  const os = calculateOffsetAndScale(
    field,
    canvasSectionWidth,
    canvasHeight,
    fieldScreenWidth,
    fieldScreenHeight,
    anchorSetup,
    sport.props.pitch.minPadding2D
  )
  props.twoDOffsetX = os.offsetX
  props.twoDOffsetY = os.offsetY
  props.scale = os.scale

  const { twoDOffsetX, twoDOffsetY, scale } = props
  // TODO apply * props.canvasElementPixelRatio
  props.canvas2DPixelScale = scale
  canvasContainer.style.width = `${
    (fieldScreenWidth + twoDOffsetX * 2) * scale
  }px`

  props.mapCtx = twoDCanvas.getContext('2d')
  props.coverCtx = coverCanvas.getContext('2d')

  const { canvas2DPixelScale, coverCtx, session } = props

  // Set scaled dimensions and position of canvas and cover

  twoDCanvas.style.width = `${(fieldScreenWidth + twoDOffsetX * 2) * scale}px`
  twoDCanvas.style.height = `${(fieldScreenHeight + twoDOffsetY * 2) * scale}px`
  twoDCanvas.width = (fieldScreenWidth + twoDOffsetX * 2) * canvas2DPixelScale
  twoDCanvas.height = (fieldScreenHeight + twoDOffsetY * 2) * canvas2DPixelScale

  coverCanvas.style.top = `0px`
  coverCanvas.style.left = `0px`
  coverCanvas.style.backgroundPosition = 'center'
  coverCanvas.style.width = `${(fieldScreenWidth + twoDOffsetX * 2) * scale}px`
  coverCanvas.style.height = `${
    (fieldScreenHeight + twoDOffsetY * 2) * scale
  }px`
  coverCanvas.width = twoDCanvas.width
  coverCanvas.height = twoDCanvas.height

  const backgroundImg = new Image()
  const centerPitchLogo = new Image()

  clearMapFrame(coverCtx)

  // New sport needs to be added here
  switch (pitchType) {
    case sportTypes.items.demonstration.value:
      coverCanvas.style.background = field.color
      backgroundImg.src = pitchTexture
      drawDemonstrationLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      break
    case sportTypes.items.rugbyUnion.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      backgroundImg.src = pitchTexture
      drawRugbyLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      // only draw center logo when session is live (not in setup)
      if (!anchorSetup.active && !session.isMatchMode) {
        centerPitchLogo.onload = () => {
          coverCtx.save()
          coverCtx.globalAlpha = 0.7
          coverCtx.drawImage(
            centerPitchLogo,
            getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - 50 / 2,
            getCanvasCoordinate(canvas2DPixelScale, null, field.height / 2)
              .scaleY -
              50 / 2,
            50,
            50
          )
          coverCtx.restore()
        }
      }
      centerPitchLogo.src = teams.A.logo
      break
    case sportTypes.items.rugbyLeague.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawRugbyLeagueLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      break
    case sportTypes.items.americanFootball.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawAmericanFootballLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      draw2DAmericanFootballPitchNumbers(
        field,
        dimensions,
        getCanvasCoordinate,
        canvas2DPixelScale,
        coverCtx
      )
      break
    // case sportTypes.items.boxing?.value:
    //   drawBoxingLines(props, 'rgba(255,255,255,0.9)')
    //   coverCanvas.style.background = field.color
    //   coverCanvas.style.backgroundImage = `url(${boxingTexture})`
    //   break
    case sportTypes.items.australianRules.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      backgroundImg.src = pitchTexture
      drawAustralianRulesLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale,
        twoDCanvas,
        null
      )
      break
    case sportTypes.items.canadianFootball.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawCanadianFootballLines(
        props,
        'rgba(255,255,255,1)',
        3,
        coverCtx,
        canvas2DPixelScale,
        null
      )
      // const { dimensions } = props
      drawCanadianFootballPitchNumbers(
        field,
        dimensions,
        getCanvasCoordinate,
        canvas2DPixelScale,
        coverCtx,
        canvasHeight * 0.02453271028
      )
      // only draw center logo when session is live (not in setup)
      if (!anchorSetup.active) {
        centerPitchLogo.onload = () => {
          coverCtx.save()
          coverCtx.globalAlpha = 0.7
          coverCtx.drawImage(
            centerPitchLogo,
            getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - 50 / 2,
            getCanvasCoordinate(canvas2DPixelScale, null, field.height / 2)
              .scaleY -
              50 / 2,
            50,
            50
          )
          coverCtx.restore()
        }
      }
      centerPitchLogo.src = teams.A.logo
      break
    case sportTypes.items.soccer.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawSoccerLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale,
        null,
        props.pitchFlipped,
        props.pitchRotated
      )
      break
    default:
      break
  }

  // Create rugby players
  create2DTags(props)

  // Draw axis
  if (anchorSetup.active) {
    const canvasOrigin = getCanvasCoordinate(canvas2DPixelScale, 0, 0)
    drawAxisArrows(
      coverCtx,
      { x: canvasOrigin.scaleX, y: canvasOrigin.scaleY },
      props.pitchFlipped,
      sport
    )
  }

  // // Draw person
  // let personImgWidth = 30
  // let personImg = new Image()
  // personImg.src = personImgSrc
  // personImg.onload = (event) => {
  //   if (coverCtx) {
  //     coverCtx.drawImage(
  //       event.target,
  //       getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - personImgWidth / 2,
  //       getCanvasCoordinate(canvas2DPixelScale, null, 0).scaleY,
  //       personImgWidth,
  //       personImgWidth
  //     )
  //   }
  // }

  // Debug

  // coverCtx.save()

  // for (let i in dimensions) {
  //   let point = dimensions[i]
  //   let coordinate = getCanvasCoordinate(canvas2DPixelScale, point.x, point.y)
  //   coverCtx.textAlign = 'center'
  //   coverCtx.fillStyle = '#000000'
  //   coverCtx.strokeStyle = '#000000'
  //   coverCtx.font = '14px Mark Pro'
  //   let number = i.split('P')[1]
  //   coverCtx.fillText(number, coordinate.scaleX, coordinate.scaleY)
  //   coverCtx.fillText(number, coordinate.scaleX, coordinate.scaleY)
  // }
  // coverCtx.restore()
}

function drawAxisArrows(ctx, origin, invertAxes, sport) {
  if (sport.value === sportTypes.items.soccer.value) return

  ctx.strokeStyle = 'blue'
  ctx.lineWidth = 1

  const xSign = invertAxes ? -1 : 1
  const ySign = invertAxes ? 1 : -1

  // Draw x-axis
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y)
  ctx.lineTo(origin.x + 20 * xSign, origin.y)
  ctx.stroke()

  // Draw y-axis
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x, origin.y)
  ctx.stroke()
  // Draw x-axis arrow
  ctx.beginPath()
  ctx.moveTo(origin.x + 20 * xSign, origin.y)
  ctx.lineTo(origin.x + 20 * xSign - 4 * xSign, origin.y - 2)
  ctx.moveTo(origin.x + 20 * xSign, origin.y)
  ctx.lineTo(origin.x + 20 * xSign - 4 * xSign, origin.y + 2)
  ctx.stroke()

  // Draw y-axis arrow
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x - 2, origin.y + 20 * ySign - 4 * ySign)
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x + 2, origin.y + 20 * ySign - 4 * ySign)
  ctx.stroke()

  // Draw labels
  ctx.font = '15px Arial'
  ctx.fillStyle = 'black'
  ctx.fillText('x', origin.x + 20 * xSign + 5, origin.y + 5)
  ctx.fillText('y', origin.x + 5, origin.y + 20 * ySign - 5)
}

function create2DTags(props) {
  props.players2D = {}
  props.balls2D = {}
  props.tags2DSelected = {}

  const {
    player2DSize,
    ball2DSize,
    canvas2DPixelScale,
    canvasContainer2D,
    teams
  } = props

  const ballCanvas = genCanvas(ball2DSize, ball2DSize, canvas2DPixelScale)
  const ballCanvasSelected = genCanvas(
    ball2DSize,
    ball2DSize,
    canvas2DPixelScale
  )
  const ballCanvasInPlay = genCanvas(ball2DSize, ball2DSize, canvas2DPixelScale)

  // Create Match Officials Canvas
  const officialCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(officialCanvas)
  draw2DCircle(
    officialCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'rgba(0,0,0,0.6)', // Border color
    'gray', // Fill color for match officials
    player2DSize / 2,
    null
  )
  props.officials2D = officialCanvas

  canvasContainer2D.append(ballCanvas)
  draw2DCircle(
    ballCanvas.getContext('2d'),
    ball2DSize / 2,
    ball2DSize / 2,
    'black',
    'rgba(255,255,0,0.8)',
    ball2DSize / 2,
    null
  )
  canvasContainer2D.append(ballCanvasSelected)
  draw2DCircle(
    ballCanvasSelected.getContext('2d'),
    ball2DSize / 2,
    ball2DSize / 2,
    'rgb(255,255,0)',
    'rgba(0,0,0,0.8)',
    ball2DSize / 2,
    null
  )
  canvasContainer2D.append(ballCanvasInPlay)
  draw2DCircle(
    ballCanvasInPlay.getContext('2d'),
    ball2DSize / 2,
    ball2DSize / 2,
    'black',
    'rgba(255,0,0,0.8)',
    ball2DSize / 2,
    null
  )

  props.ball2D = ballCanvas
  props.ball2DSelected = ballCanvasSelected
  props.ball2DInPlay = ballCanvasInPlay

  // Create Team A Canvas
  const playerACanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  const playerACanvasSelected = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )

  canvasContainer2D.append(playerACanvas)
  draw2DCircle(
    playerACanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'rgba(0,0,0,0.6)',
    teams.A.color || 'red',
    player2DSize / 2,
    null
  )

  canvasContainer2D.append(playerACanvasSelected)
  draw2DCircle(
    playerACanvasSelected.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'black',
    'rgba(0,0,0,0.6)',
    player2DSize / 2,
    null
  )

  props.playersA2D = playerACanvas
  props.playersA2DSelected = playerACanvasSelected

  // Create Team B Canvas

  const playerBCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  const playerBCanvasSelected = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )

  canvasContainer2D.append(playerBCanvas)
  draw2DCircle(
    playerBCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'rgba(0,0,0,0.6)',
    teams.B.color || 'blue',
    player2DSize / 2,
    null
  )
  canvasContainer2D.append(playerBCanvasSelected)
  draw2DCircle(
    playerBCanvasSelected.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'black',
    'rgba(0,0,0,0.6)',
    player2DSize / 2,
    null
  )

  props.playersB2D = playerBCanvas
  props.playersB2DSelected = playerBCanvasSelected
}

//--> Generate 2D Canvas
const genCanvas = (width, height, scale) => {
  const canvas = document.createElement('canvas')
  canvas.style.width = `${width}px`
  canvas.style.height = `${height}px`
  canvas.width = width * scale
  canvas.height = height * scale
  canvas.style.display = 'none'
  canvas.style.position = 'absolute'
  return canvas
}

export const appendTo2dCanvas = (
  canvas,
  className,
  canvasContainer2D,
  twoDCanvas,
  fieldWidth,
  fieldHeight,
  twoDOffsetX,
  twoDOffsetY,
  scale
) => {
  canvasContainer2D.append(canvas)

  canvas.style.width = `${(fieldWidth + twoDOffsetX * 2) * scale}px`
  canvas.style.height = `${(fieldHeight + twoDOffsetY * 2) * scale}px`
  canvas.width = twoDCanvas.width
  canvas.height = twoDCanvas.height
  canvas.style.position = 'absolute'
  canvas.className = className
}
