import { Options } from '../data_types'
import { RawFlightEventData } from '../events/flight/types'
import { FormattedSession } from '../sessions/types'
import { sportTypes } from '../sports/data_types'
import { UnitSystem } from '../units/types'
import { aggregationOperatorTypes } from './aggregation_operators/data_types'
import {
  MetricTypeGroup,
  MetricTypeKeys,
  MetricTypeValues,
  generateMetricTypeClass
} from './data_types'
import {
  PlayerMetricTypeGroup,
  PlayerMetricTypeValues
} from './player_data_types'
import { RawPlayerSummaryData } from './types'

export function getMetricsOptionsWithUnits(
  metricTypes: MetricTypeGroup,
  unitSystem: UnitSystem
) {
  let options = metricTypes.options
  options = options.map(({ value }) => {
    const metricItem = generateMetricTypeClass(
      metricTypes.getTypeByValue(value)
    )
    return {
      name: metricItem.getMetricDisplayWithUnits(unitSystem, null),
      value
    }
  })
  return options
}

export function getMetricsKeyOptionsWithUnits(
  metricTypes: MetricTypeGroup | PlayerMetricTypeGroup,
  unitSystem: UnitSystem
) {
  let options = metricTypes.keyOptions
  options = options.map(({ value }) => {
    const metricItem = generateMetricTypeClass(metricTypes.items[value])
    return {
      name: metricItem.getMetricDisplayWithUnits(unitSystem, null),
      value
    }
  })
  return options
}

export function aggregateAndFormatMetricsForFlights(
  metricTypes: MetricTypeGroup,
  flights: RawFlightEventData[],
  unitSystem: UnitSystem,
  formattedSession: FormattedSession
) {
  const data = {}
  for (const metricKey in metricTypes.items) {
    const flightMetric = generateMetricTypeClass(
      metricTypes.items[metricKey as MetricTypeKeys]
    )
    data[metricKey] = {
      [aggregationOperatorTypes.items.Mean.value]:
        flightMetric.getMetricFromFlights(
          flights,
          unitSystem,
          aggregationOperatorTypes.items.Mean.value,
          formattedSession
        ),
      [aggregationOperatorTypes.items.Maximum.value]:
        flightMetric.getMetricFromFlights(
          flights,
          unitSystem,
          aggregationOperatorTypes.items.Maximum.value,
          formattedSession
        ),
      [aggregationOperatorTypes.items.Minimum.value]:
        flightMetric.getMetricFromFlights(
          flights,
          unitSystem,
          aggregationOperatorTypes.items.Minimum.value,
          formattedSession
        ),
      [aggregationOperatorTypes.items.Sum.value]:
        flightMetric.getMetricFromFlights(
          flights,
          unitSystem,
          aggregationOperatorTypes.items.Sum.value,
          formattedSession
        )
    }
  }
  return data
}

export type FormattedMetricTableHeaders = {
  name: string
  key: MetricTypeKeys
  dec: number
  width: number
}

export function getMetricTableHeadersWithUnits(
  metricTypes: MetricTypeGroup,
  keys: Readonly<MetricTypeKeys[]>,
  unitSystem: UnitSystem,
  width: number,
  maxLength: number
): FormattedMetricTableHeaders[] {
  return keys.map((key) => {
    const metricInfo = generateMetricTypeClass(metricTypes.items[key])
    return {
      name: metricInfo.getMetricDisplayWithUnits(unitSystem, maxLength),
      key,
      dec: metricInfo.props.decimal,
      width
    }
  })
}

export function formatPlayerSummary(
  playerSummaryStat: any,
  formattedSession: FormattedSession,
  playerSummaryMetricTypes: PlayerMetricTypeGroup,
  unitSystem: UnitSystem
) {
  if (
    !playerSummaryMetricTypes &&
    formattedSession.sport.value !== sportTypes.items.soccer.value
  )
    return null
  const metrics = {} as {
    [key in MetricTypeKeys]?: {
      key: MetricTypeKeys
      name: string
      display: string
      value: number | string | boolean
      type: string
      tag?: string
      index?: number
      readonly?: boolean
      options?: Options<MetricTypeValues>
      hideOnDetailCard?: boolean
      tagOnClick?: (callback) => void
      group?: boolean
    }
  }
  for (const metric in playerSummaryMetricTypes.items) {
    if (playerSummaryMetricTypes) {
      const metricType =
        playerSummaryMetricTypes.items[metric as MetricTypeKeys]

      const metricInfo = generateMetricTypeClass(metricType)
      if (metricInfo) {
        const newValue = playerSummaryStat[metric]
        const options =
          typeof metricInfo.props.options === 'function'
            ? metricInfo.props.options(playerSummaryStat, formattedSession)
            : metricInfo.props.options
        metrics[metric] = {
          ...metricInfo.props,
          key: metricInfo.key,
          name: metricInfo.name,
          display: newValue
            ? metricInfo.getMetricValueWithUnits(
                newValue,
                unitSystem,
                null,
                formattedSession
              )
            : null,
          value: newValue,
          formattedValue: metricInfo.getMetricValue(
            newValue,
            null,
            formattedSession
          ),

          options: options
        }
      }
    }
  }
  return {
    rawSummaryStat: playerSummaryStat,
    metrics
  }
}
