import React, { useEffect, useMemo, useRef } from 'react'
import { goToLanding, setRedirect } from '../../ui/router/actions'
import { SessionTitle } from '../../metrics_server/sessions/components/Title/Title'
import {
  useAutoSleep,
  useSession,
  useSessions
} from '../../metrics_server/sessions/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { ConfirmationRequest } from '../../components/ConfirmationRequest/ConfirmationRequest'
import { stopSession } from '../../metrics_server/sessions/actions'
import * as ActivityList from '../ActivityList/config'
import { Button } from '../../components/Button/Button'
import { StopIcon } from '../../components/Icons/StopIcon/StopIcon'
import styles from './Session.module.scss'
import ReactTooltip from 'react-tooltip'
import { useAppDispatch } from '../../store/hooks'
import { useVersion } from '../../metrics_server/version/hooks'

export function useSessionHeaderProps() {
  const dispatch = useAppDispatch()

  const { live } = useSession()

  const sessions = useSessions()

  const session = sessions.selected

  const iconClick = () => {
    dispatch(goToLanding())
  }

  const backButton = {
    route: ActivityList.path
  }

  // Trigger redirect to session list after session is successfuly ended
  const prevLive = useRef(live)
  useEffect(() => {
    if (prevLive.current && !live) {
      dispatch(setRedirect(ActivityList.path))
    }
    prevLive.current = live
  }, [live])

  return useMemo(() => {
    return {
      title: <SessionTitle sessionId={sessions.selectedId} />,
      iconClick,
      backButton,
      ToolbarInner: live ? LiveSessionToolbar : null
    }
  }, [session, live, LiveSessionToolbar])
}

export const LiveSessionToolbar = () => {
  const { autoSleepToggleText, toggleAutoSleep, autoSleepEnabled } =
    useAutoSleep()
  const version = useVersion()
  const { session } = useSession()

  const dispatch = useAppDispatch()
  const openStopSessionConfirmationModal = () => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          dispatch(stopSession(session.id, null))
          dispatch(toggleModal({}))
        },
        ChildComponent: ConfirmationRequest,
        message: 'Are you sure?',
        className: 'modalSmall',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  return (
    <div className={styles.liveSessionToolbar}>
      {version.app.EnableAutoSleepToggle && (
        <div style={{ marginRight: '15px' }}>
          <Button
            className={`${
              autoSleepEnabled ? 'btn--blue' : 'btn--red'
            } btn--thin`}
            handleClick={toggleAutoSleep}
          >
            {autoSleepToggleText}
          </Button>
        </div>
      )}
      <div data-tip data-for='shortcuts'>
        <StopIcon scale={0.9} handleClick={openStopSessionConfirmationModal} />
      </div>
      <ReactTooltip
        id='shortcuts'
        className={styles.toolTip}
        place='bottom'
        aria-haspopup='true'
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node
        ) => {
          const d = document.documentElement

          left = Math.min(d.clientWidth - node.clientWidth, left)
          top = Math.min(d.clientHeight - node.clientHeight, top)

          left = Math.max(0, left)
          top = Math.max(0, top)

          return { top, left }
        }}
      >
        Stop Session
      </ReactTooltip>
    </div>
  )
}
