import React, { Dispatch, useEffect, useState } from 'react'
import { Action, ActionCreator } from 'redux'
import { CardList } from '../../../components/CardList/CardList'
import { Table } from '../../../components/Table/Table'
import { TableHeader, TableRow } from '../../../components/Table/Table.types'
import { HardwareState } from '../../../metrics_server/hardware/types'
import { PitchesState } from '../../../metrics_server/pitches/types'
import styles from './Hardware.module.scss'
import { VersionState } from '../../../metrics_server/version/types'

export interface HardwareProps {
  active: boolean
  version: VersionState
  selectedTabKey: string
  updateActiveTab: (tabName: string) => void
  hardware: HardwareState
  pitches: PitchesState
}

export interface CustomHardwareCellProps {
  item: any
  header: TableHeader
  highlightedRow: TableRow
  setHighlightedRow: Dispatch<TableRow>
  highlightedColumn: number | string
  setHighlightedColumn: Dispatch<number | string>
}

export function CustomHardwareCell({
  item,
  header,
  highlightedRow,
  setHighlightedRow,
  highlightedColumn,
  setHighlightedColumn
}: CustomHardwareCellProps) {
  const value = item[header.key]

  if (!value) return <noscript />

  return (
    // <div  className={styles.customHardwareCell}>
    //   <div className={styles.tableText}>{item[header.key]}</div>
    // </div>
    <div
      style={{
        background:
          highlightedColumn === header.key || highlightedRow === item.id
            ? value.color
            : 'transparent'
      }}
      onClick={(e) => {
        setHighlightedRow(item.id)
        setHighlightedColumn(header.key)
      }}
    >
      {value.count ? (
        <div className={`${styles.tableTextSmall} ${styles.hardwareCell}`}>
          <p>{value.count} Hz</p>
        </div>
      ) : null}
      {value.rssi ? (
        <div className={`${styles.tableTextSmall} ${styles.hardwareCell}`}>
          <p>{value.rssi} dBm</p>
        </div>
      ) : null}
      {value.imuLen ? (
        <div className={`${styles.tableTextSmall} ${styles.hardwareCell}`}>
          <p>
            IMU:
            {isNaN(value.imuLen)
              ? value.imuLen
              : parseFloat(value.imuLen).toFixed(0)}
          </p>
        </div>
      ) : null}
      {value.distance ? (
        <div className={`${styles.tableTextSmall} ${styles.hardwareCell}`}>
          <p>
            {isNaN(value.distance)
              ? value.distance
              : parseFloat(value.distance).toFixed(2)}{' '}
            m
          </p>
        </div>
      ) : null}
    </div>
  )
}

export const Hardware = (props: HardwareProps) => {
  const { active, version, hardware, pitches } = props
  const [highlightedRow, setHighlightedRow] = useState(null)
  const [highlightedColumn, setHighlightedColumn] = useState(null)
  const [tableHeaders, setTableHeaders] = useState([])
  const [tableData, setTableData] = useState([])

  const isNewDiags = version.app?.FeatureFlags?.EnableNewDiagnosticsScreen

  useEffect(() => {
    setTableHeaders(getTableHeaders(hardware, pitches))
  }, [hardware, pitches, highlightedColumn, highlightedRow])

  useEffect(() => {
    setTableData(getTableData(hardware, pitches))
  }, [hardware, pitches, highlightedColumn, highlightedRow])

  const getTableData = (hardware: HardwareState, pitches) => {
    const data = hardware.diagnostics.hardwareIndex.map((x, i) => {
      const item = {
        index: null,
        anchor: i <= hardware.diagnostics.finalAnchorIndex,
        id: hardware.diagnostics.encodedHardwareIndex[i]
      }

      const { inUse } = pitches
      const anchorInPitchSetup = inUse.anchors[x]
      if (anchorInPitchSetup) {
        item.index = anchorInPitchSetup.index
      } else {
        item.index = Infinity
      }

      hardware.diagnostics.hardwareIndex.forEach((y, j) => {
        const device = hardware.diagnostics.hardwareState.devices[y]

        // Skip if new diags tag and not anchor / device doesn't exist in hardware state
        if (isNewDiags && device?.type !== 'Anchor') {
          return
        }

        // RSSI
        let rssiValue = hardware.diagnostics.rssiMatrix[i][j]

        if (i === 0 && rssiValue === -105 && device) {
          rssiValue = device.masterRSSI
        }

        if (x === y) {
          item[y] = {
            imuLen: `${hardware.diagnostics.imuLenMatrix[i][j]}`
          }
        } else {
          item[y] = {
            count: `${hardware.diagnostics.countMatrix[i][j]}`,
            rssi: rssiValue,
            distance: `${hardware.diagnostics.distanceMatrix[i][j]}`
          }
        }

        if (hardware.diagnostics.countMatrix[i][j] <= 10) {
          item[y].color = 'rgba(255, 0, 0, 0.3)'
        } else if (hardware.diagnostics.countMatrix[i][j] >= 16) {
          item[y].color = 'rgba(0, 255, 0, 0.3)'
        } else {
          item[y].color = 'rgba(255, 255, 0, 0.3)'
        }
      })

      return { ...item }
    })

    // filter out non-anchors
    const filteredAnchorMatrix = Object.values(data).filter(
      (value) => value.anchor !== false
    )
    return filteredAnchorMatrix
  }

  const getTableHeaders = (hardware, pitches) => {
    const headers = hardware.diagnostics.hardwareIndex
      .map((x, i) => {
        if (
          !isNewDiags ||
          hardware.diagnostics.hardwareState.devices[x]?.type === 'Anchor'
        ) {
          return {
            name: `${hardware.diagnostics.encodedHardwareIndex[i]} ${
              i <= hardware.diagnostics.finalAnchorIndex ? '(A)' : ''
            }`,
            key: x,
            type: 'component',
            CustomComponent: ({ item, header }) => (
              <CustomHardwareCell
                highlightedRow={highlightedRow}
                setHighlightedRow={setHighlightedRow}
                highlightedColumn={highlightedColumn}
                setHighlightedColumn={setHighlightedColumn}
                item={item}
                header={header}
              />
            )
          }
        }
      })
      .filter((header) => header !== undefined)

    const { inUse } = pitches
    headers.sort((a, b) => {
      const pitchAnchorA = inUse.anchors[a.key],
        pitchAnchorB = inUse.anchors[b.key]

      let aIndex = Infinity
      let bIndex = Infinity

      if (pitchAnchorA) aIndex = pitchAnchorA.index
      if (pitchAnchorB) bIndex = pitchAnchorB.index

      if (aIndex < bIndex) return -1
      if (aIndex > bIndex) return 1
      return 0
    })
    return [{ name: 'ID', key: 'id' }, ...headers]
  }

  if (!active) return null

  return (
    <div className={styles.tableContainer}>
      <CardList
        col={12}
        items={[{}]}
        scrollerId={`scroller-${1}`}
        className='maxHeight'
      >
        <div className='card-table-container'>
          <Table
            // highlightRow={(id) => setHighlightedRow(id)}
            // highlightedRow={highlightedRow}
            options={{
              initialOrder: 'asc',
              initialSortBy: 'index',
              sortActive: false
            }}
            headerFont={13}
            tableClass={'minimalistBlack'}
            className={'container'}
            data={tableData}
            headers={tableHeaders}
          />
        </div>
      </CardList>
    </div>
  )
}
