import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../data_types'
import { EventTypeJson, EventTypeProps } from '../data_types'
import {
  GameEventSubTypeGroup,
  GameEventSubTypeKeys
} from './subType/data_types'

export type GameEventFeatures = {
  test: boolean
  tagToEventXDistance: boolean
  officiatingModeOnly: boolean
  graph: boolean
}

export const defaultGameEventFeatures: GameEventFeatures = {
  test: true,
  tagToEventXDistance: true,
  officiatingModeOnly: false,
  graph: true
}

export const gameEventTypesConfig = {
  unknown: {
    name: 'Unkonwn',
    key: 'unknown',
    value: 'UNKNOWN',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  possession: {
    name: 'Possession',
    key: 'possession',
    value: 'POSSESSION',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  ballInPlay: {
    name: 'Ball In Play',
    key: 'ballInPlay',
    value: 'BALL_IN_PLAY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  penalty: {
    name: 'Penalty',
    key: 'penalty',
    value: 'PENALTY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  try: {
    name: 'Try',
    key: 'try',
    value: 'TRY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  touchDown: {
    name: 'Touch Down',
    key: 'touchDown',
    value: 'TOUCH_DOWN',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  goalLineCrossed: {
    name: 'Goal Line Crossed',
    key: 'goalLineCrossed',
    value: 'GOAL_LINE_CROSSED',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  snap: {
    name: 'Snap',
    key: 'snap',
    value: 'SNAP',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  kickOff: {
    name: 'Kick Off',
    key: 'kickOff',
    value: 'KICKOFF',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  handOff: {
    name: 'Rush',
    key: 'handOff',
    value: 'HANDOFF',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  twoPointConversion: {
    name: '2-PT Conversion',
    key: 'twoPointConversion',
    value: 'CFL_2PT_CONVERSION',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  touch: {
    name: 'Touch',
    key: 'touch',
    value: 'TOUCH',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  soccerBallOverLine: {
    name: 'Ball Over Line',
    key: 'soccerBallOverLine',
    value: 'SOCCER_BALL_OVER_LINE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  soccerGoal: {
    name: 'Goal',
    key: 'soccerGoal',
    value: 'SOCCER_GOAL',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  tackle: {
    name: 'Tackle',
    key: 'tackle',
    value: 'TACKLE',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  safety: {
    name: 'Safety',
    key: 'safety',
    value: 'SAFETY',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  },
  defensiveTwoPoint: {
    name: 'Defensive 2-PT',
    key: 'defensiveTwoPoint',
    value: 'DEFENSIVE_2_PT',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultGameEventFeatures
    }
  }
} as const

export type GameEventTypeProps = EventTypeProps<
  GameEventSubTypeGroup,
  GameEventFeatures
>

export type GameEventTypes = DataTypes<typeof gameEventTypesConfig>

export type GameEventTypeKey = DataTypeKey<GameEventTypes>

export type GameEventTypeKeys = DataTypeKeys<GameEventTypeKey>

export type GameEventTypeValues = DataTypeValues<GameEventTypeKey>

export const getGameEventTypeGroup = (items: {
  [key in GameEventTypeKeys]?: GameEventTypes[key]
}) => {
  return getDataTypeGroup<
    GameEventTypeKeys,
    GameEventTypeValues,
    GameEventTypes,
    GameEventTypeProps
  >(items)
}

export type GameEventTypeGroup = ReturnType<typeof getGameEventTypeGroup>

export type GameEventTypeConfig = DataTypeConfig<
  GameEventTypeKeys,
  GameEventTypeValues,
  GameEventTypeProps
>

export const gameEventTypes = getGameEventTypeGroup(gameEventTypesConfig)

export type GameEventType = ReturnType<typeof gameEventTypes.getTypeByValue>

export type GameEventSubTypesJson = {
  [key in GameEventSubTypeKeys]?: EventTypeJson<GameEventFeatures>
}

export type GameEventTypesJson = {
  [key in GameEventTypeKeys]?: EventTypeJson<
    GameEventFeatures,
    GameEventSubTypesJson
  >
}

export type GameEventJson = EventTypeJson<GameEventFeatures, GameEventTypesJson>
