import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'
import styles from './StopIcon.module.scss'

export interface StopIconProps {
  scale?: number
  color?: string
  buttonText?: string
  handleClick?: () => void
  title?: string
}

export function StopIcon({
  scale = 1,
  color = sportableColors.colors.failure,
  handleClick,
  title
}: StopIconProps) {
  // return (
  //   <div onClick={handleClick} className={styles.svgAnimation}>
  //     <svg
  //       width={`${32 * scale}`}
  //       height={`${32 * scale}`}
  //       viewBox='0 0 32 32'
  //       fill='none'
  //       xmlns='http://www.w3.org/2000/svg'
  //     >
  //       <path
  //         d='M30 16C30 8.27083 23.7292 2 16 2C8.27083 2 2 8.27083 2 16C2 23.7292 8.27083 30 16 30C23.7292 30 30 23.7292 30 16Z'
  //         stroke={color}
  //         strokeWidth='3.5'
  //         stroke-miterlimit='10'
  //       />
  //       <rect
  //         x='10.05'
  //         y='10.05'
  //         width='11.9'
  //         height='11.9'
  //         rx='0.95'
  //         fill={color}
  //         stroke='#858585'
  //         strokeWidth='0.1'
  //       />
  //     </svg>
  //   </div>
  // )

  return (
    <div
      onClick={handleClick}
      className={styles.svgAnimation}
      title={title}
      style={{ display: 'flex' }}
    >
      <svg
        width={`${32 * scale}`}
        height={`${32 * scale}`}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M30 16C30 8.27083 23.7292 2 16 2C8.27083 2 2 8.27083 2 16C2 23.7292 8.27083 30 16 30C23.7292 30 30 23.7292 30 16Z'
          stroke={color}
          strokeWidth='2.25'
          strokeMiterlimit='10'
        />
        <circle cx='16' cy='16' r='11' fill={color} />
        <rect
          x='11.05'
          y='11.05'
          width='9.9'
          height='9.9'
          rx='0.95'
          fill='white'
          stroke='#858585'
          strokeWidth='0.1'
        />
      </svg>
    </div>
  )
}
