import { useEffect, useMemo } from 'react'
import { useSession } from '../../sessions/hooks'
import { SessionConfig } from '../../sessions/types'
import { useEvents } from '../hooks'
import { useEventsFilters } from '../filter'
import {
  filterTimeEvents,
  getCurrentPeriod,
  isInPlay,
  isPlayStopped
} from './functions'
import { TimeEventData, TimeEventFilters } from './types'
import { TimeEventTypeValues } from './data_types'

export function useTimeEvents() {
  // Session //
  const sessionConfig = useSession()
  const { timeEventTypes } = sessionConfig
  // ======= //

  const timeEvents = useEventsFilters({}, ['time'])

  const playStopped = isPlayStopped(timeEvents.filteredEvents)

  const inPlay = isInPlay(timeEvents.filteredEvents, timeEventTypes)

  const { currentPeriod, currentPeriodTimeEventType, nextPeriodTimeEventType } =
    useCurrentPeriod()

  const fullTime =
    currentPeriod && !nextPeriodTimeEventType && currentPeriod.timeEnd

  return {
    playStopped,
    inPlay,
    fullTime,
    currentPeriod,
    currentPeriodTimeEventType,
    nextPeriodTimeEventType,
    timeEventTypes
  }
}

export function useCurrentPeriod() {
  // Store //
  const events = useEvents()
  // ===== //

  // Session //
  const { sport } = useSession()
  const timeEventTypes = sport.props.eventTypes.items.time.props.types
  // ======= //

  const timeEvents = useEventsFilters({}, ['time'])

  const currentPeriod = useMemo(() => {
    return getCurrentPeriod(timeEvents.filteredEvents, timeEventTypes)
  }, [timeEvents.filteredEvents])

  const currentPeriodTimeEventType = useMemo(() => {
    return timeEventTypes.getTypeByValue(currentPeriod?.type)
  }, [currentPeriod])

  const nextPeriodTimeEventType = useMemo(() => {
    const currentPeriodType = currentPeriod ? currentPeriod?.type : -1
    const nextType = (currentPeriodType + 1) as TimeEventTypeValues
    const nextPeriodType = timeEventTypes.getTypeByValue(nextType)
    return nextPeriodType?.props.features?.period ? nextPeriodType : null
  }, [currentPeriod])

  return {
    currentPeriod,
    currentPeriodTimeEventType,
    nextPeriodTimeEventType
  }
}
