import { UnitSystem } from './types'

export const SI: UnitSystem = {
  key: 'SI',
  name: 'SI',
  units: {
    distance: {
      name: 'm',
      abbreviation: 'm',
      conversion: 1
    },
    speed: {
      name: 'm/s',
      abbreviation: 'm/s'
    },
    time: {
      name: 's',
      abbreviation: 's'
    },
    percentage: {
      name: '%',
      abbreviation: '%'
    },
    revs: {
      name: 'rps',
      abbreviation: 'rps'
    },
    angle: {
      name: 'radians',
      abbreviation: 'rad'
    }
  }
}

export const American: UnitSystem = {
  key: 'American',
  name: 'American',
  units: {
    distance: {
      name: 'yards',
      abbreviation: 'yd',
      conversion: 0.91440275783
    },
    speed: {
      name: 'mph',
      abbreviation: 'mph'
    },
    time: {
      name: 's',
      abbreviation: 's'
    },
    percentage: {
      name: '%',
      abbreviation: '%'
    },
    revs: {
      name: 'rps',
      abbreviation: 'rps'
    },
    angle: {
      name: 'degrees',
      abbreviation: 'deg'
    }
  }
}

export const Canadian: UnitSystem = {
  key: 'Canadian',
  name: 'Canadian',
  units: {
    distance: {
      name: 'yards',
      abbreviation: 'yd',
      conversion: 0.91440275783
    },
    speed: {
      name: 'kph',
      abbreviation: 'kph'
    },
    time: {
      name: 's',
      abbreviation: 's'
    },
    percentage: {
      name: '%',
      abbreviation: '%'
    },
    revs: {
      name: 'rpm',
      abbreviation: 'rpm'
    },
    angle: {
      name: 'degrees',
      abbreviation: 'deg'
    }
  }
}

export const British: UnitSystem = {
  key: 'British',
  name: 'British',
  units: {
    distance: {
      name: 'meters',
      abbreviation: 'm',
      conversion: 1
    },
    speed: {
      name: 'mph',
      abbreviation: 'mph'
    },
    time: {
      name: 's',
      abbreviation: 's'
    },
    percentage: {
      name: '%',
      abbreviation: '%'
    },
    revs: {
      name: 'rps',
      abbreviation: 'rps'
    },
    angle: {
      name: 'degrees',
      abbreviation: 'deg'
    }
  }
}

export const Metric: UnitSystem = {
  key: 'Metric',
  name: 'Metric',
  units: {
    distance: {
      name: 'meters',
      abbreviation: 'm',
      conversion: 1
    },
    speed: {
      name: 'kph',
      abbreviation: 'kph'
    },
    time: {
      name: 's',
      abbreviation: 's'
    },
    percentage: {
      name: '%',
      abbreviation: '%'
    },
    revs: {
      name: 'rps',
      abbreviation: 'rps'
    },
    angle: {
      name: 'degrees',
      abbreviation: 'deg'
    }
  }
}
