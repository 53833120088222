import { defaultPitch } from './pitch'
import { soccerEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  PlayerMetricTypeGroup,
  getPlayerMetricTypeGroup,
  getPlayerMetricTypesConfigFromJson,
  playerMetricTypesConfig
} from '../../metrics/player_data_types'

export const getPlayerSummaryMetricGroupFromJson = (
  json
): PlayerMetricTypeGroup => {
  return getPlayerMetricTypeGroup(
    getPlayerMetricTypesConfigFromJson(
      json.playerSummary.metrics,
      playerMetricTypesConfig
    )
  )
}

export const soccerProps = {
  eventTypes: soccerEventTypes,
  playerSummaryMetricTypes: getPlayerSummaryMetricGroupFromJson(dataTypes),
  pitch: {
    default: defaultPitch,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    setupTypes: pitchSetupTypes,
    enableRotate: true
  },
  features: {
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 18,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        a: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.complete?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: ['dist', 'hangTime', 'speed'],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false
  } as const
}
