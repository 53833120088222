import React, { useCallback, useEffect, useRef, useState } from 'react'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import styles from './Validation.module.scss'
import { CardList } from '../../../components/CardList/CardList'
import { EventFiltersForm } from '../../../components/EventFilters/EventFilters'
import {
  CanvasStyle,
  CanvasViewTypes,
  Strack
} from '../../../components/Strack/Strack.types'
import { useAppDispatch } from '../../../store/hooks'
import {
  createGameEvent,
  resetSelectedEvent,
  updateEvent,
  updateFlight
} from '../../../metrics_server/events/actions'
import {
  useEvents,
  useSelectedFormattedEvent
} from '../../../metrics_server/events/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { FormattedEventDetailCard } from '../../../components/EventDetailCard/FormattedEventDetailCard'
import { ValidationTable } from '../../../metrics_server/events/components/ValidationTable'
import { EventsFilters } from '../../../metrics_server/events/filter'
import {
  isOutcomeType,
  outcomeTypes
} from '../../../metrics_server/outcomes/data_types'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'
import { SortingState } from '@tanstack/react-table'

interface ValidationProps {
  strackReady: boolean
  strack: Strack
  canvasStyle: CanvasStyle
  canvasView: CanvasViewTypes
  active: boolean

  validationEventsFilters: EventsFilters
  validationTableSorting: SortingState
  setValidationTableSorting: (sorting: SortingState) => void

  highlightedRow: string
  setHighlightedRow: (flightId: string) => void

  updateCanvasWidth?: (canvasId: string, width: string) => void
  setCanvasStyle?: (canvasId: string, width: string) => void
  canvasId?: string
  hideCanvas?: (hide: boolean) => void
}

export function Validation(props: ValidationProps) {
  const {
    // Strack Container
    strackReady,
    strack,
    canvasStyle,
    canvasView,
    active,
    validationEventsFilters,
    validationTableSorting,
    setValidationTableSorting,
    canvasId,
    updateCanvasWidth,
    setCanvasStyle,
    hideCanvas
  } = props
  // Store //
  const events = useEvents()

  const dispatch = useAppDispatch()
  // ===== //

  // Session //
  // TODO: refactor needed - name clash with sessionConfig for strack and this hook
  const formattedSession = useSelectedFormattedSession()
  const { teamsSessions, playersSessions, live, sport, flightTypes } =
    formattedSession
  // ========//

  // Event //
  const formattedEvent = useSelectedFormattedEvent()

  const tableRef = useRef(null)
  const filterRef = useRef(null)
  const eventDetailRef = useRef(null)
  const refLeft = useRef(null)

  // Validation State //
  const [saveFlash, setSaveFlash] = useState(null)
  const [hideDetailCard, setHideDetailCard] = useState(false)

  const validationTableId = 'validationTable'

  // updates the flight with new data and shows a flash message
  const handleSaveUpdates = (data) => {
    dispatch(updateFlight(data))

    setSaveFlash(true)

    setTimeout(() => {
      setSaveFlash(null)
    }, 2500)
  }

  // KeyBoard shortcuts

  const [isInputFocused, setIsInputFocusStatus] = useState(false)

  // TODO: this is a problem - we need a way to disable hotkeys when we are typing in an input
  // useEffect(() => {
  //   // Add event listeners to all input elements
  //   const inputs = document.querySelectorAll('input') // Include other input elements if needed

  //   const enableInputFocusStatus = () => setIsInputFocusStatus(true)
  //   const disableInputFocusStatus = () => setIsInputFocusStatus(false)

  //   inputs.forEach((input) => {
  //     console.log(input)
  //     input.addEventListener('focus', enableInputFocusStatus)
  //     input.addEventListener('blur', disableInputFocusStatus)
  //   })

  //   return () => {
  //     // Remove event listeners on cleanup
  //     inputs.forEach((input) => {
  //       input.removeEventListener('focus', enableInputFocusStatus)
  //       input.removeEventListener('blur', disableInputFocusStatus)
  //     })
  //   }
  // }, [])

  const updateFlightOutcomeBasedOnHotKey = useCallback(
    (newValue) => {
      if (active) {
        if (formattedEvent) {
          // TODO: Event detail card refactor needs to fix this type mess
          const data = {
            id: formattedEvent.id,
            outcome: newValue,
            toPlayerId: null
          }
          const oldOutcomeType = formattedEvent.outcome?.selected
          const newOutcomeType = outcomeTypes.getTypeByValue(newValue)

          if (isOutcomeType.interception(newOutcomeType)) {
            data.toPlayerId = null
          } else if (
            isOutcomeType.interception(oldOutcomeType) &&
            !isOutcomeType.interception(newOutcomeType)
          ) {
            data.toPlayerId = null
          } else if (isOutcomeType.incomplete(newOutcomeType)) {
            data.toPlayerId = null
          }
          handleSaveUpdates(data)
        }
      }
    },
    [formattedEvent]
  )

  const { keyboardShortcuts } = sport.props.features

  Object.keys(keyboardShortcuts?.shortcuts || {}).forEach((key) => {
    const { condition, type, outcome, payload } =
      keyboardShortcuts.shortcuts[key]

    useKeyboardShortcut(
      [key],
      (key) => {
        if (condition) {
          if (condition(formattedEvent, flightTypes)) {
            updateFlightOutcomeBasedOnHotKey(outcome)
          }
        }
        if (type === 'createGameEvent') {
          dispatch(
            createGameEvent(formattedSession.id, {
              type: payload.value
            })
          )
        }
      },
      {
        overrideSystem: true
      }
    )
  })

  const [hotKeyData, setHotKeyData] = useState({
    key: null,
    number: '',
    released: false
  })

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        (e.key.toLowerCase() === 'p' ||
          e.key.toLowerCase() === 'r' ||
          e.key.toLowerCase() === 't') &&
        !e.repeat
      ) {
        setHotKeyData((prevData) => ({
          ...prevData,
          key: e.key.toLowerCase()
        }))
      }
    }

    const handleNumberKey = (e) => {
      if (!isNaN(e.key) && !e.repeat) {
        setHotKeyData((prevData) => ({
          ...prevData,
          number: prevData.number + e.key
        }))
      }
    }

    const handleKeyRelease = (e) => {
      if (
        (e.key.toLowerCase() === 'p' ||
          e.key.toLowerCase() === 'r' ||
          e.key.toLowerCase() === 't') &&
        !e.repeat
      ) {
        setHotKeyData((prevData) => ({
          ...prevData,
          released: true
        }))
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keydown', handleNumberKey)
    window.addEventListener('keyup', handleKeyRelease)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keydown', handleNumberKey)
      window.removeEventListener('keyup', handleKeyRelease)
    }
  }, [])

  useEffect(() => {
    if (hotKeyData.released) {
      const { key, number } = hotKeyData

      if (formattedEvent && formattedEvent.team.selected) {
        let teamId = formattedEvent.team.selected.id

        // If interception and the hotkey is for receiver use opposition team id
        if (key === 'r') {
          if (isOutcomeType.interception(formattedEvent.outcome?.selected)) {
            teamId = teamsSessions.map[teamId].oppositionTeamId
          }
        }

        const playerSession = playersSessions.byNumber[teamId]?.map[number]

        if (playerSession) {
          const data = {
            id: formattedEvent.id,
            fromPlayerId: null,
            toPlayerId: null,
            targetPlayerId: null
          }

          if (key === 'p') {
            data.fromPlayerId = playerSession.playerId
          } else if (key === 'r') {
            data.toPlayerId = playerSession.playerId
          } else if (key === 't') {
            data.targetPlayerId = playerSession.playerId
          }
          dispatch(updateFlight(data, false))
        }
      }

      // Reset hotKeyData state
      setHotKeyData({
        key: null,
        number: '',
        released: false
      })
    }
  }, [
    hotKeyData.released,
    formattedEvent,
    playersSessions,
    dispatch,
    updateFlight
  ])

  useEffect(() => {
    const resizeableTable = tableRef.current
    const resizeableFilter = filterRef.current
    const resizeableEventDetail = eventDetailRef.current
    const stylesTable = window.getComputedStyle(resizeableTable)
    const stylesFilter = window.getComputedStyle(resizeableFilter)
    const stylesEventDetail = window.getComputedStyle(resizeableEventDetail)

    let widthTable = parseInt(stylesTable.width, 10)
    let widthFilter = parseInt(stylesFilter.width, 10)
    let widthEventDetail = parseInt(stylesEventDetail.width, 10)
    let x = 0

    // Left resize
    const onMouseMoveLeftResize = (event) => {
      const dx = event.clientX - x
      x = event.clientX
      widthTable = widthTable - dx
      widthFilter = widthFilter - dx
      resizeableTable.style.width = `${widthTable}px`
      resizeableFilter.style.width = `${widthFilter}px`

      if (dx > 0) {
        // Increase the width of eventDetail when the table and filter are resized
        const newWidth = widthEventDetail + dx
        widthEventDetail = newWidth
      } else {
        // Decrease the width of eventDetail when the table and filter are resized
        const newWidth = widthEventDetail - Math.abs(dx)
        widthEventDetail = newWidth
        hideCanvas(true)
      }

      resizeableEventDetail.style.width = `${widthEventDetail}px`
    }

    const onMouseUpLeftResize = (event) => {
      const dx = event.clientX - x
      hideCanvas(false)
      if (dx > 0) {
        const newWidth = widthEventDetail + dx + 'px'
        updateCanvasWidth(canvasId, newWidth)
      } else {
        const newWidth = widthEventDetail - Math.abs(dx) + 'px'
        updateCanvasWidth(canvasId, newWidth)
      }
      document.removeEventListener('mousemove', onMouseMoveLeftResize)
      document.removeEventListener('mouseup', onMouseUpLeftResize)
    }

    const onMouseDownLeftResize = (event) => {
      x = event.clientX
      resizeableTable.style.right = styles.right
      resizeableTable.style.left = null
      resizeableFilter.style.right = styles.right
      resizeableFilter.style.left = null
      document.addEventListener('mousemove', onMouseMoveLeftResize)
      document.addEventListener('mouseup', onMouseUpLeftResize)
    }

    const resizerLeft = refLeft.current
    resizerLeft.addEventListener('mousedown', onMouseDownLeftResize)

    return () => {
      resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize)
    }
  }, [])

  return (
    <React.Fragment>
      <StrackEvents
        events={formattedEvent ? [formattedEvent.rawData] : []}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
      />
      <div className={styles.filterContainer} ref={filterRef}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <EventFiltersForm filters={validationEventsFilters} />
        </CardList>
      </div>
      <div
        className={styles.eventDetailContainer}
        ref={eventDetailRef}
        style={{ display: hideDetailCard && 'none' }}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.eventDetails}>
            <FormattedEventDetailCard saveFlash={saveFlash} />
          </div>
        </CardList>
      </div>
      {/* <button
        className={styles.hideDetailButton}
        style={{ top: hideDetailCard && '93%' }}
        onClick={() => {
          setHideDetailCard(!hideDetailCard)
          setCanvasStyle(canvasId, !hideDetailCard ? '100%' : '72%')
        }}
      >
        {hideDetailCard ? 'Show' : 'Hide'} Card
      </button> */}
      <div className={styles.tableContainer} ref={tableRef}>
        <div className={styles.tableResize}>
          <div ref={refLeft} style={{ cursor: 'col-resize' }}>
            {/* {strackReady && <SwitchLeftIcon />} */}
          </div>
        </div>
        <div className={styles.tableCard}>
          <CardList
            col={12}
            items={[{}]}
            scrollerId={`scroller-${1}`}
            className='maxHeight'
          >
            <div className={styles.table}>
              <div className='card-table-container'>
                <ValidationTable
                  eventsFilters={validationEventsFilters}
                  tableId={validationTableId}
                  active={active}
                  validationTableSorting={validationTableSorting}
                  setValidationTableSorting={setValidationTableSorting}
                />
              </div>
            </div>
          </CardList>
        </div>
      </div>
    </React.Fragment>
  )
}
