import React, { useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { isLocal } from '../metrics_server/env'
import '../index.scss'
import '../tailwind.css'

// Authentication
import { formMatrix } from '../views/Authentication/form_config'

import { AuthenticationContainer } from '../index'

// UI Actions
import * as ui from '../ui'

// Components
import Error from '../components/Error/Error'
import ErrorBoundary from '../components/Error/ErrorBoundary'
import Info from '../components/Info/Info'
import Modal from '../components/Modal/Modal'
import Success from '../components/Success/Success'
import { CustomRoutes } from './Routes'
import Notification from '../components/Notifications/Notification/Notification'

// Types
import { ViewType } from '../components/Views/Views.types'
import { AppConfig, Routes } from './App.types'

let actions = {}
for (const key in ui.actions) {
  actions = { ...actions, ...ui.actions[key] }
}

export interface AppProps {
  appConfig: AppConfig
  landing?: string
  storybook?: boolean
  router
}

function App(props: AppProps) {
  const { appConfig, landing, storybook } = props
  const { routes } = appConfig

  const [appRoutes] = useState<Routes>({
    ...routes,
    // Login route
    login: {
      path: '/login',
      view: {
        type: ViewType.FORM,
        name: 'Form Test',
        side: 1,
        formMatrix,
        Container: AuthenticationContainer
      },
      authenticate: true
    }
  })

  const Router = isLocal ? MemoryRouter : BrowserRouter

  return (
    // <React.StrictMode>
    <div
      style={{
        // height: appConfig.type.value === 'commentatorTool' ? '100%' : '95%',
        height: '95%'
      }}
    >
      <Modal />
      <Success />
      <Info />
      <Error />
      <Notification />
      <div className={'clarendon'}>Clarendon</div>
      <ErrorBoundary>
        <Router initialEntries={[landing]} initialIndex={0}>
          <div
            style={{
              height: '100%',
              border: storybook ? '5px solid #e3e3e3' : 'none'
            }}
            className='mainView'
          >
            <CustomRoutes {...props} appRoutes={appRoutes} landing={landing} />
          </div>
        </Router>
      </ErrorBoundary>
    </div>
    // </React.StrictMode>
  )
}

function mapStateToProps(state, props: AppProps) {
  return {
    user: state.user,
    router: state.router
  }
}

export default connect<any, any, AppProps>(mapStateToProps, actions)(App)
