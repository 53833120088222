import React, { useEffect, useMemo, useState } from 'react'
import { CardList } from '../../../components/CardList/CardList'
import {
  PlayerPassCard,
  PlayerPassData
} from '../../../components/PlayerPassCard/PlayerPassCard'
import { Table } from '../../../components/Table/Table'
import multiplePlayers from '../../../assets/img/multiple-players.svg'
import styles from './PassSummary.module.scss'
import {
  useSelectedFormattedSession,
  useSession
} from '../../../metrics_server/sessions/hooks'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { generateMetricTypeClass } from '../../../metrics_server/metrics/data_types'
import { aggregateAndFormatMetricsForFlights } from '../../../metrics_server/metrics/functions'

export const PassSummary = (props) => {
  // Redux //
  const events = useEvents()
  // ===== //

  // Session //
  const { playersSessions, sessionPlayers, eventTypes, flightTypes, sport } =
    useSession()
  const formattedSession = useSelectedFormattedSession()
  const passMetrics = flightTypes.items.pass.props.metricTypes
  // ===== //

  const unitSystem = useUnitsSystem(sport)

  const [passes, setPasses] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableHeaders, setTableHeaders] = useState([])

  useEffect(() => {
    setPasses(
      Object.values(events.rawData).filter(
        (flight) =>
          flight.event.type === eventTypes.items.flight?.value &&
          flight.type === flightTypes.items.pass?.value &&
          !flight.ignore
      )
    )
  }, [events.rawData])

  useEffect(() => {
    const playersSessionConfig = playersSessions.filter((x) => x.player)
    getTableData(passes, playersSessionConfig)
    getTableHeaders(passes, playersSessionConfig)
  }, [passes, unitSystem.name])

  const getTableData = (passes, playersSessionConfig) => {
    const playerIdIndex = []
    const data = []

    playersSessionConfig.forEach((x, i) => {
      playerIdIndex[i] = x.playerId
      const otherPlayers = {}
      playersSessionConfig.forEach((y) => {
        if (x.playerId !== y.playerId) {
          otherPlayers[y.playerId] = 0
          otherPlayers[`${y.playerId}_territoryGain`] = 0
        }
      })
      data[i] = {
        ...otherPlayers,
        name: `FROM - ${x.player.firstName} ${x.player.lastName}`,
        id: x.playerId
      }
    })

    passes.forEach((pass) => {
      const fromPlayerIndex = playerIdIndex.indexOf(pass.fromPlayerId)
      const toPlayerIndex = playerIdIndex.indexOf(pass.toPlayerId)
      // TODO: not needed for matches
      const territorialGain = Math.abs(pass.territorialGain)

      if (
        fromPlayerIndex >= 0 &&
        toPlayerIndex >= 0 &&
        fromPlayerIndex !== toPlayerIndex
      ) {
        data[fromPlayerIndex][pass.toPlayerId]++
        data[fromPlayerIndex][`${pass.toPlayerId}_territoryGain`] +=
          territorialGain
      }
    })

    // TODO: this is messy, refactor
    if (passMetrics.items.territorialGain) {
      const territorialGainMetricType = generateMetricTypeClass(
        passMetrics.items.territorialGain
      )
      playersSessionConfig.forEach((x, i) => {
        playersSessionConfig.forEach((y) => {
          if (x.playerId !== y.playerId) {
            data[i][`${y.playerId}_territoryGain`] = data[i][
              `${y.playerId}_territoryGain`
            ]
              ? territorialGainMetricType.getMetricValueWithUnits(
                  data[i][`${y.playerId}_territoryGain`],
                  unitSystem,
                  null,
                  null,
                  2
                )
              : ''
          }
        })
      })
    }
    setTableData(data)
  }

  const getTableHeaders = (passes, playersSessionConfig) => {
    const sortedPlayerSessionConfig = [...playersSessionConfig].sort((a, b) => {
      if (a.player.firstName < b.player.firstName) return -1
      if (a.player.firstName > b.player.firstName) return 1
      return 0
    })
    const headers = sortedPlayerSessionConfig.map((x) => {
      const headerArray = [
        {
          name: `TO - ${x.player.firstName} ${x.player.lastName}`,
          key: `${x.playerId}`
        }
      ]
      if (passMetrics.items.territorialGain)
        headerArray.push({
          name: passMetrics.items.territorialGain.props.abbr,
          key: `${x.playerId}_territoryGain`
        })
      return headerArray
    })
    const tableHeaders = [
      { name: ``, key: 'name', width: 20 },
      ...headers.flat()
    ]
    setTableHeaders(tableHeaders)
  }

  const playerPassData = useMemo(() => {
    return Object.values(sessionPlayers).map((playerSession) => {
      // Filter passes by player
      const filteredPasses = passes.filter((pass) => {
        return pass.fromPlayerId === playerSession.playerId
      })

      // Set initial data
      const data: PlayerPassData = {
        id: playerSession.playerId,
        player: playerSession.player,
        count: filteredPasses.length,
        metrics: {}
      }
      // Calculate max, min and average for each metric
      data.metrics = aggregateAndFormatMetricsForFlights(
        flightTypes.items.pass.props.metricTypes,
        filteredPasses,
        unitSystem,
        formattedSession
      )
      return data
    })
  }, [passes, unitSystem.name])
  return (
    <React.Fragment>
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <>
            <div className={styles.passComboHeader}>
              <h5>Pass Combinations</h5>
            </div>
            {Object.values(sessionPlayers).length < 2 ? (
              <div className={styles.notEnoughPlayers}>
                <img src={multiplePlayers} alt='' />
                <h5>
                  Multiple players required in session for pass combinations
                </h5>
              </div>
            ) : (
              <div className={styles.passComboTable}>
                <div className='card-table-container'>
                  <Table
                    // Row props
                    // Table props
                    options={{
                      initialOrder: 'asc',
                      initialSortBy: 'name',
                      sortActive: true
                    }}
                    headerFont={13}
                    tableClass={'minimalistBlack'}
                    className={'container'}
                    data={tableData}
                    headers={tableHeaders}
                    id={'pass-summary-table'}
                  />
                </div>
              </div>
            )}
          </>
        </CardList>
      </div>
      <div className={styles.statsContainer}>
        <CardList
          col={12}
          items={playerPassData}
          scrollerId={`scroller-${1}`}
          padding={'0 10px 0 0'}
          border={true}
        >
          <PlayerPassCard />
        </CardList>
      </div>
    </React.Fragment>
  )
}
