import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import { setBallInPlay } from '../../../../metrics_server/events/actions'
import styles from './InfoContainer.module.scss'
import { Strack } from '../../Strack.types'
import { useFormattedHardware } from '../../../../metrics_server/hardware/hooks'
import { isDeviceType } from '../../../../metrics_server/hardware/data_types'
import { BatteryIcon } from '../../../Icons/BatteryIcon/BatteryIcon'
import { SessionTagInfo } from './SessionTagInfo'

export interface StrackTagInfoProps {
  strackReady: boolean
  strack: Strack
}

export function StrackTagInfo({ strackReady, strack }: StrackTagInfoProps) {
  const dispatch = useAppDispatch()
  const formattedHardware = useFormattedHardware()

  const [tagId, setTagId] = useState(null)

  // Listen for tag click //
  useEffect(() => {
    if (strackReady) {
      strack.events.eventHandleCanvas.addEventListener('click', (e) => {
        strack.checkForTagOnClick(e, (tagId) => {
          strack.setSelectedTag(tagId)
          setTagId(tagId)
        })
      })
    }
  }, [strackReady])

  if (!tagId) return null

  const device = formattedHardware.devices.map[tagId]

  return (
    <div className={styles.tagInfoContainer}>
      <h5>
        {device.type.name} - {device && device.serial} ({device && device.id})
      </h5>
      <div className={styles.batteryAndButton}>
        <div className={styles.battery}>
          <BatteryIcon device={device} />
          <h5>{device && device.battery.value}%</h5>
        </div>
        {isDeviceType.ball(device.type) && (
          <button
            className={`${styles.ballInPlayButton}`}
            onClick={() => {
              if (device) {
                dispatch(setBallInPlay(device.id))
              } else {
                console.log('Device info not found not found')
              }
            }}
          >
            Set ball in play
          </button>
        )}
      </div>
      {strack.session && <SessionTagInfo tagId={device.id} strack={strack} />}
    </div>
  )
}
