import { Badge, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers-pro'

import React, { ComponentType } from 'react'
import styles from './DatePicker.module.scss'
import {
  PickersDay,
  PickersDayProps
} from '@mui/x-date-pickers/PickersDay/PickersDay'
import {
  SportTypeValues,
  sportTypes
} from '../../../../metrics_server/sports/data_types'
import { SportableBallProps } from '../../../Icons/SportableBall/SportableBall'
import { getSport } from '../../../../metrics_server/sports/functions'

type sessionDays = {
  startTime?: number
  endTime?: number
  sportType: SportTypeValues
}
interface DatePickerProps {
  value: Date
  label: string
  sessionDays?: sessionDays[]
  min?: Date
  max?: Date
  Icon?: ComponentType<SportableBallProps>
  onChange: (value) => void
}

export const DatePickerWrapper = ({
  value,
  label,
  sessionDays,
  min,
  max,
  Icon,
  onChange
}: DatePickerProps) => {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    console.log(date, selectedDates, pickersDayProps)
    const day = sessionDays
      ? sessionDays?.find((sess) => {
          return date.isSame(new Date(sess.startTime * 1000), 'day')
        })
      : ''

    return (
      <Badge
        key={date.toString()}
        overlap='circular'
        badgeContent={
          day ? (
            <Icon
              color={'#858585'}
              scale={0.4}
              sport={getSport(day.sportType)}
            />
          ) : undefined
        }
        sx={{ width: '37px' }}
      >
        <PickersDay {...pickersDayProps} />
      </Badge>
    )
  }

  return (
    <DesktopDatePicker
      format='dd/MM/yy'
      label={label}
      value={value}
      onChange={(value) => onChange(value)}
      minDate={min}
      maxDate={max}
      sx={{
        '& input': {
          padding: '10px' // Adjust the padding of input
        }
      }}
      // renderDay={renderWeekPickerDay}
      // renderInput={(params) => (
      //   <TextField
      //     {...params}
      //     variant={label === 'From' || label === 'To' ? 'standard' : 'outlined'}
      //     fullWidth
      //   />
      // )}
    />
  )
}
