import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'

export type DeviceTypeFeatures = {
  exampleDeviceFeature: boolean
}

export const defaultDeviceTypeFeatures: DeviceTypeFeatures = {
  exampleDeviceFeature: true
}

export const deviceTypesConfig = {
  anchor: {
    key: 'anchor',
    value: 'Anchor',
    name: 'Anchor',
    props: { features: defaultDeviceTypeFeatures }
  },
  ball: {
    key: 'ball',
    value: 'Ball',
    name: 'Ball',
    props: { features: defaultDeviceTypeFeatures }
  },
  playerTag: {
    key: 'playerTag',
    value: 'PlayerTag',
    name: 'Player Tag',
    props: { features: defaultDeviceTypeFeatures }
  }
} as const

export type DeviceTypeProps = {
  features: DeviceTypeFeatures
}

export type DeviceTypes = DataTypes<typeof deviceTypesConfig>

export type DeviceTypeKey = DataTypeKey<DeviceTypes>

export type DeviceTypeKeys = DataTypeKeys<DeviceTypeKey>

export type DeviceTypeValues = DataTypeValues<DeviceTypeKey>

export const getDeviceTypeGroup = (items: {
  [key in DeviceTypeKeys]?: DeviceTypes[key]
}) => {
  return getDataTypeGroup<
    DeviceTypeKeys,
    DeviceTypeValues,
    DeviceTypes,
    DeviceTypeProps
  >(items)
}

export type DeviceTypeGroup = ReturnType<typeof getDeviceTypeGroup>

export type DeviceTypeConfig = DataTypeConfig<
  DeviceTypeKeys,
  DeviceTypeValues,
  DeviceTypeProps
>

export const deviceTypes = getDeviceTypeGroup(deviceTypesConfig)

export type DeviceType = ReturnType<typeof deviceTypes.getTypeByValue>

export const isDeviceType = generateTypeChecks<DeviceTypeKeys, DeviceTypeGroup>(
  deviceTypes
)
