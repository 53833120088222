// @flow

import * as BABYLON from '@babylonjs/core'
import '@babylonjs/loaders'
// Import images
import leftArrow from '../../assets/img/left-pitch-arrow.svg'
import rightArrow from '../../assets/img/right-pitch-arrow.svg'
import { checkForForwardPass, deviceTypes, sportableColors } from '../../const'
import { encodeHardwareId } from '../encoding'
import {
  distance,
  distance3D,
  movingAverage,
  speed,
  getContrastingTextColor
} from '../helpers'
import { appendTo2dCanvas, load2D } from './2D/build'
import {
  drawCircle,
  drawX,
  getCrossfieldDistances,
  drawDistances
} from './2D/drawing'
// Import canvas tools
import { drawPointTarget } from './2D/targets'
import { load3D } from './3D/build'

import { applyDemonstrationSettings } from './sports/demonstration'
import { applyBoxingSettings } from './sports/boxing'
import { applyRugbySettings } from './sports/rugby'
import { applyRugbyLeagueSettings } from './sports/rugby_league'
import { applyAmericanFootballSettings } from './sports/american_football'
import { applyAustralianRulesSettings } from './sports/australian_rules'
import { applyCanadianFootballSettings } from './sports/canadian_football'

import { getLayerFromHtml, removeLayers, updateLayer } from './texture'

import { sportTypes } from '../../metrics_server/sports/data_types'
import { StrackOptions } from '../../components/Strack/Strack.types'
import { eventTypes } from '../../metrics_server/events/data_types'
import { flightEventTypes } from '../../metrics_server/events/flight/data_types'
import { applySoccerSettings } from './sports/soccer'
import { PlayerSession } from '../../metrics_server/sessions/types'
import { FlightEventSubType } from '../../metrics_server/events/flight/subTypes/data_types'

// Switch for fixed z axis
const fixedZ = false

export function convertYardsToMeters(value) {
  return value * 0.91440275783
}

export const StrackController = {
  strackTimeout: null,

  clearTimeout: () => {
    clearTimeout(StrackController.strackTimeout)
  },

  // SETUP
  Strack: function () {
    this.pitchFlipped = false
    this.pitchRotated = false

    this.canvasReady = false

    this.teamA = 0
    this.teamB = 0

    this.teams = {
      A: {},
      B: {}
    }

    this.players = {}
    this.fixedZHeight = 1.2

    this.balls = {}

    this.playerStats = {
      vMag: 0,
      aMag: 0
    }

    this.ballStats = {
      vMag: 0,
      z: 0
    }

    this.layers = {
      base: null,
      lines: null,
      teams: null,
      numbers: null
    }

    this.buffer = {}
    this.bufferHasData = false

    this.playerSelected = ''
    this.playerSphereDiameter = 1.0

    this.ballSelected = ''
    this.ballSphereDiameter = 0.7
    this.ballOutlineWidth = 0.2

    this.scaleX = 1
    this.scaleY = 1
    this.scaleZ = 1

    this.scene = null
    this.camera = null
    this.cameraSpeed = 0.05
    this.cameraCurrent = [0, 0, 0]
    this.initCameraZoom = 120

    this.engine = null

    this.selectedBalls = []

    this.aspect3DCanvas = 1.73

    this.selectedPlayer = {}

    this.kickDirection = 0

    this.ealingOffset = {
      x: 0,
      y: 0
    }
    this.defaultPitch = false

    this.init = async (
      {
        pitch,
        diags = false,
        validation = false,
        eventLabels = false,
        anchorConfig = {},
        anchorSetup,
        canvasId = 'strack',
        cover = 'rugby-cover',
        babylonActive = true,
        keyboardInputs = false,
        tracking = false,
        initialView = '2D',
        centerImgSrc,
        broadcastIntegrationEnabled = false,
        session,
        touchToRefDistance,
        eventsClickable
      }: StrackOptions,
      endLoading
    ) => {
      try {
        this.broadcastIntegrationEnabled = broadcastIntegrationEnabled

        this.centerImgSrc = centerImgSrc

        this.view = initialView

        this.validation = validation

        this.eventLabels = eventLabels

        this.keyboardInputs = keyboardInputs

        this.eventsClickable = eventsClickable

        //--> Generate pitch config

        this.pitchType = pitch.type
        const sport = sportTypes.getTypeByValue(pitch.type)
        this.sport = sportTypes.getTypeByValue(pitch.type)

        if (!pitch.coordinates) {
          this.defaultPitch = true
          pitch.coordinates = sport.props.pitch.default.pitch.coordinates
          pitch.arcs = sport.props.pitch.default.arcs
        }

        this.dimensions = pitch.coordinates

        this.arcs = pitch.arcs

        // If there there is a session check whether it is live before enabling tracking
        this.tracking = session ? session.live && tracking : tracking

        this.babylonActive = babylonActive

        this.session = session

        this.sessionTags = session?.sessionData?.playersSessions || []

        this.teams = {
          A: session?.homeTeam?.rawData || {},
          B: session?.awayTeam?.rawData || {}
        }

        this.touchToRefDistance = touchToRefDistance

        if (anchorSetup) {
          this.anchorSetup = {
            anchorConfig: anchorConfig,
            active: true
          }
        }

        if (diags) this.diags.active = true

        this.setKicksColours()

        // Set canvas ids to match rendered react components in Canvas
        this.canvasId2D = `${canvasId}-2D`
        this.coverId = `${canvasId}-${cover}`
        this.textureId = `${canvasId}-texture`
        this.canvasId3D = `${canvasId}-3D`

        this.buildEnvironment(pitch, canvasId, () => {
          this.canvasElementPixelRatio = 1.2

          // Load 2D map of pitch
          load2D(this)

          // Load Babylon 3D render of pitch else stop loading
          if (babylonActive) {
            load3D(this, endLoading)
          } else {
            endLoading()
          }

          //--> Load slow loop and WebGL
          if (tracking) {
            this.loopCounter = 1
            this.loop()
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    this.end = (cb) => {
      removeLayers(this)

      if (this.babylonActive) {
        this.scene.dispose()
        this.engine.dispose()
      }

      if (this.tracking) {
        this.stopMapLoop()
      }

      const childNodes = this.canvasContainer2D.childNodes
      const clones = []

      for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i]
        const clone = node.cloneNode()
        clones.push(clone)
      }

      for (let i = childNodes.length - 1; i >= 0; i--) {
        const node = childNodes[i]
        node.remove()
      }

      clones.forEach((clone) => {
        this.canvasContainer2D.append(clone)
      })

      cb()
    }

    this.destroy = () => {
      if (this.tracking) {
        this.stopMapLoop()
      }

      if (this.engine) {
        this.engine.stopRenderLoop()
        this.scene.dispose()
        this.engine.dispose()
      }
    }

    this.buildEnvironment = (pitch, canvasId, callback) => {
      //--> set canvas element size
      this.canvasSection = document.getElementById(`${canvasId}-canvas-section`)
      this.canvasContainer = document.getElementById(
        `${canvasId}-strack-canvas-container`
      )
      this.canvasContainer2D = document.getElementById(
        `${canvasId}-2D-canvas-container`
      )

      this.canvasHeight = parseInt(
        window.getComputedStyle(this.canvasContainer).height,
        10
      )
      this.canvasSectionWidth = parseInt(
        window.getComputedStyle(this.canvasSection).width,
        10
      )

      this.canvasWidth = this.canvasHeight * this.aspect3DCanvas

      // Set field and pole values
      // If no lengths are given set default length
      this.field = {}
      this.poles = {}
      const { field, poles, dimensions } = this

      //--> Apply environment settings and dimension

      switch (this.pitchType) {
        case sportTypes.items.demonstration.value:
          applyDemonstrationSettings(field, poles, dimensions)
          break
        case sportTypes.items.rugbyUnion.value:
          applyRugbySettings(field, poles, dimensions)
          break
        case sportTypes.items.rugbyLeague.value:
          applyRugbyLeagueSettings(field, poles, dimensions)
          break
        case sportTypes.items.americanFootball.value:
          applyAmericanFootballSettings(field, poles, dimensions)
          break
        // case sportTypes.items.boxing?.value:
        //   applyBoxingSettings(field, dimensions)
        //   break
        case sportTypes.items.australianRules.value:
          applyAustralianRulesSettings(field, dimensions)
          break
        case sportTypes.items.canadianFootball.value:
          applyCanadianFootballSettings(field, poles, dimensions)
          break
        case sportTypes.items.soccer.value:
          applySoccerSettings(field, poles, dimensions)
          break
        default:
          break
      }

      // Set field width and height
      this.fieldWidth = field.width
      this.fieldHeight = field.height

      callback()
    }

    this.updatePitchTexture = (html, type) => {
      getLayerFromHtml(html, this, (canvas) => {
        updateLayer(type, canvas, this)
      })
    }

    this.stGaugeGo = (index, val) => {
      this['gaugeTarget' + index] = Math.round((val / 100) * 45)
    }

    this.areasOfAccessActive = false

    this.setPos = (team, tagId, x, y, z) => {
      if (team) {
        const player = this.players[tagId]
        if (player) {
          player.mesh.position.x = x * this.scaleX
          player.mesh.position.z = y * this.scaleY
          player.mesh.position.y = z * this.scaleZ
          player.plane.position.x = x * this.scaleX
          player.plane.position.z = y * this.scaleY
          player.plane.position.y = z * this.scaleZ + 3
        }
      } else {
        const ball = this.balls[tagId]
        if (ball) {
          ball.mesh.position.x = x * this.scaleX
          ball.mesh.position.z = y * this.scaleY
          ball.mesh.position.y = z * this.scaleZ
        }
      }
    }

    this.twoDCanvas = null
    this.mapCtx = null
    this.mapObjects = {}

    this.flipPitch = (bool) => {
      this.pitchFlipped = bool
      if (this.events.active) {
        this.plotEventsOnCanvas(null, true)
      }
      if (this.targets.active) {
        this.drawTargets()
      }
      // Redraw pitch
      load2D(this)
    }
    this.rotatePitch = (bool) => {
      this.pitchRotated = bool
      if (this.events.active) {
        this.plotEventsOnCanvas(null, true)
      }
      if (this.targets.active) {
        this.drawTargets()
      }
      // Redraw pitch
      load2D(this)
    }
    this.rotatePoint = (point, pivot, angleDegrees) => {
      // Convert angle from degrees to radians
      const angleRadians = angleDegrees * (Math.PI / 180)

      // Translate point to origin
      const translatedX = point.scaleX - pivot.x
      const translatedY = point.scaleY - pivot.y

      // Rotate point
      const rotatedX =
        translatedX * Math.cos(angleRadians) -
        translatedY * Math.sin(angleRadians)
      const rotatedY =
        translatedX * Math.sin(angleRadians) +
        translatedY * Math.cos(angleRadians)

      // Translate point back
      const finalX = rotatedX + pivot.x
      const finalY = rotatedY + pivot.y

      return { scaleX: finalX, scaleY: finalY }
    }

    // Convert data coordinate to scaled 2D canvas coordinate
    this.getCanvasCoordinate = (scale, x, y, fixed) => {
      const { field } = this

      let fieldScreenWidth = field.width
      let fieldScreenHeight = field.height

      if (this.pitchRotated) {
        fieldScreenWidth = field.height
        fieldScreenHeight = field.width
      }

      const originOffsetX = field.originOffsetX || 0
      const originOffsetY = field.originOffsetY || 0

      if (!scale) scale = this.scale
      const offsetX = this.twoDOffsetX,
        offsetY = this.twoDOffsetY
      const coord = {
        scaleX: null,
        scaleY: null
      }

      const pivotPoint = {
        x: this.twoDCanvas.width / 2,
        y: this.twoDCanvas.height / 2
      }

      if (!this.pitchFlipped || fixed) {
        coord.scaleX =
          (x + offsetX + originOffsetX + fieldScreenWidth / 2) * scale
        coord.scaleY =
          (fieldScreenHeight / 2 - y + originOffsetY + offsetY) * scale
      } else {
        coord.scaleX =
          (offsetX - originOffsetX + fieldScreenWidth / 2 - x) * scale
        coord.scaleY =
          (fieldScreenHeight / 2 + y - originOffsetY + offsetY) * scale
      }
      if (this.pitchRotated) return this.rotatePoint(coord, pivotPoint, 90)

      return coord
    }

    // Convert data coordinate to scaled 3D dynamic texture canvas coordinate
    this.get3DCanvasCoordinate = (scale, x, y, fixed) => {
      const { field } = this

      const originOffsetX = field.originOffsetX || 0
      const originOffsetY = field.originOffsetY || 0

      if (!scale) scale = this.scale
      const offsetX = this.threeDOffsetX,
        offsetY = this.threeDOffsetY
      const coord = {
        scaleX: null,
        scaleY: null
      }
      if (!this.pitchFlipped || fixed) {
        coord.scaleX =
          (x + offsetX + originOffsetX + this.fieldWidth / 2) * scale
        coord.scaleY =
          (this.fieldHeight / 2 - y + originOffsetY + offsetY) * scale
      } else {
        coord.scaleX =
          (offsetX - originOffsetX + this.fieldWidth / 2 - x) * scale
        coord.scaleY =
          (this.fieldHeight / 2 + y - originOffsetY + offsetY) * scale
      }
      return coord
    }

    this.getPitchCoordinate = (scale, x, y) => {
      const coord = {
        pitchX: null,
        pitchY: null
      }
      const { field } = this
      const originOffsetX = field.originOffsetX || 0
      const originOffsetY = field.originOffsetY || 0

      if (!this.pitchFlipped) {
        coord.pitchX =
          x / scale - this.twoDOffsetX - originOffsetX - this.fieldWidth / 2
        coord.pitchY = -(
          y / scale -
          this.twoDOffsetY -
          originOffsetY -
          this.fieldHeight / 2
        )
      } else {
        coord.pitchX = -(
          x / scale -
          this.twoDOffsetX -
          originOffsetX -
          this.fieldWidth / 2
        )
        coord.pitchY =
          y / scale - this.twoDOffsetY - originOffsetY - this.fieldHeight / 2
      }
      return coord
    }

    this.getPitchCoordinateFromCanvasOffset = (scale, x, y) => {
      const coord = {
        x: null,
        y: null
      }
      if (!this.pitchFlipped) {
        coord.x = x / scale - this.twoDOffsetX - this.fieldWidth / 2
        coord.y = -(y / scale) + this.fieldHeight + this.twoDOffsetY
      } else {
        coord.x = -(x / scale) + this.twoDOffsetX + this.fieldWidth / 2
        coord.y = y / scale + this.twoDOffsetY
      }
      return coord
    }

    // rendering data on session summary. Live session sets to true on initiate.
    this.playbackPaused = false
    this.stopRenderLoop = false
    this.pausedRenderSpeed = 500 // 2 frames per second
    this.playingRenderSpeed = 50 // 20 frames per second

    this.stopMapLoop = () => {
      this.stopRenderLoop = true
    }

    this.calculateAnchorRadius = (tag, anchor, dist) => {
      const h = dist,
        y = Math.abs(anchor.pos.z - parseFloat(this.diags.targetCoords.zPlot))
      let radius = Math.sqrt(Math.pow(h, 2) - Math.pow(y, 2))
      radius = radius * this.canvas2DPixelScale
      return radius
    }

    this.showSessionTracking = true

    this.drawId = (x, y, serial, color) => {
      this.mapCtx.fillStyle = color
      this.mapCtx.font = '10px sans-serif'

      const textString = serial.slice(-2),
        textWidth = this.mapCtx.measureText(textString).width

      this.mapCtx.fillText(textString, x - textWidth / 2, y + 3)
    }
    this.drawPlayerNumber = (x, y, number, color) => {
      this.mapCtx.fillStyle = color
      this.mapCtx.font = '10px sans-serif'

      const textString = number,
        textWidth = this.mapCtx.measureText(textString).width

      this.mapCtx.fillText(textString, x - textWidth / 2, y + 3)
    }
    this.drawCoordinateBox = (tagId, ball) => {
      const { x, y, z } = ball
      const targetX = parseFloat(this.diags.targetCoords.x || 0)
      const targetY = parseFloat(this.diags.targetCoords.y || 0)
      const targetZ = parseFloat(this.diags.targetCoords.z || 0)

      const coordX =
        !isNaN(targetX) && this.selectedTag == tagId ? x.toFixed(2) : ''
      const deltaX =
        !isNaN(targetX) && this.selectedTag == tagId
          ? (targetX - x).toFixed(2)
          : this.selectedTag == tagId
          ? x.toFixed(2)
          : ''
      const coordY =
        !isNaN(targetY) && this.selectedTag == tagId ? y.toFixed(2) : ''
      const deltaY =
        !isNaN(targetY) && this.selectedTag == tagId
          ? (targetY - y).toFixed(2)
          : this.selectedTag == tagId
          ? y.toFixed(2)
          : ''
      const coordZ =
        !isNaN(targetZ) && this.selectedTag == tagId ? z.toFixed(2) : ''
      // const deltaZ =
      //   !isNaN(targetZ) && this.selectedTag == tagId
      //     ? (targetZ - z).toFixed(2)
      //     : this.selectedTag == tagId
      //     ? z.toFixed(2)
      //     : ''

      if (this.diags.showCoords) {
        this.mapCtx.fillStyle = 'red'
        this.mapCtx.fillRect(25, 10, 190, 85)
        this.drawText('#fff', `X : ${coordX} || D: ${deltaX}`, 30, 30)
        this.drawText('#fff', `Y : ${coordY} || D: ${deltaY}`, 30, 55)
        this.drawText('#fff', `Z : ${coordZ} || D: ${coordZ}`, 30, 80)
      }
    }
    this.drawDistanceCoordinateBox = (refXpos, eventXpos) => {
      const distance = Math.abs(eventXpos - refXpos).toFixed(1)

      const parsedDistance = parseFloat(distance)

      let color
      if (!isNaN(parsedDistance)) {
        if (parsedDistance < 2) {
          color = '#2DFE54'
        } else if (parsedDistance < 5) {
          color = 'yellow'
        } else {
          color = 'red'
        }
      }

      const padding = 25
      const textPadding = 10
      const fontSize = 40
      const boxHeight = fontSize + textPadding
      const boxWidth = fontSize * 10 + textPadding * 2

      this.mapCtx.fillStyle = 'rgba(0,0,0,0.5)'
      this.mapCtx.fillRect(
        padding,
        this.twoDCanvas.height - boxHeight - padding,
        boxWidth,
        boxHeight
      )
      this.drawTouchText(
        'white',
        `Touch To Ref: `,
        padding + textPadding,
        this.twoDCanvas.height - padding - textPadding
      )
      this.drawTouchText(
        color,
        `${parsedDistance}m`,
        boxWidth * 0.75,
        this.twoDCanvas.height - padding - textPadding
      )
    }
    this.drawText = (color, text, x, z) => {
      this.mapCtx.save()
      this.mapCtx.fillStyle = color
      this.mapCtx.font = '20px sans-serif'
      this.mapCtx.fillText(text, x, z)
      this.mapCtx.restore()
    }

    this.drawTouchText = (color, text, x, z) => {
      this.mapCtx.save()
      this.mapCtx.fillStyle = color
      this.mapCtx.font = 'bold 40px sans-serif'
      this.mapCtx.fillText(text, x, z)
      this.mapCtx.restore()
    }

    this.drawBall = (ball, timeNowInSeconds, tagId) => {
      const { x, y, serial, time, isBallInPlay } = ball
      const { scaleX, scaleY } = this.getCanvasCoordinate(
        this.canvas2DPixelScale,
        x,
        y
      )
      // this.drawMapCircle(this.mapCtx, scaleX, scaleY, t, n, true);

      if (timeNowInSeconds - time < 120 || this.dummyData) {
        this.mapCtx.save()
        if (timeNowInSeconds - time > 5 && !this.dummyData)
          this.mapCtx.globalAlpha = 0.4

        if (this.selectedTag == tagId) {
          this.mapCtx.drawImage(
            this.ball2DSelected,
            scaleX - this.ball2DSize / 2,
            scaleY - this.ball2DSize / 2
          )
        } else if (isBallInPlay) {
          this.mapCtx.drawImage(
            this.ball2DInPlay,
            scaleX - this.ball2DSize / 2,
            scaleY - this.ball2DSize / 2
          )
        } else {
          this.mapCtx.drawImage(
            this.ball2D,
            scaleX - this.ball2DSize / 2,
            scaleY - this.ball2DSize / 2
          )
        }
        this.mapCtx.restore()

        if (this.showTagIds) {
          this.drawId(
            scaleX,
            scaleY,
            serial,
            this.selectedTag == tagId ? 'white' : 'black'
          )
        } else if (isBallInPlay) {
          this.drawId(
            scaleX,
            scaleY,
            'iP',
            this.selectedTag == tagId ? 'white' : 'black'
          )
        }
      }
    }
    this.drawDistanceText = (distance, x, y) => {
      this.mapCtx.fillStyle =
        distance > 0
          ? sportableColors.colors.success
          : sportableColors.colors.failure
      this.mapCtx.font = '20px sans-serif'
      this.mapCtx.fillText(distance.toFixed(3), x, y)
    }

    this.drawX = (x, y) => {
      this.mapCtx.save()
      this.mapCtx.fillStyle = '#006699'
      this.mapCtx.strokeStyle = '#009DDC'
      this.mapCtx.lineWidth = 3
      this.mapCtx.beginPath()

      this.mapCtx.moveTo(x - 10, y - 10)
      this.mapCtx.lineTo(x + 10, y + 10)

      this.mapCtx.moveTo(x + 10, y - 10)
      this.mapCtx.lineTo(x - 10, y + 10)
      this.mapCtx.stroke()
      this.mapCtx.restore()
    }
    this.drawTargetCoords = (x, y) => {
      const { scaleX, scaleY } = this.getCanvasCoordinate(
        this.canvas2DPixelScale,
        x,
        y
      )
      this.drawX(scaleX, scaleY)
    }

    this.showTagIds = true

    this.drawPlayer = (player, timeNowInSeconds, tagId, teams) => {
      const { x, y, t, serial, time, n } = player
      let color = 'blue'
      let playerImage

      if (t === 'A') {
        color = teams.A.color
        playerImage =
          this.selectedTag === tagId ? this.playersA2DSelected : this.playersA2D
      } else if (t === 'B') {
        color = teams.B.color
        playerImage =
          this.selectedTag === tagId ? this.playersB2DSelected : this.playersB2D
      } else if (t === 'match_officials') {
        color = 'gray' // Color for match officials
        playerImage = this.officials2D // Assuming you have a canvas for match officials
      } else {
        playerImage =
          this.selectedTag === tagId ? this.playersA2DSelected : this.playersA2D
      }

      const { scaleX, scaleY } = this.getCanvasCoordinate(
        this.canvas2DPixelScale,
        x,
        y
      )

      if (timeNowInSeconds - time < 120 || this.dummyData) {
        this.mapCtx.save()
        if (timeNowInSeconds - time > 5 && !this.dummyData)
          this.mapCtx.globalAlpha = 0.4

        // Draw the player or match official
        this.mapCtx.drawImage(
          playerImage,
          scaleX - this.player2DSize / 2,
          scaleY - this.player2DSize / 2
        )

        this.mapCtx.restore()

        if (this.showTagIds) {
          this.drawId(scaleX, scaleY, serial, getContrastingTextColor(color))
        } else {
          this.drawPlayerNumber(
            scaleX,
            scaleY,
            n,
            getContrastingTextColor(color)
          )
        }
      }
    }

    this.drawMeas = (tag) => {
      if (tag.ranges) {
        for (let i = 0; i < tag.ranges.length; i++) {
          const anchorMeas = tag.ranges[i]
          const anchor = this.diags.selectedAnchors.find(
            (selectedAnchor) => anchorMeas.source === selectedAnchor.id
          )
          if (anchor) {
            const { scaleX, scaleY } = this.getCanvasCoordinate(
              this.canvas2DPixelScale,
              anchor.pos.x,
              anchor.pos.y
            )
            this.mapCtx.save()
            this.mapCtx.beginPath()
            this.mapCtx.arc(
              scaleX,
              scaleY,
              this.calculateAnchorRadius(tag, anchor, tag.ranges[i].range),
              0,
              2 * Math.PI
            )
            this.mapCtx.lineWidth = 1
            this.mapCtx.strokeStyle = 'rgba(255,255,0,0.6)'
            this.mapCtx.stroke()

            this.mapCtx.restore()

            const anchorAndTargetDistance = distance3D(
              { x: anchor.pos.x, y: anchor.pos.y, z: anchor.pos.z },
              {
                x: this.diags.targetCoords.x,
                y: this.diags.targetCoords.y,
                z: this.diags.targetCoords.z
              }
            )

            this.drawDistanceText(
              tag.ranges[i].range - anchorAndTargetDistance,
              scaleX,
              scaleY
            )
          }
        }
        // drawCircle(
        //   tag.x,
        //   tag.y,
        //   this.mapCtx,
        //   3,
        //   '#0099CC',
        //   '#0099CC',
        //   null,
        //   this.getCanvasCoordinate,
        //   this.canvas2DPixelScale
        // )
        // drawCircle(
        //   tag.x,
        //   tag.y,
        //   this.mapCtx,
        //   7,
        //   '#0099CC',
        //   null,
        //   null,
        //   this.getCanvasCoordinate,
        //   this.canvas2DPixelScale
        // )
      }
    }

    this.updateSelectedAnchors = (selectedAnchors) => {
      this.diags.selectedAnchors = selectedAnchors
    }

    this.loop = () => {
      //--> Map
      if (this.view === '2D') {
        this.runBufferPos(null, () => {
          this.clearMapFrame(this.mapCtx)
          // Update selected player

          if (!this.showSessionTracking && !this.diags.active) return
          if (this.selectedPlayer.tagId) {
            this.selectedPlayer = this.mapObjects[this.selectedPlayer.tagId]
          }
          const timeNowInSeconds = new Date().getTime() / 1000

          const drawTags = (sessionActive) => {
            for (const key in this.mapObjects) {
              const tag = this.mapObjects[key]
              let sessionTag
              if (sessionActive) {
                sessionTag = this.sessionTags.find(
                  (playerSession: PlayerSession) => {
                    return (
                      playerSession.tag &&
                      (playerSession.tag.id === parseInt(key) ||
                        playerSession.tag.serial === key)
                    )
                  }
                )
                if (!sessionTag && !this.broadcastIntegrationEnabled) continue
              }
              // get tag coords then chack if tag is off pitch
              const { scaleX, scaleY } = this.getCanvasCoordinate(
                this.canvas2DPixelScale,
                tag.x,
                tag.y
              )

              if (scaleX < 0) {
                this.drawText('#FF0000', `←${tag.serial}`, 0, scaleY)
              }
              if (scaleX > this.twoDCanvas.width) {
                this.drawText(
                  '#FF0000',
                  `${tag.serial}→`,
                  this.twoDCanvas.width - 100,
                  scaleY
                )
              }
              if (scaleY < 0) {
                this.drawText('#FF0000', `${tag.serial}↑`, scaleX, 20)
              }
              if (scaleY > this.twoDCanvas.height) {
                this.drawText(
                  '#FF0000',
                  `${tag.serial}↓`,
                  scaleX,
                  this.twoDCanvas.height - 5
                )
              }

              if (!tag.ball) {
                this.drawPlayer(tag, timeNowInSeconds, key, this.teams)
              } else {
                this.drawBall(tag, timeNowInSeconds, key)
              }
            }
          }
          // Draw Distance to Touch
          if (this.events.highlightedId && this.touchToRefDistance) {
            let refTag
            let minDistance = Number.MAX_VALUE
            const event = this.events.events.find(
              (event) => event.id === this.events.highlightedId
            )
            for (const key in this.mapObjects) {
              const tag = this.mapObjects[key]
              if (tag.t === 'match_officials' && event) {
                const distance = Math.abs(tag.y - event.positionY)

                // Update refTag and minDistance if the current tag is closer to the event
                if (distance < minDistance) {
                  refTag = tag
                  minDistance = distance
                }
              }
            }
            if (
              event &&
              refTag &&
              event.type === 'TOUCH' &&
              this.touchToRefDistance
            ) {
              this.drawDistanceCoordinateBox(refTag.x, event.positionX)
            }
          }
          if (this.diags.active) {
            this.drawTargetCoords(
              this.diags.targetCoords.x,
              this.diags.targetCoords.y
            )

            const tag = this.mapObjects[this.selectedTag]
            if (this.diags.viewAllTags) {
              drawTags(false)
            } else {
              if (tag) {
                if (!tag.ball) {
                  this.drawPlayer(
                    tag,
                    timeNowInSeconds,
                    this.selectedTag.toString()
                  )
                } else {
                  this.drawBall(
                    tag,
                    timeNowInSeconds,
                    this.selectedTag.toString()
                  )
                }
                this.drawCoordinateBox(this.selectedTag.toString(), tag)
              }
            }
            if (this.diags.showMeas) {
              if (tag) {
                this.drawMeas(tag)
              }
            }
          } else {
            drawTags(true)
          }
        })
      }

      //--> Loop Handler

      this.loopCounter++
      if (this.loopCounter > 1000) this.loopCounter = 0
      if (!this.stopRenderLoop) {
        requestAnimationFrame(this.loop)
        // setTimeout(
        //   this.loop,
        //   this.playbackPaused ? this.pausedRenderSpeed : this.playingRenderSpeed
        // )
      } else if (this.stopRenderLoop) {
        // reset render stop conditional
        this.stopRenderLoop = false
      }
    }

    // Clear 2D canvas
    this.clearMapFrame = (ctx) => {
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          (this.fieldWidth + 2 * this.twoDOffsetX) * this.canvas2DPixelScale,
          (this.fieldHeight + 2 * this.twoDOffsetY) * this.canvas2DPixelScale
        )
      }
    }

    // Create Player and Ball clippings

    this.players2D = {}
    this.tags2DSelected = {}
    this.player2DSize = 20
    this.ball2DSize = 20
    this.tags2DSelectedSize = 34

    this.tagData = {
      devices: {}
    }

    //----> Pitch Setup

    this.diags = {
      active: false,
      selectedAnchors: [],
      selectedTag: {},
      viewAllTags: false,
      showMeas: false,
      tagLabels: {},
      showCoords: false,
      targetCoords: { x: 0, y: 0, z: 0, zPlot: 0 }
    }

    //----> Diagnostics

    this.anchorSetup = {
      active: false,
      anchorConfig: {}
    }

    this.pitchSetup = {}

    this.generateSetupCanvas = (callback) => {
      this.pitchSetup.canvas = document.createElement('canvas')
      appendTo2dCanvas(
        this.pitchSetup.canvas,
        'diagsCanvas',
        this.canvasContainer2D,
        this.twoDCanvas,
        this.fieldWidth,
        this.fieldHeight,
        this.twoDOffsetX,
        this.twoDOffsetY,
        this.scale
      )
      this.pitchSetup.ctx = this.pitchSetup.canvas.getContext('2d')
      if (callback) callback()
    }

    this.drawArrow = (ctx, x, y, width, height, direction) => {
      const img = new Image()
      if (direction === 'left') {
        img.src = leftArrow
      } else {
        img.src = rightArrow
      }
      img.onload = (event) => {
        ctx.drawImage(
          event.target,
          this.getCanvasCoordinate(this.canvas2DPixelScale, x).scaleX -
            width / 2,
          this.getCanvasCoordinate(this.canvas2DPixelScale, null, y).scaleY -
            height / 2,
          width,
          height
        )
      }
    }

    this.drawSelectedSide = (side) => {
      const ctx = this.pitchSetup.ctx
      this.clearMapFrame(ctx)

      let rightTenMeter,
        leftTenMeter,
        leftOrigin,
        rightOrigin,
        rightFillOrigin,
        tagPoint1,
        tagPoint2,
        tagPoint3

      // Auto setup points
      if (this.pitchType === sportTypes.items.rugbyUnion.value) {
        rightTenMeter = this.dimensions.P7.x - this.dimensions.P6.x
        leftTenMeter = this.dimensions.P6.x - this.dimensions.P5.x

        leftOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P25.x,
          this.dimensions.P25.y
        )
        rightOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P22.x,
          this.dimensions.P22.y
        )
        rightFillOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P20.x,
          this.dimensions.P20.y
        )
        tagPoint1 = this.dimensions.P6
        tagPoint2 = this.dimensions.P19
        tagPoint3 = this.dimensions.P10
      } else if (this.pitchType === sportTypes.items.rugbyLeague.value) {
        rightTenMeter = this.dimensions.P8.x - this.dimensions.P7.x
        leftTenMeter = this.dimensions.P7.x - this.dimensions.P6.x

        leftOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P29.x,
          this.dimensions.P29.y
        )
        rightOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P25.x,
          this.dimensions.P25.y
        )
        rightFillOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P23.x,
          this.dimensions.P23.y
        )
        tagPoint1 = this.dimensions.P7
        tagPoint2 = this.dimensions.P19
        tagPoint3 = this.dimensions.P13
      } else if (this.pitchType === sportTypes.items.americanFootball.value) {
        rightTenMeter = this.dimensions.P8.x - this.dimensions.P7.x
        leftTenMeter = this.dimensions.P7.x - this.dimensions.P6.x

        leftOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P25.x,
          this.dimensions.P25.y
        )
        rightOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P21.x,
          this.dimensions.P21.y
        )
        rightFillOrigin = this.getCanvasCoordinate(
          this.canvas2DPixelScale,
          this.dimensions.P19.x,
          this.dimensions.P19.y
        )
        tagPoint1 = this.dimensions.P7
        tagPoint2 = this.dimensions.P15
        tagPoint3 = this.dimensions.P13
      } else if (this.pitchType === sportTypes.items.australianRules.value) {
        tagPoint1 = {
          x: (this.dimensions.P15.x + this.dimensions.P16.x) / 2,
          y: this.dimensions.P15.y + this.dimensions.P16.y,
          z: 0
        }

        tagPoint2 = this.dimensions.P23
        tagPoint3 = {
          x: (this.dimensions.P30.x + this.dimensions.P31.x) / 2,
          y: this.dimensions.P30.y + this.dimensions.P31.y,
          z: 0
        }
      } else if (this.pitchType === sportTypes.items.canadianFootball.value) {
        tagPoint1 = this.dimensions.P8
        tagPoint2 = this.dimensions.P17
        tagPoint3 = this.dimensions.P15
      } else if (this.pitchType === sportTypes.items.soccer.value) {
        tagPoint1 = this.dimensions.P2
        tagPoint2 = this.dimensions.P8
        tagPoint3 = this.dimensions.P3
      } else {
        return
      }

      if (side === null) return

      if (leftOrigin && side === 1) {
        ctx.fillStyle = 'rgba(0,0,0,0.5)'
        ctx.fillRect(
          leftOrigin.scaleX,
          leftOrigin.scaleY,
          (this.field.tryLineDistance / 2 - leftTenMeter) *
            this.canvas2DPixelScale,
          this.field.height * this.canvas2DPixelScale
        )

        ctx.setLineDash([10])
        ctx.lineWidth = 3
        ctx.strokeStyle = 'yellow'
        ctx.strokeRect(
          rightOrigin.scaleX + 5,
          rightOrigin.scaleY + 5,
          (this.field.tryLineDistance / 2 + leftTenMeter) *
            this.canvas2DPixelScale -
            10,
          this.field.height * this.canvas2DPixelScale - 10
        )

        this.drawArrow(
          ctx,
          this.field.width / 4,
          (this.field.height / 3) * 2,
          80,
          70,
          'right'
        )
        this.drawArrow(
          ctx,
          this.field.width / 4,
          (this.field.height / 3) * 1,
          80,
          70,
          'right'
        )
      } else if (rightOrigin && side === -1) {
        ctx.fillStyle = 'rgba(0,0,0,0.5)'
        ctx.fillRect(
          rightFillOrigin.scaleX,
          rightFillOrigin.scaleY,
          (this.field.tryLineDistance / 2 - rightTenMeter) *
            this.canvas2DPixelScale,
          this.field.height * this.canvas2DPixelScale
        )

        ctx.setLineDash([10])
        ctx.strokeStyle = 'yellow'
        ctx.lineWidth = 3
        ctx.strokeRect(
          leftOrigin.scaleX + 5,
          leftOrigin.scaleY + 5,
          (this.field.tryLineDistance / 2 + rightTenMeter) *
            this.canvas2DPixelScale -
            10,
          this.field.height * this.canvas2DPixelScale - 10
        )

        this.drawArrow(
          ctx,
          -(this.field.width / 4),
          (this.field.height / 3) * 2,
          80,
          70,
          'left'
        )
        this.drawArrow(
          ctx,
          -(this.field.width / 4),
          (this.field.height / 3) * 1,
          80,
          70,
          'left'
        )
      } else {
        if (leftOrigin) {
          ctx.setLineDash([10])
          ctx.strokeStyle = 'yellow'
          ctx.lineWidth = 3
          ctx.strokeRect(
            leftOrigin.scaleX + 5,
            leftOrigin.scaleY + 5,
            this.field.tryLineDistance * this.canvas2DPixelScale - 10,
            this.field.height * this.canvas2DPixelScale - 10
          )
        }
      }

      if (side === 0) {
        if (tagPoint1) {
          drawCircle(
            tagPoint1.x,
            tagPoint1.y,
            ctx,
            10,
            'black',
            'blue',
            null,
            this.getCanvasCoordinate,
            this.canvas2DPixelScale
          )
        }
        if (tagPoint2) {
          drawCircle(
            tagPoint2.x,
            tagPoint2.y,
            ctx,
            10,
            'black',
            'red',
            null,
            this.getCanvasCoordinate,
            this.canvas2DPixelScale
          )
        }
        if (tagPoint3) {
          drawCircle(
            tagPoint3.x,
            tagPoint3.y,
            ctx,
            10,
            'black',
            'orange',
            null,
            this.getCanvasCoordinate,
            this.canvas2DPixelScale
          )
        }
      }

      ctx.setLineDash([])
    }

    //-----> Targets

    this.targets = {
      active: false,
      targets: []
    }

    this.initiateTargetCanvas = (eventListenerCallback) => {
      this.targets.active = true
      if (this.mapCtx) this.clearMapFrame(this.mapCtx)
      this.targets.canvas = document.createElement('canvas')
      this.targets.eventHandleCanvas = document.createElement('canvas')

      appendTo2dCanvas(
        this.targets.canvas,
        'targetCanvas',
        this.canvasContainer2D,
        this.twoDCanvas,
        this.fieldWidth,
        this.fieldHeight,
        this.twoDOffsetX,
        this.twoDOffsetY,
        this.scale
      )
      appendTo2dCanvas(
        this.targets.eventHandleCanvas,
        'targetCanvas',
        this.canvasContainer2D,
        this.twoDCanvas,
        this.fieldWidth,
        this.fieldHeight,
        this.twoDOffsetX,
        this.twoDOffsetY,
        this.scale
      )

      this.targets.eventHandleCanvas.style.zIndex = 2

      this.targets.ctx = this.targets.canvas.getContext('2d')

      // Add event listeners to events canvas

      eventListenerCallback()
    }

    this.setUnitSystem = (unitSystem) => {
      this.unitSystem = unitSystem

      // update
      this.drawTargets()
    }

    this.setTargets = (
      newTargets,
      selectedTarget,
      setSelectedTarget,
      updateTargetPosition,
      unitSystem
    ) => {
      this.targets.targets = newTargets
      this.targets.selectedTarget = selectedTarget
      this.targets.setSelectedTarget = setSelectedTarget
      this.targets.updateTargetPosition = updateTargetPosition
      this.targets.unitSystem = unitSystem
    }

    this.drawTargets = () => {
      const {
        targets,
        selectedTarget,
        setSelectedTarget,
        updateTargetPosition,
        unitSystem
      } = this.targets

      if (!unitSystem) return

      this.removeCanvases('targetCanvas')

      for (let i = 0; i < targets.length; i++) {
        const target = targets[i]
        if (target.radii) {
          drawPointTarget(
            this,
            this.scale,
            target,
            selectedTarget,
            setSelectedTarget,
            updateTargetPosition,
            unitSystem
          )
        }
      }

      this.render3DTargets(targets, selectedTarget, unitSystem)
    }

    this.threeDtargets = []

    this.render3DTargets = (targets, selectedTarget, unitSystem) => {
      if (!this.babylonActive) return
      this.threeDtargets.forEach((target) => {
        target.blueMesh.dispose()
        target.redMesh.dispose()
        target.yellowMesh.dispose()
        target = null
      })
      this.threeDtargets = []

      for (let i = 0; i < targets.length; i++) {
        const target = targets[i]
        if (target.radii) {
          this.renderTarget(target, unitSystem)
        }
      }
    }

    this.renderTarget = (target, unitSystem) => {
      const targetMeshes = {
        yellowMesh: null,
        redMesh: null,
        blueMesh: null
      }
      target.radii.forEach((radius, index) => {
        if (index === 0) {
          const yellow = BABYLON.MeshBuilder.CreateCylinder(
            `${index}-${target.id}`,
            {
              height: 0.3,
              diameter: radius * 2 * unitSystem.units.distance.conversion
            },
            this.scene
          )
          yellow.position.x = target.x
          yellow.position.y = 0.1
          yellow.position.z = target.y

          yellow.material = this.materials.yellow
          targetMeshes.yellowMesh = yellow
        } else if (index === 1) {
          const red = BABYLON.MeshBuilder.CreateCylinder(
            `${index}-${target.id}`,
            {
              height: 0.2,
              diameter: radius * 2 * unitSystem.units.distance.conversion
            },
            this.scene
          )
          red.position.x = target.x
          red.position.y = 0.1
          red.position.z = target.y

          red.material = this.materials.red
          targetMeshes.redMesh = red
        } else {
          const blue = BABYLON.MeshBuilder.CreateCylinder(
            `${index}-${target.id}`,
            {
              height: 0.1,
              diameter: radius * 2 * unitSystem.units.distance.conversion
            },
            this.scene
          )
          blue.position.x = target.x
          blue.position.y = 0.1
          blue.position.z = target.y

          blue.material = this.materials.blue
          targetMeshes.blueMesh = blue
        }
      })
      this.threeDtargets.push(targetMeshes)
    }

    this.maBuff = {}

    this.updateBuffer = (data, tag, replayData) => {
      // Code for Rugby X and netball purposes only, needs to be set to 0 if actual rugby x or netball installation exists
      // This is an offset for a mock rugby x pitch draw out at ealing
      // default value if not provided is 0
      data.pos = {
        x: data.position.x,
        y: data.position.y,
        z: data.position.z
      }

      if (replayData && tag) {
        // buffer with moving avaerage filter
        const { maBuff } = this

        let { tagId } = data
        if (!tagId) tagId = data.id

        if (!Object.prototype.hasOwnProperty.call(this.buffer, tagId)) {
          console.log('== NEW TAG! : ' + data.tagId + ' :' + tagId + ' ==')
          this.buffer[tagId] = []
          maBuff[tagId] = []
        }
        while (this.buffer[tagId].length > 10) {
          this.buffer[tagId].shift()
        }
        if (data.pos.x !== 0 && data.pos.y !== 0) {
          const window = 10
          if (maBuff[tagId].length > window) {
            // PositionMap.updateMap(movingAverage(maBuff[tagId], window))

            this.buffer[tagId].push(movingAverage(maBuff[tagId], window))
            maBuff[tagId].shift()
          }
          maBuff[tagId].push(data)
        }
      } else {
        // buffer without moving average applied
        if (!!data && data.pos.x !== 0 && data.pos.y !== 0) {
          // PositionMap.updateMap(data)

          let { tagId } = data
          if (!tagId) tagId = data.id

          if (!Object.prototype.hasOwnProperty.call(this.buffer, tagId)) {
            console.log('== NEW TAG! : ' + tagId + ' :' + tagId + ' ==')
            this.buffer[tagId] = []
          }
          while (this.buffer[tagId].length > 3) {
            this.buffer[tagId].shift()
          }
          this.buffer[tagId].push(data)
        }
      }
    }

    this.clearBuffers = () => {
      this.mapObjects = {}
      this.buffer = {}
      this.maBuff = {}
    }

    this.runBufferPos = (setPos, cb) => {
      const { field } = this
      this.bufferHasData = false

      for (const key in this.buffer) {
        let timeData = false
        //--> Push Buffer Length for Each Tag

        let player = this.players[key]
        if (!player) {
          player = this.balls[key]
        }

        if (this.buffer[key].length > 1) {
          let q = 0
          let i = 0
          const prevBuffer = this.buffer[key][0]
          let t1 = 0
          let t2 = 0

          for (i = 1; i < this.buffer[key].length; i++) {
            this.bufferHasData = true

            const buffer = this.buffer[key][i]

            t1 = Math.round(prevBuffer.time * 1000)
            t2 = Math.round(buffer.time * 1000)

            let x
            let y
            let z
            if (t1 !== t2) {
              x = this.interpolate(prevBuffer.pos.x, buffer.pos.x, t1, t2, t2)
              y = this.interpolate(prevBuffer.pos.y, buffer.pos.y, t1, t2, t2)
              if (!fixedZ) {
                // Fix z for players but not ball
                !buffer.ball
                  ? (z = this.fixedZHeight)
                  : (z = this.interpolate(
                      prevBuffer.pos.z,
                      buffer.pos.z,
                      t1,
                      t2,
                      t2
                    ))
              } else {
                // Fix z for both players and ball
                z = this.fixedZHeight
              }
            } else {
              x = buffer.pos.x
              y = buffer.pos.y
              if (!fixedZ) {
                // Fix z for players but not ball
                !buffer.ball
                  ? (z = this.fixedZHeight)
                  : (z = this.interpolate(
                      prevBuffer.pos.z,
                      buffer.pos.z,
                      t1,
                      t2,
                      t2
                    ))
              } else {
                // Fix z for both players and ball
                z = this.fixedZHeight
              }
            }

            if (z < 0) {
              z = 0
            }

            z += this.playerSphereDiameter / 2.0 //so spheres sit above plane

            //--> TODO : Interpolate
            if (key === this.playerSelected) {
              this.playerStats.vMag = buffer.Vmag
              this.stGaugeGo(1, Math.round((buffer.Vmag / 13.9) * 100))
            }

            //--> If Ball
            if (key === this.ballSelected) {
              this.ballStats = {
                vMag: buffer.Vmag,
                z: z
              }
              this.stGaugeGo(2, Math.round((buffer.Vmag / 55.5) * 100))
            }

            //--> Draw circle on map
            if (!Object.prototype.hasOwnProperty.call(this.mapObjects, key)) {
              const serial = this.buffer[key][i].serial
                ? this.buffer[key][i].serial
                : encodeHardwareId(key)
              let sessionTag: PlayerSession, team
              if (this.sessionTags) {
                sessionTag = this.sessionTags.find(
                  (playerSession: PlayerSession) =>
                    playerSession.tag && playerSession.tag.id === parseInt(key)
                )
              }

              if (
                sessionTag?.playerId &&
                this.teams &&
                this.teams.A &&
                this.teams.B
              ) {
                team =
                  sessionTag.teamId === this.teams.A.id
                    ? 'A'
                    : sessionTag.teamId === this.teams.B.id
                    ? 'B'
                    : 'match_officials'
              }

              this.mapObjects[key] = {
                x: 0,
                y: 0,
                z: 0,
                t: team,
                n: sessionTag ? sessionTag.number : '',
                playerId: sessionTag ? sessionTag.playerId : '',
                serial
              }
            }

            this.mapObjects[key].x = x
            this.mapObjects[key].y = y
            this.mapObjects[key].z = z
            this.mapObjects[key].ball = buffer.type === deviceTypes.ball.value
            this.mapObjects[key].isBallInPlay = buffer.isBallInPlay
            this.mapObjects[key].vel = this.buffer[key][i].vel
            this.mapObjects[key].time = this.buffer[key][i].time

            // record rangesurements if in diags mode
            if (this.diags.active) {
              this.mapObjects[key].ranges = this.buffer[key][i].ranges
            }

            //--> Data Was Received
            timeData = true

            //--> Set Position
            if (x !== 0 && y !== 0 && this.showSessionTracking) {
              if (setPos) setPos(player && player.team, key, x, y, z)
            } else if (!this.showSessionTracking) {
              if (setPos)
                setPos(
                  player && player.team,
                  key,
                  -this.playersXpos,
                  this.playersSpacing * (1 - 1) - field.edges,
                  0
                )
            }
            break
          }

          //--> If Found Correct Record in Buffer, clear till it
          if (timeData && !this.playbackPaused) {
            while (q < i) {
              this.buffer[key].shift()
              q++
            }
          }
          this.timeCalc = false
        }
      }
      if (cb) {
        cb()
      } else {
        this.scene.render()
      }
    }

    this.switchView = (view) => {
      this.view = view
    }

    // Build Babylon 3D canvas for live and replay session and run renderloop
    this.load = (callback) => {
      //--> Load engine to canvas
      this.canvas = document.getElementById(this.canvasId3D)
      this.canvas.height = parseInt(this.canvasHeight, 10)
      this.canvas.width = this.canvasWidth
      this.engine = new BABYLON.Engine(this.canvas, true, null, false)
      //--> Add listeners to pitch
      window.addEventListener('dblclick', () => {
        if (this.scene) {
          const pickResult = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY
          )
          if (pickResult.hit) {
            if (pickResult.pickedMesh.id === 'ground') {
              this.changeCameraTarget(pickResult.pickedPoint)
            }
          }
        }
      })

      //--> Create scene and load models
      this.createScene()

      //--> Animation RenderLoop
      const optimizationSettings =
        BABYLON.SceneOptimizerOptions.HighDegradationAllowed()
      optimizationSettings.targetFrameRate = 30

      BABYLON.SceneOptimizer.OptimizeAsync(
        this.scene,
        optimizationSettings,
        () => {
          // const bufferWorker = new WebWorker(BufferWorker);
          // Stop loading and set canvas to ready
          callback()

          this.engine.runRenderLoop(() => {
            if (this.view === '3D') {
              this.runBufferPos(this.setPos.bind(this), null)
            } else {
              this.scene.render()
            }
          })

          // ---------> Rugby Ball
          // BABYLON.SceneLoader.ImportMesh("", "/static/scenes/", rugbyBallMesh.split("/")[3], this.scene, function (newMeshes) {
          //     // Set the target of the camera to the first imported mesh
          //     console.log(newMeshes)
          // });
        },
        function () {
          console.error("The engine can't init")
        }
      )
    }

    //----> Live And Post Kick Controller for Broadcast

    this.checkForTagOnClick = (e, callback) => {
      const viewportOffset = this.twoDCanvas.getBoundingClientRect()
      const x = e.pageX - viewportOffset.left,
        y = e.pageY - viewportOffset.top

      let closestX
      let closestY
      let closestId
      for (const key in this.mapObjects) {
        const tag = this.mapObjects[key],
          { scaleX, scaleY } = this.getCanvasCoordinate(
            this.scale,
            tag.x,
            tag.y
          )

        drawCircle(
          x,
          y,
          this.coverCtx,
          3,
          '#0099CC',
          '#0099CC',
          null,
          this.getCanvasCoordinate,
          this.canvas2DPixelScale
        )
        if (
          scaleX - x < 20 &&
          scaleX - x > -20 &&
          scaleY - y < 20 &&
          scaleY - y > -20
        ) {
          if (
            (Math.abs(scaleX - x) < closestX &&
              Math.abs(scaleY - y) < closestY) ||
            !closestId
          ) {
            closestX = Math.abs(scaleX - x)
            closestY = Math.abs(scaleY - y)
            closestId = key
          }
        }
      }
      callback(closestId)
    }

    this.setSelectedTag = (tagId) => {
      this.selectedTag = tagId
    }

    this.addPlayerEventListeners = (type, cb, cb2, cb3) => {
      //---> Add and remove players from the defensive line array
      // 3D Listener
      // this.scene.onPointerObservable.add((pointerInfo, eventState) => {
      //   const { pickedMesh, pickedPoint } = pointerInfo.pickInfo;
      //   if (pickedMesh) {
      //     if (pickedMesh.id.slice(0, 6) == "player") this.updateDefensiveLine(pickedMesh)
      //   }
      // }, BABYLON.PointerEventTypes.POINTERTAP, false);

      // 2D Listeners
      this.twoDCanvas.removeEventListener('click', this.handleCanvasEventClick)
      this.canvasEventType = type
      this.canvasEventCallback = cb
      this.canvasEventCallbackTwo = cb2
      this.canvasEventCallbackThree = cb3
      this.twoDCanvas.addEventListener('click', this.handleCanvasEventClick)
    }
    this.handleCanvasEventClick = (e) => {
      const { field } = this
      const { coverCtx, fieldWidth, fieldHeight } = this
      const viewportOffset = this.twoDCanvas.getBoundingClientRect()
      const x = e.pageX - viewportOffset.left,
        y = e.pageY - viewportOffset.top
      coverCtx.setLineDash([0])
      const top = this.getCanvasCoordinate(
          this.scale,
          null,
          fieldHeight
        ).scaleY,
        bottom = this.getCanvasCoordinate(this.scale, null, 0).scaleY,
        xRight = this.getCanvasCoordinate(
          this.scale,
          -(fieldWidth / 2 + field.l1)
        ).scaleX,
        xRightTry = this.getCanvasCoordinate(
          this.scale,
          -(fieldWidth / 2),
          0
        ).scaleX,
        xLeft = this.getCanvasCoordinate(
          this.scale,
          fieldWidth / 2 + field.l1
        ).scaleX,
        xLeftTry = this.getCanvasCoordinate(
          this.scale,
          fieldWidth / 2,
          0
        ).scaleX

      if (
        x < xRightTry &&
        x > xRight &&
        y < bottom &&
        y > top &&
        this.canvasEventCallbackTwo
      )
        this.canvasEventCallbackTwo(-1)
      if (
        x < xLeft &&
        x > xLeftTry &&
        y < bottom &&
        y > top &&
        this.canvasEventCallbackThree
      )
        this.canvasEventCallbackThree(1)

      for (const key in this.mapObjects) {
        const player = this.mapObjects[key],
          { scaleX, scaleY } = this.getCanvasCoordinate(
            this.scale,
            player.x,
            player.y
          )
        if (
          scaleX - x < 5 &&
          scaleX - x > -5 &&
          scaleY - y < 5 &&
          scaleY - y > -5
        ) {
          if (player.ball && this.canvasEventCallbackTwo) {
            this.canvasEventCallback(key)
          }
          if (!player.ball) {
            this.canvasEventCallback(player.playerId, player.n, player.t)
          }
        }
      }
    }

    //---> Events

    this.events = {
      active: false,
      events: [],
      highlightedId: null,
      selected: {},
      kickColours: {}
    }

    this.setKicksColours = () => {
      if (this.session && this.session.playersSessions) {
        this.session.playersSessions.byPlayerId.list.forEach((tag) => {
          this.events.kickColours[tag.playerId] = tag.player.color
        })
      }
    }

    this.createCanvasElement = (className) => {
      const div = document.createElement('div')
      this.canvasContainer2D.append(div)
      div.className = `${className} canvasElement`
      return div
    }

    this.removeCanvases = (className) => {
      const canvasElements = document.getElementsByClassName(className)

      for (let i = canvasElements.length - 1; i >= 0; i--) {
        const element = canvasElements[i]
        element.remove()
      }
    }

    this.initiateEventsCanvas = (eventListenerCallback) => {
      this.events.active = true
      if (this.mapCtx) this.clearMapFrame(this.mapCtx)
      this.events.canvas = document.createElement('canvas')
      this.events.eventHandleCanvas = document.createElement('canvas')

      appendTo2dCanvas(
        this.events.canvas,
        'eventsCanvas',
        this.canvasContainer2D,
        this.twoDCanvas,
        this.fieldWidth,
        this.fieldHeight,
        this.twoDOffsetX,
        this.twoDOffsetY,
        this.scale
      )
      appendTo2dCanvas(
        this.events.eventHandleCanvas,
        'eventsCanvas',
        this.canvasContainer2D,
        this.twoDCanvas,
        this.fieldWidth,
        this.fieldHeight,
        this.twoDOffsetX,
        this.twoDOffsetY,
        this.scale
      )

      this.events.eventHandleCanvas.style.zIndex = 2

      this.events.ctx = this.events.canvas.getContext('2d')

      // for (let key in this.playerMesh) {
      //   this.playerMesh[key].visibility = 0;
      // }
      // for (let key in this.players) {
      //   this.players[key].plane.visibility = 0
      // }
      // for (let key in this.balls) {
      //   this.balls[key].mesh.visibility = 0
      //   this.balls[key].mesh.customOutline.visibility = 0
      // }
      // if(this.ballMesh) this.ballMesh.visibility = 0

      // Add event listeners to events canvas

      eventListenerCallback(this.events.canvas)
    }
    this.plotEventsOnCanvas = (dataType, drawIgnored) => {
      // 2D
      if (!dataType) dataType = 'data'
      const { ctx } = this.events
      this.clearMapFrame(ctx)
      this.drawKicks(dataType, drawIgnored, this.validation)
      this.drawAussieRulesEvents()

      // Game events - only draw if highlighted
      this.drawTouchEvents()
      this.drawCrossfieldDistances(this.validation)
      this.drawTryEvents()
      this.drawTouchDownEvents()
      this.drawGoalLineCrossedEvents()
    }

    this.setSportscasterEvents = (sportsCasterEvents) => {
      this.events.events = sportsCasterEvents.map((x) => {
        x.data = x.data.map((packet) => {
          packet.pos = {
            x: packet.x,
            y: packet.y,
            z: packet.z
          }
          return packet
        })
        return x
      })
    }

    this.drawAussieRulesEvents = () => {
      const { ctx, events, highlightedId } = this.events
      for (let i = 0; i < events.length; i++) {
        const event = events[i]
        if (
          !event.positionExitedPitch ||
          !eventTypes.isType('aussieRules', event.event?.type)
        )
          return

        const isHighlighted = highlightedId === event.id

        drawX(
          event.positionExitedPitch.x,
          event.positionExitedPitch.y,
          ctx,
          10,
          2.5,
          isHighlighted
            ? sportableColors.colors.flightHighlight
            : sportableColors.colors.failure,
          this.getCanvasCoordinate,
          this.canvas2DPixelScale
        )
      }
    }

    this.getExitedPitchPositionFromPolyCoefficients = (flight) => {
      //[[Cx,Cy,Cz],[x,y,z],[x2,y2,z2]]
      const pNC = flight.polynomialCoefficients
      const time = flight.inPitchHangTime

      // x = Cx + x*t + x2*t*t
      const x = pNC[0][0] + pNC[1][0] * time + pNC[2][0] * time * time

      // y = Cy + y*t + y2*t*t
      const y = pNC[0][1] + pNC[1][1] * time + pNC[2][1] * time * time

      // z = Cz + z*t + z2*t*t
      const z = pNC[0][2] + pNC[1][2] * time + pNC[2][2] * time * time

      const exitPostion = { exitedPitchX: x, exitedPitchY: y, exitedPitchZ: z }

      return exitPostion
    }

    this.drawGoalLineCrossedEvents = () => {
      // draw black and yellow circle on touch events
      const { ctx, events, highlightedId } = this.events

      for (const element of events) {
        const event = element

        // Only draw if hightlighted
        if (highlightedId !== event.id) continue

        if (event.type === 'GOAL_LINE_CROSSED') {
          if (
            (event.positionX && event.positionY && !event.ignore) ||
            event.id === this.events.highlightedId
          ) {
            ctx.beginPath()

            drawCircle(
              event.positionX,
              event.positionY,
              ctx,
              4,
              'black',
              'yellow',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )

            ctx.fill()
            ctx.stroke()
          }
        }
      }
    }

    this.drawTryEvents = () => {
      // draw black and yellow circle on touch events
      const { ctx, events, highlightedId } = this.events

      for (let i = 0; i < events.length; i++) {
        const event = events[i]

        // Only draw if hightlighted
        if (highlightedId !== event.id) continue

        if (event.type === 'TRY') {
          if (
            (event.positionX && event.positionY && !event.ignore) ||
            event.id === this.events.highlightedId
          ) {
            ctx.beginPath()

            drawCircle(
              event.positionX,
              event.positionY,
              ctx,
              7.5,
              'black',
              'blue',
              // 'black',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )

            ctx.fill()
            ctx.stroke()
          }
        }
      }
    }
    this.drawTouchDownEvents = () => {
      // draw black and yellow circle on touch events
      const { ctx, events, highlightedId } = this.events

      for (let i = 0; i < events.length; i++) {
        const event = events[i]

        // Only draw if hightlighted
        if (highlightedId !== event.id) continue

        if (
          event.type === 'TOUCH_DOWN' ||
          event.type === 'HANDOFF' ||
          event.type === 'SNAP' ||
          event.type === 'TACKLE'
        ) {
          if (event.positionX && event.positionY && !event.ignore) {
            ctx.beginPath()

            drawCircle(
              event.positionX,
              event.positionY,
              ctx,
              7.5,
              'black',
              'blue',
              // 'black',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )

            ctx.fill()
            ctx.stroke()
          }
        }
      }
    }

    this.drawTouchEvents = () => {
      // draw black and blue circle on touch events
      const { ctx, events, highlightedId } = this.events

      for (let i = 0; i < events.length; i++) {
        const event = events[i]

        // Only draw if hightlighted
        if (highlightedId !== event.id) continue

        if (event.type === 'TOUCH') {
          if (event.positionX && event.positionY && !event.ignore) {
            ctx.beginPath()
            if (
              event.confirmed === null ||
              event.confirmed === undefined ||
              event.confirmed
            ) {
              drawCircle(
                event.positionX,
                event.positionY,
                ctx,
                7.5,
                'black',
                'blue',
                null,
                this.getCanvasCoordinate,
                this.canvas2DPixelScale
              )
            } else {
              drawX(
                event.positionX,
                event.positionY,
                ctx,
                15,
                3.4,
                sportableColors.colors.darkYellow,
                this.getCanvasCoordinate,
                this.canvas2DPixelScale
              )
            }

            ctx.fill()
            ctx.stroke()
          }
        }
      }
    }

    this.drawCrossfieldDistances = (validation) => {
      const { ctx, events, highlightedId } = this.events
      const { dimensions, unitSystem } = this

      const isRugby =
        this.pitchType === sportTypes.items.rugbyUnion.value ||
        this.pitchType === sportTypes.items.rugbyLeague.value

      for (let i = 0; i < events.length; i++) {
        const event = events[i]

        // Only draw if hightlighted
        if (highlightedId !== event.id) continue

        // if touch event
        if (event.type === 'TOUCH' && validation) {
          const { positionX: exitedPitchX, positionY: exitedPitchY } = event

          // get crossfield distances
          const { leftDistance, rightDistance, leftLineX, rightLineX } =
            getCrossfieldDistances(
              this.fieldHeight,
              dimensions,
              this.sport,
              exitedPitchX,
              exitedPitchY
            )
          // draw crossfield distances
          if (
            isRugby &&
            exitedPitchX < dimensions.P10.x &&
            exitedPitchX > dimensions.P2.x
          ) {
            drawDistances(
              dimensions,
              exitedPitchX,
              exitedPitchY,
              ctx,
              leftDistance,
              rightDistance,
              leftLineX,
              rightLineX,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale,
              this.sport,
              unitSystem,
              this.pitchFlipped
            )
          }
        }
      }
    }

    this.drawKicks = (dataType, drawIgnored, validation) => {
      const { ctx, events, highlightedId } = this.events
      const { unitSystem, dimensions, field, session } = this
      const isRugby =
        this.pitchType === sportTypes.items.rugbyUnion.value ||
        this.pitchType === sportTypes.items.rugbyLeague.value
      for (let i = 0; i < events.length; i++) {
        const isPass = events[i].type === flightEventTypes.items.pass.value
        const isLineout =
          events[i].type === flightEventTypes.items.lineout.value

        // move the highglight event to the back of the events array so it gets drawn last
        const eventsWithHighlightedFlightLast = events.sort((x, y) => {
          if (highlightedId) {
            return x.eventId == highlightedId
              ? 1
              : y.eventId == highlightedId
              ? -1
              : 0
          }
          return undefined
        })

        const event = highlightedId
          ? eventsWithHighlightedFlightLast[i]
          : events[i]

        const isDeviatedLineoutOrFowardPass =
          (isRugby && isPass && checkForForwardPass(event, this.pitchType)) ||
          (isRugby &&
            isLineout &&
            event.lineoutDeviated === 1 &&
            !session.isTrainingMode)

        let color = 'black'
        if (validation) {
          if (isDeviatedLineoutOrFowardPass) {
            color = sportableColors.colors.darkYellow
          } else if (event.success) {
            color = sportableColors.colors.success
          } else {
            color = 'black'
          }
        } else {
          if (event.id === this.events.highlightedId) {
            color = sportableColors.colors.flightHighlight
          } else if (isDeviatedLineoutOrFowardPass) {
            color = sportableColors.colors.darkYellow
          } else if (this.events.kickColours[event.fromPlayerId]) {
            color = this.events.kickColours[event.fromPlayerId]
          }
        }
        // if touch event
        // We need this in addition to the drawTouchEvents function
        if (
          event.type === 'TOUCH' &&
          validation &&
          event.id === highlightedId
        ) {
          const { positionX: exitedPitchX, positionY: exitedPitchY } = event

          if (
            event.confirmed === null ||
            event.confirmed === undefined ||
            event.confirmed
          ) {
            drawCircle(
              exitedPitchX,
              exitedPitchY || 0,
              ctx,
              7.5,
              'black',
              'blue',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )
          } else {
            drawX(
              event.positionX,
              event.positionY,
              ctx,
              15,
              3.4,
              sportableColors.colors.darkYellow,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )
          }
        }

        if (
          event[dataType] &&
          (!event.ignore ||
            drawIgnored ||
            event.id === this.events.highlightedId)
        ) {
          ctx.beginPath()

          // // Set dotted line for forward passes
          // if (checkForForwardPass(event, this.pitchType) && isPass && isRugby) {
          //   ctx.setLineDash([2, 5])
          // } else {
          //   ctx.setLineDash([])
          // }

          // Set thicker trajectory line for forward passes and deviated lineouts
          if (validation && isDeviatedLineoutOrFowardPass) {
            ctx.lineWidth = 5.5
          } else {
            ctx.lineWidth = 4
          }

          ctx.lineCap = 'round'
          ctx.strokeStyle = color
          const coord = this.getCanvasCoordinate(
            this.canvas2DPixelScale,
            event[dataType][0].pos.x +
              (event.offsetX ? event.offsetX : 0) +
              this.ealingOffset.x,
            event[dataType][0].pos.y +
              (event.offsety ? event.offsetY : 0) +
              this.ealingOffset.y
          )

          ctx.moveTo(coord.scaleX, coord.scaleY)
          for (let j = 1; j < event[dataType].length; j++) {
            const packet = event[dataType][j]

            //  Draw kick trajectory
            const coord = this.getCanvasCoordinate(
              this.canvas2DPixelScale,
              packet.pos.x +
                (event.offsetX ? event.offsetX : 0) +
                this.ealingOffset.x,
              packet.pos.y +
                (event.offsety ? event.offsetY : 0) +
                this.ealingOffset.y
            )

            ctx.lineTo(coord.scaleX, coord.scaleY)
          }

          ctx.stroke()

          // DRAW CIRCLE for flight that exits pitch

          // grab exited pitch coordinates
          if (event.inPitchHangTime && event.polynomialCoefficients) {
            const { exitedPitchX, exitedPitchY } =
              this.getExitedPitchPositionFromPolyCoefficients(event)

            //  Check for exited pitch
            drawCircle(
              exitedPitchX,
              exitedPitchY,
              ctx,
              4,
              'black',
              'yellow',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )

            const flightType = this.session.flightTypes.getTypeByValue(
              event.type
            )
            const flightSubType = flightType?.props
              ?.subType as FlightEventSubType

            const hasSuccessMetric = flightSubType
              ? flightSubType?.props.metricTypes.items.success
              : flightType?.props.metricTypes.items.success

            //  Check for conversion/drop-kick success
            if (hasSuccessMetric) {
              drawCircle(
                exitedPitchX,
                exitedPitchY,
                ctx,
                4,
                'black',
                sportableColors.colors.success,
                null,
                this.getCanvasCoordinate,
                this.canvas2DPixelScale
              )
            }

            //  Check for conversion/drop-kick failure
            if (hasSuccessMetric) {
              drawCircle(
                exitedPitchX,
                exitedPitchY,
                ctx,
                4,
                'black',
                sportableColors.colors.failure,
                null,
                this.getCanvasCoordinate,
                this.canvas2DPixelScale
              )
            }
          }

          // Draw Circle for all other flights and Draw X for failed flights (not straight lineouts and forward passes)

          const lastPacket = event[dataType][event[dataType].length - 1]

          if (isDeviatedLineoutOrFowardPass) {
            drawX(
              lastPacket.pos.x,
              lastPacket.pos.y,
              ctx,
              12,
              2.8,
              sportableColors.colors.darkYellow,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )
          } else {
            drawCircle(
              lastPacket.pos.x,
              lastPacket.pos.y,
              ctx,
              4,
              color,
              'white',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )
          }

          // DRAW Dotted line for bounceToTouch flights and exit pitch circle
          if (event.bouncedToTouch && event.positionAtTouch) {
            ctx.beginPath()

            // set ctx properties
            ctx.setLineDash([1, 3])
            ctx.lineWidth = 2
            ctx.strokeStyle = 'yellow'

            // Move to last packet coord
            const coord = this.getCanvasCoordinate(
              this.canvas2DPixelScale,
              lastPacket.pos.x +
                (event.offsetX ? event.offsetX : 0) +
                this.ealingOffset.x,
              lastPacket.pos.y +
                (event.offsety ? event.offsetY : 0) +
                this.ealingOffset.y
            )
            // line to exitCoords
            const exitCoord = this.getCanvasCoordinate(
              this.canvas2DPixelScale,
              event.positionAtTouch.x +
                (event.offsetX ? event.offsetX : 0) +
                this.ealingOffset.x,
              event.positionAtTouch.y +
                (event.offsety ? event.offsetY : 0) +
                this.ealingOffset.y
            )

            ctx.moveTo(coord.scaleX, coord.scaleY)

            ctx.lineTo(exitCoord.scaleX, exitCoord.scaleY)
            ctx.stroke()

            // revert ctx properties back to normal
            ctx.setLineDash([])
            ctx.lineWidth = 3

            drawCircle(
              event.positionAtTouch.x,
              event.positionAtTouch.y,
              ctx,
              4,
              'black',
              'yellow',
              null,
              this.getCanvasCoordinate,
              this.canvas2DPixelScale
            )
          }

          // Commented out for now as it is not needed
          // // draw circle at x at start of kick of all kicks that are kicked to touch except bounced to touch
          // if (
          //   event.positionAtTouch &&
          //   !event.bouncedToTouch &&
          //   event.timeAtTouch &&
          //   validation
          // ) {
          //   // Draw a circle at the starting x point of the "kicked to touch" kick
          //   let firstPacket = event[dataType][0]

          //   drawCircle(
          //     firstPacket.pos.x,
          //     event.positionAtTouch.y,
          //     ctx,
          //     4,
          //     'black',
          //     'yellow',
          //     null,
          //     this.getCanvasCoordinate,
          //     this.canvas2DPixelScale
          //   )
          // }

          // Draw lineout deviation on the touchline
          if (events[i].type === flightEventTypes.items.lineout.value) {
            const startCoordinates = event[dataType][0]?.pos
            const nearestTouchlineY =
              startCoordinates.y > field.height / 2 ? field.height + 1 : -1
            const scaledCoords = this.getCanvasCoordinate(
              this.canvas2DPixelScale,
              startCoordinates.x,
              nearestTouchlineY
            )
            ctx.save()
            ctx.fillStyle = 'black'
            ctx.font = '20px sans-serif'
            ctx.textAlign = 'center'
            ctx.textBaseline =
              startCoordinates.y > field.height / 2 ? 'bottom' : 'top'
            ctx.fillText(
              `${event.lineoutDeviation} m`,
              scaledCoords.scaleX,
              scaledCoords.scaleY
            )
            ctx.restore()
          }
        }
      }
    }
    this.clear2DCanvas = () => {
      const { coverCtx } = this
      const { ctx } = this.events
      if (ctx) {
        this.clearMapFrame(ctx)
      }
      if (coverCtx) {
        this.clearMapFrame(coverCtx)
        this.drawRugbyLines('rgba(255,255,255,0.9)')
      }
    }
    this.highlightFlight = (flightId) => {
      if (this.canvasReady) {
        this.events.highlightedId = flightId
        this.plotEventsOnCanvas('data')
      }
    }
    this.unhighlightFlight = () => {
      if (this.canvasReady) {
        this.events.highlightedId = null
        this.plotEventsOnCanvas('data')
      }
    }
    this.closestId = null
    this.eventsCanvasClickHandle = (e, stateUpdate, includeIgnored) => {
      const { offsetX, offsetY } = e
      const { events } = this.events

      const closest = {} as {
        flightId: string
        dist: number
      }
      for (let i = 0; i < events.length; i++) {
        const event = events[i]
        if (event.data && (includeIgnored || !event.ignore)) {
          const lastPoint = event.data[event.data.length - 1]
          const firstPoint = event.data[0]
          const scaledLastPoint = this.getCanvasCoordinate(
            this.scale,
            lastPoint.pos.x,
            lastPoint.pos.y
          )
          const scaledFirstPoint = this.getCanvasCoordinate(
            this.scale,
            firstPoint.pos.x,
            firstPoint.pos.y
          )
          if (
            (!(
              scaledFirstPoint.scaleX > offsetX &&
              scaledLastPoint.scaleX > offsetX
            ) ||
              !(
                scaledFirstPoint.scaleX < offsetX &&
                scaledLastPoint.scaleX < offsetX
              )) &&
            (!(
              scaledFirstPoint.scaleY > offsetY &&
              scaledLastPoint.scaleY > offsetY
            ) ||
              !(
                scaledFirstPoint.scaleY < offsetY &&
                scaledLastPoint.scaleY < offsetY
              ))
          ) {
            for (let j = 0; j < event.data.length; j++) {
              const packet = event.data[j]
              const { scaleX, scaleY } = this.getCanvasCoordinate(
                this.scale,
                packet.pos.x,
                packet.pos.y
              )
              if (
                Math.abs(scaleX - offsetX) < 10 &&
                Math.abs(scaleY - offsetY) < 10
              ) {
                const dist = distance(
                  { x: scaleX, y: scaleY },
                  { x: offsetX, y: offsetY }
                )
                if (closest.dist) {
                  if (dist < closest.dist) {
                    closest.flightId = event.id
                    closest.dist = dist
                  }
                } else {
                  closest.flightId = event.id
                  closest.dist = dist
                }
              }
            }
          }
        }
      }
      if (closest.flightId) {
        // Update react state if flight highlighted
        stateUpdate(closest.flightId)
      } else {
        stateUpdate(null)
      }
    }

    /* Render / remove kicks from canvas */

    this.kickTubes = []

    this.getFlightPath = (event) => {
      // TODO: get longest path and refactor this mf
      const pathLength = 400

      const path = []
      let lastPacket
      for (let i = 0; i < pathLength; i++) {
        if (event.data) {
          const packet = event.data[i]
          const previousPacket = event.data[i - 1]
          const nextPacket = event.data[i + 1]
          if (packet) {
            if (i === event.data.length - 1) lastPacket = packet
            if (packet.pos.x !== 0 && packet.pos.y !== 0) {
              let v3 = new BABYLON.Vector3(
                packet.pos.x + this.ealingOffset.x,
                packet.pos.z,
                packet.pos.y + this.ealingOffset.y
              )
              path.push(v3)
              v3 = null
            } else {
              if (previousPacket) {
                if (previousPacket.pos.x !== 0 && previousPacket.pos.y !== 0) {
                  let v3 = new BABYLON.Vector3(
                    previousPacket.pos.x + this.ealingOffset.x,
                    previousPacket.pos.z,
                    previousPacket.pos.y + this.ealingOffset.y
                  )
                  path.push(v3)
                  v3 = null
                } else {
                  let v3 = new BABYLON.Vector3(0, 0, -1)
                  path.push(v3)
                  v3 = null
                }
              } else if (nextPacket) {
                if (nextPacket.pos.x !== 0 && nextPacket.pos.y !== 0) {
                  let v3 = new BABYLON.Vector3(
                    nextPacket.pos.x + this.ealingOffset.x,
                    nextPacket.pos.z,
                    nextPacket.pos.y + this.ealingOffset.y
                  )
                  path.push(v3)
                  v3 = null
                } else {
                  let v3 = new BABYLON.Vector3(0, 0, -1)
                  path.push(v3)
                  v3 = null
                }
              } else {
                let v3 = new BABYLON.Vector3(0, 0, -1)
                path.push(v3)
                v3 = null
              }
            }
          } else {
            if (lastPacket) {
              let v3 = new BABYLON.Vector3(
                lastPacket.pos.x + this.ealingOffset.x,
                lastPacket.pos.z,
                lastPacket.pos.y + this.ealingOffset.y
              )
              path.push(v3)
              v3 = null
            } else {
              let v3 = new BABYLON.Vector3(0, 0, -1)
              path.push(v3)
              v3 = null
            }
          }
        } else {
          let v3 = new BABYLON.Vector3(0, 0, -1)
          path.push(v3)
          v3 = null
        }
      }

      return {
        path,
        lastPacket
      }
    }

    this.createKickTube = (event, drawIgnored) => {
      if (
        event.data &&
        (!event.ignore || drawIgnored || event.id === this.events.highlightedId)
      ) {
        const { path, lastPacket } = this.getFlightPath(event)

        let material

        if (this.validation) {
          material = event.success
            ? this.materials.success
            : this.materials.default
        } else {
          material = this.materials.players[event.fromPlayerId]
            ? this.materials.players[event.fromPlayerId]
            : this.materials.default
        }

        if (path.length > 1) {
          const tube = {
            id: event.id,
            innerTube: BABYLON.MeshBuilder.CreateTube(
              `tube`,
              {
                path: path,
                radius: 0.1,
                sideOrientation: BABYLON.Mesh.FRONTSIDE,
                tessellation: 4,
                updatable: true
              },
              this.scene
            ),
            exitSphere: null,
            endSphere: null
          }

          if (lastPacket) {
            tube.endSphere = BABYLON.MeshBuilder.CreateSphere(
              'sphere',
              { diameter: 0.5 },
              this.scene
            )
            tube.endSphere.material = material
            tube.endSphere.position.x = lastPacket.pos.x
            tube.endSphere.position.y = lastPacket.pos.z
            tube.endSphere.position.z = lastPacket.pos.y
          }

          if (event.inPitchHangTime && event.polynomialCoefficients) {
            const { exitedPitchX, exitedPitchY, exitedPitchZ } =
              this.getExitedPitchPositionFromPolyCoefficients(event)
            tube.exitSphere = BABYLON.MeshBuilder.CreateSphere(
              'sphere',
              { diameter: 0.5 },
              this.scene
            )
            tube.exitSphere.material = this.materials.selected
            tube.exitSphere.position.x = exitedPitchX
            tube.exitSphere.position.y = exitedPitchZ
            tube.exitSphere.position.z = exitedPitchY
          }

          // tube.outerTube.material = this.outerMaterial

          if (event.id == this.events.highlightedId) {
            tube.innerTube.material = this.materials.selected
          } else {
            tube.innerTube.material = material
          }
          this.kickTubes.push(tube)
          // if (!isNaN(events[j].offsetX)) tube.innerTube.translate(BABYLON.Axis.x, events[j].offsetX, BABYLON.Space.WORLD)
          // if (!isNaN(events[j].offsetY)) tube.innerTube.translate(BABYLON.Axis.z, events[j].offsetY, BABYLON.Space.WORLD)
          // if (!isNaN(events[j].offsetZ)) tube.innerTube.translate(BABYLON.Axis.y, events[j].offsetZ, BABYLON.Space.WORLD)
        }
      }
    }

    this.hideKickTube = (kickTube) => {
      kickTube.innerTube.visibility = 0
      if (kickTube.endSphere) kickTube.endSphere.visibility = 0
      if (kickTube.exitSphere) kickTube.exitSphere.visibility = 0
    }

    this.updateKickTube = (kickTube, event, drawIgnored) => {
      // Change visibility
      kickTube.innerTube.visibility = 1
      if (kickTube.endSphere) kickTube.endSphere.visibility = 1
      if (kickTube.exitSphere) kickTube.exitSphere.visibility = 1

      if (
        event.data &&
        (!event.ignore || drawIgnored || event.id === this.events.highlightedId)
      ) {
        const { path, lastPacket } = this.getFlightPath(event)

        let material

        if (this.validation) {
          material = event.success
            ? this.materials.success
            : this.materials.default
        } else {
          material = this.materials.players[event.fromPlayerId]
            ? this.materials.players[event.fromPlayerId]
            : this.materials.default
        }

        if (path.length > 1) {
          kickTube.innerTube = BABYLON.MeshBuilder.CreateTube(null, {
            path: path,
            instance: kickTube.innerTube
          })

          if (lastPacket) {
            if (!kickTube.endSphere) {
              kickTube.endSphere = BABYLON.MeshBuilder.CreateSphere(
                'sphere',
                { diameter: 0.5 },
                this.scene
              )
            }
            kickTube.endSphere.material = material
            kickTube.endSphere.position.x = lastPacket.pos.x
            kickTube.endSphere.position.y = lastPacket.pos.z
            kickTube.endSphere.position.z = lastPacket.pos.y
          } else {
            if (kickTube.endSphere) kickTube.endSphere.visibility = 0
          }

          if (event.inPitchHangTime && event.polynomialCoefficients) {
            const { exitedPitchX, exitedPitchY, exitedPitchZ } =
              this.getExitedPitchPositionFromPolyCoefficients(event)
            if (!kickTube.exitSphere) {
              kickTube.exitSphere = BABYLON.MeshBuilder.CreateSphere(
                'sphere',
                { diameter: 0.5 },
                this.scene
              )
            }
            kickTube.exitSphere.material = this.materials.selected
            kickTube.exitSphere.position.x = exitedPitchX
            kickTube.exitSphere.position.y = exitedPitchZ
            kickTube.exitSphere.position.z = exitedPitchY
          } else {
            if (kickTube.exitSphere) kickTube.exitSphere.visibility = 0
          }

          if (event.id == this.events.highlightedId) {
            kickTube.innerTube.material = this.materials.selected
          } else {
            kickTube.innerTube.material = material
          }

          // if (!isNaN(events[j].offsetX)) tube.innerTube.translate(BABYLON.Axis.x, events[j].offsetX, BABYLON.Space.WORLD)
          // if (!isNaN(events[j].offsetY)) tube.innerTube.translate(BABYLON.Axis.z, events[j].offsetY, BABYLON.Space.WORLD)
          // if (!isNaN(events[j].offsetZ)) tube.innerTube.translate(BABYLON.Axis.y, events[j].offsetZ, BABYLON.Space.WORLD)
        }
      } else {
        this.hideKickTube(kickTube)
      }
    }

    this.renderKicks = (id, dataType, drawIgnored) => {
      if (this.babylonActive) {
        if (!dataType) dataType = 'data'
        // clear kicks before rendering

        const { events } = this.events
        const { kickTubes } = this

        const newTubesNeeded = events.length - kickTubes.length

        if (newTubesNeeded > 0) {
          for (let i = 0; i < kickTubes.length; i++) {
            // update tubes using remaining event
            const eventIndex = newTubesNeeded + i
            const event = events[eventIndex]
            const kickTube = kickTubes[i]
            this.updateKickTube(kickTube, event, drawIgnored)
          }
          for (let i = 0; i < newTubesNeeded; i++) {
            // create new tubes
            const event = events[i]
            this.createKickTube(event, drawIgnored)
          }
        } else if (newTubesNeeded < 0) {
          const hideTubes = Math.abs(newTubesNeeded)
          for (let i = 0; i < hideTubes; i++) {
            // hide tubes
            const kickTube = kickTubes[i]
            this.hideKickTube(kickTube)
          }
          for (let i = hideTubes; i < kickTubes.length; i++) {
            // update tubes using remaining event
            const eventIndex = i - hideTubes
            const event = events[eventIndex]
            const kickTube = kickTubes[i]
            this.updateKickTube(kickTube, event, drawIgnored)
          }
        } else {
          for (let i = 0; i < kickTubes.length; i++) {
            // update tubes
            const event = events[i]
            const kickTube = kickTubes[i]
            this.updateKickTube(kickTube, event, drawIgnored)
          }
        }
      }
    }

    this.clearKicks = (kicks) => {
      for (let i = 0; i < kicks.length; i++) {
        const kick = kicks[i]
        this.scene.removeMesh(kick.innerTube, true)
        this.scene.removeMesh(kick.endSphere, true)
        if (kick.exitSphere) this.scene.removeMesh(kick.exitSphere, true)

        // this.scene.removeMesh(kick.outerTube, true)
        kick.innerTube.dispose()
        kick.endSphere.dispose()
        if (kick.exitSphere) kick.exitSphere.dispose()
        // kick.outerTube.dispose();
        kick.innerTube = null
        kick.endSphere = null
        if (kick.exitSphere) kick.exitSphere = null
        // kick.outerTube = null;
        this.kickTubes = this.kickTubes.filter((kickTube) => {
          return kickTube.id !== kick.id
        })
      }
    }

    //---> Player Speed

    this.calculatePlayerSpeed = (tagId) => {
      if (this.mapObjects[tagId]) {
        const { vel } = this.mapObjects[tagId]
        const playerSpeed = speed(vel.x, vel.y)
        if (playerSpeed < 0.5) return 0
        return playerSpeed
      }
      return 0
    }

    //---> Create 3D Scene

    /* change arc rotate camera target vector */
    this.changeCameraTarget = (target) => {
      this.camera.setTarget(new BABYLON.Vector3(target.x, 0, target.z))
    }
    //--> 3D Ground

    this.updateGroundTexture = (texture) => {
      if (this.babylonActive && this.groundInner) {
        this.groundInnerMaterial = new BABYLON.StandardMaterial(
          'textureGround',
          this.scene
        )
        this.groundInnerMaterial.specularColor = new BABYLON.Color3(0, 0, 0)
        this.groundInnerMaterial.diffuseTexture = new BABYLON.Texture(
          texture,
          this.scene
        )
        // this.groundInner.material = this.groundInnerMaterial
      }
    }

    this.interpolate = (v1, v2, t1, t2, t3) => {
      return v1 + ((t3 - t1) * (v2 - v1)) / (t2 - t1)
    }
  }
}
