import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import ErrorMessage from './ErrorMessage'

import { ErrorState, SetError } from '../../ui/error/types'
import { ToggleModal } from '../../ui/modal/types'

import * as modalActions from '../../ui/modal/actions'
import * as errorActions from '../../ui/error/actions'

const actions = _.assign({}, modalActions, errorActions)

interface StateProps {
  error: ErrorState
}

interface DispatchProps {
  toggleModal: ToggleModal
  setError: SetError
}

export class Error extends Component<StateProps & DispatchProps> {
  componentDidUpdate() {
    const { error, toggleModal, setError } = this.props
    const { message, proceed } = error
    console.log(message)
    if (message) {
      toggleModal({
        active: true,
        handleProceed: () => {
          setError({})
          toggleModal({})
          if (proceed) proceed()
        },
        ChildComponent: ErrorMessage,
        className: 'modalSmall'
      })
    }
  }

  render() {
    return <noscript />
  }
}

function mapStateToProps(state) {
  return {
    error: state.error
  }
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  actions
)(Error)
