import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { usePitches } from '../pitches/hooks'
import { formatHardwareData } from './functions'

export const useHardware = () => {
  const hardware = useAppSelector((state) => state.hardware)
  return hardware
}

export const useFormattedHardware = () => {
  const hardware = useHardware()
  const pitches = usePitches()

  const formattedHardwareState = useMemo(
    () => formatHardwareData(hardware, pitches?.inUse?.anchors),
    [hardware, pitches?.inUse?.anchors]
  )

  return formattedHardwareState
}

export const useBallInPlay = () => {
  const formattedHardware = useFormattedHardware()

  const ballInPlay = useMemo(() => {
    return formattedHardware.types['ball'].devices.list.find(
      (item) => item.isInPlay
    )
  }, [formattedHardware])

  return { ballInPlay }
}
