import { createAsyncThunk } from '@reduxjs/toolkit'
import { setError } from '../../ui/error/actions'
import {
  FixtureCheckRequestBody,
  PlayerCheckRequestBody,
  PlayersCheckRequestBody,
  checkFixtureRequest,
  checkGeniusStatusRequest,
  checkPlayerRequest,
  checkPlayersRequest
} from './api'
import {
  addFetchingPlayerIds,
  removeFetchingPlayerIds,
  updateFixtureFromAPIResponse,
  updateGeniusStatus,
  updatePlayersFromAPIResponse
} from './slice'

export const checkGeniusStatus = createAsyncThunk(
  'genius status',
  async (_, { dispatch }) => {
    try {
      const response = await checkGeniusStatusRequest()
      dispatch(updateGeniusStatus(response.data))
    } catch (error: any) {
      dispatch(
        setError({
          message:
            error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : `Failed to resolve genius fixture. Request failed. Check log for more detail.`
        })
      )
    }
  }
)

export const checkGeniusFixture = createAsyncThunk(
  'checkFixture',
  async (data: FixtureCheckRequestBody, { dispatch }) => {
    try {
      const response = await checkFixtureRequest(data)
      dispatch(updateFixtureFromAPIResponse(response.data))
    } catch (error: any) {
      dispatch(
        setError({
          message:
            error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : `Failed to resolve genius fixture. Request failed. Check log for more detail.`
        })
      )
    }
  }
)

export const checkGeniusPlayer = createAsyncThunk(
  'checkPlayer',
  async (data: PlayerCheckRequestBody, { dispatch }) => {
    dispatch(addFetchingPlayerIds([data.playerId]))
    try {
      const response = await checkPlayerRequest(data)
      dispatch(updatePlayersFromAPIResponse(response.data))
      dispatch(removeFetchingPlayerIds([data.playerId]))
    } catch (error: any) {
      dispatch(
        setError({
          message:
            error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : `Failed to resolve genius player. Request failed. Check log for more detail.`
        })
      )
      dispatch(removeFetchingPlayerIds([data.playerId]))
    }
  }
)

export const checkGeniusPlayers = createAsyncThunk(
  'checkPlayers',
  async (data: PlayersCheckRequestBody, { dispatch }) => {
    // Add fetching player ids
    const playerIds = data.map((p) => p.playerId)
    dispatch(addFetchingPlayerIds(playerIds))
    try {
      const response = await checkPlayersRequest(data)
      dispatch(updatePlayersFromAPIResponse(response.data))
      dispatch(removeFetchingPlayerIds(playerIds))
    } catch (error: any) {
      dispatch(
        setError({
          message:
            error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : `Failed to resolve genius player. Request failed. Check log for more detail.`
        })
      )
      dispatch(removeFetchingPlayerIds(playerIds))
    }
  }
)
