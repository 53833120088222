import { MainProps, Tabs } from '../../components/Views/Main/Main.types'
import {
  useFormattedSession,
  useSessions
} from '../../metrics_server/sessions/hooks'
import { FlightSelect } from './FlightSelect/FlightSelect'
import { Targets } from './Targets/Targets'

export enum TargetSetupTabs {
  FLIGHT_SELECT = 'FLIGHT_SELECT',
  TARGET_SELECT = 'TARGET_SELECT'
}

export const tabConfig: Tabs = {
  [TargetSetupTabs.FLIGHT_SELECT]: {
    key: TargetSetupTabs.FLIGHT_SELECT,
    name: 'Select Kicks',
    Content: FlightSelect,
    strack: {
      options: {
        teams: true,
        // targets: true,
        babylonActive: false,
        canvasId: 'flights',
        cover: 'rugby-cover',
        show2DOnly: true
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden'
      }
    }
  },
  [TargetSetupTabs.TARGET_SELECT]: {
    key: TargetSetupTabs.TARGET_SELECT,
    name: 'Set Targets',
    Content: Targets,
    strack: {
      options: {
        teams: true,
        babylonActive: false,
        targets: true,
        canvasId: 'targets',
        cover: 'rugby-cover',
        show2DOnly: true
      },
      canvasStyle: {
        width: '55%',
        height: '92%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: '25%'
      }
    }
  }
}

export const useTargetSetupTabConfig = (view: MainProps) => {
  const tabs = { ...view.tabs }
  const { selectedId } = useSessions()
  const { live } = useFormattedSession(selectedId)

  if (live) {
    delete tabs[TargetSetupTabs.FLIGHT_SELECT]
  }

  const initialTab = live
    ? tabs[TargetSetupTabs.TARGET_SELECT]
    : tabs[TargetSetupTabs.FLIGHT_SELECT]

  return { tabs, initialTab }
}
