import React from 'react'
import styles from './SessionShortcutsToolTip.module.scss'
import ReactTooltip from 'react-tooltip'
import {
  ShortcutIcon,
  ShortcutIconProps
} from '../Icons/ShortcutIcon/ShortcutIcon'
import { ShortcutScreenType, getActionPhrase } from '../../constants/shortcuts'
import { SessionsState } from '../../metrics_server/sessions/types'
import { getSport } from '../../metrics_server/sports/functions'
import { Sport } from '../../metrics_server/sports/data_types'

export interface SessionShortcutsToolTipProps {
  shortcuts: Array<ShortcutScreenType>
  color?: ShortcutIconProps['color']
  sessions: SessionsState
}

export interface ShortcutListProps {
  shortcuts: Array<ShortcutScreenType>
  sessions: SessionsState
}

export const ShortcutList: React.FC<ShortcutListProps> = ({
  shortcuts,
  sessions
}) => {
  const currentSportType =
    sessions?.activeSession?.sportType || sessions.selected.id
      ? sessions.selected.sportType
      : undefined

  let sport: Sport
  if (currentSportType) {
    sport = getSport(currentSportType)
  }

  return (
    <>
      <h4 className={styles.heading}>Session Shortcuts</h4>
      <ul>
        {shortcuts.map((screen, index) => {
          const shortcutsToDisplay =
            screen.screenName ===
            sport?.props?.features?.keyboardShortcuts?.screenName
              ? screen.shortcuts.filter((shortcut) => {
                  // Only include the shortcut if its key is present in the sport's keyboard shortcuts
                  return shortcut.key.some(
                    (key) =>
                      sport?.props?.features?.keyboardShortcuts?.shortcuts?.[
                        key
                      ]
                  )
                })
              : screen.shortcuts
          return (
            <React.Fragment key={`shortcuts - ${index}`}>
              <h5 className={styles.screenName}>{screen.screenName}</h5>
              <ul>
                {shortcutsToDisplay.map((shortcut, index) => (
                  <li
                    key={`screenShortcut - ${index}`}
                    className={styles.keyActionContainer}
                  >
                    <span
                      style={{ textTransform: 'none' }}
                      className={styles.key}
                    >
                      {shortcut.key.map((s, j) => (
                        <React.Fragment key={`shortcut - ${index} - ${j}`}>
                          <kbd className={styles.kbd}>{s}</kbd>
                          {j < shortcut.key.length - 1 && ' + '}
                        </React.Fragment>
                      ))}
                      :
                    </span>
                    <span
                      style={{ textTransform: 'none' }}
                      className={styles.action}
                    >
                      {getActionPhrase(shortcut, sport?.key)}
                    </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )
        })}
      </ul>
    </>
  )
}

export const SessionShortcutsToolTip: React.FC<
  SessionShortcutsToolTipProps
> = ({ shortcuts, sessions }) => {
  return (
    <div data-tip data-for='shortcuts'>
      <ShortcutIcon scale={1.1} />

      <ReactTooltip
        id='shortcuts'
        className={styles.toolTip}
        place='right'
        aria-haspopup='true'
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node
        ) => {
          const d = document.documentElement

          left = Math.min(d.clientWidth - node.clientWidth, left)
          top = Math.min(d.clientHeight - node.clientHeight, top)

          left = Math.max(0, left)
          top = Math.max(0, top)

          return { top, left }
        }}
      >
        <ShortcutList shortcuts={shortcuts} sessions={sessions} />
      </ReactTooltip>
    </div>
  )
}
