import { ErrorState } from '../../ui/error/types'
import {
  AppRequest,
  executeAppRequest,
  request
} from '../../utils/request_handler'
import { SERVICE_ROOT_URL } from '../api/config'

export function hardwareService(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void,
  { tagIds, type }
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to ${type}. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: type,
    data: { ids: tagIds },
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

export function enableRSSI(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to enable RSSI. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: 'enableRSSI',
    data: {},
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

export function disableRSSI(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to disable RSSI. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: 'disableRSSI',
    data: {},
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

// Sleep all tags and balls except for the ones in the pitch
export type SleepAllButOnFieldResponseBody = number[]

export const sleepAllButOnField: AppRequest<
  null,
  SleepAllButOnFieldResponseBody
> = async () => {
  const options = {
    url: 'greylist/offpitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}
