import React, { useState } from 'react'
import { formConfig } from '../form_config'

// Components
import { FormContainer } from '../../../components/Forms/Form/Form'

import styles from './RequestPasswordForm.module.scss'

const validate = (values) => {
  const errors = {
    email: undefined
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  return errors
}

export interface RequestPasswordFormProps {
  formConfig: any
  switchForm: (formKey: string) => void

  requestNewPassword: (values, loadingId, cb) => void

  authentication: any
}

export const RequestPasswordForm = (props: RequestPasswordFormProps) => {
  const { switchForm, requestNewPassword } = props

  const [submitted, setSubmitted] = useState(false)
  const [emailAccepted, setEmailAccepted] = useState(false)
  const [email, setEmail] = useState(null)

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return [
      {
        name: 'email',
        type: 'text',
        label: 'Email address'
      }
    ]
  }

  const handleSubmit = (values) => {
    const { requestPassword } = formConfig
    setEmail(values.email)
    requestNewPassword(values, requestPassword.key, (success) => {
      setSubmitted(true)
      setEmailAccepted(success)
    })
  }

  return (
    <>
      <FormContainer
        fields={fields}
        validate={validate}
        onSubmit={handleSubmit}
        submitText={'Request'}
      >
        {submitted && (
          <div className='section'>
            {emailAccepted ? (
              <div className='row'>
                <p>
                  We've sent an email to {email}, to complete the password reset
                  please follow the link contained within the email.
                </p>
              </div>
            ) : (
              <div className='row'>
                <p className={styles.resendText}>Email not recognised</p>
              </div>
            )}
          </div>
        )}
      </FormContainer>
    </>
  )
}
