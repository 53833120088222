// Used in validation and overview to select which metrics you want to see in the event tables

import React, { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { Button } from '../../../components/Button/Button'
import { ColumnForm } from '../../../components/Forms/ColumnForm/ColumnForm'
import { updateSelectedColumns } from '../../../metrics_server/events/actions'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useSession, useSessions } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { getMetricsKeyOptionsWithUnits } from '../../../metrics_server/metrics/functions'

export const useColumnSelectModal = () => {
  // Redux //
  const events = useEvents()
  const { sport } = useSession()
  const unitSystem = useUnitsSystem(sport)
  const sessions = useSessions()

  const dispatch = useAppDispatch()
  // ====== //

  const { flightMetrics } = useSession()

  const openColumnSelectModal = useCallback(
    (maxColumns = 4) => {
      const flightMetricsOptions = getMetricsKeyOptionsWithUnits(
        flightMetrics,
        unitSystem
      )
      const ChildComponent = () => {
        // Manage column state locally and save to events store on button click
        const [columns, setColumns] = useState<(string | number)[]>(
          events.columns
        )
        return (
          <>
            <ColumnForm
              title='Add/Remove Metrics'
              options={flightMetricsOptions}
              selected={columns}
              handleChange={(values) => {
                setColumns(values)
              }}
              maxSelections={maxColumns}
              invalidSelectionMessage='* Not enough metrics selected'
            />
            <Button
              handleClick={() => {
                dispatch(updateSelectedColumns(columns))
                dispatch(toggleModal({}))
              }}
              className='btn--primary'
              type='button'
            >
              Ok
            </Button>
          </>
        )
      }

      // Open modal with above ChildComponent
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          wrapper: true,
          handleProceed: () => {
            dispatch(toggleModal({}))
          },
          ChildComponent,
          className: 'modalSmall',
          handleSecondaryBtn: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [events.columns, sessions.selected, unitSystem.name]
  )

  return {
    openColumnSelectModal
  }
}
