import React from 'react'
import playerAvatarImg from '../../assets/img/player_avatar.svg'
import { AggregatedFormattedFlightData } from '../../metrics_server/events/flight/types'
import { PlayerData } from '../../metrics_server/players/types'
import { Statistic } from '../Statistic/Statistic'
import styles from './PlayerPassCard.module.scss'

export type PlayerPassData = {
  id: string
  player: PlayerData
  count: number
  metrics: AggregatedFormattedFlightData
}

export interface PlayerPassCardProps {
  item?: PlayerPassData
}

export const PlayerPassCard = (props: PlayerPassCardProps) => {
  const { item }: PlayerPassCardProps = props

  const hasBackGroundImage = item.player.img ? true : false

  let backgroundImage = {
    backgroundImage: `url("${
      hasBackGroundImage ? item.player.img : playerAvatarImg
    }")`
  }

  return (
    <div className={styles.passCard}>
      <div className={styles.cardHeader}>
        <div className={styles.imgContainer} style={backgroundImage}>
          {!hasBackGroundImage && (
            <p className={styles.playerNumber}>{item.player.number}</p>
          )}
        </div>
        <h5>{`${item.player.firstName} ${item.player.lastName}`}</h5>
        {hasBackGroundImage && <h5>{item.player.number}</h5>}
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.statColumn}>
          <Statistic title='# of Passes' stat={`${item.count}`} />
          <Statistic
            title={item.metrics.speed.Mean.name}
            stat={item.metrics.speed.Mean.formattedValue}
          />
        </div>
        <div className={styles.statColumn}>
          <Statistic
            title={item.metrics.speed.Maximum.name}
            stat={item.metrics.speed.Maximum.formattedValue}
          />
          {!!item.metrics.territorialGain && (
            <Statistic
              title={item.metrics.territorialGain.Sum.name}
              stat={item.metrics.territorialGain.Sum.formattedValue}
            />
          )}
        </div>
        <div className={styles.statColumn}>
          {!!item.metrics.reloadTime && (
            <Statistic
              title={item.metrics.reloadTime.Mean.name}
              stat={item.metrics.reloadTime.Mean.formattedValue}
            />
          )}

          <Statistic
            title={item.metrics.hangTime.Mean.name}
            stat={item.metrics.hangTime.Mean.formattedValue}
          />
        </div>
      </div>
    </div>
  )
}
