export const sportableColors = {
  colors: {
    sportableBlue: '#0099cc',
    sportableBlueLight: `rgb(0, 153, 204, 0.4)`,
    sportableRed: '#f64645',

    colorSuccess: '#88c459',
    colorError: '#f5414f',
    colorWarning: '#ffd137',

    sportableGrey: '#c5c4c8',
    sportableGreyDark: '#97979a',
    success: '#00FF00',
    failure: '#f5414f',
    darkYellow: '#feb309',
    flightHighlight: '#ffff00',
    blue: '#245ccc'
  },

  playerColors: {
    // hex colours
    red: '#cc3333',
    blue: '#245ccc',

    darkteal: '#255463',
    lightred: '#e96260',
    lightblue: '#77a0ff',
    lightyellow: '#eee657',
    teal: '#0091a1',
    orange: '#f5a058',
    purple: '#c417c9',
    green: '#129f5a',
    lightgreen: '#99de73',
    brightgreen: '#00FF00',

    // rgba - 1 opacity
    red100: 'rgba(204, 51, 51, 1)',
    blue100: 'rgba(36, 92, 204, 1)',

    darkteal100: 'rgba(37, 84, 99, 1)',
    lightred100: 'rgba(233, 98, 96, 1)',
    lightblue100: 'rgba(119, 160, 255, 1)',
    lightyellow100: 'rgba(238, 230, 87, 1)',
    teal100: 'rgba(0, 145, 161, 1)',
    orange100: 'rgba(245, 160, 88, 1)',
    purple100: 'rgba(196, 23, 201, 1)',
    green100: 'rgba(18, 159, 90, 1)',
    lightgreen100: 'rgba(153, 222, 115, 1)',
    brightgreen100: 'rgba(0, 255, 0, 1)',

    // rbga - opacity - 0.5
    red50: 'rgba(204, 51, 51, 0.5)',
    blue50: 'rgba(36, 92, 204, 0.5)',

    darkteal50: 'rgba(37, 84, 99, 0.5)',
    lightred50: 'rgba(233, 98, 96, 0.5)',
    lightblue50: 'rgba(119, 160, 255, 0.5)',
    lightyellow50: 'rgba(238, 230, 87, 0.5)',
    teal50: 'rgba(0, 145, 161, 0.5)',
    orange50: 'rgba(245, 160, 88, 0.5)',
    purple50: 'rgba(196, 23, 201, 0.5)',
    green50: 'rgba(18, 159, 90, 0.5)',
    lightgreen50: 'rgba(153, 222, 115, 0.5)',
    brightgreen50: 'rgba(0, 255, 0, 0.5)'
  }
}
