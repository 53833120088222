// import { AnchorHealth } from './AnchorHealth/AnchorHealth'
import { SlowSensor } from './SlowSensor/SlowSensor'
import { Controller } from './Controller/Controller'
import { CFLHardware } from './CFLHardware/CFLHardware'
import { CFLAnchorHealth } from './CFLAnchorHealth/CFLAnchorHealth'
import { Hardware } from './Hardware/Hardware'
import { AnchorHealth } from './AnchorHealth/AnchorHealth'
import { Tabs } from '../../components/Views/Main/Main.types'
import { useHardwareOptions } from './options'
import { useVersion } from '../../metrics_server/version/hooks'

export enum DiagnosticsTabs {
  ANCHOR_HEALTH = 'ANCHOR_HEALTH',
  CFL_ANCHOR_HEALTH = 'CFL_ANCHOR_HEALTH',
  HARDWARE_TABLE = 'HARDWARE_TABLE',
  HARDWARE_MATRIX = 'HARDWARE_MATRIX',
  HARDWARE_STATE = 'HARDWARE_STATE',
  CONTROLLER = 'CONTROLLER'
}

export const tabConfig: Tabs = {
  // New CFL Hardware Table
  [DiagnosticsTabs.HARDWARE_TABLE]: {
    key: DiagnosticsTabs.HARDWARE_TABLE,
    name: 'Overview',
    Content: CFLHardware,
    useOptions: useHardwareOptions
  },
  [DiagnosticsTabs.HARDWARE_MATRIX]: {
    // Original Matrix of all hardware devices
    key: DiagnosticsTabs.HARDWARE_MATRIX,
    name: 'Anchors',
    Content: Hardware,
    useOptions: useHardwareOptions
  },
  [DiagnosticsTabs.ANCHOR_HEALTH]: {
    // Original Anchor Health
    key: DiagnosticsTabs.ANCHOR_HEALTH,
    name: 'Anchors',
    Content: AnchorHealth,
    strack: {
      options: {
        anchors: true,
        tagInfo: true,
        babylonActive: false,
        canvasId: 'anchors',
        cover: 'rugby-cover',
        show2DOnly: true,
        tracking: true,
        anchorSetup: true,
        diags: true
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0
      } as const
    }
  },
  [DiagnosticsTabs.CFL_ANCHOR_HEALTH]: {
    // New Anchor Health for CFL with dropdown and daemon stats
    key: DiagnosticsTabs.CFL_ANCHOR_HEALTH,
    name: 'Pitch',
    Content: CFLAnchorHealth,
    strack: {
      options: {
        anchors: true,
        tagInfo: true,
        babylonActive: false,
        canvasId: 'cfl-anchors',
        cover: 'rugby-cover',
        show2DOnly: true,
        tracking: true,
        anchorSetup: true,
        diags: true
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0
      } as const
    }
  },
  [DiagnosticsTabs.HARDWARE_STATE]: {
    key: DiagnosticsTabs.HARDWARE_STATE,
    name: 'HW State',
    Content: SlowSensor
  },
  [DiagnosticsTabs.CONTROLLER]: {
    key: DiagnosticsTabs.CONTROLLER,
    name: 'Controller',
    Content: Controller
  }
}

export const useDiagnositcsTabConfig = (view) => {
  const tabs = { ...view.tabs }

  const version = useVersion()
  let initialTab = tabs[DiagnosticsTabs.HARDWARE_TABLE]
  if (version.app?.FeatureFlags?.EnableNewDiagnosticsScreen) {
    delete tabs[DiagnosticsTabs.ANCHOR_HEALTH]
  } else {
    delete tabs[DiagnosticsTabs.HARDWARE_TABLE]
    delete tabs[DiagnosticsTabs.CFL_ANCHOR_HEALTH]
    initialTab = tabs[DiagnosticsTabs.HARDWARE_MATRIX]
  }
  return {
    tabs,
    initialTab
  }
}
