import React from 'react'
import _ from 'lodash'
import moment from '../../utils/moment'
import { sportableColors } from '../../const'

import { SportableBall } from '../../components/Icons/SportableBall/SportableBall'
import { TagIcon } from '../../components/Icons/TagIcon/TagIcon'
import { AnchorIcon } from '../../components/Icons/AnchorIcon/AnchorIcon'

// Work around for floating point number errors when getting percentage
export const getPercentValue = (number) => {
  return (number * 1000) / 10
}

// Change value based on min & max limits
export const setLimits = (value, min, max) => {
  if (value === undefined || value === '' || value === null) return
  if (parseInt(value) === 0) {
    return
  }
  value = Number(value)
  if (value < min) {
    value = min
  } else if (value > max) {
    value = max
  }

  return value
}

export function sortArrayOfObjectsByKey(array, key) {
  return array.sort((a, b) => {
    const aName = a[key].toLowerCase()
    const bName = b[key].toLowerCase()
    if (aName < bName) {
      return -1
    } else if (aName > bName) {
      return 1
    }
    return 0
  })
}

// Convert packet data from mqtt stream to json
export function uInt8ToJSON(val) {
  let str = ''
  let result
  for (let i = 0; i < val.length; i++) {
    str += String.fromCharCode(val[i])
  }
  try {
    result = JSON.parse(str)
  } catch (e) {
    return null
  }
  return result
}

export function addAlpha(color, opacity) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

// Find distance between 2 coordinates
export function distance(pointA, pointB) {
  const x = Math.abs(pointA.x - pointB.x),
    y = Math.abs(pointA.y - pointB.y)
  const distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
  return distance
}

export function distance3D(pointA, pointB) {
  const x1 = parseFloat(pointA.x)
  const y1 = parseFloat(pointA.y)
  const z1 = parseFloat(pointA.z)

  const x2 = parseFloat(pointB.x)
  const y2 = parseFloat(pointB.y)
  const z2 = parseFloat(pointB.z)

  const distance = Math.pow(
    Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) + Math.pow(z2 - z1, 2),
    0.5
  )
  return distance
}

// Calculate speed from x and y velocities
export function speed(xVel, yVel) {
  const x = Math.abs(xVel),
    y = Math.abs(yVel)
  const speed = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
  return speed
}

// Get a random colour
// checks if opacity is passed as an argument, if so uses Hex colours and interpolates given opacity
export function getColor(index, opacity?) {
  const sportableColorArray = Object.values(sportableColors.playerColors)
  const sportableHexColorArray = sportableColorArray.filter((color) =>
    color.startsWith('#')
  )

  if (opacity) {
    const colorHex = sportableHexColorArray[index]
    const rgb = hexToRgb(colorHex)
    const rgbColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity || 1})`
    return rgbColor
  }

  return sportableColorArray[index]
}

export function getRgbValues(string) {
  const isHex = string.startsWith('#')
  if (isHex) {
    return hexToRgb(string)
  } else {
    const [r, g, b] = string.match(/\d+/g).map(Number)
    if (r !== undefined && g !== undefined && b !== undefined) {
      return {
        r: parseInt(r, 16),
        g: parseInt(g, 16),
        b: parseInt(b, 16)
      }
    }
  }
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : {
        r: 0,
        g: 0,
        b: 0
      }
}

// Generate array of series for force graph
// export function generateSeriesArray(data, players, teams) {
//   function findPlayer(players, tagId) {
//     return players.find(x => x.tag_id === tagId)
//   }
//   let dataSeriesArray = [];
//   for (var tagId in data) {
//     let player = findPlayer(players, tagId),
//         playerName = `${player.firstName} ${player.lastName}`;
//     let seriesData = {};
//     if (data.hasOwnProperty(tagId)) {
//       if (!!data[tagId].Timestamp) {
//         seriesData.name = tagId;
//         seriesData.points = [];
//         seriesData.columns = ["time", playerName];
//         for (var i = 0; i < data[tagId].Timestamp.length; i++) {
//           seriesData.points.push([data[tagId].Timestamp[i] * 1000, parseInt(data[tagId].SavitzkyGolay[i], 10)])
//         }
//         const color = (teams['A'].id === player.teamId) ? teams['A'].color : teams['B'].color;
//         const timeseries = new TimeSeries(seriesData)
//         dataSeriesArray.push({timeseries, color, tag_id: tagId, column: playerName})
//       }
//     }
//   }
//   return dataSeriesArray;
// }

// Convert string to Hex
export function convertToHex(str) {
  let hex = ''
  for (let i = 0; i < str.length; i++) {
    hex += '' + str.charCodeAt(i).toString(16)
  }
  return hex
}

// Sleep promise
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Is prop an empty array or empty object. Return boolean
export function isPropEmpty(prop) {
  if (prop instanceof Array) {
    if (prop.length === 0) return true
  } else {
    if (_.isEmpty(prop)) return true
  }
  return false
}

// Compare object keys
export function compareKeys(a, b) {
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(b).sort()
  return JSON.stringify(aKeys) === JSON.stringify(bKeys)
}

// Sort array of objects alphabetically by key
export const sortAlphabeticallyByKey = (objectToSort, key) => {
  return objectToSort.sort(function (a, b) {
    a = a[key].toLowerCase()
    b = b[key].toLowerCase()
    if (a == b) return 0
    return a < b ? -1 : 1
  })
}

// Decode Jwt
export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

/* Generate number options for Weight Height and Age */
export function numOptions(min, max, increment, unit, decimalPlaces) {
  const numOfOptions = (max - min) / increment,
    options = []
  for (let i = 0; i < numOfOptions; i++) {
    options.push({
      name: `${(min + i * increment).toFixed(decimalPlaces)} ${unit}`,
      value: min + i * increment
    })
  }
  return options
}

/* get previous pathname from history */

export function getPrevPathnameFromHistory(history) {
  return ''
  let inc = -1
  if (history.action === 'POP') inc = 1
  let pathname
  if (history.entries[history.index + inc]) {
    pathname = history.entries[history.index + inc].pathname
  }
  return pathname
}

// Get prop items from array of ids
export function getPropItems(items, idArray) {
  const filteredItems = []
  if (idArray) {
    idArray.forEach((id) => {
      if (items[id]) {
        filteredItems.push(items[id])
      }
    })
  }
  return filteredItems
}

// Milliseconds to formatted hours minutes and seconds string
export function milToFormat(value, options) {
  const duration = moment.duration(value)

  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()

  const sHours = hours < 10 ? `0${hours}` : `${hours}`
  const sMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const sSeconds = seconds < 10 ? `0${seconds < 0 ? 0 : seconds}` : `${seconds}`

  return `${sHours}${options.colon ? ':' : ''}${sMinutes}${
    options.colon ? ':' : ''
  }${sSeconds}`
}

// Moving average filter for playbackData

export function movingAverage(arr, n) {
  let avgX = 0
  let avgY = 0
  let avgZ = 0

  arr.forEach((p) => {
    avgX += p.pos.x
    avgY += p.pos.y
    avgZ += p.pos.z
  })

  avgX /= arr.length
  avgY /= arr.length
  avgZ /= arr.length

  arr[n / 2].pos.x = avgX
  arr[n / 2].pos.y = avgY
  arr[n / 2].pos.z = avgZ

  return arr[n / 2]
}

// Check for numbers in a string (used for first and last name validation)
export function containsNumber(str) {
  const r = RegExp('^([^0-9]*)$')
  return !r.test(str)
}

// Password RegEx
export function passwordCheck(password) {
  // Minimum 8 characters, 1 upper, 1 lower and 1 number
  const patt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
  return patt.test(password)
}

// Log component changes
export const logChangesOnWillUpdate = (nextProps, nextState, state, props) => {
  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      const prop = props[key]
      if (prop !== nextProps[key]) console.log(`$key = `, prop, nextProps[key])
    }
  }
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      const item = state[key]
      if (item !== nextState[key]) console.log(`$key = `, item, nextState[key])
    }
  }
}

export const logChangesOnUpdated = (prevProps, prevState, state, props) => {
  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      const prop = props[key]
      if (prop !== prevProps[key]) console.log(`$key props = `, key)
    }
  }
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      const item = state[key]
      if (item !== prevState[key]) console.log(`$key state = `, key)
    }
  }
}

export const hex2rgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  // return {r, g, b} // return an object
  return [r / 255, g / 255, b / 255]
}

// Notification helpers

export const renderDeviceIcon = (deviceType, sport?) => {
  if (deviceType === 'Ball') {
    return <SportableBall scale={1} sport={sport} />
  } else if (deviceType === 'PlayerTag') {
    return <TagIcon scale={1} />
  } else {
    return <AnchorIcon />
  }
}

// check for incorrect Low Battery: 300% warnings
export const checkLowBattLevelAndReturnCorrectText = (description) => {
  const splitDescription = description.split(' ')
  const percentageLevelAsString = splitDescription[splitDescription.length - 1]
  const percentageLevelAsInt = parseInt(percentageLevelAsString.slice(0, -1))

  return percentageLevelAsInt >= 100 ? 'Low Battery!' : description
}

// Get sum
export function getSumOfObjectFieldsInArray<
  I extends Record<k, number>,
  k extends string
>(data: I[], key: k, condition?: (item: I) => boolean) {
  return data.reduce((total, item) => {
    if (condition && !condition(item)) return total
    return total + (item[key] || 0)
  }, 0)
}

// Function to get contrasting text color based on background color
export function getContrastingTextColor(bgColor) {
  // Convert the background color to its RGB components
  const r = parseInt(bgColor.slice(1, 3), 16)
  const g = parseInt(bgColor.slice(3, 5), 16)
  const b = parseInt(bgColor.slice(5, 7), 16)

  // Calculate the luminance (brightness) of the background color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Use white or black text color based on the background's luminance
  return luminance > 0.5 ? '#000000' : '#ffffff'
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Date
export type FormattedDateItem = {
  value: string
  formatted: string
  formattedShort: string
}

export type FormattedDate = {
  unixMil: number
  unixSeconds: number
  time: FormattedDateItem
  weekday: FormattedDateItem
  day: FormattedDateItem
  month: FormattedDateItem
  year: FormattedDateItem
  full: FormattedDateItem
}

export function timestampToFomattedDate(unixSeconds: number): FormattedDate {
  const unixMil = unixSeconds * 1000
  const formattedDate = {
    unixMil,
    unixSeconds,
    time: {
      value: moment(unixMil).format('h:mm a'),
      formattedShort: moment(unixMil).format('HH:mm'),
      formatted: moment(unixMil).format('HH:mm:ss')
    },
    weekday: {
      value: moment(unixMil).format('d'),
      formattedShort: moment(unixMil).format('ddd'),
      formatted: moment(unixMil).format('dddd')
    },
    day: {
      value: moment(unixMil).format('D'),
      formattedShort: moment(unixMil).format('DD'),
      formatted: moment(unixMil).format('Do')
    },
    month: {
      value: moment(unixMil).format('M'),
      formattedShort: moment(unixMil).format('MMM'),
      formatted: moment(unixMil).format('MMMM')
    },
    year: {
      value: moment(unixMil).format('YYYY'),
      formattedShort: moment(unixMil).format('YY'),
      formatted: moment(unixMil).format('YYYY')
    },
    full: null
  }

  formattedDate.full = {
    value: `${formattedDate.day.formattedShort}/${formattedDate.month.value}/${formattedDate.year.formattedShort}`,
    formatted: `${formattedDate.weekday.formatted}, ${formattedDate.day.formatted} ${formattedDate.month.formatted} ${formattedDate.year.formatted}`,
    formattedShort: `${formattedDate.weekday.formattedShort}, ${formattedDate.day.formatted} ${formattedDate.month.formattedShort} ${formattedDate.year.formattedShort}`
  }

  return formattedDate
}

export const getDate = (date) => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = newDate.getMonth()
  const year = newDate.getFullYear()
  return `${day}${month}${year}`
}

export const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}
