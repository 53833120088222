import { ErrorState, SetErrorAction } from './types'
import { SET_ERROR } from './types'

export type Action<T, P> = {
  type: T
  payload: P
}

export function setError(error: ErrorState): SetErrorAction {
  return {
    type: SET_ERROR,
    payload: error
  }
}
