import React, { useEffect, useState } from 'react'
import { StrackController } from '../../../../utils/strack/strack'
import styles from './Controls.module.scss'
import { InputAdornment, TextField } from '@mui/material'
import infoImg from '../../../../assets/img/info.svg'
import { PitchDimenstionsInfoCard } from '../PitchDimensions/PitchDimenstionsInfoCard'

export interface ControlsProps {
  strack: any
  strackReady: boolean
  strackOptions: any
  canvasView: string
  toggleCanvasView: (view: string) => void
  live: boolean
}

export const Controls: React.FC<ControlsProps> = ({
  strackReady,
  strack,
  canvasView,
  toggleCanvasView,
  strackOptions,
  live
}) => {
  const { showSessionTracking, diags, defaultPitch, session } = strack

  const [showTracking, setShowTracking] = useState(showSessionTracking)
  const [viewAllTags, setViewAllTags] = useState(diags.viewAllTags)
  const [showTagIds, setShowTagIds] = useState(strack.showTagIds)
  const [showMeas, setShowMeas] = useState(diags.showMeas)
  const [showCoords, setShowCoords] = useState(diags.showCoords)
  const [targetCoords, setTargetCoords] = useState(diags.targetCoords)
  const [toggleDimensionsInfoCard, setToggleDimensionsInfoCard] =
    useState(false)

  // const { scale, getCanvasCoordinate } = strack

  // let masterPosition = getCanvasCoordinate(
  //   scale,
  //   StrackController.pitchFlipped ? 0 : 7,
  //   -3
  // )

  // let masterStyles = {
  //   top: masterPosition.scaleY,
  //   left: masterPosition.scaleX,
  //   position: 'absolute' as 'absolute'
  // }

  const flipPitch = (bool) => {
    strack.flipPitch(bool)
  }
  const rotatePitch = (bool) => {
    strack.rotatePitch(bool)
  }

  useEffect(() => {
    if (strackReady) {
      strack.showSessionTracking = showTracking
    }
  }, [showTracking])

  useEffect(() => {
    if (strackReady) {
      strack.showTagIds = showTagIds
    }
  }, [showTagIds])

  useEffect(() => {
    if (strackReady && diags) {
      diags.viewAllTags = viewAllTags
    }
  }, [viewAllTags])

  useEffect(() => {
    if (strackReady && diags) {
      diags.showMeas = showMeas
    }
  }, [showMeas])
  useEffect(() => {
    if (strackReady && diags) {
      diags.showCoords = showCoords
    }
  }, [showCoords])
  useEffect(() => {
    if (strackReady && diags) {
      diags.targetCoords = targetCoords
    }
  }, [targetCoords])

  const is3D = canvasView === '3D'

  return (
    <React.Fragment>
      <div className={styles.controlsOverlay}>
        {strackOptions.babylonActive && toggleCanvasView && (
          <div
            onClick={() => {
              toggleCanvasView(is3D ? '2D' : '3D')
            }}
            className={styles.strackButton}
          >
            {is3D ? '2D' : '3D'}
          </div>
        )}
        {strackOptions.tracking && !strackOptions.diags && !is3D && live && (
          <div
            onClick={() => {
              setShowTracking(!showTracking)
            }}
            className={styles.strackButton}
          >
            {showTracking ? 'DISABLE TRACKING' : 'ENABLE TRACKING'}
          </div>
        )}
        {strackOptions.diags && !is3D && (
          <div
            onClick={() => {
              setViewAllTags(!viewAllTags)
            }}
            className={styles.strackButton}
          >
            {viewAllTags ? 'ONE TAG' : 'ALL TAGS'}
          </div>
        )}
        {strackOptions.diags && !is3D && (
          <div
            onClick={() => {
              setShowMeas(!showMeas)
            }}
            className={styles.strackButton}
          >
            {showMeas ? 'HIDE MEAS' : 'SHOW MEAS'}
          </div>
        )}
        {strackOptions.tracking && !is3D && (live || strackOptions.diags) && (
          <div
            onClick={() => {
              setShowTagIds(!showTagIds)
            }}
            className={styles.strackButton}
          >
            {showTagIds ? 'SHOW NUMBER' : 'SHOW ID'}
          </div>
        )}
        {!is3D && (
          <>
            {session?.id !== 'setup' && (
              <div
                onClick={() => flipPitch(!strack.pitchFlipped)}
                className={styles.strackButton}
              >
                FLIP PITCH
              </div>
            )}
            {session?.sport.props.pitch.enableRotate &&
              session?.id !== 'setup' && (
                <div
                  onClick={() => rotatePitch(!strack.pitchRotated)}
                  className={styles.strackButton}
                >
                  ROTATE PITCH
                </div>
              )}
          </>
        )}
        {strackOptions.tracking && !is3D && strackOptions.diags && (
          <div
            onClick={() => {
              setShowCoords(!showCoords)
            }}
            className={styles.strackButton}
          >
            {showCoords ? 'HIDE COORDINATES' : 'SHOW COORDINATES'}
          </div>
        )}
        {strackOptions.tracking && !is3D && strackOptions.diags && (
          <div className={styles.targetCoords}>
            <TextField
              autoComplete='chrome-off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>X: </InputAdornment>
                )
              }}
              onChange={(e) =>
                setTargetCoords({ ...targetCoords, x: Number(e.target.value) })
              }
              value={targetCoords.x}
              sx={{ backgroundColor: 'white', width: '150px' }}
              type='number'
              size='small'
            />
            <TextField
              autoComplete='chrome-off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Y: </InputAdornment>
                )
              }}
              onChange={(e) =>
                setTargetCoords({ ...targetCoords, y: Number(e.target.value) })
              }
              value={targetCoords.y}
              sx={{ backgroundColor: 'white', width: '150px' }}
              type='number'
              size='small'
            />
            <TextField
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Z: </InputAdornment>
                ),
                autoComplete: 'off'
              }}
              onChange={(e) =>
                setTargetCoords({ ...targetCoords, z: Number(e.target.value) })
              }
              value={targetCoords.z}
              sx={{ backgroundColor: 'white', width: '150px' }}
              type='number'
              size='small'
            />
            <TextField
              autoComplete='chrome-off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>zPlot: </InputAdornment>
                )
              }}
              onChange={(e) =>
                setTargetCoords({
                  ...targetCoords,
                  zPlot: Number(e.target.value)
                })
              }
              value={targetCoords.zPlot}
              sx={{
                backgroundColor: 'white',
                width: '150px',
                '& .MuiTypography-root': { fontSize: '12px' }
              }}
              type='number'
              size='small'
            />
          </div>
        )}
      </div>
      {defaultPitch && (
        <div className={styles.defaultPitchLabel}>DEFAULT PITCH</div>
      )}
      {/* Dimensions Info Card */}
      {toggleDimensionsInfoCard && <PitchDimenstionsInfoCard strack={strack} />}
      <div
        onClick={() => setToggleDimensionsInfoCard(!toggleDimensionsInfoCard)}
        className={styles.pitchDimensionsInfoButton}
      >
        <img src={infoImg} alt='' />
      </div>
    </React.Fragment>
  )
}
