import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'

import { australianRulesProps } from './australian_rules'
import { rugbyUnionProps } from './rugby_union'
import { rugbyLeagueProps } from './rugby_league'
import { americanFootballProps } from './american_football'
import { boxingProps } from './boxing'
import { demonstrationProps } from './demonstration'
import { canadianFootballProps } from './canadian_football'

import { EventTypeGroup } from '../events/data_types'
import { Pitch } from '../pitches/types'

import { UnitSystemNames } from '../units/types'
import { AllEventTypeKeys } from '../events/filter'
import { soccerProps } from './soccer'
import { MetricTypeKeys } from '../metrics/data_types'
import { PitchSetupTypeGroup } from '../pitches/data_types'
import { PlayerMetricTypeGroup } from '../metrics/player_data_types'

export type SportTypeFeatures = {
  positionNumbers: boolean
  noOfPlayersInStartingLineUp: number
  maxPlayers: number
  defaultUnitSystem: UnitSystemNames
  keyboardShortcuts: any
  targetEvents: Readonly<AllEventTypeKeys[]>
  recentEventTableMetrics: Readonly<MetricTypeKeys[]>
  broadcastIntegrationAvailable: boolean
  scoreboard: boolean
  homeSideCheck: boolean
}

export const defaultSportTypeFeatures: SportTypeFeatures = {
  positionNumbers: false,
  noOfPlayersInStartingLineUp: 0,
  maxPlayers: 0,
  defaultUnitSystem: 'SI',
  keyboardShortcuts: {},
  targetEvents: [],
  recentEventTableMetrics: [],
  broadcastIntegrationAvailable: false,
  scoreboard: false,
  homeSideCheck: false
}

export const sportTypesConfig = {
  australianRules: {
    key: 'australianRules',
    value: 'AustralianRules',
    name: 'Australian Rules',
    props: australianRulesProps
  },
  rugbyUnion: {
    key: 'rugbyUnion',
    value: 'RugbyUnion',
    name: 'Rugby Union',
    props: rugbyUnionProps
  },
  rugbyLeague: {
    key: 'rugbyLeague',
    value: 'RugbyLeague',
    name: 'Rugby League',
    props: rugbyLeagueProps
  },
  americanFootball: {
    key: 'americanFootball',
    value: 'AmericanFootball',
    name: 'American Football',
    props: americanFootballProps
  },
  // boxing: {
  //   key: 'boxing',
  //   value: 'Boxing',
  //   name: 'Boxing',
  //   props: boxingProps
  // },
  demonstration: {
    key: 'demonstration',
    value: 'Demonstration',
    name: 'Demonstration',
    props: demonstrationProps
  },
  canadianFootball: {
    key: 'canadianFootball',
    value: 'CanadianFootball',
    name: 'Canadian Football',
    props: canadianFootballProps
  },
  soccer: {
    key: 'soccer',
    value: 'Soccer',
    name: 'Soccer',
    props: soccerProps
  }
} as const

console.log('SPORTS CONFIG: ', sportTypesConfig)

export type SportTypeProps = {
  eventTypes: EventTypeGroup
  playerSummaryMetricTypes?: PlayerMetricTypeGroup
  pitch: {
    default: Pitch
    setupTypes: PitchSetupTypeGroup
    minPadding2D: number
    minPadding3D: number
    cameraYScale: number
    textureCanvasScale: number
    tenYardMarkings?: number
    crossfieldLines?: Array<{
      name: string
      lower: string
      upper: string
    }>
  }
  features: SportTypeFeatures
}

export class SportType {
  props: SportTypeProps
}

export type SportTypes = DataTypes<typeof sportTypesConfig>

export type SportTypeKey = DataTypeKey<SportTypes>

export type SportTypeKeys = DataTypeKeys<SportTypeKey>

export type SportTypeValues = DataTypeValues<SportTypeKey>

export const getSportTypeGroup = (items: {
  [key in SportTypeKeys]: SportTypes[key]
}) => {
  return getDataTypeGroup<
    SportTypeKeys,
    SportTypeValues,
    SportTypes,
    SportTypeProps
  >(items)
}

export type SportTypeGroup = ReturnType<typeof getSportTypeGroup>

export type SportTypeConfig = DataTypeConfig<
  SportTypeKeys,
  SportTypeValues,
  SportTypeProps
>

export type Sport = SportTypeGroup['items'][SportTypeKeys]

export const sportTypes = getSportTypeGroup(sportTypesConfig)

export const isSportType = generateTypeChecks<SportTypeKeys, SportTypeGroup>(
  sportTypes
)
