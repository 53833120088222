import React, { memo, useMemo } from 'react'
import { PlayerData } from '../../metrics_server/players/types'
import { PlayerSession } from '../../metrics_server/sessions/types'
import { TeamListState } from '../../metrics_server/teams/types'
import styles from './TeamList.module.scss'
import { TeamListitem } from './TeamListItem/TeamListItem'
import deepEqual from 'deep-equal'
import {
  useFormattedSession,
  usePlayerPositions
} from '../../metrics_server/sessions/hooks'

export interface TeamListProps {
  teamId: string
  sessionId: string
}

export const TeamList = (props: TeamListProps) => {
  const { teamId, sessionId } = props

  const { sport } = useFormattedSession(sessionId)

  const playerPositions = usePlayerPositions(sessionId)

  const teamPlayerPositions = playerPositions[teamId]

  const startingLineup = useMemo(
    () =>
      teamPlayerPositions.list.filter(
        (playerPosition) => playerPosition.isInStartingLineup
      ),
    [teamPlayerPositions]
  )

  const subs = useMemo(
    () =>
      teamPlayerPositions.list.filter(
        (playerPosition) => !playerPosition.isInStartingLineup
      ),
    [teamPlayerPositions]
  )

  return (
    <div className={styles.teamListContainer}>
      <ul>
        {startingLineup.map((playerPosition) => (
          <TeamListitem playerPosition={playerPosition} />
        ))}
      </ul>
      <ul>
        {subs.map((playerPosition) => (
          <TeamListitem playerPosition={playerPosition} />
        ))}
      </ul>
    </div>
  )
}
