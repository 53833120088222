import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  Options,
  TypesJson
} from '../data_types'
import { GetMetricOptions, MetricHandler } from './metric'
import { RawEventData } from '../events/types'

export const playerMetricTypesConfig = {
  numberOfPasses: {
    key: 'numberOfPasses',
    value: 'NumberOfPasses',
    name: 'Number of passes',
    props: {
      abbr: 'No. Passes',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  passCompletion: {
    key: 'passCompletion',
    value: 'PassCompletion',
    name: 'Pass completion',
    props: {
      abbr: 'Pass completion',
      decimal: 0,
      type: 'percentage',
      format: 'number'
    }
  },
  averagePassDistance: {
    key: 'averagePassDistance',
    value: 'AveragePassDistance',
    name: 'Average pass distance',
    props: {
      abbr: 'Avg Pass Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  totalPassDistance: {
    key: 'totalPassDistance',
    value: 'TotalPassDistance',
    name: 'Total pass distance',
    props: {
      abbr: 'Total Pass Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  averagePassSpeed: {
    key: 'averagePassSpeed',
    value: 'AveragePassSpeed',
    name: 'Average pass speed',
    props: {
      abbr: 'Avg Pass Spd',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  shotsAttempted: {
    key: 'shotsAttempted',
    value: 'Shots Attempted',
    name: 'Shots Attempted',
    props: {
      abbr: 'Shots Att.',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  shotConversion: {
    key: 'shotConversion',
    value: 'Shot Conversion',
    name: 'Shot Conversion',
    props: {
      abbr: 'Shot Conversion',
      decimal: 0,
      type: 'percentage',
      format: 'number'
    }
  },
  averageShotSpeed: {
    key: 'averageShotSpeed',
    value: 'AverageShotSpeed',
    name: 'Average shot speed',
    props: {
      abbr: 'Avg Shot Speed',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  averageSpin: {
    key: 'averageSpin',
    value: 'AverageSpin',
    name: 'Average Spin',
    props: {
      abbr: 'Avg Spin',
      dec: 0,
      type: 'revs',
      format: 'number'
    }
  },
  numberOfDribbles: {
    key: 'numberOfDribbles',
    value: 'NumberOfDribbles',
    name: 'Number of Dribbles',
    props: {
      abbr: 'No. Dribbles',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  averageRecycleTime: {
    key: 'averageRecycleTime',
    value: 'AverageRecycleTime',
    name: 'Average Recycle Time',
    props: {
      abbr: 'Avg Recycle Time',
      decimal: 1,
      type: 'time',
      format: 'number'
    }
  },
  totalTimeInPossession: {
    key: 'totalTimeInPossession',
    value: 'TotalTimeInPossession',
    name: 'Total Time in Possession',
    props: {
      abbr: 'Total Possession Time',
      decimal: 1,
      type: 'time',
      format: 'number'
    }
  },
  numberOfTouches: {
    key: 'numberOfTouches',
    value: 'NumberOfTouches',
    name: 'Number of Touches',
    props: {
      abbr: 'No. Touches',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numberOfCrosses: {
    key: 'numberOfCrosses',
    value: 'NumberOfCrosses',
    name: 'Number of Crosses',
    props: {
      abbr: 'No. Crosses',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numberOfCorners: {
    key: 'numberOfCorners',
    value: 'NumberOfCorners',
    name: 'Number of Corners',
    props: {
      abbr: 'No. Corners',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numOfCornersWithDistance: {
    key: 'numOfCornersWithDistance',
    value: 'NumOfCornersWithDistance',
    name: 'Number of Corners with Distance',
    props: {
      abbr: 'No. Corners with Dist',
      type: 'text',
      format: 'text',
      options: [
        { name: '<5m', value: 'LessThan5' },
        { name: '5-10m', value: 'Between5And10' },
        { name: '>=10m', value: 'GreaterThan10' }
      ]
    }
  },
  averageCornerDistance: {
    key: 'averageCornerDistance',
    value: 'AverageCornerDistance',
    name: 'Average Corner Distance',
    props: {
      abbr: 'Avg Corner Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  averageCornerHangTime: {
    key: 'averageCornerHangTime',
    value: 'AverageCornerHangTime',
    name: 'Average Corner Hang Time',
    props: {
      abbr: 'Avg Corner Hg Time',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },
  averageCornerSpeed: {
    key: 'averageCornerSpeed',
    value: 'AverageCornerSpeed',
    name: 'Average Ball Speed on Corners',
    props: {
      abbr: 'Avg Corner Spd',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  numberOfFreeKicks: {
    key: 'numberOfFreeKicks',
    value: 'NumberOfFreeKicks',
    name: 'Number of Free Kicks',
    props: {
      abbr: 'No. Free Kicks',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numberOfThrowIns: {
    key: 'numberOfThrowIns',
    value: 'NumberOfThrowIns',
    name: 'Number of Throw-ins',
    props: {
      abbr: 'No. Throw-ins',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numberOfGoalKicks: {
    key: 'numberOfGoalKicks',
    value: 'NumberOfGoalKicks',
    name: 'Number of Goal Kicks',
    props: {
      abbr: 'No. Goal Kicks',
      decimal: 0,
      type: 'number',
      format: 'number'
    }
  },
  numOfGoalKicksWithDistance: {
    key: 'numOfGoalKicksWithDistance',
    value: 'NumOfGoalKicksWithDistance',
    name: 'Number of Goal Kicks with Distance',
    props: {
      abbr: 'No. Goal Kicks with Dist',
      type: 'text',
      format: 'text',
      options: [
        { name: '<5m', value: 'LessThan5' },
        { name: '5-10m', value: 'Between5And10' },
        { name: '>=10m', value: 'GreaterThan10' }
      ]
    }
  },
  averageGoalKickDistance: {
    key: 'averageGoalKickDistance',
    value: 'AverageGoalKickDistance',
    name: 'Average Goal Kick Distance',
    props: {
      abbr: 'Avg Goal Kick Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  averageGoalKickHangTime: {
    key: 'averageGoalKickHangTime',
    value: 'AverageGoalKickHangTime',
    name: 'Average Goal Kick Hang Time',
    props: {
      abbr: 'Avg Goal Kick Hg Time',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },
  averageGoalKickSpeed: {
    key: 'averageGoalKickSpeed',
    value: 'AverageGoalKickSpeed',
    name: 'Average Goal Kick Speed',
    props: {
      abbr: 'Avg Goal Kick Spd',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  }
} as const

export type PlayerMetricTypeProps = {
  abbr?: string
  type?:
    | 'distance'
    | 'time'
    | 'speed'
    | 'revs'
    | 'percentage'
    | 'number'
    | 'text'
  decimal?: number
  bool?: boolean
  format?: 'text' | 'number' | 'boolean'
  options?: Readonly<Options<any>> | GetMetricOptions
  // disableInTrainingMode?: boolean
  getValue?: (event: RawEventData) => number | string | boolean
}

export type PlayerMetricTypes = DataTypes<typeof playerMetricTypesConfig>

export type PlayerMetricTypeKey = DataTypeKey<PlayerMetricTypes>

export type PlayerMetricTypeKeys = DataTypeKeys<PlayerMetricTypeKey>

export type PlayerMetricTypeValues = DataTypeValues<PlayerMetricTypeKey>

export const getPlayerMetricTypeGroup = (items: {
  [key in PlayerMetricTypeKeys]?: PlayerMetricTypes[key]
}) => {
  return getDataTypeGroup<
    PlayerMetricTypeKeys,
    PlayerMetricTypeValues,
    PlayerMetricTypes,
    PlayerMetricTypeProps
  >(items)
}

export type PlayerMetricTypeGroup = ReturnType<typeof getPlayerMetricTypeGroup>

export type PlayerMetricTypeConfig = DataTypeConfig<
  PlayerMetricTypeKeys,
  PlayerMetricTypeValues,
  PlayerMetricTypeProps
>

export const metricTypes = getPlayerMetricTypeGroup(playerMetricTypesConfig)

export type MetricType = ReturnType<(typeof metricTypes)['getTypeByValue']>

export function generateMetricTypeClass(
  dataTypeConfig: PlayerMetricTypeConfig
) {
  if (!dataTypeConfig) return null
  return new MetricHandler(dataTypeConfig)
}

export type MetricTypeJson = {
  key: PlayerMetricTypeKeys
  name: string
  abbr: string
  decimal: number
}

export type MetricTypesJson = TypesJson<PlayerMetricTypeKeys, MetricTypeJson>

export function getPlayerMetricTypesConfigFromJson(
  typesJson: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
      }
  )[] = [],
  typesConfig: Partial<typeof playerMetricTypesConfig> = {}
) {
  return getTypesConfigFromJson<string, typeof typesConfig>(
    typesJson,
    typesConfig,
    playerMetricTypesConfig
  )
}

function getTypesConfigFromJson<
  k extends string,
  T extends { [key in k]: { key: k; name: string; props } }
>(typesJson: TypesJson<k, T[k]>, types: T, fullTypes: T): Partial<T> {
  let newMetricTypes: Partial<T>
  if (!typesJson) return {}
  typesJson.forEach((jsonType) => {
    if (typeof jsonType === 'string') {
      const type = types[jsonType] || fullTypes[jsonType]
      newMetricTypes = {
        ...newMetricTypes,
        [jsonType]: {
          ...type
        }
      }
    } else {
      const type = types[jsonType.key] || fullTypes[jsonType.key]
      newMetricTypes = {
        ...newMetricTypes,
        [jsonType.key]: {
          ...type,
          name: jsonType.name || type.name,
          props: {
            ...(type?.props || {}),
            ...jsonType
          }
        }
      }
    }
  })
  return newMetricTypes
}
