import React, { Component, memo } from 'react'
import {
  TableHeader,
  TableRow,
  EditCell,
  UpdateCellValue,
  UpdateRowsObject,
  SetEditCell,
  HighlightRow,
  GenerateRowData
} from './Table.types'
import { IconCell } from './IconCell/IconCell'
import styles from './Table.module.scss'

import { dataTypes } from './data_types'

/*====== Components ======*/
import { Button } from '../Button/Button'
import Checkbox from '../Forms/Inputs/Checkbox/Checkbox'
import {
  compareProps,
  usePropComparison
} from '../../utils/hooks/usePropComparison'
import { isBorder } from './Table'

interface TdStyle {
  width: string
  background?: string
}

export interface RowProps {
  editCell: EditCell
  item: TableRow
  headers: TableHeader[]
  updateCellValue?: UpdateCellValue
  updateEditedRows?: UpdateRowsObject
  setEditCell?: SetEditCell
  highlightRow?: HighlightRow
  highlightedRow: string
  isEditableTable?: boolean
  tableId?: string
  generateRowData?: GenerateRowData
  groups?
  isLastRow: boolean
  getRowHeight: (number) => void
}

interface RowState {
  textCellInput: {
    key: string
    value: string
  }
  rowData: TableRow
}

export class Row extends Component<RowProps, RowState> {
  private cellTextInput: React.RefObject<HTMLInputElement>
  private rowRef: React.RefObject<HTMLTableRowElement>

  constructor(props) {
    super(props)

    this.state = {
      textCellInput: {
        key: null,
        value: null
      },
      rowData: props.generateRowData
        ? props.generateRowData(props.item)
        : props.item
    }

    this.cellTextInput = React.createRef()
  }

  componentDidUpdate(prevProps: RowProps, prevState) {
    const { editCell, generateRowData, item } = this.props

    if (
      editCell &&
      (editCell.rowId !== prevProps.editCell.rowId ||
        editCell.headerKey !== prevProps.editCell.headerKey) &&
      this.cellTextInput.current
    ) {
      this.cellTextInput.current.focus()
    }

    if (this.props.item !== prevProps.item) {
      this.setState({ rowData: generateRowData ? generateRowData(item) : item })
    }
  }

  shouldComponentUpdate = (prevProps: RowProps, prevState: RowState) => {
    return true
    // if (
    //   prevProps.highlightedRow === this.props.item.tableId ||
    //   this.props.highlightedRow === this.props.item.tableId
    // )
    //   return true
    // if (this.props.headers !== prevProps.headers) return true
    // console.log('row props', this.props.item !== prevProps.item)
    // return this.props.item !== prevProps.item
  }

  updateText = (value: string) => {
    this.props.updateCellValue(value)
  }

  startEditingTextCell = (header, initialValue) => {
    this.setState({ textCellInput: { key: header.key, value: initialValue } })
  }

  updateTextCell = (value) => {
    this.setState({ textCellInput: { ...this.state.textCellInput, value } })
  }

  endEditingTextCell = (header: TableHeader) => {
    const { item, editCell } = this.props
    const { textCellInput } = this.state

    if (editCell) {
      const value = dataTypes[header.type].parse(editCell.value, header.input)
      this.props.updateEditedRows(item, editCell.headerKey, value)
      this.props.setEditCell(null, null, null)
      header.input.onChange(item, value)
    } else {
      const value = dataTypes[header.type].parse(
        textCellInput.value,
        header.input
      )
      this.setState({ textCellInput: { key: null, value: null } })
      header.input.onChange(item, value)
    }
  }

  keyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      ;(event.target as HTMLElement).blur()
    }
  }

  renderCells = (
    header: TableHeader,
    index: number,
    headers: TableHeader[],
    item: TableRow
  ) => {
    const { groups, isLastRow } = this.props
    const group = groups?.[header.group]
    const currentIndex = headers.findIndex((h) => h.key === header.key)
    const previousHeader = headers[currentIndex - 1]
    const nextHeader = headers[currentIndex + 1]

    const tdStyle: React.CSSProperties = {
      width: header.width ? `${header.width}%` : `${100 / headers.length}%`,
      borderRight:
        group && isBorder(header, nextHeader)
          ? `${group?.color} 3px solid`
          : 'none',
      borderLeft:
        group && isBorder(header, previousHeader)
          ? `${group?.color} 3px solid`
          : 'none',
      borderBottom: group && isLastRow ? `${group?.color} 3px solid` : 'none'
    }

    if (item.__backgroundColor) tdStyle.background = item.__backgroundColor
    if (item.highlightColor) tdStyle.background = item.highlightColor

    if (item.__hidden && item.__hidden[header.key]) {
      return (
        <td style={tdStyle} key={index}>
          <noscript />
        </td>
      )
    }

    if (header.type === 'component' && header.CustomComponent) {
      return (
        <td style={tdStyle} key={index}>
          <header.CustomComponent
            item={item}
            header={header}
            updateEditedRows={this.props.updateEditedRows}
          />
        </td>
      )
    }

    if (header.type === 'icon') {
      let iconColor
      if (item.__colors && item.__colors[header.key]) {
        iconColor = item.__colors[header.key]
      } else {
        iconColor = header.color
      }
      return (
        <td style={tdStyle} key={index}>
          <div onClick={(e) => header.input.callback(item)}>
            <IconCell type={item[header.key]} color={iconColor} />
          </div>
        </td>
      )
    }

    if (!header.type) header.type = 'text'
    let value
    if (!dataTypes[header.type]) {
      console.log('no data type for', dataTypes, header.type, item[header.key])
      value = 'bug - tell tom'
    } else {
      value = dataTypes[header.type].display(item[header.key])
    }

    if (header.input && !item.__fixedValue) {
      const { editCell } = this.props
      if (header.input.type === 'text') {
        const { textCellInput } = this.state
        return (
          <td
            onClick={(e) => {
              if (
                editCell &&
                (item.tableId !== editCell.rowId ||
                  header.key !== editCell.headerKey)
              ) {
                this.props.setEditCell(item.tableId, header.key, value)
              }
            }}
            className={styles.inputCell}
            style={tdStyle}
            key={index}
          >
            {!editCell ||
            (item.tableId === editCell.rowId &&
              header.key === editCell.headerKey) ? (
              <div
                className={`${styles.tableInputContainer} ${
                  header.type === 'number' ? styles.number : ''
                } ${header.type === 'text' ? styles.text : ''}`}
              >
                <input
                  ref={this.cellTextInput}
                  type='text'
                  value={
                    editCell
                      ? editCell.value
                      : textCellInput.key === header.key
                      ? textCellInput.value
                      : value
                  }
                  className={styles.tableInput}
                  onKeyPress={(e) => this.keyPressed(e)}
                  onBlur={() => {
                    this.endEditingTextCell(header)
                  }}
                  onFocus={() => {
                    this.startEditingTextCell(header, value)
                  }}
                  onChange={(e) =>
                    editCell
                      ? this.updateText(e.target.value)
                      : this.updateTextCell(e.target.value)
                  }
                  style={{
                    color: item.__color ? item.__color : '#323233'
                  }}
                />
              </div>
            ) : (
              <div
                className={`${
                  header.type === 'number' ? styles.number : styles.text
                }`}
              >
                {header.type === 'color' ? (
                  <div
                    className={styles.colorContainer}
                    style={{ background: value, width: '100%', height: '14px' }}
                  ></div>
                ) : (
                  <div className={styles.tableTextSmall}>{value}</div>
                )}
              </div>
            )}
          </td>
        )
      } else if (header.input.type === 'select') {
        const selectedOption = header.input.selectOptions.find(
          (option) => value === option.value
        )
        return (
          <td
            onClick={(e) => {
              if (
                editCell &&
                (item.tableId !== editCell.rowId ||
                  header.key !== editCell.headerKey)
              ) {
                this.props.setEditCell(item.tableId, header.key, value)
              }
            }}
            className={styles.inputCell}
            style={tdStyle}
            key={index}
          >
            {!editCell ||
            (item.tableId === editCell.rowId &&
              header.key === editCell.headerKey) ? (
              <div className={styles.tableInputContainer}>
                <select
                  value={editCell ? editCell.value : value}
                  className={styles.tableInput}
                  onChange={(e) => {
                    if (editCell) this.props.updateCellValue(e.target.value)
                    let parsedValue = e.target.value
                    if (dataTypes[header.type]) {
                      parsedValue = dataTypes[header.type].parse(parsedValue)
                    }
                    if (header.input.onChange)
                      header.input.onChange(item, parsedValue)
                  }}
                  onBlur={(e) => {
                    if (editCell)
                      this.props.updateEditedRows(
                        item,
                        header.key,
                        editCell.value
                      )
                  }}
                  style={{
                    color: item.__color ? item.__color : '#323233'
                  }}
                >
                  {header.input.selectOptions
                    .filter((option) => {
                      if (!header.input.unavailableOptions) return true
                      return (
                        value === option.value ||
                        header.input.unavailableOptions.indexOf(option.value) <
                          0
                      )
                    })
                    .map((option) => (
                      <option value={option.value}>{option.name}</option>
                    ))}
                </select>
              </div>
            ) : (
              <div
                className={`${
                  header.type === 'number' ? styles.number : styles.text
                }`}
              >
                <div className={styles.tableTextSmall}>
                  {selectedOption.name}
                </div>
              </div>
            )}
          </td>
        )
      } else if (header.input.type === 'button') {
        return (
          <td style={tdStyle} key={index}>
            <Button
              className={'btn--table'}
              handleClick={() =>
                header.input.callback(
                  item,
                  item.__buttons &&
                    item.__buttons[header.key] &&
                    item.__buttons[header.key].value
                )
              }
              disabled={
                item.__buttons &&
                item.__buttons[header.key] &&
                item.__buttons[header.key].disabled
              }
            >
              {item.__buttons && item.__buttons[header.key]
                ? item.__buttons[header.key].text
                : header.name}
            </Button>
          </td>
        )
      } else if (header.input.type === 'table') {
        return (
          <td
            onClick={(e) => {
              // this.openSelectTable()
            }}
            style={tdStyle}
            key={index}
          >
            <div
              className={`${
                header.type === 'number' ? styles.number : styles.text
              }`}
            >
              <div className={styles.tableTextSmall}>{value}</div>
            </div>
          </td>
        )
      } else if (header.input.type === 'checkbox') {
        return (
          <td style={tdStyle} key={index}>
            <div className={styles.center}>
              <Checkbox
                size='small'
                onClicked={(val) => {
                  header.input.onChange(item, val)
                }}
                checked={value}
                input={{ value }}
                type='checkbox'
                disabled={item.__disabled?.[header.key]}
              />
            </div>
          </td>
        )
      } else if (header.input.type === 'highlight') {
        return (
          <td style={tdStyle} key={index}>
            <div className={styles.center}>
              <Checkbox
                size='small'
                onClicked={(val) => {
                  header.input.onChange(item, val)
                }}
                checked={value}
                input={{ value }}
                type='highlight'
              />
            </div>
          </td>
        )
      }
    }

    if (
      header.type === 'number' &&
      typeof value === 'number' &&
      !isNaN(header.decimal)
    ) {
      value = value.toFixed(header.decimal)
    }

    return (
      <td style={tdStyle} key={index}>
        {header.type === 'color' ? (
          <div
            className={styles.colorContainer}
            style={{ background: value, width: '100%', height: '14px' }}
          ></div>
        ) : (
          <div className={styles.tableTextSmall}>{value}</div>
        )}
      </td>
    )
  }

  render() {
    const { item, headers, highlightedRow, editCell, tableId } = this.props
    const { rowData } = this.state

    return (
      <tr
        onClick={(e) => {
          if (this.props.highlightRow) {
            this.props.highlightRow(rowData.tableId, item)
          }
          // Remove edit cell if not selected
          if (editCell && item.tableId !== editCell.rowId) {
            this.props.setEditCell(null, null, null)
          }
        }}
        id={rowData.tableId}
        className={`${
          item.tableId && highlightedRow === item.tableId
            ? styles.highlightRow
            : ''
        }`}
        style={{
          color: rowData.__color ? rowData.__color : '#323233'
        }}
      >
        {headers.map((header, index) => {
          return this.renderCells(header, index, headers, rowData)
        })}
      </tr>
    )
  }
}

export default memo(Row)
