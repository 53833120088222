import React, { useMemo, useState } from 'react'
import { useBroadcastIntegrationSessionState } from '../hooks'
import { Table } from '../../../components/Table/Table'
import { useAppDispatch } from '../../../store/hooks'
import { checkGeniusPlayer } from '../thunks'
import { getBroadcastIntegrationPlayerCheckRequestBody } from '../functions'
import { TeamLogoCell } from '../../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
import { usePlayerFormModal } from '../../players/modals'
import { useFormattedSession } from '../../sessions/hooks'
import { useBroadcastIntegrationErrorModal } from '../modals'
import { updateIgnoredPlayerId } from '../slice'
import { sportableColors } from '../../../const'
import styles from './BroadcastIntegrationTable.module.scss'

export interface BroadcastIntegrationTableProps {
  teamId: string
}

export const BroadcastIntegrationTable = ({
  teamId
}: BroadcastIntegrationTableProps) => {
  // Modals //
  const { openPlayerFormModal } = usePlayerFormModal()
  // ====== //
  const formattedSession = useFormattedSession('setup')
  const { sport } = formattedSession

  const broadcastIntegrationSessionState = useBroadcastIntegrationSessionState()

  const { playersList, teamData } =
    broadcastIntegrationSessionState.teamsAndPlayers[teamId]

  const dispatch = useAppDispatch()

  const { openBroadcastIntegrationErrorModal } =
    useBroadcastIntegrationErrorModal()

  const tableHeaders = useMemo(
    () => [
      {
        name: '',
        key: 'refresh',
        type: 'icon',
        input: {
          type: 'icon',
          callback: (item) => {
            // dispatch player check
            const body = getBroadcastIntegrationPlayerCheckRequestBody(
              item.player,
              formattedSession.teams.map[teamId]
            )
            dispatch(checkGeniusPlayer(body))
          }
        }
      },
      {
        name: '',
        key: 'edit',
        type: 'icon',
        input: {
          type: 'icon',
          callback: (item) => {
            openPlayerFormModal(sport, item.player, teamId)
          }
        }
      },
      {
        name: '',
        key: 'tag',
        type: 'icon'
      },
      {
        name: 'Team',
        key: 'teamLogo',
        width: 9,
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      { name: 'First Name', key: 'firstName', width: 25, type: 'text' },
      { name: 'Last Name', key: 'lastName', width: 25, type: 'text' },
      { name: 'Certainty', key: 'certainty', width: 25, type: 'text' },
      { name: 'Genius ID', key: 'geniusID', width: 25, type: 'text' },
      {
        name: 'Error Code',
        key: 'error',
        type: 'icon',
        input: {
          type: 'icon',
          callback: (item) => {
            openBroadcastIntegrationErrorModal(item.errors)
          }
        }
      },
      {
        name: 'Ignore',
        key: 'ignore',
        width: 9,
        input: {
          type: 'checkbox',
          onChange: (item, bool) => {
            // Update a list of ignored players?
            dispatch(updateIgnoredPlayerId(item.player.id))
          }
        }
      }
    ],
    [
      formattedSession,
      teamId,
      sport,
      dispatch,
      openPlayerFormModal,
      openBroadcastIntegrationErrorModal
    ]
  )

  const tableData = useMemo(() => {
    return playersList.map((broadcastIntegrationPlayer) => {
      let backgroundColor = sportableColors.colors.colorError50

      if (broadcastIntegrationPlayer.broadcastIntegrationResult.geniusID) {
        backgroundColor = sportableColors.colors.colorSuccess50
      }

      if (broadcastIntegrationPlayer.isFetching) {
        backgroundColor = null
      }

      const row = {
        id: broadcastIntegrationPlayer.playerData.id,
        firstName: broadcastIntegrationPlayer.playerData.firstName,
        lastName: broadcastIntegrationPlayer.playerData.lastName,
        certainty:
          broadcastIntegrationPlayer.broadcastIntegrationResult.certainty.toFixed(
            2
          ),
        error: broadcastIntegrationPlayer.broadcastIntegrationResult.errors
          ? 'info'
          : null,
        errors: broadcastIntegrationPlayer.broadcastIntegrationResult.errors,
        refresh: broadcastIntegrationPlayer.isFetching ? 'spinner' : 'refresh',
        edit: 'edit',
        tag: formattedSession.playersSessions.byPlayerId.map[
          broadcastIntegrationPlayer.playerData.id
        ]?.hardwareId
          ? 'tag'
          : null,
        ignore: broadcastIntegrationPlayer.isPlayerIgnored,
        teamLogo: {
          logo: teamData.logo,
          color: teamData.color
        },
        geniusID:
          broadcastIntegrationPlayer.broadcastIntegrationResult.geniusID,
        player: broadcastIntegrationPlayer.playerData,
        __backgroundColor: backgroundColor
      }
      return row
    })
  }, [playersList, formattedSession, teamData])

  const { matchedTableData, unmatchedTableData } = useMemo(() => {
    const matchedTableData = []
    const unmatchedTableData = []

    tableData.forEach((row) => {
      if (row.geniusID) {
        matchedTableData.push(row)
      } else {
        unmatchedTableData.push(row)
      }
    })

    return {
      matchedTableData,
      unmatchedTableData
    }
  }, [tableData])

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <h3>Matched</h3>
        </div>
        <div className={styles.table}>
          <Table
            // Row props
            id={`broadcast-integration-table-${teamId}`}
            // controls
            controls={[]}
            // Table props
            options={{
              initialOrder: 'asc',
              initialSortBy: 'certainty',
              sortActive: true
            }}
            smallHead={true}
            headerFont={13}
            tableClass={'minimalistBlack'}
            className={'container'}
            data={matchedTableData}
            headers={tableHeaders}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <h3>Unmatched</h3>
        </div>
        <div className={styles.table}>
          <Table
            // Row props
            id={`broadcast-integration-table-${teamId}`}
            // controls
            controls={[]}
            // Table props
            options={{
              initialOrder: 'asc',
              initialSortBy: 'certainty',
              sortActive: true
            }}
            smallHead={true}
            headerFont={13}
            tableClass={'minimalistBlack'}
            className={'container'}
            data={unmatchedTableData}
            headers={tableHeaders}
          />
        </div>
      </div>
    </div>
  )
}
