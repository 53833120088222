import React from 'react'
import styles from './AppInformationToolTipIcon.module.scss'
import ReactTooltip from 'react-tooltip'

export type UiType = {
  name: string
  value: number
  key: string
}

export interface AppInformationToolTipIconProps {
  uiType: UiType
  isProduction: boolean
  app: any | undefined
  ui: string
  libVersion: string
  server: string
  deamon?: string
}

export const AppInformationToolTipIcon: React.FC<
  AppInformationToolTipIconProps
> = ({ uiType, isProduction, app, ui, libVersion, server, deamon }) => {
  return (
    <div data-tip data-for='appVersionsList'>
      <svg
        data-testid='threeHorizontalDotsIcon'
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.0013 10.14C12.6256 10.14 12.2535 10.214 11.9063 10.3578C11.5592 10.5016 11.2438 10.7124 10.9781 10.9781C10.7124 11.2438 10.5016 11.5592 10.3578 11.9064C10.214 12.2535 10.14 12.6256 10.14 13.0013C10.14 13.3771 10.214 13.7492 10.3578 14.0963C10.5016 14.4435 10.7124 14.7589 10.9781 15.0246C11.2438 15.2903 11.5592 15.501 11.9063 15.6448C12.2535 15.7886 12.6256 15.8626 13.0013 15.8626C13.7602 15.8625 14.4879 15.5608 15.0244 15.0241C15.5608 14.4874 15.8621 13.7595 15.862 13.0007C15.8618 12.2418 15.5602 11.5141 15.0234 10.9776C14.4867 10.4412 13.7589 10.1399 13 10.14H13.0013ZM13.0013 6.76004C13.3769 6.75987 13.7488 6.68572 14.0957 6.54184C14.4426 6.39795 14.7578 6.18714 15.0233 5.92144C15.2887 5.65575 15.4992 5.34037 15.6428 4.99331C15.7864 4.64626 15.8602 4.27432 15.86 3.89874C15.8598 3.52316 15.7857 3.15129 15.6418 2.80436C15.4979 2.45744 15.2871 2.14225 15.0214 1.87679C14.7557 1.61134 14.4403 1.40082 14.0933 1.25725C13.7462 1.11368 13.3743 1.03987 12.9987 1.04004C12.2402 1.04038 11.5129 1.34204 10.9768 1.87863C10.4407 2.41523 10.1397 3.14282 10.14 3.90134C10.1404 4.65986 10.442 5.38717 10.9786 5.92328C11.5152 6.45939 12.2428 6.76038 13.0013 6.76004V6.76004ZM13.0013 19.24C12.2425 19.24 11.5147 19.5415 10.9781 20.0781C10.4415 20.6147 10.14 21.3425 10.14 22.1013C10.14 22.8602 10.4415 23.588 10.9781 24.1246C11.5147 24.6612 12.2425 24.9626 13.0013 24.9626C13.7602 24.9626 14.488 24.6612 15.0246 24.1246C15.5612 23.588 15.8626 22.8602 15.8626 22.1013C15.8626 21.3425 15.5612 20.6147 15.0246 20.0781C14.488 19.5415 13.7602 19.24 13.0013 19.24V19.24Z'
          fill='white'
        />
      </svg>

      <ReactTooltip
        id='appVersionsList'
        className={styles.toolTip}
        place='right'
        aria-haspopup='true'
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node
        ) => {
          const d = document.documentElement

          left = Math.min(d.clientWidth - node.clientWidth, left)
          top = Math.min(d.clientHeight - node.clientHeight, top)

          left = Math.max(0, left)
          top = Math.max(0, top)

          return { top, left }
        }}
      >
        <ul data-testid='listItems'>
          <li>
            <h5 data-testid='productTitle'>Product: {uiType.name}</h5>
          </li>
          {/* {!isProduction && (
            <li>
              <h5>Environment: {!isProduction ? 'Development' : undefined}</h5>
            </li>
          )} */}
          {app?.Sport && (
            <li>
              <h5>{`Sport: ${app.Sport}`}</h5>
            </li>
          )}
          <li>
            <h5>UI: v{ui}</h5>
          </li>
          <h5>
            <li>Lib: v{libVersion}</li>
          </h5>
          <h5>
            <li>MS: v{server}</li>
          </h5>
          {!!deamon && (
            <h5 data-testid='deamonInfo'>
              <li>Daemon: {deamon}</li>
            </h5>
          )}
        </ul>
      </ReactTooltip>
    </div>
  )
}
