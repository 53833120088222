import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Options } from '../../metrics_server/data_types'

export type TextFieldOnChange = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void

export interface BasicSelectProps {
  label?
  options?: Options<any> | string[]
  selected?
  onChange?
  variant?
  size?: 'small' | 'medium'
  mb?
  readonly?
  fontSize?
  type?
  padding?
  labelMarginTop?
  labelShrink?: boolean
  onBlur?
  required?
  backgroundColor?
  width?
  inputRef?
  borderRadius?
}

export default function BasicSelect({
  options,
  selected,
  onChange,
  label,
  size,
  mb,
  variant,
  readonly,
  fontSize,
  type,
  labelShrink,
  onBlur,
  padding,
  labelMarginTop,
  required,
  backgroundColor,
  width,
  inputRef,
  borderRadius
}: BasicSelectProps) {
  const handleChange: TextFieldOnChange = (event) => {
    onChange(event.target.value as string)
  }

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <TextField
      fullWidth
      inputRef={inputRef}
      required={required}
      select={options ? true : false}
      variant={variant}
      value={selected}
      label={label}
      onChange={handleChange}
      onBlur={onBlur}
      size={size}
      inputProps={{ readOnly: readonly }}
      type={type === 'password' ? (showPassword ? 'text' : type) : type}
      InputLabelProps={{ shrink: labelShrink }}
      InputProps={
        type === 'password'
          ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }
          : null
      }
      sx={{
        width: width,
        '.MuiInputBase-input': {
          fontSize: fontSize,
          padding: padding,
          backgroundColor: backgroundColor,
          borderRadius: borderRadius
        },
        '& label': {
          fontSize: fontSize,
          top: labelMarginTop
        }
      }}
    >
      {options
        ? options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))
        : null}
    </TextField>
  )
}
