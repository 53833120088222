import React from 'react'
import styles from './Title.module.scss'
import { useTheme } from '../../../../ui/config/hook'
import { sportableColors } from '../../../../constants/sportableColors'
import { Scoreboard } from '../../../../components/Scoreboard/Scoreboard'
import { useFormattedSession } from '../../hooks'

interface SessionTitleProps {
  sessionId: string
}

export const SessionTitle = ({ sessionId }: SessionTitleProps) => {
  const theme = useTheme()
  const { isMatchMode, sport, name, startTime, isSetup } =
    useFormattedSession(sessionId)
  const color = theme ? theme.appColor : sportableColors.colors.sportableBlue

  return (
    <div className={styles.tabTitleContainer}>
      {!isSetup && isMatchMode && sport.props.features.scoreboard ? (
        <Scoreboard sessionId={sessionId} />
      ) : null}
      <h4 className={styles.teamTitle}>
        <span className={styles.firstTeam} style={{ color: color }}>
          {name}
        </span>
        <span className={styles.secondTeam} style={{ color: color }}>
          -
        </span>
        <span className={styles.date}>{startTime.full.formattedShort}</span>
      </h4>
    </div>
  )
}
