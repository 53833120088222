import React from 'react'
import { FormattedDevice } from '../../../metrics_server/hardware/types'
import { HardwareCommon } from './HardwareCommon/HardwareCommon'
import { HardwareBall } from './HardwareBall/HardwareBall'
import { HardwareTag } from './HardwareTag/HardwareTag'
import { isDeviceType } from '../../../metrics_server/hardware/data_types'

export interface HardwareItemProps {
  device: FormattedDevice
  isSessionAssignment: boolean
}

export const HardwareItem = (props: HardwareItemProps) => {
  const { device, isSessionAssignment } = props

  if (isDeviceType.ball(device.type)) {
    return (
      <HardwareBall device={device} isSessionAssignment={isSessionAssignment} />
    )
  } else if (isDeviceType.playerTag(device.type)) {
    return (
      <HardwareTag device={device} isSessionAssignment={isSessionAssignment} />
    )
  } else if (isDeviceType.anchor(device.type)) {
    return <HardwareCommon device={device} />
  } else {
    return <noscript />
  }
}
