import moment from '../../utils/moment'
import { milToFormat } from '../../utils/helpers'

export const dataTypes = {
  number: {
    display: (value) => {
      if (!value && value !== 0) return ''

      value = parseFloat(value)
      if (isNaN(value)) return ''
      return value
    },
    parse: (value) => {
      if (!value && value !== 0) return null
      value = Number(value)
      if (isNaN(value)) return null
      return value
    }
  },
  text: {
    display: (value) => {
      if (!value) return ''
      value = String(value)
      return value
    },
    parse: (value) => {
      if (!value) return null
      value = String(value)
      return value
    }
  },
  color: {
    display: (value) => {
      if (!value) return ''
      value = String(value)
      return value
    },
    parse: (value) => {
      if (!value) return null
      value = String(value)
      return value
    }
  },
  json: {
    display: (value) => {
      if (!value) return ''
      value = JSON.stringify(value)
      return value
    },
    parse: (value) => {
      if (!value) return null
      try {
        value = JSON.parse(value)
      } catch (e) {
        value = null
      }
      return value
    }
  },
  eNum: {
    display: (value, options) => {
      if (!value) return ''
      let option = options.find((option) => option.value === value)
      if (!option) return ''
      return option.name
    },
    parse: (value, options) => {
      if (!value) return null
      let option = options.find((option) => option.name === value)
      if (!option) return null
      return option.value
    }
  },
  time: {
    display: (value) => {
      if (!value) return ''
      return milToFormat(value, { colon: true })
    },
    parse: (value) => {}
  },
  date: {
    display: (value) => {
      if (!value) return ''
      return moment(value).format('HH:mm:ss')
    },
    parse: (value) => {}
  }
}
