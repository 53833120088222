import { drawCircle, drawLine } from '../2D/drawing'

export function applyAmericanFootballSettings(field, poles, dimensions) {
  field.height = dimensions.P24.y
  field.width = dimensions.P13.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P12.x - dimensions.P2.x

  field.scaledTen = field.tryLineDistance / 10
  field.edges = 3

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  poles.height = 13.72
  poles.width = 5.64
  poles.crossbarHeight = 3.05
  poles.diameter = 0.2

  field.color = 'transparent'
}
export function drawAmericanFootballLines(props, color, ctx, pixelScale, view) {
  const { field, dimensions } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  // Draw mown patches

  let numberOfVerticalPatches = 20
  let numberOfHorizontalPatches = numberOfVerticalPatches * 0.7

  let verticalPatchWidth = field.tryLineDistance / numberOfVerticalPatches
  let horizontalPatchWidth = field.height / numberOfHorizontalPatches

  let patchOrigin = dimensions.P25

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x + i * verticalPatchWidth,
        patchOrigin.y,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        verticalPatchWidth * pixelScale,
        field.height * pixelScale
      )
    }
  }

  for (let i = 0; i < numberOfHorizontalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x,
        patchOrigin.y - i * horizontalPatchWidth,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        field.tryLineDistance * pixelScale,
        horizontalPatchWidth * pixelScale
      )
    }
  }

  // Draw Borders
  ctx.beginPath()
  ctx.setLineDash([0])
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P26.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P26.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P14.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P14.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P13.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P13.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw trylines
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P2.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P2.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P25.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P25.y).scaleY
  )
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P12.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P12.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P15.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P15.y).scaleY
  )

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Drawing 5 yard markers and dashes

  let oneYardLineSpacing = field.tryLineDistance / 100

  let startingXLower = 2
  let startingXHigher = 25

  ctx.beginPath()

  for (var i = 0; i <= 9; i++) {
    let lowerPlanePoint = `P${startingXLower}`
    let higherPlanePoint = `P${startingXHigher}`

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[lowerPlanePoint].y)
        .scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[higherPlanePoint].x).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[higherPlanePoint].y)
        .scaleY
    )

    ctx.moveTo(
      getCanvasCoordinate(
        pixelScale,
        dimensions[lowerPlanePoint].x + oneYardLineSpacing * 5
      ).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[lowerPlanePoint].y)
        .scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(
        pixelScale,
        dimensions[lowerPlanePoint].x + oneYardLineSpacing * 5
      ).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[higherPlanePoint].y)
        .scaleY
    )

    startingXLower++
    startingXHigher--
  }

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Drawing 1 yard markers and dashes

  startingXLower = 2
  startingXHigher = 29

  ctx.beginPath()

  for (var i = 1; i <= 10; i++) {
    let lowerPlanePoint = `P${startingXLower}`
    let higherPlanePoint = `P${startingXHigher}`

    for (var j = 0; j <= 8; j++) {
      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, 0).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 - 6.1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 - 6.1 - 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 + 6.1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 + 6.1 + 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height - 1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          dimensions[lowerPlanePoint].x + (oneYardLineSpacing + j)
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height).scaleY
      )
    }
    startingXLower++
    startingXHigher--
  }

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw poles
  drawCircle(
    dimensions.P1.x,
    dimensions.P28.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P1.x,
    dimensions.P27.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )

  drawCircle(
    dimensions.P14.x,
    dimensions.P29.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P14.x,
    dimensions.P30.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}

export const draw2DAmericanFootballPitchNumbers = (
  field,
  dimensions,
  getCanvasCoordinate,
  canvas2DPixelScale,
  ctx
) => {
  let numberCanvasCoordPoints = [
    'P24',
    'P23',
    'P22',
    'P21',
    'P20',
    'P19',
    'P18',
    'P17',
    'P16'
  ]

  let numbers = [
    '1  0',
    '2  0',
    '3  0',
    '4  0',
    '5  0',
    '4  0',
    '3  0',
    '2  0',
    '1  0'
  ]

  let upperY = field.height - 8 - 0.5
  let lowerY = 8 - 0.5

  numberCanvasCoordPoints.forEach((point, i) => {
    const scaledCanvasCoord = getCanvasCoordinate(
      canvas2DPixelScale,
      dimensions[point].x,
      null
    )

    const scaledUpperYCoord = getCanvasCoordinate(
      canvas2DPixelScale,
      null,
      upperY
    )

    const scaledLowerYCoord = getCanvasCoordinate(
      canvas2DPixelScale,
      null,
      lowerY
    )

    ctx.textAlign = 'center'
    ctx.fillStyle = '#FFFFFF'
    ctx.strokeStyle = '#FFFFFF'
    ctx.font = '16px Mark Pro'

    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledLowerYCoord.scaleY)
    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledUpperYCoord.scaleY)
  })
}

export const drawAmericanFootballPitchNumbers = (strack, ctx) => {
  const { field, dimensions, get3DCanvasCoordinate, canvas3DPixelScale } =
    strack

  let numberCanvasCoordPoints = [
    'P24',
    'P23',
    'P22',
    'P21',
    'P20',
    'P19',
    'P18',
    'P17',
    'P16'
  ]

  let numbers = [
    '1  0',
    '2  0',
    '3  0',
    '4  0',
    '5  0',
    '4  0',
    '3  0',
    '2  0',
    '1  0'
  ]

  let upperY = field.height - 8 - 0.5
  let lowerY = 8 - 0.5

  numberCanvasCoordPoints.forEach((point, i) => {
    const scaledCanvasCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      dimensions[point].x,
      null
    )

    const scaledUpperYCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      null,
      upperY
    )

    const scaledLowerYCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      null,
      lowerY
    )

    ctx.textAlign = 'center'
    ctx.fillStyle = '#FFFFFF'
    ctx.strokeStyle = '#FFFFFF'
    ctx.font = '16px Mark Pro'

    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledLowerYCoord.scaleY)
    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledUpperYCoord.scaleY)
  })
}
