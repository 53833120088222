import React from 'react'

import TabTitle from './Title/TabTitle'
import { Tabs } from './Tabs/Tabs'
import { Options } from '../../../Options/Options'
import { Option } from '../../Views.types'

import styles from './Header.module.scss'
import { BackButton } from '../../../Icons/BackButtonIcon/BackButton'
import { SwitchTab } from '../Main'
import { MainProps, Tab } from '../Main.types'
import { ViewHeaderProps } from '../../Views.types'
import { goBack, setRedirect } from '../../../../ui/router/actions'
import { useAppDispatch } from '../../../../store/hooks'
import { exitSession } from '../../../../metrics_server/sessions/actions'

interface HeaderProps extends ViewHeaderProps {
  view: MainProps
  switchTab: SwitchTab
  selectedTabKey: string
  tabsArray: Tab[]
  options?: Option[]
}

export function Header(props: HeaderProps) {
  const dispatch = useAppDispatch()

  const {
    view,
    tabsArray,
    selectedTabKey,
    switchTab,
    ToolbarInner,
    title,
    backButton,
    options
  } = props

  return (
    <React.Fragment>
      {/* <div className={styles.toolBarContainer}>
        <TopToolBar
          backButton={
            backButton
              ? {
                  name: !!backButton.text ? backButton.text : 'Back',
                  callback: () => setRedirect(backButton.route)
                }
              : null
          }
          breadcrumbs={breadcrumbs}
          options={toolBarOptions}
        >
          {ToolbarInner && <ToolbarInner />}
        </TopToolBar>
      </div> */}
      <div className={styles.headerContainer}>
        {tabsArray.length > 0 ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {backButton && (
                <BackButton
                  scale={0.7}
                  buttonText={backButton.text ? backButton.text : 'Back'}
                  style={{ padding: '0 15px' }}
                  handleClick={() => {
                    dispatch(exitSession())
                    dispatch(
                      backButton.route
                        ? setRedirect(backButton.route)
                        : goBack()
                    )
                  }}
                />
              )}
              <div className={styles.optionsBorder}></div>
              <Tabs
                tabs={tabsArray}
                selectedTabKey={selectedTabKey}
                switchTab={switchTab}
              />
            </div>

            <TabTitle title={title ? title : view.name} />
            <div className={styles.optionsBorder}></div>
            {ToolbarInner && (
              <div style={{ padding: '0px 10px' }}>
                <ToolbarInner />
              </div>
            )}
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {backButton && (
              <BackButton
                scale={0.7}
                buttonText={backButton.text ? backButton.text : 'Back'}
                style={{ padding: '0 15px 0 15px' }}
                handleClick={() =>
                  dispatch(
                    backButton.route ? setRedirect(backButton.route) : goBack()
                  )
                }
              />
            )}
            <div className={styles.optionsBorder}></div>
            <div className={styles.title}>
              <h2>{title ? title : view.name}</h2>
            </div>
          </div>
        )}

        {/* options */}
        {!!options && options.length !== 0 && (
          <div className={styles.optionsContainer}>
            <div className={styles.optionsBorder}></div>
            <Options
              options={options}
              backgroundColor={'transparent'}
              color={'#323233'}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
