import { ADD_FLIGHT } from '../events/types'

import _ from 'lodash'
import moment from '../../utils/moment'

import {
  SessionsState,
  SessionData,
  SessionsActionType,
  RawSessionData
} from './types'

import { JOIN_TEAM } from '../teams/types'
import { EDIT_PLAYER } from '../players/types'
import { REMOVE_USER_DATA } from '../types'
import { sessionTypes } from './data_types'
import { subSessionTypes } from './sub_sessions/data_types'
import { sessionModeTypes } from './modes/data_types'
import { sportTypeKey } from '../sports'
import { isTraining } from './functions'
import { sportTypes } from '../sports/data_types'

const {
  GET_RECENT_SESSIONS,
  UPDATE_FILTER_OPTIONS,
  FETCH_FILTERED_SESSIONS,
  SET_FILTERED_SESSIONS,
  FETCH_FILTERED_SESSIONS_FAILED,
  SET_SESSION,
  EXIT_SESSION,
  SET_SETUP_SESSION,
  ADD_PLAYER_SESSION,
  UPDATE_PLAYER_SESSION,
  REMOVE_PLAYER_SESSION,
  REMOVE_BALL_FROM_SESSION,
  CREATE_SESSION,
  CREATING_SESSION,
  STOP_SESSION,
  START_SESSION,
  SET_ACTIVITY_LIST_SCROLL_HEIGHT,
  UPDATE_SESSION,
  SET_ACTIVE_SESSION,
  SET_AUTO_SLEEP,
  GET_SCOREBOARD,
  SET_SESSION_UPLOAD_STATUS,
  GET_PLAYER_SESSION,
  UPDATE_USE_CUSTOM_NAME,
  SET_CUSTOM_NAME
} = SessionsActionType

export const initialState: SessionsState = {
  // Setup form
  useCustomName: true,
  customName: '',
  rawData: {},
  selectedId: null,
  creatingSession: false,
  selected: {} as SessionData,
  filter: {
    items: [],
    options: {
      team: 'All',
      startTime:
        moment(new Date().getTime() - 2629743833)
          .startOf('day')
          .valueOf() / 1000,
      endTime:
        moment(new Date().getTime()).endOf('day').valueOf() / 1000 + 2628000,
      limitQuantity: 200,
      type: 'All',
      subType: 'All',
      searchStr: '',
      state: 'All',
      sportType: 'All'
    },
    fetching: false,
    hasValidData: false
  },
  recent: {
    items: [],
    quantity: 10
  },
  graph: {
    items: {}
  },
  activeSession: null,

  activityListScrollHeight: 0,

  autoSleepEnabled: null,

  scoreboard: {
    firstTeam: {
      scoreSum: 0,
      teamId: '0',
      teamName: ''
    },
    secondTeam: {
      scoreSum: 0,
      teamId: '0',
      teamName: ''
    }
  },
  isUserInSession: false
}

export function sessionsReducer(state = initialState, action) {
  let playersSessions
  let rawData
  switch (action.type) {
    case SET_ACTIVE_SESSION:
      return {
        ...state,
        activeSession: action.payload
      }

    case GET_RECENT_SESSIONS:
      rawData = _.keyBy(action.payload, 'id')
      return {
        ...state,
        rawData: {
          ...state.rawData,
          ...rawData
        },
        recent: {
          ...state.recent,
          items: action.payload
        }
      }
    case UPDATE_FILTER_OPTIONS:
      return {
        ...state,
        filter: {
          ...state.filter,
          options: { ...state.filter.options, ...action.payload }
        }
      }
    case FETCH_FILTERED_SESSIONS:
      return {
        ...state,
        filter: {
          ...state.filter,
          options: { ...state.filter.options, ...action.payload },
          fetching: true
        }
      }
    case SET_FILTERED_SESSIONS:
      rawData = _.keyBy(action.payload.data, 'id')
      return {
        ...state,
        rawData: {
          ...state.rawData,
          ...rawData
        },
        filter: {
          ...state.filter,
          items: action.payload.data,
          fetching: false,
          hasValidData: true
        }
      }
    case FETCH_FILTERED_SESSIONS_FAILED:
      return {
        ...state,
        filter: {
          ...state.filter,
          items: [],
          fetching: false
        }
      }

    case SET_SESSION:
      playersSessions = action.payload.playersSessions
      return {
        ...state,
        selectedId: action.payload.id,
        selected: {
          ...action.payload,
          flights: [],
          playersSessions: playersSessions || []
        },
        isUserInSession: true
      }
    case EXIT_SESSION:
      return {
        ...state,
        isUserInSession: false
      }
    case SET_SETUP_SESSION:
      playersSessions = action.payload.playersSessions
      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            ...action.payload
          }
        }
      }

    case JOIN_TEAM:
      return {
        ...state,
        filter: {
          ...state.filter,
          hasValidData: false
        }
      }

    case GET_PLAYER_SESSION:
      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            playersSessions: action.payload.data || []
          }
        }
      }
    case ADD_PLAYER_SESSION:
      if (action.payload.playerId) {
        const selectedSession = state.selected
        const trainingMode = isTraining(selectedSession.type)
        const isRugby = sportTypes.isType(
          'rugbyUnion',
          selectedSession.sportType
        )
        playersSessions = state.rawData.setup.playersSessions
          // Remove previous player assignment
          .filter((x) => {
            if (x.playerId === action.payload.playerId) {
              return false
            } else if (
              x.number === action.payload.number &&
              x.teamId === action.payload.teamId &&
              !trainingMode &&
              isRugby
            ) {
              return false
            }
            return true
          })
          // Remove previous tag assignment
          .map((playerSession) => {
            const x = { ...playerSession }
            if (
              x.playerId &&
              x.tag &&
              action.payload.tag &&
              x.tag.id === action.payload.tag.id
            ) {
              delete x.tag
              delete x.id
            }
            return x
          })
      } else if (action.payload.tag) {
        // Remove previous / duplicate ball assignment
        playersSessions = state.rawData.setup.playersSessions.filter((x) => {
          if (!x.playerId && x.tag && x.tag.id === action.payload.tag.id) {
            return false
          }
          return true
        })
      }

      if (!playersSessions) {
        playersSessions = []
        console.error('playersSessions is undefined', action.payload)
      }

      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            playersSessions: [...playersSessions, action.payload]
          }
        }
      }

    case UPDATE_PLAYER_SESSION:
      let updatedPlayerSessions = state.rawData.setup.playersSessions.map(
        (playerSession) => {
          let x = { ...playerSession }
          if (
            (x.number === action.payload.number ||
              x.playerId === action.payload.playerId) &&
            x.teamId === action.payload.teamId
          ) {
            // Update player session
            x = { ...x, ...action.payload.data }
            // make sure x.number is a number
          } else if (
            x.tag &&
            action.payload.data.tag &&
            x.tag.id === action.payload.data.tag.id
          ) {
            // Remove previous tag assignment
            delete x.tag
          }
          delete x.id
          return x
        }
      )

      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            playersSessions: updatedPlayerSessions
          }
        }
      }

    case REMOVE_PLAYER_SESSION:
      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            playersSessions: state.rawData.setup.playersSessions.filter((x) => {
              return x.playerId !== action.payload
            })
          }
        }
      }

    case REMOVE_BALL_FROM_SESSION:
      return {
        ...state,
        rawData: {
          ...state.rawData,
          setup: {
            ...state.rawData.setup,
            playersSessions: state.rawData.setup.playersSessions.filter((x) => {
              if (!x.tag) return true
              return x.tag.id !== action.payload
            })
          }
        }
      }

    case CREATE_SESSION:
      return {
        ...state,
        selected: {
          ...action.payload,
          flights: []
        },
        filter: {
          ...state.filter,
          hasValidData: false
        }
      }

    case CREATING_SESSION:
      return {
        ...state,
        creatingSession: action.payload
      }

    case START_SESSION:
      return {
        ...state,
        filter: {
          ...state.filter,
          hasValidData: false
        }
      }

    case STOP_SESSION:
      return {
        ...state,
        filter: {
          ...state.filter,
          hasValidData: false
        }
      }

    case UPDATE_SESSION:
      let selected = { ...state.selected, ...action.payload }
      // if (selected.id === action.payload.id) selected = {...selected, ...action.payload}
      return {
        ...state,
        rawData: {
          ...state.rawData,
          [action.payload.id || 'setup']: {
            ...state.rawData[action.payload.id || 'setup'],
            ...action.payload
          }
        },
        filter: {
          ...state.filter,
          items: state.filter.items.map((x) => {
            if (x.id === action.payload.id) return { ...x, ...action.payload }
            return x
          })
        },
        recent: {
          ...state.recent,
          items: state.recent.items.map((x) => {
            if (x.id === action.payload.id) return { ...x, ...action.payload }
            return x
          })
        },
        selected
      }

    case SET_AUTO_SLEEP:
      return {
        ...state,
        autoSleepEnabled: action.payload
      }

    case SET_ACTIVITY_LIST_SCROLL_HEIGHT:
      return {
        ...state,
        activityListScrollHeight: action.payload
      }
    case GET_SCOREBOARD:
      return {
        ...state,
        scoreboard: action.payload
      }
    case SET_SESSION_UPLOAD_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          items: state.filter.items.map((x) => {
            if (x.id === action.payload.id)
              return { ...x, uploadStatus: action.payload.data }
            return x
          })
        }
      }
    // case UPLOADING_SESSION:
    //   return {
    //     ...state,
    //     filter: {
    //       ...state.filter,
    //       items: state.filter.items.map((x) => {
    //         if (x.id === action.payload.id) return { ...x, uploadingSession: action.payload }
    //         return x
    //       })
    //   }
    // }
    //

    // Set custom name for session form //
    case SET_CUSTOM_NAME:
      return {
        ...state,
        customName: action.payload
      }
    case UPDATE_USE_CUSTOM_NAME:
      return {
        ...state,
        useCustomName: action.payload
      }

    // =============================== //

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
