import React, { useEffect, useRef, useState } from 'react'
import { Action, ActionCreator } from 'redux'
import { CardList } from '../../../components/CardList/CardList'
import { CanvasStyle, Strack } from '../../../components/Strack/Strack.types'
import { StrackAnchors } from '../../../components/Strack/StrackAnchors/StrackAnchors'
import { StrackCenterLogo } from '../../../components/Strack/StrackOverlays/CenterLogo/CenterLogo'
import { StrackOverlay } from '../../../components/Strack/StrackOverlays/StrackOverlay'
import { Table } from '../../../components/Table/Table'
import { WS_ROOT_URL } from '../../../const'
import { HardwareState } from '../../../metrics_server/hardware/types'
import {
  Anchor,
  Anchors,
  PitchesState
} from '../../../metrics_server/pitches/types'
import { Tag } from '../../../metrics_server/sessions/types'
import WebSocketController from '../../../utils/websockets.js'
import styles from './AnchorHealth.module.scss'

export type tagPacketData = {
  offlineTags: { [key: number]: Tag }
  onlineTags: { [key: number]: Tag }
  selected: number
}

export interface AnchorHealthProps {
  strack: Strack
  strackReady: boolean
  canvasStyle: CanvasStyle
  active: boolean
  anchorConfig: Anchors

  setOptions: ActionCreator<Action>

  selectedAnchors: Anchor[]
  hardware: HardwareState
  updateSelectedTag: ActionCreator<Action>
  toggleAnchor: (arg1?: string) => void
  pitches: PitchesState
  fetchingPitch: boolean

  tagPacketData: tagPacketData
  updateOnlineTags: ActionCreator<Action>
  updateOfflineTags: ActionCreator<Action>
}

let tagCheck
let tagChecks = {}

export function AnchorHealth({
  strack,
  strackReady,
  canvasStyle,
  active,
  anchorConfig,

  setOptions,

  selectedAnchors,
  hardware,
  updateSelectedTag,
  toggleAnchor,
  pitches,
  fetchingPitch,

  tagPacketData,
  updateOnlineTags,
  updateOfflineTags
}: AnchorHealthProps) {
  const ref = useRef(null)

  // Listen to raw websocket and update strack buffer once strack has been successfully initiated
  useEffect(() => {
    if (strackReady) {
      const onlineTags = {},
        offlineTags = {}

      strack.initiateEventsCanvas(() => {
        strack.events.eventHandleCanvas.addEventListener('click', (e) => {
          strack.checkForTagOnClick(e, (tagId) => {
            if (tagId) updateSelectedTag(tagId)

            // setShowTagInfo(tagId)
          })
        })
      })

      WebSocketController.connectWebSocket(
        WS_ROOT_URL,
        'raw',
        (data) => {
          if (!onlineTags[data.id]) {
            delete offlineTags[data.id]
          }
          onlineTags[data.id] = data
          const timeNow = new Date().getTime() / 1000
          onlineTags[data.id].packetArrivalTime = timeNow

          // Update strack buffer for tracking
          strack.updateBuffer(data)
        },
        'anchorHealth'
      )

      tagCheck = setInterval(() => {
        for (var tagId in onlineTags) {
          const timeNow = new Date().getTime() / 1000
          const tag = onlineTags[tagId]
          if (timeNow - tag.packetArrivalTime > 5) {
            offlineTags[tag.id] = tag
            delete onlineTags[tag.id]
          }
        }

        tagChecks[tagCheck] = { tagCheck }

        updateOnlineTags(onlineTags)
        // updateOfflineTags(offlineTags)
      }, 2000)
    } else if (!strackReady) {
      WebSocketController.disconnectWebSocket('raw', 'anchorHealth')
    }

    return () => {
      WebSocketController.disconnectWebSocket('raw', 'anchorHealth')

      clearInterval(tagCheck)
      delete tagChecks[tagCheck]
    }
  }, [strackReady])

  // Update selected acnhors in strack
  useEffect(() => {
    if (strackReady) {
      strack.diags.selectedAnchors = selectedAnchors
    }
  }, [selectedAnchors, strackReady])

  useEffect(() => {}, [fetchingPitch])

  useEffect(() => {
    if (strackReady) {
      let { onlineTags, selected } = tagPacketData
      strack.diags.selectedTag = onlineTags[selected] || {}
    }
  }, [tagPacketData.selected, strackReady])

  const [selectedTag, setSelectedTag] = useState(
    tagPacketData.onlineTags[tagPacketData.selected]
  )

  useEffect(() => {
    if (strackReady && selectedTag) {
      selectedTag
        ? strack.setSelectedTag(selectedTag.id)
        : strack.setSelectedTag(null)
    }
  }, [selectedTag, strackReady])

  useEffect(() => {
    setSelectedTag(tagPacketData.onlineTags[tagPacketData.selected])
  }, [tagPacketData])

  const getAnchorTableData = (
    anchors,
    selectedAnchors,
    selectedTag,
    hardware
  ) => {
    let anchorTableData = []
    for (let anchorId in anchors) {
      let anchor = anchors[anchorId]
      let row = {
        id: parseInt(anchorId),
        encodedId: anchor.encodedId,
        selected: selectedAnchors.some((x) => x.id == anchorId),
        index: anchor.index,
        dist: null,
        rssi: null
      }

      // Set tag data
      if (selectedTag) {
        let ranges = selectedTag.ranges
        if (ranges) {
          let anchor = selectedTag.ranges.find((o) => o.source == anchorId)

          if (anchor) {
            row.dist = anchor.range.toFixed(1) || '-'
            row.rssi = anchor.rssi.toFixed(1) || '-'
          }
        }
      }

      if (hardware.diagnostics.hardwareIndex.indexOf(row.id) >= 0)
        anchorTableData.push(row)
    }
    return anchorTableData
  }

  const [anchorTableData, setAnchorTableData] = useState(
    getAnchorTableData(
      pitches.inUse.anchors,
      selectedAnchors,
      selectedTag,
      hardware
    )
  )

  // Update anchor table data
  useEffect(() => {
    setAnchorTableData(
      getAnchorTableData(
        pitches.inUse.anchors,
        selectedAnchors,
        selectedTag,
        hardware
      )
    )
  }, [
    selectedTag,
    pitches.inUse.anchors,
    selectedAnchors,
    hardware.diagnostics.hardwareIndex
  ])

  const getTagTableData = (onlineTags, selectedTag, hardware) => {
    const tagTableData = []
    for (const tagId in onlineTags) {
      const tag = onlineTags[tagId]

      const index = hardware.diagnostics.hardwareIndex.indexOf(parseInt(tagId))

      let acc =
        hardware.diagnostics.hardwareState.devices[tagId]?.accStdDeviation

      if (typeof acc === 'number') acc = acc.toString()

      let imuLenDisplay = '-'
      let imuLen = 0
      if (index >= 0) {
        imuLen = hardware.diagnostics.imuLenMatrix[index][index]
      }

      if (typeof imuLen === 'number') imuLenDisplay = imuLen.toFixed(1)

      const row = {
        id: tag.id,
        encodedId: tag.encodedId,
        measLength: tag.ranges ? tag.ranges.length : '-',
        imu: imuLenDisplay,
        acc: acc,
        selected: selectedTag ? tag.id === selectedTag.id : false
      }

      tagTableData.push(row)
    }

    return tagTableData
  }

  const masterOptions = Object.values(pitches.inUse.anchors).map((anchor) => {
    return { name: anchor.encodedId, value: anchor.id }
  })
  masterOptions.unshift({ name: 'Select', value: 'none' })

  // Generate anchor array
  let { status, hardwareIndex, encodedHardwareIndex, hardwareState } =
    hardware.diagnostics
  let anchorsArray = []
  function addAnchor(anchorId, isOnline) {
    let index = hardwareIndex.indexOf(anchorId)
    let anchor = {
      online: isOnline,
      id: anchorId,
      encodedId: encodedHardwareIndex[index],
      batt: null
    }
    let device = hardwareState.devices[anchorId]
    if (device) {
      anchor.batt = device.slowSensors.battery
    }
    anchorsArray.push(anchor)
  }
  status.online.anchors.forEach((anchorId) => addAnchor(anchorId, true))
  status.offline.anchors.forEach((anchorId) => addAnchor(anchorId, false))

  // if (!active) return null;

  return (
    <div ref={ref} className={styles.pitchViewContainer}>
      <div className={styles.topTableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <Table
              id={'anchors'}
              options={{
                initialOrder: 'asc',
                initialSortBy: 'index',
                sortActive: true
              }}
              headerFont={13}
              tableClass={'minimalistBlack'}
              className={'container'}
              data={anchorTableData}
              headers={[
                { name: 'Anchor ID', key: 'encodedId', width: 20 },
                { name: 'Dist', key: 'dist', width: 34 },
                { name: 'RSSI', key: 'rssi', width: 34 },
                {
                  name: '',
                  key: 'selected',
                  width: 12,
                  input: {
                    type: 'checkbox',
                    onChange: (item, value) => {
                      toggleAnchor(item.id)
                    }
                  }
                }
              ]}
              controls={[
                { name: 'Clear all', callback: () => toggleAnchor() },
                { name: 'Select all', callback: () => toggleAnchor('all') }
              ]}
            />
          </div>
        </CardList>
      </div>
      <div className={styles.bottomRightTableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${2}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <Table
              options={{
                initialOrder: 'asc',
                initialSortBy: 'id',
                sortActive: true
              }}
              headerFont={13}
              tableClass={'minimalistBlack'}
              className={'container'}
              data={getTagTableData(
                tagPacketData.onlineTags,
                selectedTag,
                hardware
              )}
              headers={[
                { name: 'Tag ID', key: 'encodedId', width: 20 },
                { name: 'Meas Len', key: 'measLength', width: 20 },
                { name: 'IMU Rate', key: 'imu', width: 20 },
                { name: 'Acc Std Dev', key: 'acc', width: 28 },
                {
                  name: '',
                  key: 'selected',
                  width: 12,
                  input: {
                    type: 'checkbox',
                    onChange: (item, value) => {
                      updateSelectedTag(item.id)
                    }
                  }
                }
              ]}
            />
          </div>
        </CardList>
      </div>
    </div>
  )
}
