import { JSONValue } from 'vanilla-jsoneditor'
import { AmericanFootballPitch } from '../sports/american_football/pitch'
import { BoxingPitch } from '../sports/boxing/pitch'
import { SportTypeValues } from '../sports/data_types'
import { RugbyLeaguePitch } from '../sports/rugby_league/pitch'
import { RugbyUnionPitch } from '../sports/rugby_union/pitch'

export type PitchTypes =
  | RugbyLeaguePitch
  | RugbyUnionPitch
  | BoxingPitch
  | AmericanFootballPitch

export interface PitchesState {
  items: {
    [id: string]: PitchTypes
  }
  pitchType: number
  inUse: PitchTypes
  side: number
  master: number | string
  anchorBump: number
  autoLocateActive: boolean
  isAutoSetup: boolean
  setupTagList: number[]
  pitchJson: string
  anchorJson: string
  setupType: number
}

export type RawPitchData = {
  id: string
  type: SportTypeValues
  pitch: PitchDimensions
  name: string
  arcs: PitchArcs
  anchors: Anchors
}

export interface Coordinate {
  x: number
  y: number
  z: number
}

export interface Anchors {
  [id: number]: Anchor
}

export interface Pitch {
  id: string
  type: SportTypeValues
  pitch: PitchDimensions
  name: string
  arcs: PitchArcs
  anchors: Anchors
}

export interface PitchDimensions {
  length: number
  width: number
  poles?: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: PitchCoordinates
}

export interface PitchCoordinates {
  [point: string]: Coordinate
}

export interface PitchArc {
  a: number
  b: number
  c: number
  d: number
  end: string
  name: string
  start: string
}

export type PitchArcs = PitchArc[]

export interface Anchor {
  id: number
  serial: string
  pos: Coordinate
  index: number
}

// Pitch Form + Setup //

export interface PitchFile {
  selectedPitchId: number
}

export interface CSVFile {
  name: string

  anchorValue: string
  anchorFile: string
  anchorExtension: string
  anchorFileWithoutIgnored: Blob
  anchorJson: Blob

  pitchValue: string
  pitchFile: string | null
  pitchExtension: string
  pitchFileWithoutIgnored: Blob
  pitchJson: Blob

  save: boolean
}

export interface JSONUploadState {
  coordinateJSON?: JSONValue
  anchorJSON?: JSONValue
}

export interface Base64Result {
  type: string
  base64: string | ArrayBuffer
}

// Action types
export const GET_PITCHES = 'GET_PITCHES'
export const SET_PITCH_IN_USE = 'SET_PITCH_IN_USE'
export const UPDATE_PITCH_SIDE = 'UPDATE_PITCH_SIDE'
export const UPDATE_MASTER = 'UPDATE_MASTER'
export const UPDATE_ANCHOR_BUMP = 'UPDATE_ANCHOR_BUMP'
export const TOGGLE_AUTO_SETUP = 'TOGGLE_AUTO_SETUP'
export const UPDATE_PITCH_SETUP_TAG_LIST = 'UPDATE_PITCH_SETUP_TAG_LIST'
export const SET_AUTO_LOCATE_ACTIVE = 'SET_AUTO_LOCATE_ACTIVE'
export const UPDATE_PITCH_SETUP_TYPE = 'UPDATE_PITCH_SETUP_TYPE'
