import React, { useMemo } from 'react'
import { TeamLogo } from '../TeamLogo/TeamLogo'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { Chip } from '@mui/material'

export type ImageType = {
  logo: string
  color: string
}
export interface SearchableDropdownItem {
  name: string
  id?: string | number
  image?: ImageType
  value: string | number
}
export interface SearchableDropdownProps {
  items: SearchableDropdownItem[]
  filterName?: string
  value: string | number | (string | number)[]
  label: string
  disabledValue?: string
  isMultiple: boolean
  inputVariant?: 'filled' | 'outlined' | 'standard'
  onSelect: (value, filterName?) => void
  fontSize?: number
  logoSize?: number
  minWidth?: number
  noOptionsText?: string
  noOptionsButtonText?: string
  labelShrink?: boolean
  noOptionsButtonHandler?: () => void
  size?: 'small' | 'medium'
  error?: boolean
  errorMessage?: string
}

export const SearchableDropdown = ({
  items,
  value,
  label,
  size,
  disabledValue,
  isMultiple,
  filterName,
  inputVariant,
  fontSize,
  logoSize,
  onSelect,
  minWidth,
  noOptionsText,
  noOptionsButtonText,
  labelShrink,
  noOptionsButtonHandler,
  error,
  errorMessage
}: SearchableDropdownProps) => {
  // const renderLogo = (
  //   option: SearchableDropdownItem,
  //   isAdornement?: boolean
  // ) => {
  //   if (!option) return null

  //   if (!!option.logo) {
  //     return null
  //   }

  //   if (option.logo !== undefined && option.logo) {
  //     return <TeamLogo logo={option.logo} size={30} />
  //   }

  //   if (option.image !== undefined && option.image) {
  //     if (isAdornement) {
  //       return (
  //         <InputAdornment position='start'>
  //           <TeamLogo
  //             logo={selectedValue.image.logo}
  //             color={selectedValue.image.color}
  //             size={30}
  //           />
  //         </InputAdornment>
  //       )
  //     }
  //     return (
  //       <TeamLogo
  //         logo={option.image.logo}
  //         color={option.image.color}
  //         size={30}
  //       />
  //     )
  //   }
  // }
  // Define a type guard to check if value is of type SearchableDropdownItem
  function isDropdownItem(item: any): item is SearchableDropdownItem {
    return 'name' in item && 'id' in item && 'value' in item
  }

  const selectedOption = useMemo(() => {
    return items.find((item) => item.value === value)
  }, [value, items])

  return (
    <Box sx={{ width: '100%', minWidth: minWidth }}>
      <Autocomplete
        multiple={isMultiple}
        isOptionEqualToValue={(option, value) => {
          if (!value || !option) {
            console.log(value, option, '=============', label)
            return false
          }
          return option.value === value.value
        }}
        id='combo-box-demo'
        size={size}
        options={items}
        getOptionLabel={(option: SearchableDropdownItem) =>
          typeof option === 'string' ? option : option.name
        }
        // value is an array when isMultiple is true and an Object when it's not
        // this affects how renderInput, renderTags and getOptionsDisabled work
        value={selectedOption}
        onChange={(e, selectedOption: SearchableDropdownItem) => {
          if (selectedOption) {
            if (Array.isArray(selectedOption)) {
              onSelect(selectedOption.map((option) => option.value))
            } else {
              onSelect(selectedOption.value)
            }
          }
        }}
        sx={{
          '& label': { fontSize: fontSize, top: 4 }
        }}
        renderOption={(props, option: SearchableDropdownItem) => (
          <Box
            component='li'
            sx={{ display: 'flex', gap: 2 }}
            {...props}
            key={option.value}
          >
            {option.image ? (
              <TeamLogo
                logo={option.image.logo}
                color={option.image.color}
                size={30}
              />
            ) : (
              ''
            )}
            {option.name}
          </Box>
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) =>
            filterName ? (
              <div
                key={option.value}
                style={{ fontSize: '8px', marginLeft: '2px' }}
              >
                {option.name},
              </div>
            ) : (
              <Chip
                key={option.value}
                label={option.name}
                {...getTagProps({ index })}
                avatar={
                  option.image ? (
                    <TeamLogo
                      logo={option.image.logo}
                      color={option.image.color}
                      size={30}
                    />
                  ) : null
                }
                variant='outlined'
                sx={{
                  padding: '20px',
                  '& .MuiChip-label': {
                    paddingLeft: 0
                  },
                  '& .MuiChip-deleteIcon': {
                    marginRight: 0,
                    marginLeft: '10px'
                  }
                }}
              />
            )
          )
        }}
        noOptionsText={
          noOptionsButtonHandler ? (
            <Button
              variant='contained'
              endIcon={<AddCircleOutlineOutlinedIcon sx={{ color: 'white' }} />}
              onClick={() => noOptionsButtonHandler()}
            >
              {noOptionsButtonText || 'Add'}
            </Button>
          ) : noOptionsText ? (
            noOptionsText
          ) : (
            'Not Found'
          )
        }
        getOptionDisabled={(option: SearchableDropdownItem) => {
          if (!option) {
            console.error(option, '=============', label, items)
            return false
          }
          if (disabledValue && option.id === disabledValue) {
            return true
          }
          if (
            disabledValue &&
            isMultiple &&
            selectedOption &&
            Array.isArray(selectedOption) &&
            selectedOption.some((val) => val.id === option.id)
          ) {
            return true
          }
          return false
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              variant={inputVariant}
              InputLabelProps={{ shrink: labelShrink }}
              error={error} // Set error state
              helperText={errorMessage} // Display the error text
              InputProps={
                isMultiple || filterName
                  ? { ...params.InputProps, style: { fontSize: fontSize } }
                  : {
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          {selectedOption?.image ? (
                            <TeamLogo
                              logo={selectedOption.image.logo}
                              color={selectedOption.image.color}
                              size={logoSize ? logoSize : 30}
                            />
                          ) : null}
                        </InputAdornment>
                      ),
                      style: { fontSize: fontSize }
                    }
              }
            />
          )
        }}
      />
    </Box>
  )
}
