import _ from 'lodash'

export const calculateOffsetAndScale = (
  field,
  canvasWidth,
  canvasHeight,
  fieldWidth,
  fieldHeight,
  anchorSetup,
  minOffset = 10
) => {
  // Set larger offset for diagnostics so anchors are visible
  // calculate offsets and scale depending on aspect ratio and anchor position

  const sectionRatio = canvasWidth / canvasHeight
  let pitchRatio = fieldWidth / fieldHeight

  const minOffsetX = minOffset
  const minOffsetY = minOffset

  let outerAnchorCoords = { xAnchor: 0, yAnchor: 0 }

  const pitchOffsetCalculator = (minX, minY, xAnchor = 0, yAnchor = 0) => {
    let offsetX, offsetY, scale
    if (minX) {
      scale = (canvasWidth - minX * 2) / (fieldWidth + xAnchor * 2)
      offsetX = minX / scale + xAnchor
      offsetY =
        (2 * offsetX * canvasHeight +
          canvasHeight * fieldWidth -
          canvasWidth * fieldHeight) /
        (canvasWidth * 2)
    } else {
      scale = (canvasHeight - minY * 2) / (fieldHeight + yAnchor * 2)
      offsetY = minY / scale + yAnchor
      // Set Scale
      offsetX = canvasWidth / 2 / scale - fieldWidth / 2
    }
    return { offsetX, offsetY, scale }
  }

  let offsetX, offsetY, scale

  if (anchorSetup.active && !_.isEmpty(anchorSetup.anchorConfig)) {
    outerAnchorCoords = getFurthestAnchorsFromSidelines(
      field,
      anchorSetup.anchorConfig,
      fieldWidth,
      fieldHeight
    )
    const { xAnchor, yAnchor } = outerAnchorCoords
    pitchRatio = (xAnchor + fieldWidth / 2) / (yAnchor + fieldHeight / 2)
  }
  console.log(sectionRatio, pitchRatio)
  if (sectionRatio < pitchRatio) {
    const o = pitchOffsetCalculator(
      minOffsetX,
      null,
      outerAnchorCoords.xAnchor,
      null
    )
    offsetX = o.offsetX
    offsetY = o.offsetY
    scale = o.scale
  } else {
    const o = pitchOffsetCalculator(
      null,
      minOffsetY,
      null,
      outerAnchorCoords.yAnchor
    )
    offsetX = o.offsetX
    offsetY = o.offsetY
    scale = o.scale
  }
  return {
    offsetX,
    offsetY,
    scale
  }
}

const getFurthestAnchorsFromSidelines = (
  field,
  anchorConfig,
  fieldWidth,
  fieldHeight
) => {
  let xAnchor = 0,
    yAnchor = 0

  for (const key in anchorConfig) {
    const { pos } = anchorConfig[key]
    const x = Math.abs(pos.x - field.originOffsetX) - fieldWidth / 2,
      y = Math.abs(pos.y - field.originOffsetY) - fieldHeight / 2
    if (x > xAnchor) {
      xAnchor = x
    }
    if (y > yAnchor) {
      yAnchor = y
    }
  }

  return { xAnchor, yAnchor }
}
