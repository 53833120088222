// /* eslint-disable */
// uses tailwindcss classes
// @ts-nocheck

import React, { useEffect, useState, useMemo } from 'react'
import { Action, ActionCreator } from 'redux'
import { HardwareState } from '../../../metrics_server/hardware/types'
import { PitchesState } from '../../../metrics_server/pitches/types'
import { sportableColors } from '../../../constants/sportableColors'
import { tagPacketData } from '../AnchorHealth/AnchorHealth'
import { distance3D } from '../../../utils/helpers'
import * as Sentry from '@sentry/react'
import { environment } from '../../../metrics_server/env'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'

export interface CFLHardwareProps {
  active: boolean
  selectedTabKey: string
  updateActiveTab: (tabName: string) => void
  hardware: HardwareState
  pitches: PitchesState

  tagPacketData: tagPacketData
  updateOnlineTags: ActionCreator<Action>
}

// Added these to prevent sentry from being spammed with errors
// Currently set so sentry exception is only captured once per app load
// Remove capturedException to fire once per tag
const missingTagInfo = {}
let capturedException = false

// TODO: Move to functions file or hardware hook
const getAnchorMatrix = (hardware: HardwareState, pitches, version) => {
  const { inUse } = pitches

  let masterId, masterIndex, masterInPitchSetup

  // find master and set id, index and inPitchSetup
  for (const hardwareId in hardware.diagnostics.hardwareState.devices) {
    const dev = hardware.diagnostics.hardwareState.devices[hardwareId]
    if (dev && dev.slowSensors.uwbRole === 'uwbMain') {
      masterId = dev.id
      masterIndex = hardware.diagnostics.hardwareIndex.indexOf(masterId)
      masterInPitchSetup = inUse.anchors[masterId]
    }
  }

  const data = hardware.diagnostics.hardwareIndex.map((x, i) => {
    const item = {
      index: null,
      anchor: i <= hardware.diagnostics.finalAnchorIndex,
      id: hardware.diagnostics.encodedHardwareIndex[i]
    }

    hardware.diagnostics.hardwareIndex.forEach((y, j) => {
      const device = hardware.diagnostics.hardwareState.devices[y]

      let rssiValue = hardware.diagnostics.rssiMatrix[i][j]

      if (i === masterIndex && rssiValue === -105 && device) {
        rssiValue = device.masterRSSI
      }

      try {
        if (device.type === 'Anchor') {
          const anchorInPitchSetup = inUse.anchors[y]
          const uwbDistance =
            hardware.diagnostics.distanceMatrix[j]?.[masterIndex]
          let positionHealth, positionHealthColor

          if (anchorInPitchSetup && masterInPitchSetup) {
            const distanceToMaster = distance3D(
              anchorInPitchSetup.pos,
              masterInPitchSetup.pos
            )
            positionHealth = Math.abs(distanceToMaster - uwbDistance).toFixed(2)

            if (positionHealth <= 0.3) {
              positionHealthColor = 'rgba(71, 206, 71, 1)'
            } else if (positionHealth <= 1) {
              positionHealthColor = 'rgba(254, 126, 3, 1)'
            } else if (positionHealth > 1) {
              positionHealthColor = 'rgba(255, 0, 0, 1)'
            }
          }
          if (x === y) {
            item[y] = {
              imuLen: `${hardware.diagnostics.imuLenMatrix[i][j]}`,
              rssi: rssiValue,
              master: device.slowSensors.uwbRole === 'uwbMain'
            }
          } else {
            item[y] = {
              count: `${hardware.diagnostics.countMatrix[i][j]}`,
              rssi: rssiValue,
              distance: `${hardware.diagnostics.distanceMatrix[i][j]}`,
              serial: hardware.diagnostics.encodedHardwareIndex[j],
              positionQuality:
                hardware.diagnostics.hardwareState?.devices[y]?.dataMetrics
                  ?.positionQuality?.percentage,
              master: device.slowSensors.uwbRole === 'uwbMain',
              id: device.id,
              positionHealth,
              positionHealthColor
              // deamonStats:
              //   hardware.diagnostics.hardwareState?.devices[y]?.dataMetrics
              //     ?.daemonStats
            }
          }
          if (hardware.diagnostics.countMatrix[i][j] <= 10) {
            item[y].color = 'rgba(255, 0, 0, 1)'
          } else if (hardware.diagnostics.countMatrix[i][j] >= 16) {
            item[y].color = 'rgba(71, 206, 71, 1)'
          } else {
            item[y].color = 'rgba(254, 126, 3, 1)'
          }
        }
      } catch (error) {
        if (!missingTagInfo[y] && !capturedException) {
          console.log(
            `no device found in hardware state for: ${y}. Capturing error`
          )
          Sentry.captureException(
            `no device found in hardware state for: ${y}`,
            {
              tags: {
                LocationName: version.app?.LocationName,
                Product: version.app?.Product,
                Environment: environment.ENV,
                Deployment: environment.deployment
              }
            }
          )
          capturedException = true
          missingTagInfo[y] = true
        }
      }
    })
    return {
      ...item
    }
  })

  // filter out non-anchors
  const filteredAnchorMatrix = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value.anchor !== false)
  )

  return filteredAnchorMatrix
}

// TODO: adding type to prop here causes an issue when setting the initial tabs
export const CFLHardware = (props: any) => {
  const { active, hardware, pitches, version } = props

  const formattedHardware = useFormattedHardware()

  const [anchorMatrix, setAnchorMatrix] = useState(
    getAnchorMatrix(hardware, pitches, version)
  )

  // const deviceCategorized = useHardwareDevice(
  //   hardware.diagnostics.hardwareState.devices
  // )

  useEffect(() => {
    setAnchorMatrix(getAnchorMatrix(hardware, pitches, version))
  }, [hardware, pitches])

  const getTagAndBallsData = (hardware) => {
    const onlineTagsAndBalls = [
      ...hardware.diagnostics.status.online.balls,
      ...hardware.diagnostics.status.online.tags
    ]

    const deviceData = []
    for (const tagId in hardware.diagnostics.hardwareState.devices) {
      // if(!onlineTagsAndBalls.includes(parseInt(tagId))) continue
      if (onlineTagsAndBalls.includes(parseInt(tagId))) {
        const tag = hardware.diagnostics.hardwareState.devices[tagId]
        const index = hardware.diagnostics.hardwareIndex.indexOf(
          parseInt(tagId)
        )

        let acc = Number(
          hardware.diagnostics.hardwareState.devices[tagId]?.accStdDeviation
        ).toFixed(2)

        const location =
          hardware.diagnostics.hardwareState.devices[tagId]?.dataMetrics
            .location

        const positionDataRate =
          hardware.diagnostics.hardwareState.devices[tagId]?.positionDataRate

        if (typeof acc === 'number') acc = acc.toString()

        let imuLenDisplay = '-'
        let imuLen = 0
        if (index >= 0) {
          imuLen = hardware.diagnostics.imuLenMatrix[index][index]
        }

        if (typeof imuLen === 'number') imuLenDisplay = imuLen.toFixed(1)

        const device = {
          id: tag.id,
          type: tag.type,
          location: location,
          encodedId: tag.serial,
          measLength: tag.ranges ? tag.ranges.length : '-',
          imu: imuLenDisplay,
          acc: acc,
          positionDataRate
        }
        if (positionDataRate <= 10) {
          device.color = 'rgba(255, 0, 0, 1)'
        } else if (positionDataRate >= 16) {
          device.color = 'rgba(71, 206, 71, 1)'
        } else {
          device.color = 'rgba(254, 126, 3, 1)'
        }

        deviceData.push(device)
      }
    }

    const categorized = {
      Ball: [],
      PlayerTag: []
    }

    deviceData.forEach((device) => {
      if (device.type === 'Ball') {
        categorized.Ball.push(device)
      } else if (device.type === 'PlayerTag') {
        categorized.PlayerTag.push(device)
      }
    })

    return categorized
  }

  const ballsAndTags = getTagAndBallsData(hardware)

  if (!active) return null

  return (
    <div
      className={`overflow-y-auto h-[calc(100vh-150px)] pt-2 p-4 scroll-m-1 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent scrollbar-rounded-thumb`}
    >
      <AnchorMatrixRow data={anchorMatrix} hardware={hardware} />
      <BallRow data={ballsAndTags['Ball']} hardware={hardware} />
      <PlayerTagRow data={ballsAndTags['PlayerTag']} hardware={hardware} />
    </div>
  )
}

const AnchorMatrixRow = ({ data, hardware }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState('0')

  // Create a mapping of full names to abbreviations
  // const abbreviateKey = (key) => {
  //   const mapping = {
  //     imuCount: 'IMUCt.',
  //     uwbCount: 'UWBCt.',
  //     imuDrops: 'IMUDr.',
  //     uwbDrops: 'UWBDr.',
  //     imuRepeats: 'IMURp.',
  //     rangeRepeats: 'RngRp.',
  //     rangeCount: 'RngCt.',
  //     rangeImuCount: 'R-IMUCt.',
  //     rangeLockCount: 'R-LkCt.',
  //     avgAlgorithmTime: 'Alg.Time'
  //   }

  //   return mapping[key] || key
  // }

  // check if data is empty object

  const selectedRowData = data[selectedRowIndex]

  if (
    hardware.diagnostics.status.online.anchors.length === 0 ||
    selectedRowData === undefined
  ) {
    return <EmptyState title='No Anchors' />
  }

  const selectedRowSerial = selectedRowData.id

  const allRows = Object.keys(data).map((key) => ({ key, value: data[key].id }))
  const sortedRows = allRows.sort((a, b) => a.value.localeCompare(b.value))

  const allAnchors = Object.keys(data['0']).filter(
    (key) =>
      key !== 'id' &&
      key !== 'index' &&
      key !== 'anchor' &&
      key !== 'positionHealth'
  )
  return (
    <div className='flex flex-col mt-1'>
      <h2>Anchors</h2>
      <div className='flex flex-row items-center'>
        <div className='flex flex-col mr-8 w-52'>
          <select
            className='p-2 z-10 mt-2 w-36 rounded font-semibold text-base'
            value={selectedRowIndex}
            onChange={(e) => setSelectedRowIndex(e.target.value)}
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            {sortedRows.map((item) => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors
            .sort((a, b) => {
              const masterA =
                selectedRowData[a]?.master ||
                hardware.diagnostics.hardwareState.devices[a]?.master
              const masterB =
                selectedRowData[b]?.master ||
                hardware.diagnostics.hardwareState.devices[b]?.master

              return masterB - masterA // Ensure master is rendered first
            })
            .map((anchorKey) => {
              const columnData = selectedRowData[anchorKey] || {}

              return (
                <div
                  key={anchorKey}
                  className='p-1 rounded'
                  style={{
                    border:
                      selectedRowSerial ===
                      hardware.diagnostics.hardwareState.devices[anchorKey]
                        ?.serial
                        ? `5px solid ${sportableColors.colors.sportableBlue}`
                        : `${
                            columnData.color
                              ? `5px solid ${columnData.color}`
                              : `5px solid ${sportableColors.colors.sportableGrey}`
                          }`
                  }}
                >
                  <div className='p-1 flex flex-col items-center'>
                    <h5 className='mb-1'>
                      {columnData.serial ||
                        hardware.diagnostics.hardwareState.devices[anchorKey]
                          ?.serial}
                      {columnData.master ||
                      hardware.diagnostics.hardwareState.devices[anchorKey]
                        ?.master
                        ? '(M)'
                        : ''}
                    </h5>
                    {columnData.count &&
                    columnData.rssi &&
                    columnData.distance ? (
                      <>
                        <p className='text-xs whitespace-nowrap'>{`${columnData.count}Hz`}</p>
                        <p className='text-xs whitespace-nowrap'>{`${columnData.rssi}dBm`}</p>
                        <p className='text-xs whitespace-nowrap'>{`${columnData.distance}m`}</p>
                      </>
                    ) : (
                      <>
                        <p className='text-xs whitespace-nowrap'>{`${columnData.imuLen}`}</p>
                        <p className='text-xs whitespace-nowrap'>{`${columnData.rssi}dBm`}</p>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className='flex flex-row items-center mt-4'>
        <div className='flex flex-col mr-8 w-52'>
          <div
            className='p-2 text-base font-semibold w-32 rounded'
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            Position Health
          </div>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors.map((anchorKey) => {
            const columnData = selectedRowData[anchorKey] || {}
            return (
              <div key={anchorKey} className='p-1'>
                <div
                  className='border p-2 text-center rounded'
                  style={{
                    border: `${
                      columnData.positionHealthColor
                        ? `5px solid ${columnData.positionHealthColor}`
                        : `5px solid ${sportableColors.colors.sportableGrey}`
                    }`
                  }}
                >
                  {columnData.positionHealth || '-'}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* disabling row until confirmation from Loji and Dario as to what they want here */}
      {/* Link Quality Row */}
      {/* <div className='flex flex-row items-center mt-4'>
        <div className='flex flex-col mr-8 w-52'>
          <div
            className='p-2 text-base font-semibold w-32 rounded'
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            Link Quality
          </div>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors.map((anchorKey) => {
            const columnData = selectedRowData[anchorKey] || {}
            return (
              <div key={anchorKey} className='p-1'>
                <div
                  className='border p-2 text-center rounded'
                  style={{
                    border: `${
                      columnData.color
                        ? `5px solid ${columnData.color}`
                        : `5px solid ${sportableColors.colors.sportableGrey}`
                    }`
                  }}
                >
                  {columnData.positionQuality || '-'}
                </div>
              </div>
            )
          })}
        </div>
      </div> */}

      {/* Daemon Stats Row
      <div className='flex flex-row items-center mt-4'>
        <div className='flex flex-col mr-8 w-52'>
          <div
            className='p-2 text-base font-semibold w-32 rounded'
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            Daemon Stats
          </div>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors.map((anchorKey) => {
            const columnData = selectedRowData[anchorKey] || {}
            return (
              <div key={anchorKey} className='p-1'>
                <div
                  className='border p-2 text-center rounded'
                  style={{
                    border: `${
                      columnData.color
                        ? `5px solid ${columnData.color}`
                        : `5px solid ${sportableColors.colors.sportableGrey}`
                    }`
                  }}
                >
                  {columnData.deamonStats
                    ? Object.keys(columnData.deamonStats).map((key) => (
                        <div key={key} className='text-xs whitespace-nowrap'>
                          {`${abbreviateKey(key)}: ${
                            columnData.deamonStats[key].percentage ||
                            columnData.deamonStats[key].time
                          }`}
                        </div>
                      ))
                    : '-'}
                </div>
              </div>
            )
          })}
        </div>
      </div> */}
    </div>
  )
}

const BallRow = ({ data, hardware }) => {
  const [isOnPitch, setIsOnPitch] = useState(false)

  if (hardware.diagnostics.status.online.balls.length === 0) {
    return <EmptyState title='No Ball Tags' />
  }

  const filteredData = data.filter((ball) => {
    if (isOnPitch) {
      return ball?.location?.inPitch
    }
    return true
  })

  return (
    <div className='flex flex-row mt-14'>
      <div className='flex flex-col mr-8 w-52'>
        <h2>Balls</h2>
        <div className='flex mt-4 z-10 rounded'>
          <div
            onClick={() => setIsOnPitch(false)}
            className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-l  ${
              isOnPitch ? 'hover:bg-gray-300' : 'text-white'
            }`}
            style={{
              backgroundColor: isOnPitch
                ? '#E5E7EB'
                : sportableColors.colors.sportableBlue
            }}
          >
            ALL
          </div>
          <div
            onClick={() => setIsOnPitch(true)}
            className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-r  ${
              isOnPitch ? 'text-white' : 'hover:bg-gray-300'
            }`}
            style={{
              backgroundColor: isOnPitch
                ? sportableColors.colors.sportableBlue
                : '#E5E7EB'
            }}
          >
            ON PITCH
          </div>
        </div>
      </div>
      <div className='grid grid-cols-16 gap-1 w-full'>
        {filteredData.map((ball, index) => (
          <div key={index} className='p-1'>
            <BallItem ball={ball} isOnPitch={isOnPitch} />
          </div>
        ))}
      </div>
    </div>
  )
}

const BallItem = ({ ball, isOnPitch }) => {
  const getBackgroundStyles = (ball) => {
    if (ball?.location?.ballInPlay && ball?.location?.inPitch) {
      return 'rgba(71, 206, 71, 0.4)'
    }

    if (isOnPitch || ball?.location?.inPitch) {
      return `${sportableColors.colors.sportableBlueLight}`
    }

    return ``
  }

  return (
    <div
      className='p-1 flex flex-col items-center rounded'
      style={{
        // border: getBorderStyles(ball),
        border: `5px solid ${ball.color}`,
        backgroundColor: getBackgroundStyles(ball)
      }}
    >
      <h5 className={`mb-2 ${'font-bold'}`}>{ball.encodedId}</h5>

      <p
        className={`${
          isOnPitch && ball?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {ball?.positionDataRate
          ? `Pos: ${ball.positionDataRate.toFixed(2)} Hz`
          : 'N/A'}
      </p>
      <p
        className={`${
          isOnPitch && ball?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {ball?.measLength ? `Ancs: ${ball?.measLength}` : 'N/A'}
      </p>
      <p
        className={`${
          isOnPitch && ball?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {ball?.imu ? `IMU: ${ball?.imu}Hz` : 'N/A'}
      </p>

      <p
        className={`${
          isOnPitch && ball?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {ball?.acc ? `Acc: ${ball?.acc}` : 'N/A'}
      </p>
    </div>
  )
}

const PlayerTagRow = ({ data, hardware }) => {
  const [isOnPitch, setIsOnPitch] = useState(false)

  if (hardware.diagnostics.status.online.tags.length === 0) {
    return <EmptyState title='No Player Tags' />
  }

  const filteredData = data.filter((tag) => {
    if (isOnPitch) {
      return tag?.location?.inPitch
    }
    return true
  })

  return (
    <div className='flex flex-row mt-14'>
      <div className='flex flex-col mr-8 w-52 h-52'>
        <h2>Tags</h2>
        <div className='flex mt-4 z-10 rounded'>
          <div
            onClick={() => setIsOnPitch(false)}
            className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-l  ${
              isOnPitch ? 'hover:bg-gray-300' : 'text-white'
            }`}
            style={{
              backgroundColor: isOnPitch
                ? '#E5E7EB'
                : sportableColors.colors.sportableBlue
            }}
          >
            ALL
          </div>
          <div
            onClick={() => setIsOnPitch(true)}
            className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-r  ${
              isOnPitch ? 'text-white' : 'hover:bg-gray-300'
            }`}
            style={{
              backgroundColor: isOnPitch
                ? sportableColors.colors.sportableBlue
                : '#E5E7EB'
            }}
          >
            ON PITCH
          </div>
        </div>
      </div>
      <div className='grid grid-cols-16 gap-1 w-full'>
        {filteredData.map((tag, index) => (
          <div
            key={index}
            className='p-1'
            style={{
              gridColumnEnd: 'auto',
              gridRowEnd: 'auto'
            }}
          >
            <PlayerTagItem tag={tag} isOnPitch={isOnPitch} />
          </div>
        ))}
      </div>
    </div>
  )
}

const PlayerTagItem = ({ tag, isOnPitch }) => {
  const getBackgroundStyles = (tag) => {
    if (isOnPitch || tag?.location?.inPitch) {
      return `${sportableColors.colors.sportableBlueLight}`
    }

    return ``
  }

  return (
    <div
      className={`p-1 flex flex-col items-center rounded ${
        isOnPitch && !tag?.location?.inPitch ? 'hidden' : ''
      }`}
      style={{
        // border: getBorderStyles(tag),
        border: `5px solid ${tag.color}`,
        backgroundColor: getBackgroundStyles(tag)
      }}
    >
      <h5 className={`mb-1  ${'font-bold'}`}>{tag.encodedId}</h5>

      <p
        className={`${
          isOnPitch && tag?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {tag?.positionDataRate
          ? `Pos: ${tag.positionDataRate.toFixed(2)} Hz`
          : 'N/A'}
      </p>
      <p
        className={`${
          isOnPitch && tag?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {tag?.measLength ? `Ancs: ${tag?.measLength}` : 'N/A'}
      </p>
      <p
        className={`${
          isOnPitch && tag?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {tag?.imu ? `IMU: ${tag?.imu}` : 'N/A'}
      </p>
      <p
        className={`${
          isOnPitch && tag?.location?.inPitch
            ? 'text-xs whitespace-nowrap font-bold'
            : 'text-xs whitespace-nowrap'
        }`}
      >
        {tag?.acc ? `Acc: ${tag?.acc}` : 'N/A'}
      </p>
    </div>
  )
}

const EmptyState = ({ title }) => (
  <div className='flex flex-row mt-12 items-center justify-center h-32'>
    <p className='text-xl'>{title}</p>
  </div>
)
